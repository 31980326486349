import { createRoot, Root } from "react-dom/client";
import { AppRoot } from "../../components/common/app_root";
import { ResourcePermission } from "../../models/resource_permission";
import { ConfiguredKpiIndexPage } from "./configured_kpi_index_page";
import React from "react";
const configuredKpiIndexRoot: Root[] = [];
/**
 * Initialize react component ConfiguredKpiIndexPage within all elements with data-toggle="configured-kpi-index".
 * State is expected to be in JSON format.
 */
export function initializeConfiguredKpiIndexPage(): void {
  $('[data-toggle="configured-kpi-index"]').each((index, element) => {
    const assetId = $(element).data("asset-id") as string;
    const permissions = $(element).data("permissions") as ResourcePermission;
    const root = createRoot(element);
    configuredKpiIndexRoot.push(root);
    root.render(
      <AppRoot>
        <ConfiguredKpiIndexPage assetId={assetId} permissions={permissions} />
      </AppRoot>,
    );
  });
}
/**
 * Remove react component from all elements with data-toggle="maintenance-form"
 */
export function destroyConfiguredKpiIndexPage(): void {
  configuredKpiIndexRoot.forEach((root) => {
    root.unmount();
  });
  configuredKpiIndexRoot.length = 0;
}
