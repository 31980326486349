/// <reference types="./../definitions/index" />;
import Bluebird, * as Promise from "bluebird";
import {
  clone,
  each,
  find,
  isFunction,
  isNil,
  isNumber,
  isString,
  toInteger,
  uniq,
} from "lodash";
import moment, { Moment } from "moment";
import AssetNotificationChannel from "../channels/asset_notification_channel";
import ContextStateMachineChannel from "../channels/context_state_machine_channel";
import {
  SensorDataCableValue,
  SensorDataChannel,
  SensorEventSubscriber,
} from "../channels/sensor_data_channel";
import { SensorValueType } from "../models/sensor";
import { logger } from "../utils/logger";
import { ItemSelection, ItemSelectionHandler } from "../widgets/item_selection";
import Widget from "../widgets/widget";

export class WidgetController
  implements SensorEventSubscriber, ItemSelectionHandler
{
  static instance: WidgetController = undefined;

  static getInstance() {
    if (isNil(WidgetController.instance)) {
      logger.warn(
        "Widget Controller createInstance must be called before usage.",
      );
      // to avoid circular dependencies in imports, the create instance method must be called externally.
    }
    return WidgetController.instance;
  }

  static createInstance(): WidgetController {
    WidgetController.instance = new WidgetController();
    return WidgetController.instance;
  }

  static runOnLoad(widgetClasses: Array<typeof Widget>) {
    const instance = WidgetController.createInstance();
    instance.initWidgets(widgetClasses);
  }

  static runOnUnload() {
    const instance = WidgetController.getInstance();
    if (!isNil(instance)) {
      instance.unregisterWidgets();
      instance.contextStateMachineChannel.unsubscribeAll();
      instance.sensorDataChannel.unsubscribeAll();
    }
  }

  initialized: boolean = false;

  sensorDataChannel: SensorDataChannel;
  contextStateMachineChannel: ContextStateMachineChannel;
  assetNotificationChannel: AssetNotificationChannel;

  widgets: Widget[];

  constructor() {
    this.sensorDataChannel = new SensorDataChannel();
    this.contextStateMachineChannel = new ContextStateMachineChannel();
    this.assetNotificationChannel = new AssetNotificationChannel();
    this.widgets = [];
  }

  initWidgets(widgetClasses: Array<typeof Widget>) {
    if (this.initialized) {
      return;
    }
    widgetClasses.forEach((widgetClass) => {
      try {
        const widgets = widgetClass.initWidgets(
          widgetClass,
          widgetClass as any,
        );
        each(widgets, (widget) => {
          try {
            this.registerWidget(widget);
          } catch (e) {
            logger.logError(e);
          }
        });
      } catch (e) {
        logger.error(
          `Error during initialization of ${
            (widgetClass as any)?.widgetClassName
          }`,
        );
        logger.error(e);
      }
    });
    this.initialized = true;
  }

  unregisterWidgets() {
    // create shallow copy of widgets to not alter array during iteration
    const widgets = clone(this.widgets);
    each(widgets, (widget) => {
      try {
        this.unregisterWidget(widget);
      } catch (e) {
        logger.logError(e as Error);
      }
    });
  }

  handleSensorValueUpdate(
    attributeKeyId: number,
    sensorId: number,
    value: SensorValueType,
    time: Moment,
    unit?: string,
  ) {}

  /**
   * Finds a widget by id.
   *
   * @param {string | number} id Widget ID
   * @returns {Widget}
   */
  getWidget(id: string | number): Widget {
    let widgetId: string | number;
    if (isString(id)) {
      const cleanedId = id.replace("widget-", "");
      if (!isNaN(parseInt(cleanedId))) {
        widgetId = toInteger(cleanedId);
      } else {
        widgetId = id;
      }
    } else if (isNumber(id)) {
      widgetId = id;
    } else {
      return null;
    }
    return find(this.widgets, (widget) => widget.widgetId == widgetId);
  }

  /**
   * Iterates of all widgets and calls asnyc iterator with each widget.
   * The method waits for each iterator call to complete.
   *
   * @param iterator Async iterator
   */
  forEachWidget(
    iterator: (widget: Widget, index: number) => Promise<any> | any,
  ): Bluebird<any> {
    return Bluebird.map(this.widgets, (widget, index) => {
      const promise = iterator(widget, index) as Promise<any>;
      if (!Bluebird.is(promise)) {
        return;
      }

      return promise.catch((error: Error) => {
        logger.error(`Error updating widget ${widget.widgetId}`);
        logger.logError(error);
      });
    });
  }

  setTimeScope(start: Moment, end: Moment): Bluebird<any> {
    return this.forEachWidget((widget) => widget.setTimeScope(start, end));
  }

  setSamplingRate(value: number, unit: string): Bluebird<any> {
    return this.forEachWidget((widget) => widget.setSamplingRate(value, unit));
  }

  setBeginAtZero(beginAtZero: boolean): Bluebird<any> {
    return this.forEachWidget((widget) => widget.setBeginAtZero(beginAtZero));
  }

  handleItemSelection(itemSelection: ItemSelection<any>): Bluebird<any> {
    return this.forEachWidget((widget) =>
      widget.handleItemSelection(itemSelection),
    );
  }

  private registerWidget(widget: Widget) {
    const sensorIds = uniq(widget.getSensorIdsForUpdate());
    each(sensorIds, (sensorId) => {
      if (!isNil(sensorId)) {
        this.sensorDataChannel.addEventListener(widget, sensorId);
      }
    });
    const contextStateMachineIds = uniq(
      widget.getContextStateMachineIdsForUpdate(),
    );
    each(contextStateMachineIds, (contextStateMachineId) => {
      if (!isNil(contextStateMachineId)) {
        this.contextStateMachineChannel.addEventListener(
          widget,
          contextStateMachineId,
        );
      }
    });

    const assetIdsForNotifications = uniq(
      widget.getAssetIdsForNotificationUpdate(),
    );
    each(assetIdsForNotifications, (assetId) => {
      if (!isNil(assetId)) {
        this.assetNotificationChannel.addEventListener(widget, assetId);
      }
    });

    if (this.widgets.indexOf(widget) === -1) {
      this.widgets.push(widget);
    }
  }

  private unregisterWidget(widget: Widget) {
    try {
      const sensorIds = uniq(widget.getSensorIdsForUpdate());
      each(sensorIds, (sensorId) => {
        if (!isNil(sensorId)) {
          this.sensorDataChannel.removeEventListener(widget, sensorId);
        }
      });
    } catch (e) {
      logger.error(e);
    }

    try {
      const contextStateMachineIds = uniq(
        widget.getContextStateMachineIdsForUpdate(),
      );
      each(contextStateMachineIds, (contextStateMachineId) => {
        if (!isNil(contextStateMachineId)) {
          this.contextStateMachineChannel.unsubscribe(contextStateMachineId);
          this.contextStateMachineChannel.removeEventListener(
            widget,
            contextStateMachineId,
          );
        }
      });
    } catch (e) {
      logger.error(e);
    }

    try {
      const assetIds = widget.getAssetIdsForNotificationUpdate();
      each(assetIds, (assetId) => {
        if (!isNil(assetId)) {
          this.assetNotificationChannel.unsubscribe(assetId);
          this.assetNotificationChannel.removeEventListener(widget, assetId);
        }
      });
    } catch (e) {
      logger.error(e);
    }
    try {
      widget.cleanup();
    } catch (e) {
      logger.error(e);
    }

    // remove widget from array
    const index = this.widgets.indexOf(widget);
    if (index !== -1) {
      this.widgets.splice(index, 1);
    }
  }
}

// export function injectEvent(
//   assetId: number = -1, // -1 for all assets
//   event_id: number = 1234, // example event id
//   time = new Date(),
//   severity_level: string = "info",
// ): void {
//   const subscription = App.cable.subscriptions.subscriptions.find(
//     (s) => s.identifier == '{"channel":"UserNotificationChannel"}',
//   );
//   if (isNil(subscription)) {
//     logger.error("UserNotificationChannel not subscribed");
//     return;
//   }

//   /** @type {*} */
//   const message: EventDataCableValue = {
//     action: "",
//     asset_id: assetId,
//     event_id: 321,
//     message: {
//       asset: {
//         id: assetId,
//         name: {
//           de: "Assetname",
//           en: "Asset Name",
//         },
//       },
//       root_asset: {
//         id: 1,
//         name: {
//           de: "Root Asset",
//           en: "Root Asset",
//         },
//       },
//       message: {
//         de: "Ereignisnachricht",
//         en: "Event Message",
//       },
//       code: "1234",

//       description: {
//         de: "Beschreibung",
//         en: "Description",
//       },
//       event_id: event_id,
//       severity_level: "info",
//       from: time.toISOString(),
//       read_by_user: false,
//       event_type_id: 321,
//       url: "/",
//       name: {
//         de: "Test Ereignis",
//         en: "Test Event",
//       },
//       timestamp: time.toISOString(),
//     },
//   };
// }

export function injectSensorValue(
  sensorId: number,
  value: number | string,
  attribute_key_id?: number,
  unit?: string,
  time = moment(),
): void {
  const channel = WidgetController.getInstance().sensorDataChannel;
  const message: SensorDataCableValue = {
    action: "",
    message: { value: value, time: time.toISOString(), unit: unit },
    sensor_id: sensorId,
    attribute_key_id,
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
  (channel as any).handleDataMessage(message, channel.modelListeners[sensorId]);
}

export function injectSensorValueInTimeInterval(
  sensorId: number,
  attributeKeyId: number,
  intervalSeconds: number,
  value: number | ((secondsSinceStart: number) => number),
  unit?: string,
  endTime?: Moment | string | Date,
) {
  const startTime = moment();

  const theEndTime = endTime ? moment(endTime) : null;
  const timer = setInterval(() => {
    const secondsSinceStart = moment().diff(startTime, "seconds");
    if (theEndTime && moment().isAfter(theEndTime)) {
      clearInterval(timer);
      console.log(
        "Stopping timer for sensor",
        sensorId,
        "at",
        moment().format(),
      );
    }

    const theValue = isFunction(value) ? value(secondsSinceStart) : value;

    injectSensorValue(sensorId, theValue, attributeKeyId, unit);
  }, intervalSeconds * 1000);

  return timer;
}
