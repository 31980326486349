import { Close } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import { isNil } from "lodash";
import * as React from "react";

import { MaterialUiDatePicker } from "../../common/date_picker";

export interface MeasurementFilter {
  from?: Date;
  to?: Date;
}
export interface MeasurementFilterProps {
  filter: MeasurementFilter;
  onFilterChange: (filter: MeasurementFilter) => void;
}

export const MeasurementFilterView: React.FunctionComponent<
  MeasurementFilterProps
> = ({ filter, onFilterChange }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} lg>
        <Grid container spacing={2}>
          <Grid item xs={6} minWidth={150}>
            <MaterialUiDatePicker
              type="datetime"
              noValueLabel={"---"}
              value={isNil(filter.from) ? null : moment(filter.from)}
              dateFormat="L LT"
              autoApply
              onChange={(date) => {
                onFilterChange({
                  ...filter,
                  from: date?.toDate(),
                });
              }}
              maxDate={isNil(filter.to) ? null : moment(filter.to)}
              label={I18n.t("frontend.from")}
              helperText={I18n.t(
                "frontend.measurements.measurement_filter.from_helper_text",
              )}
              endAdornment={
                <IconButton
                  size="small"
                  onClick={() => {
                    onFilterChange({ ...filter, from: null });
                  }}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
              size="small"
            />
          </Grid>

          <Grid item xs={6} minWidth={150}>
            <MaterialUiDatePicker
              dateFormat="L LT"
              noValueLabel={"---"}
              type="datetime"
              value={isNil(filter.to) ? null : moment(filter.to)}
              label={I18n.t("frontend.to")}
              helperText={I18n.t(
                "frontend.measurements.measurement_filter.to_helper_text",
              )}
              minDate={isNil(filter.from) ? null : moment(filter.from)}
              onChange={(date) => {
                onFilterChange({
                  ...filter,
                  to: date?.toDate(),
                });
              }}
              endAdornment={
                <IconButton
                  size="small"
                  onClick={() => {
                    onFilterChange({ ...filter, to: null });
                  }}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
              size="small"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
