import * as React from "react";

import { useState } from "react";
import { ErrorBoundary } from "../../common/error_boundary";

import { isEmpty } from "lodash";
import { Action } from "../../../actions/actions.types";
import { IDType } from "../../../utils/urls/url_utils";
import { AppContext } from "../../common/app_context/app_context_provider";
import { SialogicDialog } from "../../common/sialogic_dialog";
import { SensorDisplay } from "../../sensors/sensor_display";
import { AssetPropertiesWidget } from "../../widgets/asset_properties_widget";
import { DashboardContext } from "../dashboard_context/dashboard_context";
import { DashboardActionContext } from "./dashboard_action_context";
import { DashboardActionContextType } from "./dashboard_action_context.types";
import { DashboardActionContextProviderProps } from "./dashboard_action_context_provider.types";

/**
 * DashboardActionContextProvider is a React Function Component that provides dashboard actions as a context.
 * It uses the AppContext and DashboardContext to manage the state and actions related to the dashboard.
 *
 * @param {DashboardActionContextProviderProps} props - The properties for the provider component.
 * @param {React.ReactNode} props.children - The child components that will be wrapped by this provider.
 *
 * @returns {JSX.Element} The provider component that wraps its children with the DashboardActionContext.
 *
 * @context {DashboardActionContextType} context - The context value that includes methods to perform actions,
 * add sensors to display, and reset sensors to display.
 *
 * @method performAction - Performs a given action and sets the current action and shown sensors based on the context.
 * @param {Action} action - The action to be performed.
 * @param {any} context - The context in which the action is performed.
 *
 * @method addSensorToDisplay - Adds a sensor to the list of sensors to be displayed.
 * @param {IDType} sensorId - The ID of the sensor to be added.
 *
 * @method resetSensorsToDisplay - Resets the list of sensors to be displayed.
 *
 * @state {Action | null} currentAction - The current action being performed.
 * @state {IDType[]} shownSensors - The list of sensor IDs that are currently shown.
 * @state {boolean} dialogOpen - The state indicating whether the dialog is open or closed.
 *
 * @component SialogicDialog - A dialog component that displays the current action's related content.
 * @component ErrorBoundary - A component that catches JavaScript errors anywhere in their child component tree.
 * @component AssetPropertiesWidget - A widget component that displays asset properties.
 * @component SensorDisplay - A component that displays sensors based on the provided sensor IDs.
 */
export const DashboardActionContextProvider: React.FunctionComponent<
  DashboardActionContextProviderProps
> = ({ children }) => {
  const dashboardCtx = React.useContext(DashboardContext);

  const [currentAction, setCurrentAction] = useState<Action | null>(null);

  const [shownSensors, setShownSensors] = useState<IDType[]>([]);

  // memoized context
  const context = React.useMemo<DashboardActionContextType>(() => {
    return {
      performAction(action, context) {
        if (action && context) {
          setCurrentAction(action);
          if (context?.sensorId) setShownSensors([context.sensorId]);
          setDialogOpen(true);
        }
      },
      addSensorToDisplay(sensorId) {
        setShownSensors((prev) => [...prev, sensorId]);
      },
      resetSensorsToDisplay() {
        setShownSensors([]);
      },
    };
  }, [setCurrentAction]);

  const [dialogOpen, setDialogOpen] = useState(false);
  return (
    <DashboardActionContext.Provider value={context}>
      {children}
      <SialogicDialog
        open={dialogOpen}
        allowFullScreen
        maxWidth="xl"
        onClose={() => {
          setDialogOpen(false);
          setCurrentAction(null);
        }}
      >
        {!dialogOpen ? null : (
          <ErrorBoundary>
            {currentAction.action_type == "asset_properties" && (
              <AssetPropertiesWidget assetId={dashboardCtx.asset?.id} />
            )}
            {currentAction.action_type == "sensor" &&
              !isEmpty(shownSensors) && (
                <SensorDisplay sensorIds={shownSensors} />
              )}
          </ErrorBoundary>
        )}
      </SialogicDialog>
    </DashboardActionContext.Provider>
  );
};
