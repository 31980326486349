/// <reference types="../definitions/index" />;
/// <reference types="../definitions/knight-lab__timelinejs" />;
import * as React from "react";

import { AppRoot } from "../components/common/app_root";
import {
  AssetMaintenanceTimelineWidget as AssetMaintenanceTimelineWidgetComponent,
  AssetMaintenanceTimelineWidgetDefinition,
} from "../components/widgets/asset_maintenance_timeline_widget";
import { AssetMaintenanceTimelineWidgetConfigSerialized } from "./asset_maintenance_timeline_widget.types";
import Widget from "./widget";

export class AssetMaintenanceTimelineWidget extends Widget<AssetMaintenanceTimelineWidgetConfigSerialized> {
  static getDomClassName(): string {
    return "asset-maintenance-timeline-widget";
  }

  static languageLoadPromise: Promise<void>;
  urls: string[];

  protected readMembersFromElement(element: JQuery<HTMLElement>): void {
    super.readMembersFromElement(element);
    const config = element.data(
      "config",
    ) as AssetMaintenanceTimelineWidgetConfigSerialized;
    this.config = config;
  }

  protected initComponent(element: JQuery<HTMLElement>) {
    super.initComponent(element);
    const props =
      AssetMaintenanceTimelineWidgetDefinition.serializedConfigToProps(
        this.config,
      );
    this.root.render(
      <AppRoot>
        <AssetMaintenanceTimelineWidgetComponent {...props} />
      </AppRoot>,
    );
  }

  getSensorIdsForUpdate(): number[] {
    return [];
  }

  cleanup(): void {
    super.cleanup();
  }
}
