import { Box, Grid, Skeleton } from "@mui/material";
import { find, isEqual, isNil, reject } from "lodash";
import * as React from "react";
import { TreeMode } from "../../json_api/tree_items";
import { TreeItem } from "../../models/tree_item";
import { IDType } from "../../utils/urls/url_utils";
import { ItemTree } from "../common/item_tree";
import { useLoadAssetTypeTreeQuery } from "../../queries/asset_type_data";
interface AssetTypeTreeSelectProps {
  label?: string;
  // The asset id whose tree to load
  assetTypeId?: IDType;
  selectable?: boolean;
  // or the preloaded asset tree
  assetTypeTree?: AssetTypeTreeItem[];
  treeMode?: TreeMode;
  selectedIds?: IDType[];
  maxHeight?: number | string;

  multiselect?: boolean;
  itemContent?: (
    item: AssetTypeTreeItem,
    parent?: AssetTypeTreeItem,
  ) => React.ReactElement;
  onSelectionChange?: (selectedIds: IDType[]) => void;
}

export interface AssetTypeTreeItem extends TreeItem {
  icon_url?: string;
  icon?: string;
}
export const AssetTypeTreeSelect: React.FunctionComponent<
  AssetTypeTreeSelectProps
> = ({
  treeMode = "subtree",
  selectedIds = [],
  multiselect = false,
  selectable = true,
  ...props
}) => {
  const [selectedAssetTypeIds, setSelectedAssetTypeIds] = React.useState(
    selectedIds ?? [],
  );

  const { isLoading, data: assetTypeTree } = useLoadAssetTypeTreeQuery({
    variables: {
      assetTypeId: props.assetTypeId,
      treeMode: treeMode,
    },
    enabled: !isNil(props.assetTypeId),
  });

  React.useEffect(() => {
    if (
      !isLoading &&
      !isEqual(selectedIds, selectedAssetTypeIds) &&
      !isNil(props.onSelectionChange)
    ) {
      props.onSelectionChange(selectedAssetTypeIds);
    }
  }, [selectedAssetTypeIds]);

  return isLoading ? (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={40} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={40} />
      </Grid>
    </Grid>
  ) : (
    <Box overflow="auto" maxHeight={props.maxHeight}>
      <ItemTree
        multiselect={multiselect}
        roots={assetTypeTree}
        checkable={selectable}
        expandAll={true}
        checkedItemIds={selectedAssetTypeIds}
        onItemChecked={(item) => {
          if (multiselect) {
            setSelectedAssetTypeIds([...selectedAssetTypeIds, item.id]);
          } else {
            setSelectedAssetTypeIds([item.id]);
          }
        }}
        onItemClicked={(item) => {
          if (find(selectedAssetTypeIds, item)) {
            setSelectedAssetTypeIds(reject(selectedAssetTypeIds, item.id));
          } else {
            setSelectedAssetTypeIds([...selectedAssetTypeIds, item.id]);
          }
        }}
        onItemUnchecked={(item) => {
          setSelectedAssetTypeIds(reject(selectedAssetTypeIds, item.id));
        }}
        itemContent={props.itemContent}
      />
    </Box>
  );
};
