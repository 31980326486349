import EditIcon from "@mui/icons-material/Edit";
import CaretUp from "@mui/icons-material/ExpandLess";
import CaretDown from "@mui/icons-material/ExpandMore";
import ShowIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { NumericDictionary, find, isEmpty, isNil, map } from "lodash";
import moment from "moment";
import * as React from "react";
import {
  MeasurementJSONApiAttributes,
  MeasurementJsonObject,
} from "../../../json_api/measurement";
import { MeasurementPlanJSONApiAttributes } from "../../../json_api/measurement_plan";
import { MeasurementTypeJsonAttributes } from "../../../json_api/measurement_type";
import { ActiveStorageStoredFile } from "../../../models/active_storage_stored_file";
import { MeasurementValueAttributes } from "../../../models/measurement";
import { MeasurementCategory } from "../../../models/measurement_category";
import { MeasurementTypeDiagramType } from "../../../models/measurement_type";
import {
  MeasurementValueDefinitionAttributes,
  mvdRangeString,
} from "../../../models/measurement_value_definition";
import { unitDisplayString } from "../../../utils/unit_conversion";
import {
  assetMeasurementPlanMeasurementPath,
  editMeasurementPath,
  measurementPath,
} from "../../../utils/urls";
import { getValueString } from "../../../utils/value_format";
import { AttachmentList } from "../../common/attachment_list";
import { IBox, IBoxContent, IBoxTitle } from "../../common/ibox";
import { JSONSchemaValueRow } from "../../common/json_schema_value_row";
import { MeasurementGraph } from "./measurement_graph";
import { MeasurementValuesSection } from "./measurement_values_section";
import { use } from "chai";
import { ReferenceMeasurementValue } from "../utils/measurement_reference_eval";

/**
 * Properties for the `MeasurementItem` component.
 *
 * @interface MeasurementItemProperties
 *
 * @property {MeasurementJSONApiAttributes} measurement - The measurement data to be displayed.
 * @property {MeasurementValueAttributes[]} measurementValues - A list of measurement values associated with the measurement.
 * @property {MeasurementPlanJSONApiAttributes} [measurementPlan] - Optional measurement plan details.
 * @property {MeasurementTypeJsonAttributes} [measurementType] - Optional measurement type details.
 * @property {string} [heading] - Optional heading text for the measurement item.
 * @property {MeasurementValueDefinitionAttributes[]} orderedMeasurementValueDefinitions - Definitions of measurement values in a specific order.
 * @property {NumericDictionary<MeasurementValueDefinitionAttributes>} [measurementValueDefinitionsMap] - Optional map of measurement value definitions by ID.
 * @property {NumericDictionary<MeasurementCategory>} measurementCategoryById - A dictionary of measurement categories indexed by ID.
 * @property {ReferenceMeasurementValue[]} referenceMeasurementValues - A list of reference measurement values.
 * @property {boolean} [collapsed] - Optional flag indicating whether the item is collapsed.
 * @property {boolean} [printMode] - Optional flag indicating whether the item is in print mode.
 * @property {boolean} [showTools] - Optional flag indicating whether tool buttons should be displayed.
 * @property {React.ReactElement | React.ReactElement[]} [toolButtons] - Optional custom tool buttons to display.
 * @property {(collapsed: boolean) => void} [onToggle] - Optional callback triggered when the collapsed state changes.
 * @property {(file: ActiveStorageStoredFile) => void} [onShowFilePreview] - Optional callback triggered to show a file preview.
 * @property {MeasurementTypeDiagramType} [diagramType] - Optional type of diagram associated with the measurement.
 */
interface MeasurementItemProperties {
  measurement: MeasurementJSONApiAttributes;
  measurementValues: MeasurementValueAttributes[];
  measurementPlan?: MeasurementPlanJSONApiAttributes;
  measurementType?: MeasurementTypeJsonAttributes;
  heading?: string;
  orderedMeasurementValueDefinitions: MeasurementValueDefinitionAttributes[];
  measurementValueDefinitionsMap?: NumericDictionary<MeasurementValueDefinitionAttributes>;
  measurementCategoryById: NumericDictionary<MeasurementCategory>;
  referenceMeasurementValues?: ReferenceMeasurementValue[];

  collapsed?: boolean;
  printMode?: boolean;
  showTools?: boolean;

  toolButtons?: React.ReactElement | React.ReactElement[];

  onToggle?: (collapsed: boolean) => void;

  onShowFilePreview?: (file: ActiveStorageStoredFile) => void;
  diagramType?: MeasurementTypeDiagramType;
}

const MeasurementValueDisplay: React.FunctionComponent<{
  vals: MeasurementValueAttributes[];
  mvd: MeasurementValueDefinitionAttributes;
}> = ({ mvd, vals }) => {
  const value =
    find(vals, (value) => value.measurement_value_definition_id == mvd.id) ??
    ({
      title: "---",
      unit: "",
    } as unknown as MeasurementValueAttributes);
  return (
    <Typography variant="body1">
      {getValueString(value.value)} {unitDisplayString(mvd.unit)}
      {isNil(value.percent) || mvd.unit == "%" ? null : (
        <>
          <br />
          {getValueString(value.percent)} %
        </>
      )}
    </Typography>
  );
};
export const MeasurementItem: React.FunctionComponent<
  MeasurementItemProperties
> = (props) => {
  const metaInfoCols = isEmpty(props.measurement.meta_info) ? 0 : 4;

  const showDiagram =
    props.measurementValues && props.measurementValues?.length > 0;
  const isPrint = useMediaQuery("print");
  return (
    <IBox>
      <IBoxTitle
        sx={{ paddingBottom: 0 }}
        tools={
          <Box displayPrint="none">
            {props.showTools === false ? null : (
              <>
                <IconButton
                  size="small"
                  aria-label={I18n.t("frontend.edit")}
                  title={I18n.t("frontend.edit")}
                  href={editMeasurementPath(props.measurement.id)}
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>

                <IconButton
                  size="small"
                  aria-label={I18n.t("frontend.show")}
                  title={I18n.t("frontend.show")}
                  href={measurementPath(props.measurement.id)}
                >
                  <ShowIcon fontSize="inherit" />
                </IconButton>
                <IconButton
                  className="ml-2"
                  size="small"
                  onClick={(e) => {
                    props.onToggle(!props.collapsed);
                  }}
                >
                  {props.collapsed ? (
                    <CaretDown fontSize="inherit" />
                  ) : (
                    <CaretUp fontSize="inherit" />
                  )}
                </IconButton>
              </>
            )}
            {props.toolButtons}
          </Box>
        }
      >
        <Tooltip
          title={
            isNil(props.heading)
              ? I18n.t("frontend.measurement_list_item.show_measurement")
              : props.heading
          }
        >
          <Link
            href={assetMeasurementPlanMeasurementPath(
              props.measurementPlan?.asset_id,
              props.measurement.measurement_plan_id,
              props.measurement.id,
              "html",
            )}
          >
            <Typography variant="h5" className="auto-hyphen">
              {isNil(props.heading)
                ? I18n.t("frontend.measurement_list_item.heading", {
                    date: moment(props.measurement.time).format("L LT"),
                  })
                : props.heading}
            </Typography>
          </Link>
        </Tooltip>
      </IBoxTitle>
      <IBoxContent className="pt-1 pb-1">
        <Grid container>
          <Grid item container xs={12} className="align-items-center">
            <Grid item container xs={12} lg={10}>
              {map(props.orderedMeasurementValueDefinitions, (mvd, index) => (
                <Grid item xs={2} lg={1} key={`mvd-${mvd.id}`}>
                  <Typography variant="body1" fontWeight="bold">
                    {mvd.title}
                  </Typography>
                  <Typography variant="caption">
                    {mvdRangeString(mvd, props.measurementType?.interval_unit)}
                  </Typography>
                  <MeasurementValueDisplay
                    vals={props.measurementValues}
                    mvd={mvd}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Collapse
              in={!props.collapsed || props.printMode}
              sx={{ displayPrint: "block" }}
            >
              <hr />
              <Grid container spacing={2}>
                <Grid item container xs={12} className="mt-3">
                  <Grid item xs={12} sm={3} lg={2}>
                    <Typography variant="body1" fontWeight="bold">
                      {I18n.t("activerecord.attributes.measurement.created_by")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={9} lg={10} pl={2}>
                    <span>{props.measurement.created_by_name}</span>
                  </Grid>
                </Grid>
                {isEmpty(props.measurement.note) ? null : (
                  <>
                    <hr />
                    <Grid item container xs={12}>
                      <Grid item xs={12} sm={3} lg={2}>
                        <Typography variant="body1" fontWeight="bold">
                          {I18n.t("activerecord.attributes.measurement.note")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={9} lg={10} pl={2}>
                        <span>{props.measurement.note}</span>
                      </Grid>
                    </Grid>
                  </>
                )}
                {isEmpty(props.measurement.meta_info) ? null : (
                  <>
                    <hr />
                    <Grid item xs={12} className="mt-3">
                      <Typography variant="h5">
                        {I18n.t(
                          "frontend.measurement_list_item.meta_info_heading",
                        )}
                      </Typography>
                    </Grid>

                    <JSONSchemaValueRow
                      schema={props.measurementPlan.meta_info_schema}
                      jsonValues={props.measurement.meta_info}
                    />
                  </>
                )}

                {
                  <>
                    <hr />
                    <Grid item xs={12} className="mt-3">
                      <Typography variant="h5">
                        {I18n.t(
                          "frontend.measurement_list_item.values_heading",
                        )}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={showDiagram ? (isPrint ? 12 : 6) : 12}
                    >
                      <MeasurementValuesSection
                        {...props}
                        mvdIntervalUnit={props.measurementType.interval_unit}
                        showPercentage={
                          props.measurementPlan.measurement_type_type ===
                            "distribution_measurement_type" &&
                          props.measurementType.interval_unit !== "%"
                        }
                      />
                    </Grid>
                    {showDiagram ? (
                      <Grid
                        item
                        xs={12}
                        lg={showDiagram ? (isPrint ? 12 : 6) : 12}
                      >
                        <Box p={3}>
                          <MeasurementGraph
                            measurement={
                              props.measurement as MeasurementJsonObject
                            }
                            measurementValueDefinitions={
                              props.orderedMeasurementValueDefinitions
                            }
                            referenceMeasurementValues={
                              props.referenceMeasurementValues
                            }
                            diagramType={props.diagramType}
                          />
                        </Box>
                      </Grid>
                    ) : null}
                  </>
                }
                <Grid item xs={12}>
                  {filesRow(props)}
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
      </IBoxContent>
    </IBox>
  );
};

function filesRow(props: MeasurementItemProperties): React.ReactElement {
  if (isEmpty(props.measurement.attachments)) return null;
  return (
    <Grid container spacing={4}>
      <hr />
      <Grid item xs={12} className="mt-3">
        <Typography variant="h5">
          {I18n.t("frontend.measurement_list_item.files_heading")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <AttachmentList
          attachments={props.measurement.attachments}
          onShow={props.onShowFilePreview}
        />
      </Grid>
    </Grid>
  );
}
