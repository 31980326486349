import { Cancel, Check, Link } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Fab,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";
import * as JSONAPI from "jsonapi-typescript";
import { defaultTo, each, isEmpty, isNil, last } from "lodash";
import React, { useCallback, useContext, useState } from "react";
import { translatedAttributeName } from "../../i18n/translation_helper";
import { DeviceJSONObject } from "../../json_api/device";
import { jsonApiSingleResourceToFlatObject } from "../../json_api/jsonapi_tools";
import { Device } from "../../models/device";
import {
  useCreateDevice,
  useLoadDeviceQuery,
  useUpdateDevice,
} from "../../queries/device_form_data";
import {
  asset_devices_path,
  device_pairing_device_path,
  devices_path,
} from "../../routes";
import { HttpError } from "../../utils/jquery_helper";
import { logger } from "../../utils/logger";
import { redirectTo } from "../../utils/redirection";
import { IDType } from "../../utils/urls/url_utils";
import { AppContext } from "../common/app_context/app_context_provider";
import { SialogicContext } from "../common/app_context/app_context_provider.types";
import { FixedBottomArea } from "../common/fixed_bottom_area";
import { FloatingButtons } from "../common/floating_buttons";
import { LoadingWrapper } from "../common/loading_wrapper";

type RequestMode = "delete" | "update" | "create";
type OperationMode = "new" | "edit" | "show";

export interface DeviceFormProps {
  onSave?: (device: DeviceJSONObject) => void;
  deviceId?: IDType;
  onCancel?: () => void;
  buttonMode?: "global" | "card";
  readOnly?: boolean;
  canEdit?: boolean;
  mode?: OperationMode;
  assetId?: IDType;
  organizationId?: IDType;
}

type DeviceFormErrors = Partial<Record<keyof DeviceJSONObject, string>>;

function validate(
  device: DeviceJSONObject,
  deviceErrors: DeviceFormErrors = {},
): DeviceFormErrors {
  const nameError = device?.name ? null : I18n.t("errors.messages.blank");

  const uidError = device?.uid ? null : I18n.t("errors.messages.blank");

  if (nameError === null) {
    delete deviceErrors.name;
  } else {
    deviceErrors.name = nameError;
  }
  if (uidError === null) {
    delete deviceErrors.uid;
  } else {
    deviceErrors.uid = uidError;
  }
  return deviceErrors;
}

export const DeviceForm: React.FC<DeviceFormProps> = (props) => {
  const context = useContext(AppContext);
  const readOnly = props.readOnly || false;
  let operationMode = props.mode;
  if (isNil(props.mode)) {
    if (isNil(props.deviceId)) {
      operationMode = "new";
    } else {
      operationMode = props.readOnly === true ? "show" : "edit";
    }
  }

  const [device, setDevice] = useState<DeviceJSONObject>({
    device_id: props.deviceId,
    pairing_start: "",
    pairing_end: "",
    name: "",
    uid: "",
    description: "",
    hw_ver: "",
    sw_ver: "",
    os_ver: "",
    middleware_ver: "",
    enabled: true,
  });
  const [deviceErrors, setDeviceErrors] = useState<DeviceFormErrors>({});
  const [isProcessing, setIsProcessing] = useState(false);

  const { data: loadedDevice, isLoading: isLoadingDevice } = useLoadDeviceQuery(
    {
      variables: { id: props.deviceId, include: [] },
      enabled: operationMode !== "new",
    },
  );
  React.useEffect(() => {
    if (loadedDevice) {
      setDevice(loadedDevice);
    }
  }, [loadedDevice]);

  const {
    mutateAsync: createDevice,
    isPending: createPending,
    error: createError,
  } = useCreateDevice();

  const {
    mutateAsync: updateDevice,
    isPending: updatePending,
    error: updateError,
  } = useUpdateDevice();

  React.useEffect(() => {
    setIsProcessing(createPending || updatePending);
  }, [createPending, updatePending]);

  React.useEffect(() => {
    if (!createError) {
      return;
    }
    handleRequestError(createError as HttpError);
  }, [createError]);

  React.useEffect(() => {
    if (!updateError) {
      return;
    }
    handleRequestError(updateError as HttpError);
  }, [updateError]);

  const handleRequestError = useCallback(
    (err: HttpError) => {
      const errors: DeviceFormErrors = {};

      void toasts.error(I18n.t("base.error"), I18n.t("base.error"));

      each(
        (err.request?.responseJSON as JSONAPI.DocWithErrors)?.errors,
        (error) => {
          logger.error(error);
          const attrWithError = error?.source?.pointer as string;
          const aName = last(attrWithError?.split("/")) as keyof Device;
          if (!isNil(aName)) {
            errors[aName] = error.detail;
          }
        },
      );
      setDeviceErrors(errors);
    },
    [updateDevice, createDevice, validate],
  );

  const submit = useCallback(() => {
    let promise: Promise<JSONAPI.SingleResourceDoc<string, DeviceJSONObject>>;
    let mode: RequestMode;
    if (!device.id) {
      mode = "create";
      promise = createDevice({
        device: { ...device, asset_id: props.assetId },
      });
    } else {
      mode = "update";
      promise = updateDevice({
        device,
      });
    }
    void promise.then((res) => {
      handleRequestSuccess(
        mode,
        res,
        props.assetId,
        setDevice,
        props.onSave,
        context,
      );
    });
  }, [device]);

  React.useEffect(() => {
    if (device) {
      setDeviceErrors(validate(device));
    }
  }, [device]);

  return (
    <Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={
              device?.id
                ? I18n.t("frontend.devices.device_edit_form.heading", {
                    device: device.name,
                  })
                : I18n.t("frontend.devices.device_edit_form.heading_new")
            }
          />
          <CardContent>
            <Box padding={2}>
              <LoadingWrapper loading={isProcessing || isLoadingDevice}>
                {isProcessing ? null : (
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        label={translatedAttributeName("device", "name")}
                        helperText={deviceErrors.name}
                        value={device.name || ""}
                        onChange={(e) => {
                          const newDevice = {
                            ...device,
                            name: e.target.value,
                          };
                          setDevice(newDevice);
                        }}
                        inputProps={{ readOnly }}
                        error={
                          !isEmpty(deviceErrors?.name) || isEmpty(device.name)
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        label={translatedAttributeName("device", "uid")}
                        helperText={deviceErrors.uid}
                        value={device.uid || ""}
                        onChange={(e) => {
                          setDevice({
                            ...device,
                            uid: e.target.value,
                          });
                        }}
                        inputProps={{ readOnly }}
                        error={
                          !isEmpty(deviceErrors?.uid) || isEmpty(device.uid)
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label={translatedAttributeName("device", "description")}
                        helperText={deviceErrors.description}
                        value={device.description || ""}
                        onChange={(e) => {
                          setDevice({
                            ...device,
                            description: e.target.value,
                          });
                        }}
                        inputProps={{ readOnly }}
                        error={!isEmpty(deviceErrors.description)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        value={device.hw_ver || ""}
                        helperText={deviceErrors.hw_ver}
                        label={translatedAttributeName("device", "hw_ver")}
                        onChange={(e) => {
                          setDevice({
                            ...device,
                            hw_ver: e.target.value,
                          });
                        }}
                        inputProps={{ readOnly }}
                        error={!isEmpty(deviceErrors.hw_ver)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        value={device.sw_ver || ""}
                        helperText={deviceErrors.sw_ver}
                        label={translatedAttributeName("device", "sw_ver")}
                        onChange={(e) => {
                          setDevice({
                            ...device,
                            sw_ver: e.target.value,
                          });
                        }}
                        inputProps={{ readOnly }}
                        error={!isEmpty(deviceErrors.sw_ver)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        value={device.os_ver || ""}
                        label={translatedAttributeName("device", "os_ver")}
                        helperText={deviceErrors.os_ver}
                        onChange={(e) => {
                          setDevice({
                            ...device,
                            os_ver: e.target.value,
                          });
                        }}
                        inputProps={{ readOnly }}
                        error={!isEmpty(deviceErrors.os_ver)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        value={device.middleware_ver || ""}
                        helperText={deviceErrors.middleware_ver}
                        label={translatedAttributeName(
                          "device",
                          "middleware_ver",
                        )}
                        onChange={(e) => {
                          setDevice({
                            ...device,
                            middleware_ver: e.target.value,
                          });
                        }}
                        inputProps={{ readOnly }}
                        error={!isEmpty(deviceErrors.middleware_ver)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl>
                        <FormControlLabel
                          label={I18n.t("base.enabled")}
                          control={
                            <Checkbox
                              checked={
                                isNil(device.enabled) ? true : device.enabled
                              }
                              onChange={(e) => {
                                setDevice({
                                  ...device,
                                  enabled: e.target.checked,
                                });
                              }}
                              inputProps={{ readOnly }}
                            />
                          }
                        />

                        <FormHelperText>
                          {I18n.t(
                            "frontend.devices.device_edit_form.enabled_helper",
                          )}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
              </LoadingWrapper>
            </Box>
          </CardContent>
          {props.buttonMode != "card" ? null : (
            <CardActions>
              {props.mode != "show" ? (
                <Button
                  color="primary"
                  disabled={isProcessing}
                  startIcon={<Check />}
                  onClick={() => {
                    void submit();
                  }}
                >
                  {I18n.t("frontend.save")}
                </Button>
              ) : null}
              <Button
                disabled={isProcessing}
                startIcon={<Cancel />}
                onClick={() => {
                  if (props.onCancel) {
                    props.onCancel();
                  } else {
                    redirectTo("back");
                  }
                }}
              >
                {I18n.t("frontend.cancel")}
              </Button>
            </CardActions>
          )}
          {props.buttonMode == "card" ? null : (
            <FixedBottomArea id="fixed-bottom-area">
              <FloatingButtons
                isProcessing={isProcessing}
                onSubmit={() => {
                  void submit();
                }}
                onCancel={() => {
                  if (props.onCancel) {
                    props.onCancel();
                  } else {
                    redirectTo("back");
                  }
                }}
                disableSave={isProcessing || !isEmpty(deviceErrors)}
                showScrollToTopBtn={true}
                saveTitle={I18n.t("frontend.organizations.form.submit_title")}
              >
                {device?.id && (
                  <Fab
                    size="medium"
                    color="default"
                    aria-label="pairing"
                    title={I18n.t("frontend.devices.device_edit_form.pairing")}
                    href={device_pairing_device_path(device.id)}
                  >
                    <Link />
                  </Fab>
                )}
              </FloatingButtons>
            </FixedBottomArea>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

function handleRequestSuccess(
  mode: RequestMode,
  resultData: JSONAPI.SingleResourceDoc<string, DeviceJSONObject>,
  assetId: IDType,
  setDevice: (device: DeviceJSONObject) => void,
  onSuccess: (device: DeviceJSONObject) => void,
  context: SialogicContext,
) {
  if (mode === "create") {
    void toasts.success(I18n.t("base.successfully_created"));
  } else {
    void toasts.success(I18n.t("base.successfully_updated"));
  }

  const device = jsonApiSingleResourceToFlatObject(resultData);
  setDevice(device);

  if (!isNil(onSuccess)) {
    onSuccess(device);
  } else {
    redirectTo(
      defaultTo(
        context.referrer,
        assetId ? asset_devices_path(assetId) : devices_path(),
      ),
    );
  }
}
