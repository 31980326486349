import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { isEmpty, merge } from "lodash";
import * as React from "react";
import { loadAsset } from "../../json_api/asset";
import { AssetInfoWidgetConfigSerialized } from "../../widgets/asset_info_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import { AttributeRow } from "../common/attribute_row";
import { UseQuery } from "../common/use_query";
import { OrganizationCard } from "../organizations/organization_card";
import { AssetInfoWidgetProps } from "./asset_info_widget.types";
import { SialogicWidgetDefinition } from "./sialogic_widget_component";
import { WidgetBox } from "./widget_box";
import { useLoadAssetQuery } from "../../queries/asset_data";

export const AssetInfoWidget: React.FC<AssetInfoWidgetProps> = (props) => {
  const { isLoading, data: asset } = useLoadAssetQuery({
    variables: {
      id: props.assetId,
      includes: ["asset_type", "operator", "manufacturer"],
    },
  });
  return (
    <WidgetBox
      {...props}
      title={I18n.t("frontend.widgets.asset_info_widget.title")}
    >
      <Box m={1}>
        {isLoading ? (
          <Skeleton height={200} />
        ) : (
          <Grid container spacing={1}>
            <AttributeRow
              attributeName={I18n.t("activerecord.attributes.asset.name")}
              value={asset?.name}
              divider
              dense
            />
            <AttributeRow
              attributeName={I18n.t("activerecord.attributes.asset.asset_type")}
              value={asset?.asset_type_name}
              divider
              dense
            />
            {isEmpty(asset?.serial) ? null : (
              <AttributeRow
                attributeName={I18n.t("activerecord.attributes.asset.serial")}
                value={asset?.serial}
                divider
                dense
              />
            )}
            {asset?.product_model_name ? (
              <AttributeRow
                attributeName={I18n.t(
                  "activerecord.attributes.asset.product_model",
                )}
                value={asset?.product_model_name}
                divider
                dense
              />
            ) : null}
            {asset?.manufacturer?.name ? (
              <AttributeRow
                attributeName={I18n.t(
                  "activerecord.attributes.asset.manufacturer",
                )}
                value={asset?.manufacturer.name}
                divider
                dense
              />
            ) : null}
            {asset?.operator ? (
              <Grid item container xs={12}>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    {I18n.t("activerecord.attributes.asset.operator")}
                  </Typography>
                </Grid>
                <Grid item xs={12} container justifyContent="center">
                  <Grid item xs={10}>
                    <OrganizationCard organization={asset?.operator} />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        )}
      </Box>
    </WidgetBox>
  );
};

function serializedConfigToProps(
  config: AssetInfoWidgetConfigSerialized,
): AssetInfoWidgetProps {
  return merge(widgetBoxPropsFromSerializedConfig(config), {
    assetId: config.asset_id,
    assetType: config.asset_type,
    assetTypeId: config.asset_type_id,
  });
}

export const AssetInfoWidgetDefinition: SialogicWidgetDefinition<
  typeof AssetInfoWidget,
  typeof serializedConfigToProps
> = {
  Component: AssetInfoWidget,
  serializedConfigToProps: serializedConfigToProps,
};
