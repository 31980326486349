import * as JSON from "json-typescript";
import * as JSONAPI from "jsonapi-typescript";
import { User } from "../models/user";
import { Widget } from "../models/widget";

export const WIDGET_JSONAPI_RESOURCE_TYPE = "widgets";

export interface WidgetJSONObject extends Widget, JSON.Object {}

export type WidgetJSONAPIAttributes =
  JSONAPI.AttributesObject<WidgetJSONObject>;
