import { each, isNil, toString } from "lodash";
import { Moment } from "moment";
import moment from "../initializers/moment";

import { SensorValueType } from "../models/sensor";
import {
  EventSubscriberBase,
  EventSubscription,
  ModelDataChannel,
} from "./model_data_channel";
import { logger } from "../utils/logger";

export interface SensorDataCableValue {
  action: string;
  attribute_key_id: number;
  sensor_id: number;
  message: SensorValueDataMessage;
}

export interface SensorValueDataMessage {
  time: string;
  unit: string;
  value: SensorValueType;
}

export interface SensorEventSubscriber extends EventSubscriberBase {
  handleSensorValueUpdate(
    attributeKeyId: number,
    sensorId: number,
    value: SensorValueType,
    time: Moment,
    unit?: string,
  ): void;
}

const WILDCARD_ATTRIBUTE_ID = -1;
export class SensorDataChannel extends ModelDataChannel<
  SensorEventSubscriber,
  SensorDataCableValue
> {
  protected getChannelNameWithParams(
    modelId: number,
  ): string | ActionCable.ChannelNameWithParams {
    return {
      channel: "SensorDataChannel",
      sensor_id: toString(modelId),
    };
  }

  public subscribe(modelId: number) {
    logger.debug("Subscribing to sensor data channel", modelId);
    super.subscribe(modelId);
  }
  protected handleDataMessage(
    data: SensorDataCableValue,
    listeners: EventSubscription<SensorEventSubscriber>[],
  ): void {
    if (isNil(listeners)) return;

    each(listeners, (listener) => {
      listener.f.handleSensorValueUpdate(
        data.attribute_key_id,
        data.sensor_id,
        data.message.value,
        moment(data.message.time),
        data.message.unit,
      );
    });
  }
}

export default SensorDataChannel;
