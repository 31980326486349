import { GridColDef } from "@mui/x-data-grid";
import { AssetJSONObject, AssetStateInfo } from "../../json_api/asset";
import {
  defaultTo,
  each,
  find,
  isEmpty,
  isNil,
  sortBy,
  toInteger,
  uniqBy,
  upperFirst,
} from "lodash";
import { StateContext } from "../../models/state_context";
import { StateWidget } from "./state_widget";
import * as React from "react";
import {
  generateLinkForItemActionRoute,
  ItemAction,
} from "../../models/item_action";
import { IDType } from "../../utils/urls/url_utils";
import { ItemActionButton } from "../common/item_action_button";
import { RequestMethod } from "../../utils/jquery_helper";

export const addStateContextColumns = (
  columns: GridColDef<AssetJSONObject>[],
  width: number,
  stateContexts: StateContext[],
): GridColDef<AssetJSONObject>[] => {
  if (isEmpty(stateContexts)) {
    return columns;
  } else {
    each(
      // only uniq columns und sorted by name
      sortBy(
        uniqBy(stateContexts, (s) => s.id),
        (s) => s.name,
      ),
      (ctx) => {
        columns.push({
          field: `state_${ctx.id}`,
          display: "flex",
          headerAlign: "center",
          align: "center",
          width,
          headerName: upperFirst(ctx.name),
          valueGetter: (value, row) => {
            const assetStates = row.asset_states as Record<
              string,
              AssetStateInfo
            >;
            if (isNil(assetStates)) return null;
            const stateInfo: AssetStateInfo = assetStates[ctx.identifier];
            return stateInfo?.name;
          },
          renderCell: (params) => {
            const assetStates = params.row.asset_states as Record<
              string,
              AssetStateInfo
            >;

            if (isNil(assetStates)) return null;
            const stateInfo: AssetStateInfo = assetStates[ctx.identifier];
            if (isNil(stateInfo)) return null;

            const csm = find(
              params.row.context_state_machines,
              (csm) => csm.id === stateInfo.csm_id,
            );

            const theCsm = csm || {
              id: stateInfo.csm_id,
              current_state_since: stateInfo.since,
              current_state: {
                id: stateInfo.s_id,
                name: stateInfo.name,
                icon: stateInfo.icon,
                color: stateInfo.color,
                identifier: stateInfo.state,
                criticality: stateInfo.criticality_val,
              },
              state_context: {
                id: stateInfo.sc_id,
                identifier: ctx.identifier,
                name: stateInfo.name,
              },
              stateful_item_id: toInteger(params.row.id),
              stateful_item_type: "Asset",
            };

            return (
              <StateWidget
                inline={true}
                widgetBox={false}
                contextStateMachineId={stateInfo.csm_id}
                contextStateMachine={theCsm}
                userCanChangeState={false}
              />
            );
          },
        });
      },
    );
  }
  return columns;
};

export function buttonForAssetAction(
  action: ItemAction,
  assetId: IDType,
  key: string | number,
): React.ReactElement {
  const link = linkForAssetAction(action, assetId);
  const title = defaultTo(
    action.title,
    I18n.t(`frontend.organizations.list.actions.${action.action}`),
  );
  if (!isNil(link)) {
    return (
      <ItemActionButton
        key={key}
        action={action}
        link={link.route}
        method={link.method}
        title={title}
        onComplete={() => {
          if (action.reload_on_success) {
            window.location.reload();
          }
        }}
      />
    );
  } else {
    return null;
  }
}

const linkForAssetAction = (
  action: ItemAction,
  assetId: IDType,
): { route: string; method?: RequestMethod } => {
  if (action.route) {
    return generateLinkForItemActionRoute(action.route, action.params, assetId);
  }
};
