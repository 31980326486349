import { Box, Grid } from "@mui/material";
import {
  cloneDeep,
  defaultTo,
  each,
  isNil,
  merge,
  toInteger,
  values,
} from "lodash";
import { Moment } from "moment";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import SVG from "react-inlinesvg";
import { BaseMapping } from "../../charting/svg/mapping_base";
import { WidgetController } from "../../controller/widget_controller";
import { jsonApiSingleResourceToFlatObject } from "../../json_api/jsonapi_tools";
import { SensorValueType } from "../../models/sensor";
import {
  SensorAttributesForSvgMapping,
  SensorMappingAttributes,
} from "../../models/svg_animation_widget_config";
import { getTimeString } from "../../utils/time_strings";
import { SvgAnimationWidgetConfigSerialized } from "../../widgets/svg_animation_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import { LoadingIcon } from "../common/icon";
import { DashboardActionContext } from "../dashboard/dashboard_action_context/dashboard_action_context";
import { SialogicWidgetDefinition } from "./sialogic_widget_component";
import {
  MappedSensorConfig,
  SvgAnimationWidgetProps,
} from "./svg_animation_widget.types";
import {
  buildMappingsFromProps,
  calculateValue,
  initializeSvgElement,
  setImageReference,
} from "./svg_animation_widget.utils";
import { WidgetBox } from "./widget_box";
import { WidgetTimestampGridItem } from "./widget_timestamp";
import { Action } from "../../actions/actions.types";
import { logger } from "../../utils/logger";
import { redirectTo } from "../../utils/redirection";
import { asset_sensor_path } from "../../routes";

export const SvgAnimationWidget: React.FunctionComponent<
  SvgAnimationWidgetProps
> = ({
  dataUpdateEnabled = true,
  encloseInWidgetBox = true,
  ...props
}: SvgAnimationWidgetProps) => {
  const [title, setTitle] = useState(defaultTo(props.title as string, ""));
  const [titleLinkUrl, setTitleLinkUrl] = useState(
    defaultTo(props.titleLinkUrl, ""),
  );
  const [titleLinkTarget, setTitleLinkTarget] = useState(
    defaultTo(props.linkTarget, "_self"),
  );
  const [contentLinkUrl, setContentLinkUrl] = useState(
    defaultTo(props.contentLinkUrl, props.titleLinkUrl),
  );
  const [contentLinkTarget, setContentLinkTarget] = useState(
    defaultTo(props.contentLinkTarget, defaultTo(props.linkTarget, "_self")),
  );
  const [lastTimestamp, setLastTimestamp] = useState(null);
  const [fullscreen, setFullscreen] = useState(false);

  // Refs for various elements and mappings
  const svgElement = useRef<SVGSVGElement>(null);
  // initialize the sensor and mapping data
  const sensorBySensorId = useRef<Record<string, MappedSensorConfig>>({});
  const mappingsBySensorId = useRef<Record<string, BaseMapping[]>>({});
  const invalidSensorMappings = useRef<BaseMapping[]>([]);

  //Update the title when the title prop changes
  useEffect(() => {
    setTitle(defaultTo(props.title as string, ""));
  }, [props.title]);

  //Update the titleLinkUrl when the titleLinkUrl prop changes
  useEffect(() => {
    setTitleLinkUrl(defaultTo(props.titleLinkUrl, ""));
  }, [props.titleLinkUrl]);

  //Update the titleLinkTarget when the linkTarget prop changes
  useEffect(() => {
    setTitleLinkTarget(defaultTo(props.linkTarget, "_self"));
  }, [props.linkTarget]);

  //Update the contentLinkUrl when the contentLinkUrl prop changes
  useEffect(() => {
    setContentLinkUrl(defaultTo(props.contentLinkUrl, props.titleLinkUrl));
  }, [props.contentLinkUrl]);

  //Update the contentLinkTarget when the contentLinkTarget prop changes
  useEffect(() => {
    setContentLinkTarget(
      defaultTo(props.contentLinkTarget, defaultTo(props.linkTarget, "_self")),
    );
  }, [props.contentLinkTarget]);

  const dashboardActionCtx = useContext(DashboardActionContext);
  // Handle click events on SVG elements
  const handleElementClick = React.useCallback(
    (
      event: MouseEvent,
      element: SVGElement,
      action: Action,
      mappingConfig: SensorMappingAttributes,
      sensor?: SensorAttributesForSvgMapping,
    ) => {
      try {
        if (dashboardActionCtx) {
          dashboardActionCtx.performAction(action, {
            sensorId: sensor?.sensor?.id as number,
            assetId: sensor?.sensor?.asset_id as number,
          });
        } else {
          if (sensor?.sensor?.id) {
            if (event.ctrlKey) {
              redirectTo(
                asset_sensor_path(
                  sensor.sensor.asset_id as number,
                  sensor.sensor.id as number,
                ),
              );
              return;
            } else {
              dashboardActionCtx?.performAction(
                {
                  action_type: "sensor",
                  asset_selector: { asset_search_mode: "asset" },
                },
                {
                  sensorId: sensor.sensor.id as number,
                  assetId: sensor.sensor.asset_id as number,
                },
              );
            }
            console.log("Sensor ID: ", sensor.sensor.id);
          }
        }
      } catch (e) {
        logger.error(e);
      }
    },
    [dashboardActionCtx],
  );

  // initial building of mappings
  useEffect(() => {
    const mpgs = buildMappingsFromProps(props, handleElementClick);
    setLastTimestamp(mpgs.mostRecentTime);
    invalidSensorMappings.current = mpgs.invalidSensorMappings;
    mappingsBySensorId.current = mpgs.mappingsBySensorId;
    sensorBySensorId.current = mpgs.sensorBySensorId;

    return () => {
      // unmount mappings
      each(mpgs.mappingsBySensorId, (mappings, sensorId) => {
        each(mappings, (mapping) => {
          mapping.destroy();
        });
      });
    };
  }, [props.sensorMappings]);

  const handleSensorValueUpdate = useCallback(
    (
      attributeKeyId: number,
      sensorId: number,
      value: SensorValueType,
      time: Moment,
      unit?: string,
    ) => {
      const sensorConfig = cloneDeep(sensorBySensorId.current[sensorId]);
      // update the sensor value to the most recent value
      sensorConfig.value = calculateValue(
        value as number,
        sensorConfig.compiledValueFormula,
        sensorConfig.sensor,
      );
      sensorConfig.time = time;
      sensorBySensorId.current[sensorId] = sensorConfig;
      // process all the mapping affected by the sensor update
      values(mappingsBySensorId.current[sensorId]).forEach((mapping) => {
        mapping.applyValueToSvg(sensorConfig, svgElement.current);
      });
      if (isNil(lastTimestamp) || time.isAfter(lastTimestamp)) {
        setLastTimestamp(time);
      }
    },
    [lastTimestamp, sensorBySensorId, mappingsBySensorId],
  );

  // handle Sensor value update
  useEffect(() => {
    const subscriber = { handleSensorValueUpdate };
    const subscriptions: number[] = [];
    if (dataUpdateEnabled) {
      Object.keys(sensorBySensorId.current).forEach((sensorId) => {
        if (WidgetController.getInstance()) {
          if (dataUpdateEnabled) {
            subscriptions.push(
              WidgetController.getInstance().sensorDataChannel.addEventListener(
                subscriber,
                toInteger(sensorId),
              ),
            );
          }
        }
      });
    }
    return () => {
      const instance = WidgetController.getInstance();
      if (!isNil(instance)) {
        each(subscriptions, (sId) => {
          WidgetController.getInstance().sensorDataChannel.removeEventListenerId(
            sId,
          );
        });
      }
    };
  }, [sensorBySensorId.current, dataUpdateEnabled]);

  React.useEffect(() => {
    if (svgElement.current) {
      setImageReference(
        {
          svgImageElementSelector: props.svgImageElementSelector,
          svgImageUrl: props.svgImageUrl,
        },
        svgElement.current,
      );
    }
  }, [props.svgImageUrl, props.svgImageElementSelector]);

  const content = (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Box m="auto" width="90%">
          <SVG
            loader={<LoadingIcon size="4x" />}
            style={{
              maxWidth: defaultTo(props.svgMaxWidth, "100%"),
              maxHeight: fullscreen ? "80vh" : props.svgMaxHeight || "90vh",
            }}
            src={defaultTo(props.svgCode, props.svgUrl)}
            width="100%"
            height={fullscreen ? "75vh" : null}
            innerRef={
              ((element: SVGSVGElement) => {
                if (svgElement.current === element) return;

                svgElement.current = element;
                initializeSvgElement(
                  element,
                  {
                    svgImageUrl: props.svgImageUrl,
                    svgImageElementSelector: props.svgImageElementSelector,
                  },
                  invalidSensorMappings.current,
                  mappingsBySensorId.current,
                  sensorBySensorId.current,
                );
              }) as any // the new version changed the typing to be a refobejct, but a function is still valid
            }
          />
        </Box>
      </Grid>
      <WidgetTimestampGridItem
        timestamp={getTimeString(null, lastTimestamp)}
        align="center"
      />
    </Grid>
  );

  return (
    <>
      {!encloseInWidgetBox ? (
        content
      ) : (
        <WidgetBox
          {...props}
          title={title}
          titleLinkUrl={titleLinkUrl}
          contentLinkUrl={contentLinkUrl}
          onFullscreen={(fullscreen) => setFullscreen(fullscreen)}
          allowFullscreen
        >
          {content}
        </WidgetBox>
      )}
    </>
  );
};

function serializedConfigToProps(
  config: SvgAnimationWidgetConfigSerialized,
): SvgAnimationWidgetProps {
  const sensorMappings = config.mappings?.map((sensorMappingWithJsonApiDoc) => {
    if (isNil(sensorMappingWithJsonApiDoc)) return null;
    const sensorJsonApiData = sensorMappingWithJsonApiDoc.sensor?.sensor;
    if (isNil(sensorJsonApiData)) return sensorMappingWithJsonApiDoc;
    const sensor = !isNil(sensorJsonApiData)
      ? jsonApiSingleResourceToFlatObject(sensorJsonApiData)
      : null;
    const d = {
      ...sensorMappingWithJsonApiDoc,
      sensor: sensorJsonApiData
        ? { ...sensorMappingWithJsonApiDoc.sensor, sensor }
        : null,
    };
    return d;
  });

  return merge(widgetBoxPropsFromSerializedConfig(config), {
    svgCode: config.svg_code,
    svgUrl: config.svg_url,

    contentLinkTarget: config.title_link_target,
    svgMaxWidth: config.max_svg_width,
    svgMaxHeight: config.max_svg_height,
    dataUpdateEnabled: isNil(config.disable_update)
      ? true
      : !config.disable_update,
    svgImageElementSelector: config.image_placeholder_selector,
    svgImageUrl: config.image_url,
    sensorMappings: sensorMappings,
  } as SvgAnimationWidgetProps);
}

export const SvgAnimationWidgetDefinition: SialogicWidgetDefinition<
  typeof SvgAnimationWidget,
  typeof serializedConfigToProps
> = {
  Component: SvgAnimationWidget,
  serializedConfigToProps: serializedConfigToProps,
};
