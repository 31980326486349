import {
  clone,
  countBy,
  defaults,
  filter,
  get,
  isEmpty,
  isNil,
  last,
  map,
  set,
} from "lodash";
import { MeasurementCategorization } from "../../../models/measurement_categorization";
import { MeasurementPlan } from "../../../models/measurement_plan";
import {
  MeasurementType,
  MeasurementTypes,
  MeasurementTypesUnderscore,
} from "../../../models/measurement_type";
import { MeasurementValueDefinition } from "../../../models/measurement_value_definition";
import { TypedErrorMap } from "../../../utils/error_map";
import { AutoDispatchReduceStore } from "../../common/auto_dispatch_reduce_store";
import { MeasurementPlanFormStepNumbers } from "../views/measurement_plan_form";
import { MeasurementPlanCreationMode } from "../views/measurement_plan_form_step_1";
import {
  AddMeasurementValueDefinitionAction,
  BackAction,
  EnableEditAction,
  FinishStep1Action,
  FormMode,
  LoadInitialStateAction,
  MeasurementPlanAction,
  MoveMeasurementValueDefinitionAction,
  RemoveMeasurementValueDefinitionAction,
  ResetErrorsAction,
  ResetStateAction,
  SelectCreationModeAction,
  SelecteMeasurementCategorizationAction,
  SelectMeasurementTypeTemplateAction,
  SelectMeasurementUnitAction,
  SelectTypeOfMeasurementAction,
  SetErrorsAction,
  SetProcessingAction,
  UpdateMeasurementPlanAction,
  UpdateMeasurementValueDefinitionAction,
} from "./measurement_plan_actions";
import MeasurementPlanDispatcher from "./measurement_plan_dispatcher";

export interface MeasurementPlanState {
  step: MeasurementPlanFormStepNumbers;
  submitUrl?: string;
  submitMethod?: "POST" | "PATCH";
  assetId: number | string;
  measurementPlan: MeasurementPlan;
  allowEdit?: boolean;
  templateTypes: MeasurementType[];
  templateType: MeasurementType;
  unit?: string;
  mode: FormMode;
  createBy: MeasurementPlanCreationMode;
  typeOfMeasurement: MeasurementTypes;
  availableMeasurementCategorizations: MeasurementCategorization[];
  errors: TypedErrorMap<MeasurementPlan>;
  isProcessing: boolean;
  hasChanges: boolean;

  measurementValueDefinitionsToDelete: MeasurementValueDefinition[];
}

type ActionHandler = (
  state: MeasurementPlanState,
  action: MeasurementPlanAction,
) => MeasurementPlanState;

/**
 * The Flux store for the measurement plan form.
 * Contains the actual business logic in the reducer methods.
 * Each reducer method is named after the action type.
 */
export class MeasurementPlanStore extends AutoDispatchReduceStore<
  MeasurementPlanState,
  MeasurementPlanAction
> {
  static DEFAULT_RRULE = "RRULE:FREQ=WEEKLY;INTERVAL=1;BYDAY=MO,FR";

  constructor() {
    super(MeasurementPlanDispatcher);
  }

  getInitialState(): MeasurementPlanState {
    return {
      step: 1,
      allowEdit: true,
      templateTypes: [],
      templateType: undefined,
      measurementPlan: {
        rrule: MeasurementPlanStore.DEFAULT_RRULE,
        measurement_type: {
          type: "MeasurementTypes::DistributionMeasurementType",
          measurement_value_definitions: [],
          allow_measurement_notes: true,
          allow_attachments: true,
          type_short: "distribution_measurement_type",
        },
      },
      createBy: "measurementType",
      typeOfMeasurement: "MeasurementTypes::DistributionMeasurementType",
      availableMeasurementCategorizations: [],
      measurementValueDefinitionsToDelete: [],
      assetId: undefined,
      isProcessing: false,
      unit: undefined,
      mode: "create",
      hasChanges: false,
      errors: {},
    };
  }

  RESET_STATE(
    state: MeasurementPlanState,
    action: ResetStateAction,
  ): MeasurementPlanState {
    return this.getInitialState();
  }

  LOAD_INITIAL_STATE(
    state: MeasurementPlanState,
    action: LoadInitialStateAction,
  ): MeasurementPlanState {
    let measurementPlan: MeasurementPlan;

    if (action.mode === "create") {
      measurementPlan = defaults(
        action.measurementPlan,
        this.getInitialState().measurementPlan,
      );
    } else {
      measurementPlan = { ...action.measurementPlan };
    }
    const defaultUnit =
      measurementPlan?.measurement_type?.measurement_value_definitions?.[0]
        ?.unit;

    return {
      ...this.getInitialState(),
      step: action.mode === "create" ? 1 : 2,
      submitUrl: action.submitUrl,
      submitMethod: action.submitMethod,
      assetId: action.assetId,
      measurementPlan: measurementPlan,
      allowEdit: action.mode !== "show",
      templateTypes: action.templateTypes,
      templateType: action.templateTypes?.[0],
      unit: defaultUnit,
      mode: action.mode,
      createBy: isEmpty(action.templateTypes) ? "measurementType" : "template",
      typeOfMeasurement: measurementPlan.measurement_type.type,
      availableMeasurementCategorizations:
        action.availableMeasurementCategorizations,
    };
  }

  /******************************
   * Form step actions
   * ****************************/
  FINISH_STEP_1(
    state: MeasurementPlanState,
    action: FinishStep1Action,
  ): MeasurementPlanState {
    if (state.mode !== "create" || state.step !== 1) {
      return state;
    }

    const measurementPlan: MeasurementPlan = { ...state.measurementPlan };

    const shortType: MeasurementTypesUnderscore =
      state.typeOfMeasurement ===
      "MeasurementTypes::DistributionMeasurementType"
        ? "distribution_measurement_type"
        : "independent_measurement_type";
    // Update measurement type
    if (state.createBy === "measurementType") {
      // create from scratch -> set type from measurement plan if defined
      measurementPlan.measurement_type = {
        ...measurementPlan.measurement_type,
        type: state.typeOfMeasurement,
        type_short: shortType,
      };
    } else {
      // create from template

      // Copy measurement value definitions
      const measurementValueDefinitions = map(
        state.templateType?.measurement_value_definitions,
        (mvd) => {
          const measurementValueDefinition = { ...mvd };
          delete measurementValueDefinition.id;

          return measurementValueDefinition;
        },
      );

      // Copy configuration from template keep title set by user if present
      const measurementType = {
        ...state.templateType,
        title: isEmpty(measurementPlan.measurement_type?.title)
          ? state.templateType?.title
          : measurementPlan.measurement_type?.title,
        measurement_value_definitions: measurementValueDefinitions,
      };

      // delete the id to enforce recreation
      delete measurementType.id;

      measurementPlan.measurement_type = measurementType;
      if (isEmpty(measurementPlan.key)) {
        measurementPlan.key = measurementType.key;
      }

      // remove the id to enforce recreation -> we do not reference measurement templates twice
      delete measurementPlan.measurement_type_id;
    }

    // Check if title is set
    if (isEmpty(measurementPlan?.measurement_type?.title)) {
      const errors: TypedErrorMap<MeasurementPlan> = {};
      errors.measurement_type =
        errors.measurement_type ?? ({} as TypedErrorMap<MeasurementType>);
      errors.measurement_type.title = `${I18n.t(
        "activerecord.attributes.measurement_type.title",
      )} ${I18n.t("errors.messages.blank")}`;
      return { ...state, errors: errors, step: 1 };
    }

    const defaultUnit =
      measurementPlan?.measurement_type?.measurement_value_definitions?.[0]
        ?.unit;

    const newState: MeasurementPlanState = {
      ...state,
      measurementPlan: measurementPlan,
      unit: defaultUnit,
      step: 2,
      hasChanges: true,
      errors: {},
    };
    return newState;
  }

  BACK(state: MeasurementPlanState, action: BackAction): MeasurementPlanState {
    if (state.mode !== "create" || state.step !== 2) {
      return state;
    }

    const newState = {
      ...this.getInitialState(),
      templateType: state.templateType,
      templateTypes: state.templateTypes,
      createBy: state.createBy,
      typeOfMeasurement: state.typeOfMeasurement,
      mode: state.mode,
      assetId: state.assetId,
      availableMeasurementCategorizations:
        state.availableMeasurementCategorizations,
    };
    const { measurementPlan } = newState;
    measurementPlan.measurement_type.title =
      state.measurementPlan?.measurement_type?.title;

    return newState;
  }

  /** MEASUREMENT TYPE TEMPLATE */

  SELECT_MEASUREMENT_TYPE_TEMPLATE(
    state: MeasurementPlanState,
    action: SelectMeasurementTypeTemplateAction,
  ): MeasurementPlanState {
    let newMeasurementPlan = state.measurementPlan;
    if (isEmpty(state.measurementPlan?.measurement_type?.title)) {
      // Set measurement type title to template title if not set
      newMeasurementPlan = {
        ...newMeasurementPlan,
        measurement_type: {
          ...newMeasurementPlan.measurement_type,
          title: action.template.title,
        },
      };
    }

    return {
      ...state,
      hasChanges: true,
      templateType: action.template,
      measurementPlan: newMeasurementPlan,
    };
  }

  /** MEASUREMENT CATEGORIZATION */
  SELECT_MEASUREMENT_CATEGORIZATION(
    state: MeasurementPlanState,
    action: SelecteMeasurementCategorizationAction,
  ): MeasurementPlanState {
    if (
      state.measurementPlan?.measurement_type?.measurement_categorization?.id ==
      action.mCat?.id
    )
      return state;

    // reset all definition categories if categorization changed
    const measurementType = clone(state.measurementPlan?.measurement_type);
    measurementType.measurement_categorization = action.mCat;
    measurementType.measurement_value_definitions =
      measurementType?.measurement_value_definitions
        ? clone(measurementType?.measurement_value_definitions)
        : [];
    measurementType.measurement_value_definitions.forEach(
      (measurementValueDefinition, i) => {
        const newDefinition = { ...measurementValueDefinition };
        delete newDefinition.measurement_category;
        delete newDefinition.measurement_category_id;

        measurementType.measurement_value_definitions[i] = newDefinition;
      },
    );

    return {
      ...state,
      measurementPlan: {
        ...state.measurementPlan,
        measurement_type: measurementType,
      },
      hasChanges: true,
    };
  }

  /******************************
   * Measurement Plan Update
   * ****************************/

  UPDATE_MEASUREMENT_PLAN(
    state: MeasurementPlanState,
    action: UpdateMeasurementPlanAction,
  ): MeasurementPlanState {
    return {
      ...state,
      measurementPlan: action.measurementPlan,
      hasChanges: true,
    };
  }

  /******************************
   * Measurement Value Definitions
   * ****************************/

  ADD_MEASUREMENT_VALUE_DEFINITION(
    state: MeasurementPlanState,
    action: AddMeasurementValueDefinitionAction,
  ) {
    const measurementType = clone(
      get(state, ["measurementPlan", "measurement_type"], {
        measurement_value_definitions: [],
      } as MeasurementType),
    );
    if (isNil(measurementType.measurement_value_definitions)) {
      measurementType.measurement_value_definitions = [];
    }
    const lastDefinition = last(measurementType.measurement_value_definitions);
    const newPosition =
      isNil(lastDefinition) || isNil(lastDefinition.position)
        ? 0
        : lastDefinition.position + 1;
    const min =
      measurementType.type === "MeasurementTypes::DistributionMeasurementType"
        ? lastDefinition?.max
        : undefined;
    measurementType.measurement_value_definitions.push({
      position: newPosition,
      unit: state.unit,
      title: null,
      value_type: null,
      is_interval:
        state.measurementPlan.measurement_type.type ===
        "MeasurementTypes::DistributionMeasurementType",
      min,
    });

    return {
      ...state,
      measurementPlan: {
        ...state.measurementPlan,
        measurement_type: measurementType,
      },
      hasChanges: true,
    };
  }

  REMOVE_MEASUREMENT_VALUE_DEFINITION(
    state: MeasurementPlanState,
    action: RemoveMeasurementValueDefinitionAction,
  ): MeasurementPlanState {
    const measurementType = { ...state.measurementPlan.measurement_type };

    const definitions = clone(measurementType.measurement_value_definitions);
    let definitionsToDelete: MeasurementValueDefinition[];
    if (action.index >= 0 && action.index < definitions.length) {
      definitionsToDelete = definitions.splice(action.index, 1);
    } else {
      return;
    }
    measurementType.measurement_value_definitions = definitions;
    return {
      ...state,
      measurementPlan: {
        ...state.measurementPlan,
        measurement_type: measurementType,
      },

      measurementValueDefinitionsToDelete:
        state.measurementValueDefinitionsToDelete.concat(definitionsToDelete),
      hasChanges: true,
    };
  }

  MOVE_MEASUREMENT_VALUE_DEFINITION(
    state: MeasurementPlanState,
    action: MoveMeasurementValueDefinitionAction,
  ): MeasurementPlanState {
    const measurementType = { ...state.measurementPlan.measurement_type };

    const definitions = clone(measurementType.measurement_value_definitions);
    if (action.index >= 0 && action.index < definitions.length) {
      // swap places with previous or next element
      const temp = definitions[action.index];
      const newIndex = action.index + action.direction;
      if (newIndex < 0 || newIndex >= definitions.length) return state;

      definitions[action.index] = definitions[newIndex];
      definitions[newIndex] = temp;
      temp.position = newIndex;
      measurementType.measurement_value_definitions = definitions;
      return {
        ...state,
        measurementPlan: {
          ...state.measurementPlan,
          measurement_type: measurementType,
        },
        hasChanges: true,
      };
    } else {
      return state;
    }
  }

  UPDATE_MEASUREMENT_VALUE_DEFINITION(
    state: MeasurementPlanState,
    action: UpdateMeasurementValueDefinitionAction,
  ): MeasurementPlanState {
    const measurementType = { ...state.measurementPlan.measurement_type };

    const definitions = clone(measurementType.measurement_value_definitions);
    definitions[action.index] = action.mvd;
    measurementType.measurement_value_definitions = definitions;

    const newState = {
      ...state,
      measurementPlan: {
        ...state.measurementPlan,
        measurement_type: measurementType,
      },
      hasChanges: true,
    };

    if (!isNil(action.mvd.min) && !isNil(action.mvd.max)) {
      if (action.mvd.min >= action.mvd.max) {
        set(
          newState,
          [
            "errors",
            "measurement_type",
            "measurement_value_definitions",
            action.index,
            "min",
          ],
          I18n.t(
            "activerecord.errors.models.measurement_value_definition.attributes.min.larger_than_max",
          ),
        );
      } else {
        delete newState.errors?.measurement_type
          ?.measurement_value_definitions?.[action.index]?.min;
      }
    }

    if (!isEmpty(action.mvd.key)) {
      if (filter(definitions, (d) => d.key === action.mvd.key).length > 1) {
        set(
          newState,
          [
            "errors",
            "measurement_type",
            "measurement_value_definitions",
            action.index,
            "key",
          ],
          I18n.t(
            "activerecord.errors.models.measurement_value_definition.attributes.key.taken",
          ),
        );
      } else {
        delete newState.errors?.measurement_type
          ?.measurement_value_definitions?.[action.index]?.key;
      }
    }

    return newState;
  }

  SELECT_MEASUREMENT_UNIT(
    state: MeasurementPlanState,
    action: SelectMeasurementUnitAction,
  ) {
    const measurementType = state.measurementPlan?.measurement_type;
    const measurementValueDefinitions =
      measurementType?.measurement_value_definitions;
    if (
      isEmpty(measurementValueDefinitions) ||
      measurementType.type !== "MeasurementTypes::DistributionMeasurementType"
    ) {
      // just apply unit selection
      return { ...state, unit: action.unit };
    }

    const newMeasurementType = {
      ...measurementType,
      measurement_value_definitions: map(
        measurementValueDefinitions,
        (def) => ({ ...def, unit: action.unit }),
      ),
    };

    return {
      ...state,
      unit: action.unit,
      measurementPlan: {
        ...state.measurementPlan,
        measurement_type: newMeasurementType,
      },
      measurement_type: newMeasurementType,
      hasChanges: true,
    };
  }

  SELECT_CREATION_MODE(
    state: MeasurementPlanState,
    action: SelectCreationModeAction,
  ): MeasurementPlanState {
    return {
      ...state,

      createBy: action.createBy,
    };
  }

  SELECT_TYPE_OF_MEASUREMENT(
    state: MeasurementPlanState,
    action: SelectTypeOfMeasurementAction,
  ): MeasurementPlanState {
    return {
      ...state,
      typeOfMeasurement: action.typeOfMeasurement,
    };
  }

  /** Modifies the state and sets processing flag to indicate that something is going on.
   *
   *
   * @param {MeasurementPlanState} state
   * @param {SetProcessingAction} action
   * @returns {MeasurementPlanState}
   * @memberof MeasurementPlanStore
   */
  SET_PROCESSING(
    state: MeasurementPlanState,
    action: SetProcessingAction,
  ): MeasurementPlanState {
    return {
      ...state,
      isProcessing: action.isProcessing,
    };
  }
  /******************************/
  /* ERRORS
   *******************************/

  SET_ERRORS(
    state: MeasurementPlanState,
    action: SetErrorsAction,
  ): MeasurementPlanState {
    const response = action.errorResponse;

    return {
      ...state,
      errors: response.errors,
    };
  }

  /** Resets validation errors on the dataset
   *
   *
   * @param {MeasurementPlanState} state Current state
   * @param {ResetErrorsAction} action
   * @returns {MeasurementPlanState} State without error objects
   * @memberof MeasurementPlanStore
   */
  RESET_ERRORS(
    state: MeasurementPlanState,
    action: ResetErrorsAction,
  ): MeasurementPlanState {
    return {
      ...state,
      errors: {},
    };
  }

  ENABLE_EDIT(
    state: MeasurementPlanState,
    action: EnableEditAction,
  ): MeasurementPlanState {
    if (state.mode === "edit" && action.allowEdit === false) {
      return {
        ...state,
        mode: "show",
        allowEdit: false,
      };
    } else if (state.mode === "show" && action.allowEdit === true) {
      return {
        ...state,
        mode: "edit",
        allowEdit: true,
      };
    }

    return state;
  }
}

export default new MeasurementPlanStore();
