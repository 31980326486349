/// <reference types="../../definitions/index" />;
import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { cloneDeep, each, every, map, noop } from "lodash";
import * as React from "react";
import { ResourcePermissions } from "../../models/permission";
import {
  getResourceDescription,
  getResourceName,
} from "./resource_translations";
import { Check, Square, SquareOutlined } from "@mui/icons-material";

interface PermissionFormProperties {
  permission: ResourcePermissions;
  onChange: (permission: ResourcePermissions) => void;
  readOnly?: boolean;
}

export function PermissionForm(props: PermissionFormProperties) {
  const onChangeAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const checked = event.target.checked;
    const permission = cloneDeep(props.permission);
    each(permission.actions, (_checked, action) => {
      permission.actions[action] = checked;
    });
    props.onChange(permission);
  };

  const onChange = (
    action: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    event.persist();
    const checked = event.target.checked;
    const permission = cloneDeep(props.permission);
    permission.actions[action] = checked;
    props.onChange(permission);
  };

  return (
    <Grid container>
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <h5>{getResourceName(props.permission.resource)}</h5>
        </Grid>
        <Grid item xs={12}>
          {getResourceDescription(props.permission.resource)}
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={12} md={4} lg={2}>
          <Box marginRight={1} borderRight={1}>
            <FormControlLabel
              label={I18n.t("frontend.permission.actions.all")}
              control={
                <Checkbox
                  color="primary"
                  name="permission[all]"
                  checked={every(props.permission.actions)}
                  disabled={props.readOnly}
                  onChange={props.readOnly ? noop : onChangeAll}
                />
              }
            />
          </Box>
        </Grid>
        {map(props.permission.actions, (checked, action) => {
          return (
            <Grid item xs={12} md={4} lg={2} key={`checkbox-${action}`}>
              <FormControlLabel
                label={I18n.t(`frontend.permission.actions.${action}`)}
                control={
                  <Checkbox
                    color="primary"
                    name={`permission[${action}]`}
                    disabled={props.readOnly}
                    checked={checked}
                    onChange={
                      props.readOnly ? noop : (event) => onChange(action, event)
                    }
                  />
                }
              />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
}
