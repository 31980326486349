// WORKAROUND for leaflet images and webpack(see: https://github.com/PaulLeCam/react-leaflet/issues/255#issuecomment-269750542)
import L from "leaflet";

const markerIconUrl = require("../../../node_modules/leaflet/dist/images/marker-icon.png");
const markerIconRetinaUrl = require("../../../node_modules/leaflet/dist/images/marker-icon-2x.png");
const markerShadowUrl = require("../../../node_modules/leaflet/dist/images/marker-shadow.png");

L.Icon.Default.prototype.options.iconUrl = markerIconUrl;
L.Icon.Default.prototype.options.iconRetinaUrl = markerIconRetinaUrl;
L.Icon.Default.prototype.options.shadowUrl = markerShadowUrl;
L.Icon.Default.imagePath = ""; // necessary to avoid Leaflet adds some prefix to image path.
