import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { defaultTo, isEmpty, merge } from "lodash";
import * as React from "react";

import { Help } from "@mui/icons-material";
import { ContextStateMachineJSONObject } from "../../json_api/context_state_machines";
import { EventPatternJSONAPIAttributes } from "../../json_api/event_pattern";
import { ModelErrors } from "../../json_api/jsonapi_tools";
import { StateJSONObject } from "../../json_api/state";
import { StateContextJSONObject } from "../../json_api/state_contexts";
import { EventPatternAttributes } from "../../models/event_pattern";
import { TimeDurationInput } from "../common/time_duration_input";
import { EventPatternCommonFormFields } from "./event_pattern_common_form_fields";
import { EventPatternConditionFields } from "./event_pattern_condition_fields";
interface SensorEventPatternTypeSelectProps {
  eventPattern: EventPatternJSONAPIAttributes;
  errors?: ModelErrors<EventPatternAttributes>;
  onPatternUpdate(newType: EventPatternJSONAPIAttributes): void;
  readonly?: boolean;
}

export const SensorDataTransmissionEventPatternForm: React.FunctionComponent<
  SensorEventPatternTypeSelectProps
> = (props) => {
  const [selectedState, setSelectedState] = React.useState<{
    csm: ContextStateMachineJSONObject;
    context: StateContextJSONObject;
    state: StateJSONObject;
  }>(null);
  return (
    <Grid item xs={12} container>
      <Grid item xs={12}>
        <TextField
          size="small"
          required
          fullWidth
          helperText={
            isEmpty(props.errors?.name)
              ? I18n.t("frontend.event_patterns.form.name_help")
              : props.errors?.name
          }
          title={I18n.t(
            "activerecord.attributes.event_patterns/event_pattern.name",
          )}
          error={!isEmpty(props.errors.name)}
          InputProps={{ readOnly: props.readonly }}
          value={props.eventPattern.name}
          label={I18n.t(
            "activerecord.attributes.event_patterns/event_pattern.name",
          )}
          onChange={(e) =>
            props.onPatternUpdate({
              ...props.eventPattern,
              name: e.currentTarget.value,
            })
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Box m={1} p={2}>
            <Grid container spacing={2}>
              <EventPatternCommonFormFields
                eventPattern={props.eventPattern}
                errors={props.errors}
                onPatternUpdate={props.onPatternUpdate}
                readonly={props.readonly}
              />
            </Grid>
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper>
          <Box m={1} p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  {I18n.t(
                    "frontend.event_patterns.form.data_transmission_trigger_condition_header",
                  )}
                  <Tooltip
                    title={I18n.t(
                      "frontend.event_patterns.form.data_transmission_trigger_condition_help",
                    )}
                  >
                    <IconButton size="small">
                      <Help />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <EventPatternConditionFields
                  eventPattern={props.eventPattern}
                  readonly={props.readonly}
                  errors={props.errors}
                  unit="s"
                  onComparatorSelect={(comparator, changes) => {
                    const newPattern = merge(
                      { ...props.eventPattern, comparator },
                      changes,
                    );
                    props.onPatternUpdate(newPattern);
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper>
          <Box m={1} p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  {I18n.t("frontend.event_patterns.form.timing_header")}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={6}>
                <TimeDurationInput
                  readonly={props.readonly}
                  value={props.eventPattern.time_threshold}
                  title={I18n.t(
                    "activerecord.attributes.event_patterns/event_pattern.time_threshold",
                  )}
                  onValueUpdate={(newDuration) =>
                    props.onPatternUpdate({
                      ...props.eventPattern,
                      time_threshold: newDuration?.toISOString(),
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TimeDurationInput
                  readonly={props.readonly}
                  title={I18n.t(
                    "activerecord.attributes.event_patterns/event_pattern.cool_down_time",
                  )}
                  value={props.eventPattern.cool_down_time}
                  onValueUpdate={(newDuration) =>
                    props.onPatternUpdate({
                      ...props.eventPattern,
                      cool_down_time: newDuration?.toISOString(),
                    })
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  {I18n.t(
                    "frontend.event_patterns.form.notification_settings_header",
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth size="small">
                  <FormControlLabel
                    label={I18n.t(
                      "activerecord.attributes.event_patterns/event_pattern.enabled_repeated_notification",
                    )}
                    control={
                      <Checkbox
                        size="small"
                        readOnly={props.readonly}
                        name="enable_repeated"
                        checked={defaultTo(
                          props.eventPattern.enabled_repeated_notification,
                          false,
                        )}
                        onChange={(event) => {
                          props.onPatternUpdate({
                            ...props.eventPattern,
                            enabled_repeated_notification: event.target.checked,
                          });
                        }}
                      />
                    }
                  />
                  <FormHelperText>
                    {I18n.t(
                      "frontend.event_patterns.form.help_repeated_notification",
                    )}
                  </FormHelperText>
                </FormControl>
              </Grid>
              {props.eventPattern.enabled_repeated_notification ? (
                <Grid item xs={12}>
                  <TimeDurationInput
                    title={I18n.t(
                      "activerecord.attributes.event_patterns/event_pattern.repeated_notification_after",
                    )}
                    readonly={props.readonly}
                    value={props.eventPattern.repeated_notification_after}
                    onValueUpdate={(newDuration) =>
                      props.onPatternUpdate({
                        ...props.eventPattern,
                        repeated_notification_after: newDuration?.toISOString(),
                      })
                    }
                  />
                </Grid>
              ) : null}
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};
