import { useMutation, useQueryClient } from "@tanstack/react-query";
import { JSONSchema7 } from "json-schema";
import { Object } from "json-typescript";
import { SingleResourceDoc } from "jsonapi-typescript";
import { isNil, sortBy } from "lodash";
import { createQuery } from "react-query-kit";
import { jsonApiSingleResourceToFlatObject } from "../../../json_api/jsonapi_tools";
import {
  WIDGET_JSONAPI_RESOURCE_TYPE,
  WidgetJSONAPIAttributes,
} from "../../../json_api/widget";
import { ActiveStorageStoredFile } from "../../../models/active_storage_stored_file";
import {
  api_widget_available_sensors_path,
  api_widget_path,
  api_widgets_sensor_types_path,
  api_widgets_types_path,
  store_attachments_api_widget_path,
  widget_config_schema_path,
} from "../../../routes";
import {
  loadDataFromUrl,
  sendData,
  sendJsonApiData,
} from "../../../utils/jquery_helper";
import { buildJsonApiSubmitData } from "../../../utils/jsonapi_form_tools";
import {
  assetDashboardWidgetsPath,
  assetTypesDashboardWidgetsPath,
} from "../../../utils/urls";
import { IDType } from "../../../utils/urls/url_utils";
import {
  WidgetEditorAvailableSensor,
  WidgetEditorSensorType,
  WidgetTypeInfo,
} from "./widget_contfig_editor_types";

import { Blob } from "@rails/activestorage";
export const useLoadWidget = createQuery<
  WidgetJSONAPIAttributes,
  { id: IDType }
>({
  queryKey: [WIDGET_JSONAPI_RESOURCE_TYPE],
  fetcher: async ({ id }) => {
    const widgetDoc = await loadDataFromUrl<
      SingleResourceDoc<string, WidgetJSONAPIAttributes>
    >(
      api_widget_path(id, {
        _options: true,
        format: "json",
      }),
    );
    return jsonApiSingleResourceToFlatObject(widgetDoc);
  },
});
export const useLoadWidgetSchema = createQuery<JSONSchema7, { type: string }>({
  queryKey: ["widget_schema"],
  fetcher: async ({ type }) => {
    return loadDataFromUrl<JSONSchema7>(widget_config_schema_path(type));
  },
});

export const useLoadWidgetTypes = createQuery<WidgetTypeInfo[], void>({
  queryKey: ["widget_types"],
  fetcher: async () => {
    return loadDataFromUrl<WidgetTypeInfo[]>(api_widgets_types_path());
  },
});

export const useLoadWidgetSensorTypes = createQuery<
  WidgetEditorSensorType[],
  void
>({
  queryKey: ["widget_sensor_types"],
  fetcher: async () => {
    return loadDataFromUrl<WidgetEditorSensorType[]>(
      api_widgets_sensor_types_path(),
    );
  },
});

export const useLoadWidgetSensorInfo = createQuery<
  WidgetEditorAvailableSensor[],
  { assetId: IDType }
>({
  queryKey: ["widget_sensor_info"],
  fetcher: async ({ assetId }) => {
    const sensorsInfos = await loadDataFromUrl<WidgetEditorAvailableSensor[]>(
      api_widget_available_sensors_path(assetId),
    );

    return sortBy(sensorsInfos, "name");
  },
});

function submitUrl(
  widgetId: IDType,
  dashboardId: IDType,
  dashboardType: string,
  assetId: IDType,
  assetTypeId: IDType,
): string {
  if (isNil(widgetId)) {
    // create new widget
    if (dashboardType == "asset_type_dashboard") {
      return assetTypesDashboardWidgetsPath(assetTypeId, dashboardId);
    } else {
      return assetDashboardWidgetsPath(assetId, dashboardId);
    }
  } else {
    return api_widget_path(widgetId);
  }
}

async function createOrUpdateWidget({
  id,
  config,
  name,
  dashboardId,
  dashboardType,
  assetId,
  assetTypeId,
  widgetTypeIdentifier,
  attachments,
}: {
  id: IDType;
  dashboardId: IDType;
  dashboardType: string;
  assetId: IDType;
  assetTypeId: IDType;
  config: Object;
  name: string;
  widgetTypeIdentifier: string;
  attachments: Record<string, ActiveStorageStoredFile>;
}) {
  const url = submitUrl(id, dashboardId, dashboardType, assetId, assetTypeId);
  const { submitData, mode } = buildJsonApiSubmitData(
    { id, config: JSON.stringify(config || {}), name },
    WIDGET_JSONAPI_RESOURCE_TYPE,
  );

  (submitData as Record<string, any>)["widget_class_name"] =
    widgetTypeIdentifier;
  (submitData as Record<string, any>)["attachments"] = attachments;

  sendJsonApiData<unknown, { status: string }>(
    url,
    submitData,
    mode == "create" ? "POST" : "PATCH",
  );
}

export const useSaveWidgetConfig = () => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: createOrUpdateWidget,
    onSuccess: (data, sourceData) => {
      client.invalidateQueries({
        queryKey: [WIDGET_JSONAPI_RESOURCE_TYPE],
      });
      return data;
    },
  });
};

export const useCreateWidget = () => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: createOrUpdateWidget,
    onSuccess: (data, sourceData) => {
      client.invalidateQueries({
        queryKey: [WIDGET_JSONAPI_RESOURCE_TYPE],
      });
      return data;
    },
  });
};

export const useSaveBlobAsWidgetAttachment = () => {
  return useMutation({
    mutationFn: async ({
      attachmentName,
      blob,
      widgetId,
    }: {
      attachmentName: string;
      blob: Blob;
      widgetId: IDType;
    }): Promise<Record<string, ActiveStorageStoredFile>> => {
      const attachments = {
        [attachmentName]: isNil(blob) ? null : blob.signed_id,
      };
      const response = await sendData(
        store_attachments_api_widget_path(widgetId),
        { attachments },
        "PATCH",
      );

      return response as Record<string, ActiveStorageStoredFile>;
    },
  });
};

export const useDeleteWidget = () => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: async ({ id }: { id: IDType }) => {
      const url = api_widget_path(id);
      await sendData(url, {}, "DELETE");
    },
    onSuccess: (data, sourceData) => {
      client.invalidateQueries({
        queryKey: [WIDGET_JSONAPI_RESOURCE_TYPE],
      });
    },
  });
};
