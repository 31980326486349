/// <reference types="../../definitions/index"/>

export * from "./asset_event_urls";
export * from "./asset_urls";
export * from "./comment_urls";
export * from "./context_state_machines";
export * from "./device_urls";
export * from "./kpi_urls";
export * from "./location_urls";
export * from "./maintenance_jobs_urls";
export * from "./maintenance_plan_urls";
export * from "./measurement_plan_urls";
export * from "./notification_setting_urls";
export * from "./organization_urls";
export * from "./report_plan_urls";
export * from "./role_urls";
export * from "./sensor_urls";

export * from "./widget_urls";

export function activeStorageDownloadUrl(activeStorageUrl: string) {
  if (activeStorageUrl.indexOf("?") == -1) {
    return `${activeStorageUrl}?disposition=attachment`;
  } else {
    return `${activeStorageUrl}&disposition=attachment`;
  }
}
