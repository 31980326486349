import { useMutation, useQueryClient } from "@tanstack/react-query";
import { SingleResourceDoc } from "jsonapi-typescript";
import { createQuery } from "react-query-kit";
import { ASSET_JSONAPI_RESOURCE_TYPE } from "../json_api/asset";
import {
  ASSET_JSONAPI_UPDATE_ATTRIBUTES,
  DEVICE_JSONAPI_RESOURCE_TYPE,
  DeviceJsonApiIncludes,
  DeviceJSONObject,
} from "../json_api/device";
import { jsonApiSingleResourceToFlatObject } from "../json_api/jsonapi_tools";
import { api_device_path, api_devices_path } from "../routes";
import { loadDataFromUrl, sendJsonApiData } from "../utils/jquery_helper";
import {
  addHasOneRelationToJsonApiSubmitData,
  buildJsonApiSubmitData,
  JSONApiFormRequestMode,
} from "../utils/jsonapi_form_tools";
import { IDType } from "../utils/urls/url_utils";

interface EditDeviceOptions {
  device: DeviceJSONObject;
}

function buildSubmitData(device: DeviceJSONObject): {
  mode: JSONApiFormRequestMode;
  submitData?: SingleResourceDoc<string, DeviceJSONObject>;
} {
  const submitData = buildJsonApiSubmitData<Partial<DeviceJSONObject>>(
    device,
    "devices",
    ASSET_JSONAPI_UPDATE_ATTRIBUTES,
  );
  const { submitData: submitDataDoc, mode } = submitData;
  // set the model_for attribute to the default value if it is not set - defaults to asset

  if (mode == "create") {
    addHasOneRelationToJsonApiSubmitData(
      submitDataDoc,
      "asset",
      ASSET_JSONAPI_RESOURCE_TYPE,
      device.asset_id,
      false,
    );
  }

  // addHasOneRelationToJsonApiSubmitData(
  //   submitDataDoc,
  //   "organization",
  //   ORGANIZATION_JSONAPI_RESOURCE_TYPE,
  //   // prefer the passed object, but fallback to the one in the device
  //   selectedOrganization?.id || device.organization_id,
  //   true,
  // );

  return {
    submitData: submitDataDoc as SingleResourceDoc<string, DeviceJSONObject>,
    mode,
  };
}

export const useDeleteDevice = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: IDType) => {
      return sendJsonApiData(api_device_path(id), null, "DELETE");
    },
    onSuccess(res) {
      queryClient.invalidateQueries({
        queryKey: [DEVICE_JSONAPI_RESOURCE_TYPE],
      });
      return res;
    },
  });
};

export const useCreateDevice = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ device }: EditDeviceOptions) => {
      const jsonAPISubmitData = buildSubmitData(device);
      const { mode, submitData } = jsonAPISubmitData;
      if (mode !== "create") {
        throw new Error("Device ID is required for create");
      }

      addHasOneRelationToJsonApiSubmitData(
        submitData,
        "asset",
        ASSET_JSONAPI_RESOURCE_TYPE,
        device.asset_id || device.asset?.id,
        false,
      );
      return await sendJsonApiData<
        unknown,
        SingleResourceDoc<string, DeviceJSONObject>
      >(api_devices_path(), submitData, "POST");
    },
    onError: (createError) => {
      console.error("Error creating device", createError);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [DEVICE_JSONAPI_RESOURCE_TYPE],
      });
      return res;
    },
  });
};

export const useUpdateDevice = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ device }: EditDeviceOptions) => {
      const jsonAPISubmitData = buildSubmitData(device);
      const { mode, submitData } = jsonAPISubmitData;
      if (mode !== "update") {
        throw new Error("Device ID is required for update");
      }

      addHasOneRelationToJsonApiSubmitData(
        submitData,
        "asset",
        ASSET_JSONAPI_RESOURCE_TYPE,
        device.asset_id || device.asset?.id,
        false,
      );
      return await sendJsonApiData<
        unknown,
        SingleResourceDoc<string, DeviceJSONObject>
      >(api_device_path(device.id), submitData, "PATCH");
    },
    onError: (errors) => {
      console.error("Error updating device", errors);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [DEVICE_JSONAPI_RESOURCE_TYPE],
      });
      return res;
    },
  });
};

export const useLoadDeviceQuery = createQuery<
  DeviceJSONObject,
  { id: IDType; include: DeviceJsonApiIncludes[] }
>({
  queryKey: [DEVICE_JSONAPI_RESOURCE_TYPE],

  fetcher: async ({ id, include = [] }) => {
    const doc = await loadDataFromUrl<
      SingleResourceDoc<string, DeviceJSONObject>
    >(api_device_path(id, { _options: true, include: include.join(",") }));
    return jsonApiSingleResourceToFlatObject(doc);
  },
});
