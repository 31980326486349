import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import BackIcon from "@mui/icons-material/NavigateBefore";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { defaultTo, find, get, isEmpty, isNil, set, toString } from "lodash";
import * as React from "react";

import { MeasurementCategorization } from "../../../models/measurement_categorization";
import { MeasurementPlan } from "../../../models/measurement_plan";
import { MeasurementTypes } from "../../../models/measurement_type";
import { MeasurementValueDefinition } from "../../../models/measurement_value_definition";
import { dialog } from "../../../utils/dialog";
import { TypedErrorMap } from "../../../utils/error_map";
import { FixedBottomArea } from "../../common/fixed_bottom_area";
import { FloatingButton, FloatingButtons } from "../../common/floating_buttons";
import { IBox, IBoxContent, IBoxTitle } from "../../common/ibox";
import { MeasurementUnitSelect } from "../../common/measurement_unit_select";
import { NotificationSettingsInput } from "../../common/notification_settings_input";
import { RotationInput } from "../../common/rotation_input/rotation_input";
import { FormMode } from "../data/measurement_plan_actions";
import { MeasurementPlanCreationMode } from "./measurement_plan_form_step_1";
import { MeasurementValueDefinitionList } from "./measurement_value_definition_list";

interface MeasurementPlanDetailsFormProperties {
  measurementPlan: MeasurementPlan;

  createBy: MeasurementPlanCreationMode;
  typeOfMeasurement?: MeasurementTypes;
  allowMvdDelete?: boolean;
  allowEdit?: boolean;
  mode: FormMode;

  // measurement unit in case of distribution measurements
  unit?: string;
  // unit for intervall values

  availableMeasurementCategorizations: MeasurementCategorization[];
  processing?: boolean;

  onAddMeasurementValueDefinition?: () => void;
  onUpdateMeasurementValueDefinition?: (
    updatedMvd: MeasurementValueDefinition,
    index: number,
  ) => void;
  onMoveMeasurementValueDefinition?: (index: number, direction: 1 | -1) => void;
  onRemoveMeasurementValueDefinition?: (
    mvd: MeasurementValueDefinition,
    index: number,
  ) => void;

  onCategorizationSelect: (mCat: MeasurementCategorization) => void;
  onUpdateMeasurementPlan: (mPlan: MeasurementPlan) => void;
  onUnitSelect: (unit: string) => void;
  onSubmit?: (plan: MeasurementPlan) => void;
  onCancel?: () => void;
  onBack?: () => void;
  onToggleEdit?: (editable: boolean) => void;
  onDelete?: () => void;
  errors?: TypedErrorMap<MeasurementPlan>;
}

function setMeasurementPlanAttribute(
  oldData: MeasurementPlan,
  attributeName: keyof MeasurementPlan,
  attributeValue: any,
  onUpdateMeasurementPlan: (measurementPlan: MeasurementPlan) => void,
) {
  onUpdateMeasurementPlan({
    ...oldData,
    [attributeName]: attributeValue,
  });
}

function handleCategorizationSelect(
  props: MeasurementPlanDetailsFormProperties,
  categoryId: string,
) {
  const selectedCategorization =
    categoryId == ""
      ? null
      : props?.availableMeasurementCategorizations.find(
          (categorization) => categorization.id == categoryId,
        );

  const anAssignedCategory = find(
    props.measurementPlan?.measurement_type?.measurement_value_definitions,
    (mvd) => !isNil(mvd.measurement_category_id),
  );

  if (
    (!isNil(
      props.measurementPlan?.measurement_type?.measurement_categorization,
    ) && // category was assigned prior
      !isNil(anAssignedCategory) && // at least one category has been assigned
      selectedCategorization?.id !=
        props.measurementPlan?.measurement_type?.measurement_categorization
          ?.id) ||
    (isNil(selectedCategorization) && !isNil(anAssignedCategory))
  ) {
    // no category was selected and categrories are assigned)
    // categorization change !!! Will remove all categories
    void dialog
      .fire({
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: I18n.t("frontend.no"),
        confirmButtonText: I18n.t("frontend.yes"),
        title: I18n.t(
          "frontend.measurement_plan_form.category_select_popup.popup_title",
        ),
        html: I18n.t(
          "frontend.measurement_plan_form.category_select_popup.confirm_text",
        ),
      })
      .then((result) => {
        if (!result.isConfirmed) {
          return;
        }
        props.onCategorizationSelect(selectedCategorization);
      });
  } else {
    props.onCategorizationSelect(selectedCategorization);
  }
}
/**
 * A list of maintenace plans of an asset.
 */
export const MeasurementPlanDetailsForm: React.FC<
  MeasurementPlanDetailsFormProperties
> = (props) => {
  const measurementValueDefintions = get(props, [
    "measurementPlan",
    "measurement_type",
    "measurement_value_definitions",
  ]);
  const measurementType = defaultTo(
    get(props, ["measurementPlan", "measurement_type"]),
    { measurement_value_definitions: [] },
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} key="base-data-row">
        <IBox>
          <IBoxTitle>
            <h4>
              {I18n.t(
                "frontend.measurement_plan_form.create_measurement_plan_step_2_heading",
                {},
              )}
            </h4>
          </IBoxTitle>
          <IBoxContent>
            <Grid container spacing={2}>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12} xl={6}>
                  <TextField
                    inputProps={{ readOnly: props.allowEdit === false }}
                    fullWidth={true}
                    id="name-text-field"
                    size="small"
                    label={I18n.t(
                      "activerecord.attributes.measurement_plan.title",
                    )}
                    required={true}
                    value={toString(
                      props.measurementPlan?.measurement_type?.title,
                    )}
                    error={
                      !isNil(props.errors?.measurement_type) &&
                      !isEmpty(props.errors?.measurement_type?.title)
                    }
                    helperText={
                      isNil(props.errors?.measurement_type)
                        ? ""
                        : props.errors?.measurement_type?.title
                    }
                    onChange={(changeEvent) => {
                      setMeasurementPlanAttribute(
                        props.measurementPlan,
                        "measurement_type",
                        {
                          ...props.measurementPlan.measurement_type,
                          title: changeEvent.target.value,
                        },
                        props.onUpdateMeasurementPlan,
                      );
                    }}
                  ></TextField>
                </Grid>
                <Grid item xs={12} xl={6}>
                  <RotationInput
                    rrule={props.measurementPlan.rrule}
                    rotationType="planned_after_time"
                    allowChooseRotationType={false}
                    allowEdit={props.allowEdit}
                    onApplyChange={(rrule, rotationType, criticalValue) => {
                      setMeasurementPlanAttribute(
                        props.measurementPlan,
                        "rrule",
                        rrule,
                        props.onUpdateMeasurementPlan,
                      );
                    }}
                    showDelete={true}
                    showLabel={false}
                  />
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={12} xl={6}>
                  <NotificationSettingsInput
                    value={props.measurementPlan.notification_setting}
                    options={[
                      "none",
                      "one_day_ahead",
                      "two_days_ahead",
                      "one_week_ahead",
                    ]}
                    allowEdit={props.allowEdit}
                    error={props.errors?.notification_setting}
                    onChange={(value) =>
                      setMeasurementPlanAttribute(
                        props.measurementPlan,
                        "notification_setting",
                        value,
                        props.onUpdateMeasurementPlan,
                      )
                    }
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} lg={4} sx={{ paddingTop: 0 }}>
                <FormGroup>
                  <FormControlLabel
                    label={I18n.t(
                      "frontend.measurement_plan_form.allow_attachments",
                    )}
                    control={
                      <Checkbox
                        size="small"
                        checked={
                          props.measurementPlan?.measurement_type
                            ?.allow_attachments == true
                        }
                        value="allow_attachments"
                        onChange={(changeEvent) => {
                          setMeasurementPlanAttribute(
                            props.measurementPlan,
                            "measurement_type",
                            {
                              ...props.measurementPlan?.measurement_type,
                              allow_attachments: changeEvent.target.checked,
                            },
                            props.onUpdateMeasurementPlan,
                          );
                        }}
                      />
                    }
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12} lg={4}>
                <FormGroup>
                  <FormControlLabel
                    label={I18n.t(
                      "frontend.measurement_plan_form.allow_measurement_notes",
                    )}
                    control={
                      <Checkbox
                        size="small"
                        checked={
                          props.measurementPlan.measurement_type
                            ?.allow_measurement_notes == true
                        }
                        value="allow_measurement_notes"
                        onChange={(changeEvent) => {
                          setMeasurementPlanAttribute(
                            props.measurementPlan,
                            "measurement_type",
                            {
                              ...props.measurementPlan?.measurement_type,
                              allow_measurement_notes:
                                changeEvent.target.checked,
                            },
                            props.onUpdateMeasurementPlan,
                          );
                        }}
                      />
                    }
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextField
                  fullWidth={true}
                  inputProps={{ readOnly: props.allowEdit === false }}
                  label={I18n.t(
                    "activerecord.attributes.measurement_types/measurement_type.key",
                  )}
                  size="small"
                  value={
                    props.measurementPlan?.key ||
                    props.measurementPlan?.measurement_type?.key ||
                    ""
                  }
                  helperText={
                    isNil(props.errors?.key)
                      ? I18n.t("frontend.measurement_plan_form.key_help")
                      : props.errors?.key
                  }
                  error={
                    !isNil(props.errors?.key) && !isEmpty(props.errors?.key)
                  }
                  placeholder={I18n.t(
                    "frontend.measurement_plan_form.key_placeholder",
                  )}
                  onChange={(changeEvent) => {
                    props.onUpdateMeasurementPlan({
                      ...props.measurementPlan,
                      key: changeEvent.target.value,
                      measurement_type: {
                        ...props.measurementPlan?.measurement_type,
                        key: changeEvent.target.value,
                      },
                    });
                  }}
                />
              </Grid>
            </Grid>
          </IBoxContent>
        </IBox>
      </Grid>
      <Grid item xs={12} key="props-row">
        {props.typeOfMeasurement ===
        "MeasurementTypes::DistributionMeasurementType" ? (
          <IBox key="ibox-add-props">
            <IBoxContent>
              <Grid container>
                <Grid item xs={12} className="mt-3">
                  <MeasurementUnitSelect
                    allowEdit={props.allowEdit && props.createBy !== "template"}
                    unit={toString(props.unit)}
                    id={`select-unit-select`}
                    labelText={I18n.t(
                      "frontend.measurement_plan_form.select_measurement_value_unit",
                    )}
                    onChange={(newUnit) => {
                      if (!isNil(props.onUpdateMeasurementValueDefinition)) {
                        props.onUnitSelect?.(newUnit);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} className="mt-3">
                  <MeasurementUnitSelect
                    allowEdit={props.allowEdit && props.createBy !== "template"}
                    unit={
                      isEmpty(
                        props.measurementPlan?.measurement_type?.interval_unit,
                      )
                        ? ""
                        : props.measurementPlan?.measurement_type?.interval_unit
                    }
                    id={`select-interval-unit-label`}
                    labelText={I18n.t(
                      "frontend.measurement_plan_form.select_interval_unit",
                    )}
                    onChange={(newUnit) => {
                      if (!isNil(props.onUpdateMeasurementValueDefinition)) {
                        setMeasurementPlanAttribute(
                          props.measurementPlan,
                          "measurement_type",
                          {
                            ...props.measurementPlan?.measurement_type,
                            interval_unit: newUnit,
                          },
                          props.onUpdateMeasurementPlan,
                        );
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} className="mt-3">
                  <TextField
                    select
                    fullWidth
                    label={I18n.t(
                      "frontend.measurement_plan_form.select_categorization",
                    )}
                    disabled={
                      props.createBy === "template" || props.allowEdit === false
                    }
                    id="select-categorization-select"
                    value={toString(
                      props.measurementPlan?.measurement_type
                        ?.measurement_categorization?.id,
                    )}
                    onChange={(event) => {
                      handleCategorizationSelect(props, event.target.value);
                    }}
                  >
                    <MenuItem key="none" value="">
                      <i>{I18n.t("frontend.no_selection")}</i>
                    </MenuItem>
                    {props.availableMeasurementCategorizations?.map(
                      (cat, index) => (
                        <MenuItem key={index} value={cat.id}>
                          {cat.title}
                        </MenuItem>
                      ),
                    )}
                  </TextField>
                </Grid>
              </Grid>
            </IBoxContent>
          </IBox>
        ) : null}
      </Grid>
      <Grid item xs={12} key="value-list-row">
        <IBox>
          <IBoxTitle>
            <h4>
              {props.typeOfMeasurement ===
              "MeasurementTypes::DistributionMeasurementType"
                ? I18n.t(
                    "frontend.measurement_plan_form.distribution_intervals_heading",
                  )
                : I18n.t(
                    "frontend.measurement_plan_form.measurement_values_heading",
                  )}
            </h4>
          </IBoxTitle>
          <IBoxContent>
            <Grid container>
              <Grid item xs={12}>
                <MeasurementValueDefinitionList
                  errors={
                    props.errors?.measurement_type
                      ?.measurement_value_definitions
                  }
                  allowEdit={props.allowEdit}
                  unit={props.unit}
                  interval_unit={
                    props.measurementPlan?.measurement_type?.interval_unit
                  }
                  allowDelete={props.allowMvdDelete}
                  categorization={
                    props.measurementPlan.measurement_type
                      .measurement_categorization
                  }
                  measurementValueDefinitions={
                    props.measurementPlan.measurement_type
                      .measurement_value_definitions
                  }
                  typeOfMeasurement={props.typeOfMeasurement}
                  onAddMeasurementValueDefinition={
                    props.onAddMeasurementValueDefinition
                  }
                  onUpdateMeasurementValueDefinition={
                    props.onUpdateMeasurementValueDefinition
                  }
                  onMoveMeasurementValueDefinition={
                    props.onMoveMeasurementValueDefinition
                  }
                  onRemoveMeasurementValueDefinition={
                    props.onRemoveMeasurementValueDefinition
                  }
                ></MeasurementValueDefinitionList>
              </Grid>
            </Grid>
          </IBoxContent>
        </IBox>
      </Grid>
      <FixedBottomArea key="bot-buttons">
        <FloatingButtons
          showScrollToTopBtn={true}
          isProcessing={props.processing}
          onCancel={props.allowEdit === false ? null : props.onCancel}
          onSubmit={
            props.allowEdit === false
              ? null
              : () => {
                  props.onSubmit(props.measurementPlan);
                }
          }
        >
          {" "}
          <Grid container>
            {isNil(props.onBack) ? (
              <></>
            ) : (
              <Grid item xs={12}>
                <FloatingButton
                  title={I18n.t("frontend.back")}
                  size="medium"
                  //color="primary"
                  onClick={() => {
                    if (props.onBack) props.onBack();
                  }}
                >
                  <BackIcon />
                </FloatingButton>
              </Grid>
            )}
            {!isNil(props.onToggleEdit) && props.allowEdit !== false ? (
              <></>
            ) : (
              <Grid item xs={12}>
                <FloatingButton
                  title={I18n.t("frontend.edit")}
                  disabled={props.processing}
                  size="medium"
                  onClick={() => {
                    if (props.onToggleEdit) props.onToggleEdit(true);
                  }}
                >
                  <EditIcon />
                </FloatingButton>
              </Grid>
            )}
            {props.mode === "create" || isNil(props.onDelete) ? (
              <></>
            ) : (
              <Grid item xs={12}>
                <FloatingButton
                  disabled={props.processing}
                  title={I18n.t("frontend.delete")}
                  size="medium"
                  color="secondary"
                  onClick={() => {
                    if (props.onDelete) props.onDelete();
                  }}
                >
                  <DeleteIcon />
                </FloatingButton>
              </Grid>
            )}
          </Grid>
        </FloatingButtons>
      </FixedBottomArea>
    </Grid>
  );
};
