import React, { useState } from "react";

import { Box, Grid, Paper, Typography } from "@mui/material";
import { DeviceForm } from "../../components/devices/device_form";
import { DeviceJsonApiAttributes } from "../../json_api/device";
import { ResourcePermission } from "../../models/resource_permission";
import { IDType } from "../../utils/urls/url_utils";
interface DeviceFormProps {
  deviceId?: IDType;
  assetId?: IDType;
  organizationId?: IDType;
  permissions: ResourcePermission;
  onSave: (device: Partial<DeviceJsonApiAttributes>) => void;
}

export const DeviceEditPage: React.FC<DeviceFormProps> = ({
  deviceId,
  assetId,
  organizationId,
  onSave,
}) => {
  return (
    <Paper>
      <Box p={2}>
        <Grid container>
          <Grid item xs={12}>
            <DeviceForm
              onSave={onSave}
              deviceId={deviceId}
              assetId={assetId}
              organizationId={organizationId}
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};
