import { MenuItem, TextField } from "@mui/material";
import { MeasurementTypeJsonObject } from "../../../json_api/measurement_type";
import { MeasurementTypeDiagramType } from "../../../models/measurement_type";
import * as React from "react";
interface MeasurementGraphTypeSelectProps {
  graphType: MeasurementTypeDiagramType;
  measurementType: MeasurementTypeJsonObject;
  onChangeGraphType: (graphType: MeasurementTypeDiagramType) => void;
}

export const MeasurementGraphTypeSelect: React.FunctionComponent<
  MeasurementGraphTypeSelectProps
> = ({ graphType, measurementType, onChangeGraphType }) => {
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChangeGraphType(event.target.value as MeasurementTypeDiagramType);
  };

  const [singleMeasurementDiagramType, setSingleMeasurementDiagramType] =
    React.useState<MeasurementTypeDiagramType>(graphType);

  React.useEffect(() => {
    if (
      !singleMeasurementDiagramType &&
      measurementType?.type_short === "distribution_measurement_type"
    ) {
      setSingleMeasurementDiagramType("pie");
    } else {
      setSingleMeasurementDiagramType("line");
    }
  }, [measurementType]);

  React.useEffect(() => {
    if (singleMeasurementDiagramType !== graphType) {
      setSingleMeasurementDiagramType(graphType);
    }
  }, [graphType]);

  React.useEffect(() => {
    if (onChangeGraphType) {
      onChangeGraphType(singleMeasurementDiagramType);
    }
  }, [singleMeasurementDiagramType]);
  return (
    <TextField
      label={I18n.t("activerecord.attributes.measurement_type.diagram_type")}
      value={singleMeasurementDiagramType}
      select
      size="small"
      onChange={(e) => {
        setSingleMeasurementDiagramType(
          e.target.value as MeasurementTypeDiagramType,
        );
      }}
    >
      <MenuItem value="line">
        {I18n.t("activerecord.attributes.measurement_type.diagram_types.line")}
      </MenuItem>
      <MenuItem value="bar">
        {I18n.t("activerecord.attributes.measurement_type.diagram_types.bar")}
      </MenuItem>
      <MenuItem value="radar">
        {I18n.t("activerecord.attributes.measurement_type.diagram_types.radar")}
      </MenuItem>
      <MenuItem value="pie">
        {I18n.t("activerecord.attributes.measurement_type.diagram_types.pie")}
      </MenuItem>
    </TextField>
  );
};
