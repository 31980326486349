import { capitalize, concat, isNil, toUpper, upperCase } from "lodash";

import {
  add_asset_organization_user_group_path,
  remove_asset_organization_user_group_path,
} from "../routes";
import { IDType } from "../utils/urls/url_utils";
import { RequestMethod } from "../utils/jquery_helper";

export interface ItemAction {
  type: "action" | "route";
  route?: string;
  title?: string;
  action?: string;
  icon?: string;
  tooltip?: string;
  method: RequestMethod; // 'put' | 'post' | 'get' | 'delete';
  api: boolean;
  reload_on_success?: boolean;
  confirm?: string;
  params: string[];
}

const allowedRoutes: Record<
  string,
  {
    routeFn: (...args: any) => string;
    method: RequestMethod;
  }
> = {
  remove_asset_organization_user_group_path: {
    routeFn: remove_asset_organization_user_group_path,
    method: "DELETE",
  },
  add_asset_organization_user_group_path: {
    routeFn: add_asset_organization_user_group_path,
    method: "POST",
  },
};

export function generateLinkForItemActionRoute(
  routesName: string,
  params: IDType[],
  itemId: IDType,
): {
  route: string;
  method?: RequestMethod;
} {
  if (isNil(allowedRoutes[routesName])) {
    throw new Error(`Named Route ${routesName} not allowed or unknown`);
  }
  const paramsWithId = concat(params, [itemId]);
  return {
    route: allowedRoutes[routesName].routeFn(...paramsWithId),
    method: toUpper(allowedRoutes[routesName].method),
  };
}
