/// <reference types="../../../definitions/index" />;
import { isEmpty, isNil } from "lodash";

import { Box, Grid, Typography } from "@mui/material";
import { Moment } from "moment";
import * as React from "react";
import {
  ChartStatistics,
  computeChartStatisticsForData,
} from "../../../charting/chart_data/chart_data_statistics";
import { getDiagramLineColor } from "../../../charting/diagram_constants";
import { MeasurementChartDataCreator } from "../../../charting/measurements/measurement_chart_data_creator";
import { PlotlyLineChart } from "../../../charting/plotly_line_chart";
import { Plotly } from "../../../charting/plotly_package";
import { ChartStatisticsDisplay } from "../../../charting/react/chart_statistics_display";
import { MeasurementTypeJsonAttributes } from "../../../json_api/measurement_type";
import { MeasurementValueAttributes } from "../../../models/measurement";
import { MeasurementCategory } from "../../../models/measurement_category";
import { MeasurementValueDefinition } from "../../../models/measurement_value_definition";
import { ReferenceMeasurementValue } from "../utils/measurement_reference_eval";
import { logger } from "../../../utils/logger";

export interface MeasurementValueDiagramProps {
  title?: string;
  measurementValueDefinitions: MeasurementValueDefinition[];
  measurementCategories: MeasurementCategory[];
  measurementValues: MeasurementValueAttributes[];
  measurementType: MeasurementTypeJsonAttributes;
  referenceMeasurementValues?: ReferenceMeasurementValue[];

  mvdIntervalUnit: string;
  startTime?: Moment;
  endTime?: Moment;
  groupMeasurementValues?: boolean;

  diagramMode?: "linePerTime" | "linePerValue";
}

export interface MeasurementValueDiagramState {
  isPrinting: boolean;
  diagramDataUrl: string;

  chartData: {
    chartData: Partial<Plotly.PlotData>[];
    statistics: ChartStatistics[];
    xAxisLayout: Partial<Plotly.Layout>;
  };
}

export class MeasurementValueDiagram extends React.Component<
  MeasurementValueDiagramProps,
  MeasurementValueDiagramState
> {
  static defaultProps: Partial<MeasurementValueDiagramProps> = {
    title: "",
    measurementValues: [],
  };

  private diagramElement: HTMLDivElement;
  private dataRevision = 0;
  private uiRevision = 0;

  constructor(props: MeasurementValueDiagramProps) {
    super(props);

    this.state = {
      isPrinting: false,
      diagramDataUrl: null,
      chartData: this.buildData(props.diagramMode === "linePerTime"),
    };
  }

  buildData(perTimeData: boolean = false) {
    try {
      const chartDataCreator = new MeasurementChartDataCreator();
      const isDistribution =
        this.props.measurementType.type_short ===
        "distribution_measurement_type";
      if (perTimeData) {
        if (this.props.groupMeasurementValues) {
          chartDataCreator.createChartDataWithLinesPerTimeAndGrouped(
            this.props.measurementValueDefinitions,
            this.props.measurementCategories,
            this.props.measurementValues,
            this.props.mvdIntervalUnit,
            this.props.referenceMeasurementValues,
            isDistribution,
          );
        } else {
          chartDataCreator.createChartDataWithLinesPerTime(
            this.props.measurementValueDefinitions,
            this.props.measurementCategories,
            this.props.measurementValues,
            this.props.mvdIntervalUnit,
            this.props.referenceMeasurementValues,
            isDistribution,
          );
        }
      } else {
        this.props.groupMeasurementValues
          ? chartDataCreator.createCategoryChartData(
              this.props.measurementValueDefinitions,
              this.props.measurementCategories,
              this.props.measurementValues,
              this.props.mvdIntervalUnit,
              this.props.referenceMeasurementValues,
              isDistribution,
            )
          : chartDataCreator.createChartData(
              this.props.measurementValueDefinitions,
              this.props.measurementCategories,
              this.props.measurementValues,
              this.props.mvdIntervalUnit,
              this.props.referenceMeasurementValues,
              isDistribution,
            );
      }
      const chartData = chartDataCreator.getChartData();

      return {
        chartData,
        statistics: computeChartStatisticsForData(
          null,
          chartData,
          this.props.diagramMode === "linePerTime" ? "string" : "time",
          "numeric",
        ),
        xAxisLayout: chartDataCreator.getAxisLayout(),
      };
    } catch (err) {
      logger.error(err);
      return null;
    }
  }

  componentDidMount(): void {}

  componentWillUnmount() {}

  componentDidUpdate(prevProps: MeasurementValueDiagramProps) {
    if (
      prevProps.measurementValueDefinitions !==
        this.props.measurementValueDefinitions ||
      prevProps.measurementValues !== this.props.measurementValues ||
      prevProps.groupMeasurementValues !== this.props.groupMeasurementValues ||
      prevProps.diagramMode !== this.props.diagramMode ||
      prevProps.referenceMeasurementValues !==
        this.props.referenceMeasurementValues
    ) {
      this.uiRevision++;
      this.dataRevision++;
      try {
        this.setState(
          {
            chartData: this.buildData(this.props.diagramMode === "linePerTime"),
          },
          () => this.updateDiagramData(),
        );
      } catch (err) {
        logger.error(err);
      }
    }
  }

  render(): React.ReactNode {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="caption">
            {this.props.diagramMode === "linePerValue"
              ? I18n.t(
                  "frontend.measurements.measurement_value_diagram.measurements_over_time",
                )
              : I18n.t(
                  "frontend.measurements.measurement_value_diagram.measurement_value_time_diagram",
                )}
          </Typography>
          <Box
            ref={(de: HTMLDivElement) => {
              this.updatePlotlyDiagram(de);
            }}
            minHeight={400}
          />
        </Grid>

        {isNil(this.state.chartData?.statistics) ? null : (
          <Grid item xs={12}>
            <ChartStatisticsDisplay
              chartStatistics={this.state.chartData?.statistics}
            />
          </Grid>
        )}
      </Grid>
    );
  }

  private updatePlotlyDiagram(element: HTMLDivElement): void {
    if (element !== this.diagramElement && !isNil(this.diagramElement)) {
      Plotly.purge(this.diagramElement);
    }

    if (this.diagramElement === element) {
      return;
    }
    this.diagramElement = element;

    if (isNil(this.diagramElement)) {
      return;
    }

    this.updateDiagramData();
  }

  updateDiagramData(): void {
    if (
      isNil(this.diagramElement) ||
      isEmpty(this.state.chartData?.chartData)
    ) {
      return;
    }

    const de = Plotly.react(
      this.diagramElement,
      this.state.chartData.chartData,
      {
        ...this.createLayout(),
        ...this.state.chartData.xAxisLayout,
      },
      {
        responsive: true,
        displaylogo: false,

        locale: I18n.locale,
        modeBarButtons: PlotlyLineChart.modeBarButtons,
      },
    );
  }

  private createLayout(): Partial<Plotly.Layout> {
    return {
      title: this.props.title,

      hoverlabel: {
        bgcolor: "rgba(200,200,200,0.8)",

        font: {
          family: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
          size: 11,
          color: "rgb(103, 106, 108)",
        },
      },
      margin: {
        t: 40,
        b: 40,
        l: 10,
        r: 10,
      },

      datarevision: this.dataRevision,
      uirevision: this.uiRevision,
      autosize: true,
      showlegend: true,

      legend: {
        orientation: "v",
        yanchor: "top",
        xanchor: "left",
        groupclick: "toggleitem",

        x: 1,
        y: 1,
      },
      xaxis: {
        title: I18n.t("widgets.line_diagram_widget.x_axis_label"),
        linewidth: 1,
        spikethickness: 2,
        automargin: true,
      },
      yaxis: {
        automargin: true,
        autorange: true,
        autorangeoptions: {
          minallowed: this.props.diagramMode == "linePerTime" ? null : 0,
        },
      },
      font: {
        family: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
        size: 11,
        color: "rgb(103, 106, 108)",
      },
    };
  }
}
