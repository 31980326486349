import { Grid } from "@mui/material";
import { isEmpty, isNil, isNumber, merge } from "lodash";
import moment, { Moment } from "moment";
import React, { useCallback, useEffect, useId, useMemo, useState } from "react";
import { PlotlyGaugeChart } from "../../charting/plotly_gauge_chart";
import { WidgetController } from "../../controller/widget_controller";
import { SensorValueType } from "../../models/sensor";
import { getValueRangeForValue } from "../../utils/status_helper";
import { getTimeString } from "../../utils/time_strings";
import { convertToUnit } from "../../utils/unit_conversion";
import { sensorUrl } from "../../utils/urls";
import { LevelIndicatorWidgetConfigSerialized } from "../../widgets/level_indicator_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import { PercentageBar } from "../common/percentage_bar";
import { LevelIndicatorWidgetProps } from "./level_indicator_widget.types";
import { SialogicWidgetDefinition } from "./sialogic_widget_component";
import { loadSensorData } from "./utils/load_sensor_data";
import { WidgetBox } from "./widget_box";
import { WidgetTimestampGridItem } from "./widget_timestamp";

function titleFromProps(
  props: LevelIndicatorWidgetProps,
  fallback: string = "",
): string {
  return isEmpty(props.title) ? fallback : (props.title as string);
}

export const LevelIndicatorWidget: React.FunctionComponent<
  LevelIndicatorWidgetProps
> = ({
  minRange = 0,
  maxRange = 100,
  displayMode = "bar",
  noStepIntervals = false,
  ...props
}: LevelIndicatorWidgetProps) => {
  const [title, setTitle] = useState(titleFromProps(props));
  const [titleLinkUrl, setTitleLinkUrl] = useState(props.titleLinkUrl);
  const [contentLinkUrl, setContentLinkUrl] = useState(props.contentLinkUrl);
  const [value, setValue] = useState(props.value);
  const [status, setStatus] = useState(props.status);
  const [sensor, setSensor] = useState(null);
  const [valueRanges, setValueRanges] = useState(props.valueRanges);
  const [displayUnit, setDisplayUnit] = useState(
    props.displayUnit || props.unit,
  );
  const [timestamp, setTimestamp] = useState(props.timestamp);
  const [currentRange, setCurrentRange] = useState(
    getValueRangeForValue(props.value, props.valueRanges),
  );
  const [fullscreen, setFullscreen] = useState(false);
  const [sensorName, setSensorName] = useState(props.sensorName);
  const [timeRange, setTimeRange] = useState(props.timeRange);

  // Update sensor data when time range changes
  useEffect(() => {
    if (
      ((!props.timeRange?.start ||
        timeRange?.start?.isSame(props.timeRange?.start)) &&
        !props.timeRange?.end) ||
      timeRange?.end?.isSame(props.timeRange?.end)
    ) {
      setTimeRange(props.timeRange);
    }
  }, [props.timeRange?.start, props.timeRange?.end]);

  // Load sensor data when time range changes
  useEffect(() => {
    if (sensor?.id != props.sensorId) {
      // only load new sensors if the sensor id has changed
      void loadSensorData(
        props.sensorId,
        timeRange,
        props.fallbackToLastValue,
        props.ignoreTimeScope,
      ).then((sensorWithValue) => {
        const { sensor, value, status, timestamp } = sensorWithValue || {};
        // set sensor dependent values
        setSensor(sensor);
        setValue(value);
        setStatus(status);
        setTimestamp(moment(timestamp));
      });
    }
  }, [props.sensorId, timeRange?.start, timeRange?.end]);

  // Update sensor data when sensor title changes
  useEffect(() => {
    setTitle(titleFromProps(props, title));
  }, [props.title]);

  // Update sensor data when sensor link url changes
  useEffect(() => {
    setTitleLinkUrl(props.titleLinkUrl);
    setContentLinkUrl(props.contentLinkUrl);
  }, [props.titleLinkUrl, props.contentLinkUrl]);

  // Update sensor data when sensor value changes
  useEffect(() => {
    if (props.value !== value) {
      setValue(props.value);
    }
  }, [props.value]);

  // Update sensor data when sensor status changes
  useEffect(() => {
    setStatus(props.status);
  }, [props.status]);

  // Update sensor data when sensor value ranges change
  useEffect(() => {
    if (props.valueRanges !== valueRanges) {
      setValueRanges(props.valueRanges);
    }
  }, [props.valueRanges]);

  // Update sensor data when sensor display unit changes
  useEffect(() => {
    setDisplayUnit(props.displayUnit);
  }, [props.displayUnit]);

  // Update sensor data when sensor timestamp changes
  useEffect(() => {
    if (props.timestamp !== timestamp) {
      setTimestamp(props.timestamp);
    }
  }, [props.timestamp]);

  // Update sensor data when sensor current value changes
  useEffect(() => {
    if (!isNil(valueRanges)) {
      const currentRange = getValueRangeForValue(value, valueRanges);
      setCurrentRange(currentRange);
    }
  }, [valueRanges, value]);

  // Update sensor name when sensor name changes
  useEffect(() => {
    setSensorName(props.sensorName);
  }, [props.sensorName]);

  // Update status when current range changes
  useEffect(() => {
    setStatus(currentRange?.status);
  }, [currentRange]);

  const handleSensorValueUpdate = useCallback(
    (
      attributeKeyId: number,
      sensorId: number,
      value: SensorValueType,
      time: Moment,
      unit?: string,
    ) => {
      if (
        !isNumber(value) ||
        !props.updateEnabled ||
        sensorId !== props.sensorId ||
        (!isNil(timeRange?.start) && time.isBefore(timeRange?.start)) ||
        (!isNil(timeRange?.end) && time.isAfter(timeRange?.end))
      ) {
        return;
      }

      const targetUnit = props.displayUnit || sensor?.display_unit || unit;
      if (!isNil(unit) && !isNil(unit)) {
        value = convertToUnit(value, unit, targetUnit);
      }

      setValue(value);
      setTimestamp(time);
    },
    [
      timeRange?.start,
      timeRange?.end,
      props.sensorId,
      props.displayUnit,
      sensor?.display_unit,
    ],
  );

  const id = useId();

  const displayComponent = useMemo(() => {
    if (displayMode === "gauge") {
      return (
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <PlotlyGaugeChart
              value={value}
              divId={`widget-${props.widgetId}-diagram-container-${id}`}
              unit={displayUnit || props.unit}
              range={[minRange, maxRange]}
              valueRanges={valueRanges}
              height={fullscreen ? 600 : props.gaugeHeight}
              maxWidth={fullscreen ? "80%" : null}
              noStepIntervals={noStepIntervals}
            />
          </Grid>
          {isNil(timestamp) ? null : (
            <WidgetTimestampGridItem
              timestamp={getTimeString(props.timeScopeName, timestamp)}
              align="center"
            />
          )}
        </Grid>
      );
    } else {
      return (
        <PercentageBar
          value={value}
          max={maxRange}
          unit={props.unit}
          status={status}
          timestamp={getTimeString(props.timeScopeName, timestamp)}
        />
      );
    }
  }, [
    value,
    status,
    displayUnit,
    props.unit,
    props.widgetId,
    props.gaugeHeight,
    noStepIntervals,
    props.timeScopeName,
    maxRange,
    timestamp,
    fullscreen,
    valueRanges,
  ]);

  useEffect(() => {
    if (sensor) {
      // Write sensor information to state.
      setTitle(titleFromProps(props, sensor.name));
      setValueRanges(sensor.value_ranges);
      setDisplayUnit(
        props.displayUnit ||
          sensor.display_unit ||
          sensor.attribute_key_unit ||
          props.unit,
      );
      setTitleLinkUrl(
        sensorUrl(
          {
            id: sensor.id,
            assetId: sensor.asset_id,
          },
          "html",
        ),
      );
    }
  }, [sensor]);

  // Subscribe to sensor data updates
  useEffect(() => {
    const subscribeHandler = { handleSensorValueUpdate };
    let subscriptionId: number;
    if (WidgetController.getInstance()) {
      if (props.updateEnabled && timeRange?.contains(moment())) {
        subscriptionId =
          WidgetController.getInstance().sensorDataChannel.addEventListener(
            subscribeHandler,
            props.sensorId,
          );
      }
    }
    return () => {
      WidgetController.getInstance().sensorDataChannel.removeEventListenerId(
        subscriptionId,
        props.sensorId,
      );
    };
  }, [props.updateEnabled, props.sensorId, timeRange?.start, timeRange?.end]);

  return (
    <WidgetBox
      {...props}
      title={title}
      titleLinkUrl={titleLinkUrl}
      contentTitle={sensorName}
      contentLinkUrl={contentLinkUrl}
      onFullscreen={(fullscreen) => {
        setFullscreen(fullscreen);
      }}
    >
      {displayComponent}
    </WidgetBox>
  );
};

function serializedConfigToProps(
  config: LevelIndicatorWidgetConfigSerialized,
): LevelIndicatorWidgetProps {
  return merge(widgetBoxPropsFromSerializedConfig(config), {
    updateEnabled: !config.disable_update,
    iconName: config.icon_name,
    iconSize: config.icon_size,
    vertical: config.vertical,
    value: config.value,
    valueRanges: config.value_ranges,
    displayMode: config.display_mode ?? "bar",
    unit: config.unit,
    displayUnit: config.display_unit,
    minRange: config.range_min ?? 0,
    maxRange: config.range_max ?? 100,
    noStepIntervals: config.no_step_intervals,
    timestamp: config.timestamp ? moment(config.timestamp) : null,
    measurementType: config.measurement_type,
    sensorType: config.sensor_type,
    sensorId: config.sensor_id,
    ignoreTimeScope: config.ignore_time_scope,
    fallbackToLastValue: config.fallback_to_last_value,
    gaugeHeight: config.gauge_height,
  } as LevelIndicatorWidgetProps);
}

export const LevelIndicatorWidgetDefinition: SialogicWidgetDefinition<
  typeof LevelIndicatorWidget,
  typeof serializedConfigToProps
> = {
  Component: LevelIndicatorWidget,
  serializedConfigToProps: serializedConfigToProps,
};
