import Promise, { CancellationError } from "bluebird";
import {
  isEmpty,
  isFunction,
  isNil,
  keyBy,
  map,
  toArray,
  toInteger,
  toString,
} from "lodash";
import { DateRange } from "moment-range";
Promise.config({ cancellation: true }); // enable cancelation

import { loadStateChangeData } from "../../components/context_state_changes/state_change_data_loader";

import {
  loadDataFromUrl,
  loadDataFromUrlWithQueryClient,
} from "../../utils/jquery_helper";
import { IDType } from "../../utils/urls/url_utils";

import Bluebird from "bluebird";
import {
  ContextStateMachineJSONObject,
  loadContextStateMachine,
} from "../../json_api/context_state_machines";
import { LineDiagramData } from "../../messages/line_diagram_data";
import { LineDiagramStringData } from "../../messages/line_diagram_string_data";

import { CancelledError } from "@tanstack/react-query";
import { logger } from "../../utils/logger";
import {
  AnnotationData,
  BinaryChartData,
  BinaryChartDataLoadResult,
  Dataset,
  NumberOrStringValue,
  QueryTimeSeriesParameters,
  StateData,
  TimeSeriesDataParameter,
  TimeSeriesLoadResult,
  ValueTrendData,
} from "./chart_data_loader.types";
import { search } from "core-js/fn/symbol";

/**
 * Loader class for chart data.
 * This automatically handles running requests and cancels them if new data is requested.
 */
export class ChartDataLoader {
  /**
   * Merges the provided `options` with the `defaultParameter` to create a final `TimeSeriesDataParameter` object.
   *
   * @param options - The primary `TimeSeriesDataParameter` object containing the desired values.
   * @param defaultParameter - An optional `TimeSeriesDataParameter` object containing default values.
   * @returns A new `TimeSeriesDataParameter` object with merged values from `options` and `defaultParameter`.
   */
  static mergeUrlDataParameters(
    options: TimeSeriesDataParameter,
    defaultParameter?: TimeSeriesDataParameter,
  ): TimeSeriesDataParameter {
    const trStart =
      options.timeRange?.start ?? defaultParameter?.timeRange?.start;
    const finalOptions: TimeSeriesDataParameter = {};
    const trEnd = options.timeRange?.end ?? defaultParameter?.timeRange?.end;

    const timeRangeStart =
      options.timeRange?.start ?? defaultParameter?.timeRange?.start;

    const timeRangeEnd =
      options.timeRange?.end ?? defaultParameter?.timeRange?.end;

    if (!isNil(timeRangeStart) || !isNil(timeRangeEnd)) {
      finalOptions.timeRange = new DateRange(timeRangeStart, timeRangeEnd);
    }

    finalOptions.samplingRate =
      options.samplingRate ?? defaultParameter?.samplingRate;

    finalOptions.samplingMode =
      options.samplingMode ?? defaultParameter?.samplingMode;

    finalOptions.offsetYAxis = defaultParameter?.offsetYAxis;

    return finalOptions;
  }
  /**
   * Creates a url query string for given sensor data options
   * @param options The options for the sensor data(time range, sampling rate)
   * @return a url query string
   */
  static getUrlQuery(
    options: TimeSeriesDataParameter,
    searchParams = new URLSearchParams(),
  ): URLSearchParams {
    const params: string[] = [];

    if (
      !isNil(options.timeRange?.start) &&
      options.timeRange?.start.isValid()
    ) {
      searchParams.set("min_time", options.timeRange?.start.toISOString());
    }

    if (!isNil(options.timeRange?.end) && options.timeRange?.end.isValid()) {
      searchParams.set("max_time", options.timeRange?.end.toISOString());
    }

    if (
      !isNil(options.samplingRate?.unit) &&
      !isNil(options.samplingRate?.value) &&
      !isNaN(options.samplingRate.value)
    ) {
      searchParams.set("sampling_rate", options.samplingRate.value.toString());
      searchParams.set("sampling_rate_unit", options.samplingRate.unit);
    }

    const samplingMode = options.samplingMode;
    if (!isNil(samplingMode)) {
      searchParams.set("sampling_mode", samplingMode);
    }

    if (options.offsetYAxis) {
      searchParams.set("offset_y_axis", "true");
    }

    return searchParams;
  }

  static convertBinaryData<
    SourceType extends LineDiagramStringData | LineDiagramData,
  >(
    sourceData: SourceType,
    queryOptions?: QueryTimeSeriesParameters,
  ): BinaryChartData<NumberOrStringValue<SourceType>> {
    let keyId;
    if (typeof sourceData.keyId === "number") keyId = sourceData.keyId;
    else if (typeof sourceData.keyId === "bigint")
      keyId = Number(sourceData.keyId);
    else {
      keyId = toInteger(sourceData.keyId);
    }
    return {
      key_id: keyId,
      series_name: queryOptions?.label?.title ?? sourceData.seriesName,
      unit: sourceData.unit,
      mintime: new Date(sourceData.mintime),
      maxtime: new Date(sourceData.maxtime),
      minvalue: sourceData.minvalue as NumberOrStringValue<SourceType>,
      maxvalue: sourceData.maxvalue as NumberOrStringValue<SourceType>,
      x: sourceData.x.map((x) => new Date(x)),
      y: sourceData.y as NumberOrStringValue<SourceType>[],
      open: sourceData.open as NumberOrStringValue<SourceType>[],
      close: sourceData.close as NumberOrStringValue<SourceType>[],
      low: sourceData.low as NumberOrStringValue<SourceType>[],
      high: sourceData.high as NumberOrStringValue<SourceType>[],
    };
  }
  private runningChartDataRequest: Promise<TimeSeriesLoadResult<Dataset>[]>;
  private runningBinaryChartDataRequest: Promise<
    BinaryChartDataLoadResult<any, number | string>[]
  >;
  private runningValueTrendDataRequest: Promise<ValueTrendData[]>;
  private runningAnnotationDataRequest: Promise<AnnotationData[]>;
  private runningStateDataRequest: Promise<StateData[]>;

  /**
   * Create chart data loader
   */
  constructor() {
    this.runningChartDataRequest = null;
    this.runningBinaryChartDataRequest = null;
    this.runningValueTrendDataRequest = null;
    this.runningAnnotationDataRequest = null;
    this.runningStateDataRequest = null;
  }

  /**
   * Load chart data for given urls.
   * If there is a running request for chart data the previous request is aborted.
   * @param datasetOptions List of urls to load chart data from
   * @param defaultOptions Additional options for chart data(time range, sampling rate)
   * @return A Promise to the chart datasets
   */
  loadChartData<AdditionalInfo = any>(
    datasetOptions: Array<{ baseUrl: string } & TimeSeriesDataParameter>,
    defaultOptions: TimeSeriesDataParameter,
    additionalInfo?: AdditionalInfo[],
  ): Promise<TimeSeriesLoadResult<Dataset, AdditionalInfo>[]> {
    // cancel running request
    if (
      !isNil(this.runningChartDataRequest) &&
      isFunction(this.runningChartDataRequest.cancel)
    ) {
      this.runningChartDataRequest.cancel();
    }

    const queryOptions = map(datasetOptions, (options, index) => {
      const mergedDatasetOptions = ChartDataLoader.mergeUrlDataParameters(
        options,
        defaultOptions,
      );
      const url = URL.parse(
        options.baseUrl,
        options.baseUrl.startsWith("/") ? window.location.origin : undefined,
      );
      ChartDataLoader.getUrlQuery(mergedDatasetOptions, url.searchParams);
      return {
        options: mergedDatasetOptions,
        url: url.toString(),
        additionalInfo: additionalInfo ? additionalInfo[index] : null,
      };
    });
    this.runningChartDataRequest = Promise.mapSeries(
      queryOptions,
      (options) => {
        return loadDataFromUrlWithQueryClient<Dataset>(
          [options.url],
          options.url,
          "json",
          options.options.timeRange?.contains(moment()) ? 30000 : 120000,
        ).then((data) => ({
          data,
          options: options.options,
          additionalInfo: options.additionalInfo,
        }));
      },
    ).then((datasetLoadResults) => {
      this.runningChartDataRequest = null; // request completed

      return datasetLoadResults;
    });

    return this.runningChartDataRequest;
  }

  /** Loads
   *
   *
   * @param {(Array<{ baseUrl: string } & TimeSeriesDataParameter>)} datasetOptions Options valid for the current dataset
   * @param {TimeSeriesDataParameter} defaultOptions Default Options overriding / extending the dataset options
   * @return {*}  {Promise<BinaryChartDataLoadResult[]>}
   * @memberof ChartDataLoader
   */
  loadBinaryChartData<AdditionalInfo>(
    datasetOptions: Array<
      {
        baseUrl: string;
        dataType?: "number" | "text";
      } & TimeSeriesDataParameter
    >,
    defaultOptions?: TimeSeriesDataParameter,
    additionalInfo?: AdditionalInfo[],
  ): Bluebird<BinaryChartDataLoadResult<AdditionalInfo, number | string>[]> {
    // cancel running request
    if (!isNil(this.runningBinaryChartDataRequest)) {
      this.runningBinaryChartDataRequest
        .catch(CancellationError, (e) => {
          // ignore the cancellation error as we dont't care
        })
        .catch(CancelledError, (e) => {
          // ignore the cancellation error as we dont't care
        })
        .cancel();
    }

    const queryOptions = map(datasetOptions, (options, index) => {
      const mergedDatasetOptions = ChartDataLoader.mergeUrlDataParameters(
        options,
        defaultOptions,
      );
      const base = options.baseUrl.endsWith("?")
        ? options.baseUrl.slice(0, options.baseUrl.length - 1)
        : options.baseUrl;
      const url = URL.parse(
        base,
        base.startsWith("/") ? window.location.origin : undefined,
      );
      ChartDataLoader.getUrlQuery(mergedDatasetOptions, url.searchParams);
      return {
        options: mergedDatasetOptions,
        dataType: options.dataType,
        url: url.toString(),

        additionalInfo: additionalInfo ? additionalInfo[index] : null,
      };
    });

    this.runningBinaryChartDataRequest = Bluebird.mapSeries(
      queryOptions ?? [],

      (datasetOption) => {
        return loadDataFromUrlWithQueryClient<ArrayBuffer>(
          [datasetOption.url],
          datasetOption.url,
          "bin",
          // cache data for one minute if the current time is in the time range otherwise cache for 4 minutes
          datasetOption.options.timeRange?.contains(moment())
            ? 1000 * 60
            : 1000 * 60 * 4,
        ).then((buffer) => {
          let data: LineDiagramStringData | LineDiagramData;
          try {
            // parse protobuffer and convert dates
            const DataKlass =
              datasetOption.dataType == "text"
                ? LineDiagramStringData
                : LineDiagramData;
            data = DataKlass.fromBinary(new Uint8Array(buffer));
          } catch (e) {
            // try to parse as string data ... maybe we did not pass the dataType
            if (e.name == "RangeError") {
              data = LineDiagramStringData.fromBinary(new Uint8Array(buffer));
            }
            logger.warn(e);
          }

          return {
            data: ChartDataLoader.convertBinaryData(data, datasetOption),
            options: datasetOption.options,
            additionalInfo: datasetOption.additionalInfo,
          };
        });
      },
    )
      .then((datasets) => {
        return datasets;
      })
      .finally(() => {
        this.runningBinaryChartDataRequest = null; // request completed
      });

    return this.runningBinaryChartDataRequest;
  }

  /**
   * Load value trend data for given urls.
   * If there is a running request for value trend data the previous request is aborted.
   * @param urls List of urls to load value trend data from
   * @param options Additional options for value trend data(time range, sampling rate)
   * @return A Promise to the value trend datasets
   */
  loadValueTrendData(
    urls: string[],
    options: TimeSeriesDataParameter,
  ): Promise<ValueTrendData[]> {
    // cancel running request
    if (!isNil(this.runningValueTrendDataRequest)) {
      this.runningValueTrendDataRequest.cancel();
    }

    this.runningValueTrendDataRequest = Promise.mapSeries(urls, (baseUrl) => {
      const url = URL.parse(
        baseUrl,
        baseUrl.startsWith("/") ? window.location.origin : undefined,
      );
      // start new request
      ChartDataLoader.getUrlQuery(options, url.searchParams);
      return loadDataFromUrl<ValueTrendData>(url.toString());
    }).then((valueTrends: ValueTrendData[]) => {
      this.runningValueTrendDataRequest = null; // request completed

      return valueTrends;
    });

    return this.runningValueTrendDataRequest;
  }

  /**
   * Load annotation data for given urls.   *
   * If there is a running request for annotation data the previous request is aborted.
   * @param urls List of urls to load annotation data from
   * @param options Additional options for annotation data(time range, sampling rate)
   * @return A Promise to the annotations
   */
  loadAnnotationData(urls: string[]): Promise<AnnotationData[]> {
    // cancel running request
    if (!isNil(this.runningAnnotationDataRequest)) {
      this.runningAnnotationDataRequest.cancel();
    }

    // start new request
    this.runningAnnotationDataRequest = Bluebird.mapSeries(urls, (url) => {
      return loadDataFromUrl<AnnotationData>(url);
    }).then((annotations: AnnotationData[]) => {
      this.runningAnnotationDataRequest = null; // request completed

      return annotations;
    });

    return this.runningAnnotationDataRequest;
  }

  loadStateData(ids: IDType[], timeRange?: DateRange): Promise<StateData[]> {
    this.runningStateDataRequest?.cancel();

    if (isEmpty(ids)) {
      this.runningStateDataRequest = null;
      return Promise.resolve([] as StateData[]);
    }
    const loadCsmDataPromises = toArray(ids).map((id) =>
      loadContextStateMachine(id),
    );
    const loadedStateMachines = new Map<
      number,
      ContextStateMachineJSONObject
    >();
    this.runningStateDataRequest = Bluebird.all(loadCsmDataPromises)
      .then((stateMachines) => {
        stateMachines.forEach((csm) => {
          loadedStateMachines.set(csm.id, csm);
        });

        return Bluebird.all(
          stateMachines.map((csm) =>
            loadStateChangeData(csm.id, {
              from: timeRange?.start,
              to: timeRange?.end,
              sort: "asc",
              include_next: true,
              include_prev: true,
            }).then((loadedStateChanges) => ({
              csm,
              stateChanges: loadedStateChanges,
            })),
          ),
        );
      })
      .then((stateChanges) => {
        return stateChanges.map((stateTransitions) => {
          const stateChangeData: StateData = {
            csmId: stateTransitions.csm.id,
            csm: stateTransitions.csm,
            possible_states: keyBy(
              stateTransitions.csm.possible_states,
              (item) => toString(item.id),
            ),
            state_context: stateTransitions.csm.state_context,
            current_state: stateTransitions.csm.current_state,
            stateChanges: stateTransitions.stateChanges,
          };
          return stateChangeData;
        });
      });

    return this.runningStateDataRequest;
  }

  /** Cancels all running promises
   *
   *
   * @memberof ChartDataLoader
   */
  cancelLoading() {
    if (!isNil(this.runningAnnotationDataRequest)) {
      this.runningAnnotationDataRequest.cancel();
    }
    if (!isNil(this.runningChartDataRequest)) {
      this.runningChartDataRequest.cancel();
    }
    if (!isNil(this.runningBinaryChartDataRequest)) {
      this.runningBinaryChartDataRequest.cancel();
    }
    if (!isNil(this.runningValueTrendDataRequest)) {
      this.runningValueTrendDataRequest.cancel();
    }

    this.runningStateDataRequest?.cancel();
  }
}
