import { Box, Paper, Typography } from "@mui/material";
import * as React from "react";
import { EventPatternJSONObject } from "../../json_api/event_pattern";
import { IDType } from "../../utils/urls/url_utils";

import { Add, KeyboardArrowLeft } from "@mui/icons-material";
import { useConfirm } from "material-ui-confirm";
import { AppContext } from "../../components/common/app_context/app_context_provider";
import { FixedBottomArea } from "../../components/common/fixed_bottom_area";
import { FloatingButtons } from "../../components/common/floating_buttons";
import { useDeleteEventPattern } from "../../queries/event_pattern_data";
import { MqttUserList } from "../../components/mqtt_users/mqtt_user_table";
import { ResourcePermission } from "../../models/resource_permission";
import { redirectTo } from "../../utils/redirection";
import { error, success } from "../../utils/toasts";
import { MqttUserJSONObject } from "../../json_api/mqtt_user";
import { SialogicDialog } from "../../components/common/sialogic_dialog";
import { MqttUserForm } from "../../components/mqtt_users/mqtt_user_form";

interface MqttUsersIndexProps {
  assetId: IDType;
  userId: IDType;
  deviceId: IDType;
  permissions?: ResourcePermission;
}

export const MqttUsersIndexPage: React.FunctionComponent<
  MqttUsersIndexProps
> = ({ assetId, userId, deviceId, permissions }) => {
  const [editMqttUser, setMqttUser] = React.useState<MqttUserJSONObject>();
  const [editMode, setEditMode] = React.useState<"show" | "edit" | "new">(
    "show",
  );

  const appCtx = React.useContext(AppContext);
  const confirm = useConfirm();

  const {
    mutateAsync: deleteEventPattern,
    isPending: deletePending,
    isSuccess: deleteSuccess,
    error: deleteError,
  } = useDeleteEventPattern();

  React.useEffect(() => {
    if (deleteSuccess) {
      void success(
        I18n.t("frontend.event_patterns.index.delete_success"),
        I18n.t("frontend.event_patterns.index.delete_success"),
      );
    }
  }, [deleteSuccess, deleteError]);

  React.useEffect(() => {
    if (deleteError) {
      void error(
        I18n.t("frontend.event_patterns.index.delete_error"),
        deleteError.message,
      );
    }
  }, [deleteError]);

  const requestItemAction = React.useCallback(
    (e: MqttUserJSONObject, mode: "show" | "edit" | "new" | "delete") => {
      if (mode === "delete") {
        confirm({
          allowClose: true,
          description: I18n.t(
            "frontend.event_patterns.index.delete_event_pattern_confirm",
          ),
        })
          .then(() => {
            deleteEventPattern(e.id);
          })
          .catch(() => {
            // do nothing if not confirmed
          });
      } else {
        setEditMode(mode);
        setMqttUser(e);
      }
    },
    [deleteEventPattern],
  );
  return (
    <Paper>
      <Box padding={2}>
        <MqttUserList assetId={assetId} userId={userId} deviceId={deviceId} />
      </Box>

      <FixedBottomArea>
        {permissions?.create && (
          <FloatingButtons
            showScrollToTopBtn
            cancelIcon={<KeyboardArrowLeft />}
            onCancel={() => {
              redirectTo("back");
            }}
            submitBtnIcon={<Add />}
            submitBtnColor="primary"
            onSubmit={
              permissions?.create
                ? () => {
                    setMqttUser(null);
                    setEditMode("new");
                  }
                : null
            }
            saveTitle={I18n.t("frontend.mqtt_users.add_new_mqtt_user")}
          />
        )}
      </FixedBottomArea>
      {editMode == "new" && (
        <SialogicDialog
          title={I18n.t("frontend.mqtt_users.add_new_mqtt_user")}
          onClose={() => {
            setEditMode(null);
            setMqttUser(null);
          }}
          open={editMode == "new"}
        >
          <MqttUserForm
            mqttUserId={null}
            editMode={editMode}
            relations={{ deviceId, assetId, userId }}
            onSaved={(user) => {
              setEditMode(null);
              setMqttUser(null);
            }}
          />
        </SialogicDialog>
      )}
    </Paper>
  );
};
