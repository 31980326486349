/* eslint-disable @typescript-eslint/no-unused-vars */
import { History } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";

import { defaultTo, find, isEmpty, isNil, merge } from "lodash";

import { StateContext } from "app/javascript/models/state_context";
import moment, { Moment } from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import { ContextStateMachineEventSubscriber } from "../../channels/context_state_machine_channel";
import { WidgetController } from "../../controller/widget_controller";
import { State } from "../../models/state";
import { getStateColor } from "../../utils/colors";

import { ContextStateMachineJSONObject } from "../../json_api/context_state_machines";
import { StateJSONObject } from "../../json_api/state";
import { StateWidgetConfigSerialized } from "../../widgets/state_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import { SialogicDialog } from "../common/sialogic_dialog";
import { ContextStateChangeList } from "../context_state_changes/context_state_change_list";

import { StateDisplay } from "../states/state_display";
import { StateModifier } from "../states/state_modifier";
import { SialogicWidgetDefinition } from "./sialogic_widget_component";
import { StateWidgetProps } from "./state_widget.types";
import { WidgetBox } from "./widget_box";
import { useLoadContextStateMachine } from "../../queries/context_state_machine_data";

export const StateWidget: React.FunctionComponent<StateWidgetProps> = ({
  enableStateUpdate = true,
  userCanChangeState: enableEditState = true,
  widgetBox = true,
  inline = false,
  contextStateMachineId,
  ...props
}) => {
  const [historyDialogOpen, setHistoryDialogOpen] = useState(false);
  const [contextStateMachine, setContextStateMachine] = useState<
    ContextStateMachineJSONObject | undefined
  >();

  const [currentState, setCurrentState] = useState<StateJSONObject | undefined>(
    contextStateMachine?.current_state,
  );

  const [currentStateSince, setCurrentStateSince] = useState<Moment>(
    contextStateMachine?.current_state_since
      ? moment(contextStateMachine.current_state_since)
      : undefined,
  );
  const {
    data: loadedContextStateMachine,
    isLoading,
    error,
  } = useLoadContextStateMachine({
    variables: { id: props.contextStateMachine?.id || contextStateMachineId },
    initialData: props.contextStateMachine,
    enabled: Boolean(contextStateMachineId),
  });

  useEffect(() => {
    if (loadedContextStateMachine) {
      setContextStateMachine(loadedContextStateMachine);
      setCurrentState(loadedContextStateMachine.current_state);
      setCurrentStateSince(
        loadedContextStateMachine.current_state_since
          ? moment(loadedContextStateMachine.current_state_since)
          : undefined,
      );
    }
  }, [loadedContextStateMachine]);

  // strange doublication of state since we need to access it in the handleContextStateMachineUpdate callback
  const availableStatesRef = React.useRef<StateJSONObject[]>(
    contextStateMachine?.possible_states,
  );
  const [availableStates, setAvailableStates] = useState<StateJSONObject[]>(
    contextStateMachine?.possible_states,
  );

  useEffect(() => {
    if (!isEmpty(contextStateMachine?.possible_states)) {
      availableStatesRef.current = contextStateMachine.possible_states;
      setAvailableStates(contextStateMachine?.possible_states);
    }
  }, [contextStateMachine]);

  const handleContextStateMachineUpdate = (
    contextStateMachineForUpdateId: number,
    stateContext: StateContext,
    newState: State,
    time: Moment,
    stateful_item_id: number,
    stateful_item_type: string,
  ) => {
    if (
      isNil(props.timeRange) ||
      (props.timeRange?.contains(time) && availableStatesRef.current)
    ) {
      const newStateData = defaultTo(
        find(availableStatesRef.current, (s) => s.id === newState.id),
        newState as StateJSONObject,
      );

      setCurrentState(newStateData);
    }
  };
  useEffect(() => {
    if (!contextStateMachine) return;

    if (enableStateUpdate) {
      const eventHandler: ContextStateMachineEventSubscriber = {
        handleContextStateMachineUpdate,
      };
      const csmId = contextStateMachine?.id ?? contextStateMachineId;
      if (isNil(csmId)) return;

      const instance = WidgetController.getInstance();
      const handlerId = instance.contextStateMachineChannel.addEventListener(
        eventHandler,
        csmId as number,
      );

      return () => {
        instance.contextStateMachineChannel.removeEventListenerId(
          handlerId,
          csmId as number,
        );
      };
    }
  }, [contextStateMachine?.id, enableStateUpdate]);

  const handleStateSaved = (newState: State) => {
    // Add your logic here to handle the new state
  };

  const content = (
    <Grid container>
      <Grid item xs={12}>
        <StateDisplay
          inline={inline}
          stateName={currentState?.name}
          criticality={currentState?.criticality}
          stateColor={getStateColor(currentState)}
          stateIcon={currentState?.icon}
          imageUrl={currentState?.image_url}
          stateDescription={currentState?.description}
        />
      </Grid>

      {!enableEditState || isEmpty(availableStates) ? null : (
        <Grid item xs={12} className="backend-link toggleable">
          <StateModifier
            id={`context-state-machine-widget-${props.widgetId}`}
            contextStateMachine={contextStateMachine}
            currentState={currentState}
            states={availableStates}
            onStateSaved={(newState) => handleStateSaved(newState)}
          />
        </Grid>
      )}
    </Grid>
  );

  if (widgetBox) {
    return (
      <WidgetBox
        {...props}
        title={defaultTo(contextStateMachine?.state_context?.name, "---")}
        footer={
          <>
            <IconButton
              size="small"
              onClick={() => setHistoryDialogOpen(true)}
              title="Show History"
            >
              <History />
            </IconButton>
            <small className="state-change-date">
              {defaultTo(
                moment(contextStateMachine?.current_state_since).format("L LT"),
                "---",
              )}
            </small>
          </>
        }
        onRequestDebug={() => {
          console.log(
            `Widget ${props.widgetId} ${props.className}:`,
            props,
            "State :",
            { contextStateMachine },
          );
        }}
      >
        <>
          {content}
          {historyDialogOpen ? (
            <SialogicDialog
              disableEscapeKeyDown
              open={historyDialogOpen}
              scroll="paper"
              onClose={() => setHistoryDialogOpen(false)}
            >
              <ContextStateChangeList csmId={contextStateMachine.id} />
            </SialogicDialog>
          ) : null}
        </>
      </WidgetBox>
    );
  } else {
    return content;
  }
};

function serializedConfigToProps(
  config: StateWidgetConfigSerialized,
): StateWidgetProps {
  return merge(widgetBoxPropsFromSerializedConfig(config), {
    contextStateMachineId: config.context_state_machine_id,
    states: config.states,
    enableStateUpdate: !config.disable_update,
    userCanChangeState: config.enable_state_edit,
  } as StateWidgetProps);
}

export const StateWidgetDefinition: SialogicWidgetDefinition<
  typeof StateWidget,
  typeof serializedConfigToProps
> = {
  Component: StateWidget,
  serializedConfigToProps,
};
