import * as React from "react";

import JQuery from "jquery";
import * as JSONAPI from "jsonapi-typescript";
import { isEmpty } from "lodash";
import { Root, createRoot } from "react-dom/client";
import { MeasurementPlanJSONAPIAttributesObject } from "../../json_api/measurement_plan";
import { MeasurementPlan } from "../../models/measurement_plan";
import { AppRoot } from "../common/app_root";
import { MeasurementPlanList } from "./views/measurement_plan_list";
import { jsonApiResourceCollectionToFlatObjects } from "../../json_api/jsonapi_tools";

const measurementPlanListRoots: Root[] = [];
export function initializeMeasurementPlanList(
  itemOrSelector: JQuery | string = '[data-toggle="measurement-plan-list"]',
) {
  let item: JQuery;
  if (itemOrSelector instanceof JQuery) {
    item = itemOrSelector;
  } else {
    item = $(itemOrSelector as string);
  }

  item.each((index, element) => {
    const el = $(element);
    const assetId: string = el.data("asset-id") as string;
    const pageSize = parseInt(el.data("page-size") as string);
    const root = createRoot(element);
    root.render(
      <AppRoot>
        <MeasurementPlanList pageSize={pageSize} assetId={assetId} />
      </AppRoot>,
    );
    measurementPlanListRoots.push(root);
  });
}

export function destroyMeasurementPlanList() {
  measurementPlanListRoots.forEach((r) => r.unmount());
  measurementPlanListRoots.length = 0;
}
