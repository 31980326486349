import { Cancel, Check } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  TextField,
} from "@mui/material";
import { DocWithErrors, SingleResourceDoc } from "jsonapi-typescript";
import {
  defaultTo,
  each,
  isEmpty,
  isNil,
  isNumber,
  last,
  toInteger,
  trim,
} from "lodash";
import React from "react";
import { translatedAttributeName } from "../../i18n/translation_helper";
import { AssetTemplateJSONObject } from "../../json_api/asset_template";
import { AssetTypeJSONObject } from "../../json_api/asset_type";
import { jsonApiSingleResourceToFlatObject } from "../../json_api/jsonapi_tools";
import { AssetTemplate } from "../../models/asset_template";
import {
  useCreateAssetTemplate,
  useLoadAssetTemplate,
  useUpdateAssetTemplate,
} from "../../queries/asset_template_data";
import { api_asset_template_path } from "../../routes";
import { HttpError } from "../../utils/jquery_helper";
import { logger } from "../../utils/logger";
import { redirectTo } from "../../utils/redirection";
import { IDType } from "../../utils/urls/url_utils";
import { AppContext } from "../common/app_context/app_context_provider";
import { SialogicContext } from "../common/app_context/app_context_provider.types";
import { LoadingWrapper } from "../common/loading_wrapper";
import { ManufacturerSelect } from "../manufacturers/manufacturer_select";
import { TimezoneSelect } from "../time_zones/timezone_select";

type OperationMode = "show" | "edit" | "new";
type RequestMode = "create" | "update";

export type AssetTemplateFormProps = {
  assetTemplateId?: number;
  assetTypeId?: number;
  assetType?: AssetTypeJSONObject;
  mode?: OperationMode;
  canEdit?: boolean;
  readOnly?: boolean;
  buttonMode?: "global" | "card";
  onCancel?: () => void;
  onSuccess?: (assetTemplate: AssetTemplateJSONObject) => void;
};

type AssetTemplateFormErrors = Partial<Record<keyof AssetTemplate, string>>;

function validate(
  assetTemplate: AssetTemplateJSONObject,
  oldErrors: AssetTemplateFormErrors = {},
): AssetTemplateFormErrors {
  const titleError = isEmpty(trim(assetTemplate?.title))
    ? I18n.t("errors.messages.blank")
    : null;
  if (titleError === null) {
    delete oldErrors.title;
  } else {
    oldErrors.title = titleError;
  }

  const nameError = isEmpty(trim(assetTemplate?.name))
    ? I18n.t("errors.messages.blank")
    : null;
  if (nameError === null) {
    delete oldErrors.name;
  } else {
    oldErrors.name = nameError;
  }

  return oldErrors;
}

export const AssetTemplateForm: React.FC<AssetTemplateFormProps> = (props) => {
  const context = React.useContext(AppContext);
  let operationMode = props.mode;
  if (isNil(props.mode)) {
    if (isNil(props.assetTemplateId)) {
      operationMode = "new";
    } else {
      operationMode = props.readOnly === true ? "show" : "edit";
    }
  }

  const [readonly] = React.useState(operationMode == "show");
  const [assetType] = React.useState<AssetTypeJSONObject>(props.assetType);
  const [assetTemplate, setAssetTemplate] =
    React.useState<AssetTemplateJSONObject>({
      id: props.assetTemplateId,
      asset_type_id: props.assetTypeId,
    });
  const [assetTemplateErrors, setAssetTemplateErrors] =
    React.useState<AssetTemplateFormErrors>({});
  const [isProcessing, setIsProcessing] = React.useState(false);

  const { data: loadedAssetTemplate, isLoading: assetTemplateLoading } =
    useLoadAssetTemplate({
      variables: { assetTemplateId: assetTemplate.id || props.assetTemplateId },
      enabled: !isNil(props.assetTemplateId),
      initialData: defaultTo(assetTemplate, undefined),
    });

  React.useEffect(() => {
    if (loadedAssetTemplate) {
      setAssetTemplate(loadedAssetTemplate);
    }
  }, [loadedAssetTemplate]);

  const handleRequestError = React.useCallback((err: HttpError) => {
    const errors: AssetTemplateFormErrors = {};

    void toasts.error(I18n.t("base.error"), I18n.t("base.error"));

    each((err.request?.responseJSON as DocWithErrors)?.errors, (error) => {
      logger.error(error);
      const attrWithError = error?.source?.pointer as string;
      const aName = last(attrWithError?.split("/")) as keyof AssetTemplate;
      if (!isNil(aName)) {
        errors[aName] = error.detail;
      }
    });
    setAssetTemplateErrors(errors);
  }, []);

  const { mutateAsync: createAssetTemplate, isPending: createPending } =
    useCreateAssetTemplate(handleRequestError);
  const { mutateAsync: updateAssetTemplate, isPending: updatePending } =
    useUpdateAssetTemplate(handleRequestError);

  const submit = React.useCallback(() => {
    let promise: Promise<SingleResourceDoc<string, AssetTemplateJSONObject>>;
    let mode: RequestMode;
    if (!assetTemplate.id) {
      mode = "create";
      promise = createAssetTemplate({ assetTemplate });
    } else {
      mode = "update";
      promise = updateAssetTemplate({ assetTemplate });
    }
    void promise.then((resp) => {
      handleRequestSuccess(
        mode,
        resp,
        props.assetTypeId,
        setAssetTemplate,
        props.onSuccess,
        context,
      );
    });
  }, [assetTemplate]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={
              isNil(assetTemplate?.id)
                ? I18n.t("frontend.asset_templates.form.heading_new", {
                    asset_type: assetType?.name,
                  } as Record<string, string>)
                : I18n.t("frontend.asset_templates.form.heading", {
                    asset_type: assetType?.name,
                  } as Record<string, string>)
            }
          />

          <CardContent>
            <LoadingWrapper loading={isProcessing || assetTemplateLoading}>
              {isProcessing ? null : (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        autoFocus
                        required
                        fullWidth
                        inputProps={{ readOnly: readonly }}
                        title={translatedAttributeName(
                          "asset_template",
                          "title",
                        )}
                        label={translatedAttributeName(
                          "asset_template",
                          "title",
                        )}
                        value={defaultTo(assetTemplate.title, "")}
                        error={
                          isEmpty(assetTemplate.title) ||
                          !isEmpty(assetTemplateErrors?.title)
                        }
                        disabled={isProcessing}
                        helperText={assetTemplateErrors?.title}
                        onChange={(e) => {
                          setAssetTemplateErrors(validate(assetTemplate));
                          setAssetTemplate({
                            ...assetTemplate,
                            title: e.target.value,
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        inputProps={{ readOnly: readonly }}
                        title={translatedAttributeName(
                          "asset_template",
                          "name",
                        )}
                        label={translatedAttributeName(
                          "asset_template",
                          "name",
                        )}
                        value={defaultTo(assetTemplate.name, "")}
                        error={
                          isEmpty(assetTemplate.name) ||
                          !isEmpty(assetTemplateErrors?.name)
                        }
                        disabled={isProcessing}
                        helperText={assetTemplateErrors?.name}
                        onChange={(e) => {
                          setAssetTemplateErrors(validate(assetTemplate));
                          setAssetTemplate({
                            ...assetTemplate,
                            name: e.target.value,
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        inputProps={{ readOnly: readonly }}
                        title={translatedAttributeName(
                          "asset_template",
                          "description",
                        )}
                        label={translatedAttributeName(
                          "asset_template",
                          "description",
                        )}
                        value={defaultTo(assetTemplate.description, "")}
                        error={!isEmpty(assetTemplateErrors?.description)}
                        disabled={isProcessing}
                        helperText={assetTemplateErrors?.description}
                        onChange={(e) => {
                          const oldErrors: AssetTemplateFormErrors =
                            validate(assetTemplate);
                          setAssetTemplateErrors(oldErrors);
                          setAssetTemplate({
                            ...assetTemplate,
                            description: e.target.value,
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        inputProps={{ readOnly: readonly }}
                        title={translatedAttributeName(
                          "asset_template",
                          "short_name",
                        )}
                        label={translatedAttributeName(
                          "asset_template",
                          "short_name",
                        )}
                        value={defaultTo(assetTemplate.short_name, "")}
                        error={!isEmpty(assetTemplateErrors?.short_name)}
                        disabled={isProcessing}
                        helperText={assetTemplateErrors?.short_name}
                        onChange={(e) => {
                          const oldErrors: AssetTemplateFormErrors =
                            validate(assetTemplate);
                          setAssetTemplateErrors(oldErrors);
                          setAssetTemplate({
                            ...assetTemplate,
                            short_name: e.target.value,
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        inputProps={{ readOnly: readonly }}
                        title={translatedAttributeName("asset_template", "key")}
                        label={translatedAttributeName("asset_template", "key")}
                        value={defaultTo(assetTemplate.key, "")}
                        error={!isEmpty(assetTemplateErrors?.key)}
                        disabled={isProcessing}
                        helperText={assetTemplateErrors?.key}
                        onChange={(e) => {
                          const oldErrors: AssetTemplateFormErrors =
                            validate(assetTemplate);
                          setAssetTemplateErrors(oldErrors);
                          setAssetTemplate({
                            ...assetTemplate,
                            key: e.target.value,
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        inputProps={{ readOnly: readonly }}
                        title={translatedAttributeName(
                          "asset_template",
                          "min_count",
                        )}
                        label={translatedAttributeName(
                          "asset_template",
                          "min_count",
                        )}
                        value={defaultTo(assetTemplate.min_count, "")}
                        error={
                          (!isEmpty(assetTemplate.min_count) &&
                            (!isNumber(assetTemplate.min_count) ||
                              !(assetTemplate.min_count >= 0))) ||
                          !isEmpty(assetTemplateErrors?.min_count)
                        }
                        disabled={isProcessing}
                        helperText={assetTemplateErrors?.min_count}
                        onChange={(e) => {
                          const oldErrors: AssetTemplateFormErrors =
                            validate(assetTemplate);
                          setAssetTemplateErrors(oldErrors);
                          setAssetTemplate({
                            ...assetTemplate,
                            min_count: parseInt(e.target.value),
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        inputProps={{ readOnly: readonly }}
                        title={translatedAttributeName(
                          "asset_template",
                          "max_count",
                        )}
                        label={translatedAttributeName(
                          "asset_template",
                          "max_count",
                        )}
                        value={defaultTo(assetTemplate.max_count, "")}
                        error={
                          (!isEmpty(assetTemplate.max_count) &&
                            (!isNumber(assetTemplate.max_count) ||
                              !(assetTemplate.max_count >= 0) ||
                              !(
                                assetTemplate.max_count >=
                                assetTemplate.min_count
                              ))) ||
                          !isEmpty(assetTemplateErrors?.max_count)
                        }
                        disabled={isProcessing}
                        helperText={assetTemplateErrors?.max_count}
                        onChange={(e) => {
                          const oldErrors: AssetTemplateFormErrors =
                            validate(assetTemplate);
                          setAssetTemplateErrors(oldErrors);
                          setAssetTemplate({
                            ...assetTemplate,
                            max_count: parseInt(e.target.value),
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ManufacturerSelect
                        fullWidth
                        error={Boolean(assetTemplateErrors?.manufacturer_id)}
                        selectedManufacturerId={assetTemplate.manufacturer_id}
                        disabled={isProcessing}
                        helperText={assetTemplateErrors?.manufacturer_id}
                        readOnly={readonly}
                        onChange={(newManufacturerId) => {
                          const oldErrors: AssetTemplateFormErrors =
                            validate(assetTemplate);
                          setAssetTemplateErrors(oldErrors);
                          setAssetTemplate({
                            ...assetTemplate,
                            manufacturer_id: isNil(newManufacturerId)
                              ? null
                              : toInteger(newManufacturerId),
                          });
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TimezoneSelect
                        label={translatedAttributeName(
                          "asset_template",
                          "timezone",
                        )}
                        fullWidth
                        selectedTimezoneKey={assetTemplate.timezone}
                        disabled={isProcessing}
                        helperText={assetTemplateErrors?.timezone}
                        readOnly={readonly}
                        onChange={(newTimezone) => {
                          const oldErrors: AssetTemplateFormErrors =
                            validate(assetTemplate);
                          setAssetTemplateErrors(oldErrors);
                          setAssetTemplate({
                            ...assetTemplate,
                            timezone: newTimezone,
                          });
                        }}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </LoadingWrapper>
          </CardContent>

          {props.buttonMode != "card" ? null : (
            <CardActions>
              {props.mode !== "show" ? (
                <Button
                  color="primary"
                  disabled={isProcessing}
                  startIcon={<Check />}
                  onClick={() => {
                    void submit();
                  }}
                >
                  {I18n.t("frontend.save")}
                </Button>
              ) : null}
              <Button
                disabled={isProcessing}
                startIcon={<Cancel />}
                onClick={() => {
                  if (props.onCancel) {
                    props.onCancel();
                  } else {
                    redirectTo("back");
                  }
                }}
              >
                {props.mode == "show"
                  ? I18n.t("frontend.close")
                  : I18n.t("frontend.cancel")}
              </Button>
            </CardActions>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

function handleRequestSuccess(
  mode: RequestMode,
  resultData: SingleResourceDoc<string, AssetTemplateJSONObject>,
  assetTypeId: IDType,
  setAssetTemplate: (assetTemplate: AssetTemplateJSONObject) => void,
  onSuccess: (assetTemplate: AssetTemplateJSONObject) => void,
  context: SialogicContext,
) {
  if (mode === "create") {
    void toasts.success(I18n.t("base.successfully_created"));
  } else {
    void toasts.success(I18n.t("base.successfully_updated"));
  }

  const assetTemplate = jsonApiSingleResourceToFlatObject(resultData);
  setAssetTemplate(assetTemplate);

  if (!isNil(onSuccess)) {
    onSuccess(assetTemplate);
  } else {
    redirectTo(
      defaultTo(context.referrer, api_asset_template_path(assetTemplate.id)),
    );
  }
}
