import { defaultTo, includes, noop, toNumber, toString } from "lodash";
import * as React from "react";

import { Cancel, Save } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import ScheduleIcon from "@mui/icons-material/Schedule";
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { TranslateOptions } from "i18n-js";
import { getRotationDescription } from "../../../utils/rotation_description";
import {
  MaterialUiDatePicker,
  MaterialUiDatePickerProps,
} from "../date_picker";
import { IconButton as CustomIconmButton } from "../icon_button";

import ReactRRuleGenerator from "../../../3rd_party/react_rrule_generator/lib";
import { SialogicDialog } from "../sialogic_dialog";
import { RRuleForm } from "./rrule_form";

export type RotationTypes =
  | "unplanned"
  | "planned_after_time"
  | "planned_after_runtime"
  | "planned_after_runtime_or_time";
type RotationInputProps = React.PropsWithChildren<{
  rrule?: string;
  invalid?: boolean;

  rotationType?: RotationTypes;

  allowChooseRotationType?: boolean;

  criticalValue?: number;

  showDelete?: boolean;
  showLabel?: boolean;

  allowEdit?: boolean;

  variant?: "textfield" | "text";
  onApplyChange: (
    rrule: string,
    rotationType: RotationTypes,
    criticalValue: number,
  ) => void;
  onCancel?: () => void;
}>;

/**
 * An input field to configure the rotation of a maintenance plan.
 * Rotations can be based on a time schedule or a running time sensor.
 * A rrule(time schedule) or a threshold can be configured depending on the rotation type.
 *
 *
 *
 * @param {RotationInputProps} {
 *   rotationType = "unplanned",
 *   rrule = "RRULE:FREQ=WEEKLY;INTERVAL=1;BYDAY=MO",
 *   allowChooseRotationType = true,
 *   criticalValue = 100,
 *   invalid = false,
 *   allowEdit = true,
 *   showDelete = false,
 *   showLabel = true,
 *   variant = "textfield",
 *   onChange = noop,
 *   onChangeRotationType = noop,
 *   ...props
 * }
 * @return {*}
 */
export const RotationInput: React.FunctionComponent<RotationInputProps> = ({
  rotationType = "unplanned",
  rrule = "RRULE:FREQ=WEEKLY;INTERVAL=1;BYDAY=MO",
  allowChooseRotationType = true,
  criticalValue = 100,
  invalid = false,
  allowEdit = true,
  showDelete = false,
  showLabel = true,
  variant = "textfield",
  onApplyChange: onChange = noop,
  ...props
}: RotationInputProps) => {
  const [currentRrule, setCurrentRrule] = React.useState<string>(rrule);
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const [currentRotationType, setCurrentRotationType] =
    React.useState<RotationTypes>(defaultTo(rotationType, "unplanned"));
  const [currentCriticalValue, setCurrentCriticalValue] =
    React.useState<number>(criticalValue);

  React.useEffect(() => setCurrentRrule(rrule), [rrule]);
  React.useEffect(
    () => setCurrentCriticalValue(criticalValue),
    [criticalValue],
  );

  const onApplyChange = React.useCallback(() => {
    onChange(
      defaultTo(currentRrule, "RRULE:FREQ=WEEKLY;INTERVAL=1;BYDAY=MO"),
      defaultTo(currentRotationType, "unplanned"),
      defaultTo(currentCriticalValue, 100),
    );

    setIsEditing(false);
  }, [currentRrule, currentRotationType, currentCriticalValue]);

  const handleRotationTypeChange = React.useCallback((value: RotationTypes) => {
    const rotationType: RotationTypes = includes(["None", ""], value)
      ? "unplanned" // set unplanned as value for None or empty strings -> which shouldn't happen anymore
      : value;
    setCurrentRotationType(rotationType);
  }, []);

  return (
    <React.Fragment>
      {variant == "text" ? (
        <Tooltip title={I18n.t("frontend.click_to_edit")}>
          <Grid
            item
            container
            xs={12}
            className={invalid ? "is-invalid" : null}
            onClick={() => setIsEditing(true)}
            style={{ cursor: "pointer" }}
          >
            <Grid item xs="auto">
              <Typography variant="caption" mr={1}>
                {I18n.t("frontend.maintenance_plan_form.rotation")}:
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <CustomIconmButton
                className="mr-1 text-success"
                onClick={() => setIsEditing(true)}
                icon="pencil"
              />
              <span className="editable-value">
                {getRotationDescription(rotationType, criticalValue, rrule)}
              </span>
            </Grid>
          </Grid>
        </Tooltip>
      ) : (
        <TextField
          key="rot-tf"
          size="small"
          fullWidth={true}
          id="standard-full-width"
          title={I18n.t("frontend.click_to_edit")}
          label={I18n.t("frontend.common.rotation_input.rotation")}
          style={{ cursor: "pointer" }}
          error={invalid}
          value={getRotationDescription(
            currentRotationType,
            criticalValue,
            rrule,
          )}
          InputProps={{
            style: { caretColor: "transparent" },
            readOnly: allowEdit === false,
            startAdornment: (
              <InputAdornment position="start">
                <ScheduleIcon fontSize="inherit" />
              </InputAdornment>
            ),
            endAdornment:
              allowEdit === false ? (
                <></>
              ) : (
                <InputAdornment position="end">
                  <IconButton
                    color="default"
                    size="small"
                    className="mr-1"
                    onClick={(e) => {
                      e.stopPropagation();
                      return setIsEditing(true);
                    }}
                  >
                    <EditIcon fontSize="inherit" />
                  </IconButton>
                  {showDelete ? (
                    <IconButton
                      color="default"
                      size="small"
                      title={I18n.t(
                        "frontend.common.rotation_input.reset_rotation",
                      )}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsEditing(false);
                        setCurrentRrule(null);
                        onChange(
                          null,
                          defaultTo(rotationType, "unplanned"),
                          criticalValue,
                        );
                      }}
                    >
                      <ClearIcon fontSize="inherit" />
                    </IconButton>
                  ) : null}
                </InputAdornment>
              ),
          }}
          onClick={allowEdit === false ? noop : () => setIsEditing(true)}
          contentEditable={false}
        />
      )}
      <SialogicDialog
        allowFullScreen={false}
        key="rot-modal"
        open={isEditing}
        maxWidth="xl"
        onClose={() => setIsEditing(false)}
        title={I18n.t("frontend.common.rotation_input.rotation")}
        buttons={
          <ButtonGroup>
            <Button
              color="primary"
              onClick={() => onApplyChange()}
              startIcon={<Save fontSize="inherit" />}
            >
              {I18n.t("frontend.submit")}
            </Button>
            <Button
              onClick={() => {
                if (props.onCancel) {
                  props.onCancel();
                }
                setIsEditing(false);
              }}
              startIcon={<Cancel fontSize="inherit" />}
            >
              {I18n.t("frontend.cancel")}
            </Button>
          </ButtonGroup>
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {allowChooseRotationType ? (
              <TextField
                select
                value={currentRotationType || "unplanned"}
                label={I18n.t("frontend.common.rotation_input.rotation_type")}
                onChange={(event) =>
                  handleRotationTypeChange(event.target.value as RotationTypes)
                }
              >
                <MenuItem value="unplanned">
                  {I18n.t(
                    "frontend.maintenance_plan_form.rotation_types.unplanned",
                  )}
                </MenuItem>

                <MenuItem value="planned_after_runtime">
                  {I18n.t(
                    "frontend.maintenance_plan_form.rotation_types.planned_after_runtime",
                  )}
                </MenuItem>
                <MenuItem value="planned_after_time">
                  {I18n.t(
                    "frontend.maintenance_plan_form.rotation_types.planned_after_time",
                  )}
                </MenuItem>
                <MenuItem value="planned_after_runtime_or_time">
                  {I18n.t(
                    "frontend.maintenance_plan_form.rotation_types.planned_after_runtime_or_time",
                  )}
                </MenuItem>
              </TextField>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>

          {currentRotationType === "planned_after_runtime" ||
          currentRotationType === "planned_after_runtime_or_time" ? (
            <>
              {currentRotationType === "planned_after_runtime_or_time" ? (
                <Grid item xs={12}>
                  <Typography variant="h5">
                    {I18n.t(
                      "frontend.maintenance_plan_form.rotation_types.planned_after_runtime",
                    )}
                  </Typography>
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <TextField
                  type="number"
                  label={I18n.t("frontend.maintenance_plan_form.limit")}
                  value={toString(currentCriticalValue)}
                  onChange={(event) =>
                    setCurrentCriticalValue(toNumber(event.target.value))
                  }
                  onKeyUp={(event) => {
                    if (event.key === "Enter") {
                      event.stopPropagation();
                      onApplyChange();
                    }
                  }}
                  InputProps={{ endAdornment: "h" }}
                />
              </Grid>
            </>
          ) : null}
          {currentRotationType === "planned_after_time" ||
          currentRotationType === "planned_after_runtime_or_time" ? (
            <>
              {currentRotationType === "planned_after_runtime_or_time" ? (
                <>
                  <Grid item xs={12} my={2}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5">
                      {I18n.t(
                        "frontend.maintenance_plan_form.rotation_types.planned_after_time",
                      )}
                    </Typography>
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12}>
                <RRuleForm rrule={currentRrule} onChange={setCurrentRrule} />
              </Grid>
            </>
          ) : null}
        </Grid>
      </SialogicDialog>
    </React.Fragment>
  );
};
