import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import {
  PRODUCT_MODEL_ASSET_TEMPLATE_SETTING_JSONAPI_RESOURCE_TYPE,
  ProductModelAssetTemplateSettingJSONObject,
} from "../json_api/product_model_asset_template_settings";
import {
  addHasOneRelationToJsonApiSubmitData,
  buildJsonApiSubmitData,
} from "../utils/jsonapi_form_tools";
import { ASSET_TEMPLATE_JSONAPI_RESOURCE_TYPE } from "../json_api/asset_template";
import { PRODUCT_MODEL_JSONAPI_RESOURCE_TYPE } from "../json_api/product_model";
import { loadDataFromUrl, sendJsonApiData } from "../utils/jquery_helper";
import { CollectionResourceDoc, SingleResourceDoc } from "jsonapi-typescript";
import {
  api_product_model_asset_template_setting_path,
  api_product_model_asset_template_settings_path,
} from "../routes";
import { jsonApiResourceCollectionToFlatObjects } from "../json_api/jsonapi_tools";
import { IDType } from "../utils/urls/url_utils";
import { createQuery } from "react-query-kit";

export interface ProductModelAssetTemplateQuery {
  productModelId: IDType;
  assetTemplateIds: IDType[];
}
type ProductModelAssetTemplateQueryKey = [
  string,
  ProductModelAssetTemplateQuery,
];

export const useFetchProductModelAssetTemplateSetting = createQuery<
  ProductModelAssetTemplateSettingJSONObject[],
  ProductModelAssetTemplateQuery
>({
  queryKey: [PRODUCT_MODEL_ASSET_TEMPLATE_SETTING_JSONAPI_RESOURCE_TYPE],
  fetcher: async ({ assetTemplateIds, productModelId }) => {
    const result = await loadDataFromUrl<
      CollectionResourceDoc<string, ProductModelAssetTemplateSettingJSONObject>
    >(
      api_product_model_asset_template_settings_path({
        filter: {
          product_model: productModelId,
          asset_template: assetTemplateIds.join(","),
        },
        include: "asset_template.children",
        _options: true,
        page: { size: 100 },
      }),
    );
    return jsonApiResourceCollectionToFlatObjects(result);
  },
});

export const useCreateProductModelAssetTemplateSetting = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      productModelAssetTemplateSettings: ProductModelAssetTemplateSettingJSONObject,
    ) => {
      const submitData = buildJsonApiSubmitData(
        productModelAssetTemplateSettings,
        PRODUCT_MODEL_ASSET_TEMPLATE_SETTING_JSONAPI_RESOURCE_TYPE,
        ["count"],
      );

      if (submitData.mode !== "create")
        throw new Error("Invalid mode - expected create");

      addHasOneRelationToJsonApiSubmitData(
        submitData.submitData,
        "asset_template",
        ASSET_TEMPLATE_JSONAPI_RESOURCE_TYPE,
        productModelAssetTemplateSettings.asset_template_id,
        false,
      );
      addHasOneRelationToJsonApiSubmitData(
        submitData.submitData,
        "product_model",
        PRODUCT_MODEL_JSONAPI_RESOURCE_TYPE,
        productModelAssetTemplateSettings.product_model_id,
        false,
      );

      return sendJsonApiData<
        unknown,
        SingleResourceDoc<string, ProductModelAssetTemplateSettingJSONObject>
      >(
        api_product_model_asset_template_settings_path(),
        submitData.submitData,
        "POST",
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [PRODUCT_MODEL_ASSET_TEMPLATE_SETTING_JSONAPI_RESOURCE_TYPE],
      });
    },
  });
};

export const useUpdateProductModelAssetTemplateSetting = (
  onError: (eerData: unknown) => void,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      editableSettings: ProductModelAssetTemplateSettingJSONObject,
    ) => {
      const submitData = buildJsonApiSubmitData(
        editableSettings,
        PRODUCT_MODEL_ASSET_TEMPLATE_SETTING_JSONAPI_RESOURCE_TYPE,
        ["count"],
      );

      if (submitData.mode !== "update") {
        throw new Error("Invalid mode - expected update");
      }

      return sendJsonApiData<
        unknown,
        SingleResourceDoc<string, ProductModelAssetTemplateSettingJSONObject>
      >(
        api_product_model_asset_template_setting_path(editableSettings.id),
        submitData.submitData,
        "PATCH",
      );
    },
    onError,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [PRODUCT_MODEL_ASSET_TEMPLATE_SETTING_JSONAPI_RESOURCE_TYPE],
      });
    },
  });
};

export const useDeleteProductModelAssetTemplateSetting = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: IDType) => {
      return sendJsonApiData(
        api_product_model_asset_template_setting_path(id),
        null,
        "DELETE",
      );
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [PRODUCT_MODEL_ASSET_TEMPLATE_SETTING_JSONAPI_RESOURCE_TYPE],
      });
    },
  });
};
