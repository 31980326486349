import { Paper } from "@mui/material";
import * as React from "react";
import { IDType } from "../../utils/urls/url_utils";

import {
  KeyboardArrowLeft,
  PauseCircle,
  PlayCircle,
} from "@mui/icons-material";
import { Box } from "@mui/system";
import { FixedBottomArea } from "../../components/common/fixed_bottom_area";
import { FloatingButtons } from "../../components/common/floating_buttons";
import { MqttMessageViewer } from "../../components/mqtt_viewer/mqtt_message_viewer";
import { redirectTo } from "../../utils/redirection";

interface MqttViewerPageProps {
  assetId: IDType;
  onCancel?: () => void;
}

export const MqttViewerPage: React.FunctionComponent<MqttViewerPageProps> = ({
  assetId,
  onCancel,
}) => {
  const [enabled, setEnabled] = React.useState(true);
  return (
    <Paper>
      <Box p={2}>
        <MqttMessageViewer assetId={assetId} enabled={enabled} />
      </Box>
      <FixedBottomArea>
        <FloatingButtons
          showScrollToTopBtn
          cancelIcon={<KeyboardArrowLeft />}
          onCancel={() => {
            onCancel ? onCancel() : redirectTo("back");
          }}
          submitBtnIcon={enabled ? <PauseCircle /> : <PlayCircle />}
          submitBtnColor="primary"
          onSubmit={() => setEnabled(!enabled)}
          saveTitle={enabled ? I18n.t("base.stop") : I18n.t("base.start")}
        />
      </FixedBottomArea>
    </Paper>
  );
};
