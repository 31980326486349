import { Box, CircularProgress, Grid, TextField } from "@mui/material";
import * as React from "react";
import { GeocoderResultAddress, LocationPicker } from "./location_picker";

import { defaultTo, each, isArray, isEmpty, map, toNumber } from "lodash";
import { LocationJSONObject } from "../../json_api/location";
import { IDType } from "../../utils/urls/url_utils";
import { FloatingButtons } from "../common/floating_buttons";
import {
  mapGeoSearchAddressToLocation,
  useCreateLocation,
  useLoadLocation,
  useUpdateLocation,
} from "../../queries/location_data";
import { logger } from "../../utils/logger";
import { error, success } from "../../utils/toasts";
import { FixedBottomArea } from "../common/fixed_bottom_area";
import { redirectTo } from "../../utils/redirection";
import { asset_path, organization_path } from "../../routes";
import { is } from "bluebird";
interface LocationFormProps {
  locationId?: IDType;

  localizableId?: IDType;
  localizableType?: "asset" | "organization";
  location?: LocationJSONObject;
  disabled?: boolean;
  onLocationChange?: (newLocation: LocationJSONObject) => void;

  allowSave?: boolean;
}

export const LocationForm: React.FunctionComponent<LocationFormProps> = ({
  allowSave,
  ...props
}: LocationFormProps) => {
  const [location, setLocation] = React.useState<LocationJSONObject>(() => {
    if (props.location as LocationJSONObject) return props.location;
    const l = {} as LocationJSONObject;
    if (props.localizableType === "asset") {
      l.asset = { id: props.localizableId };
    }
    if (props.localizableType === "organization") {
      l.organization = { id: props.localizableId };
    }
    return l;
  });

  const { data: loadedLocation, isLoading } = useLoadLocation({
    variables: { id: props.locationId },
    enabled: Boolean(props.locationId),
  });

  React.useEffect(() => {
    if (loadedLocation) {
      setLocation(loadedLocation);
    }
  }, [loadedLocation]);

  React.useEffect(() => {
    if (location !== props.location) {
      setLocation(props.location);
    }
  }, [props.location]);

  React.useEffect(() => {
    if (props.onLocationChange && location !== props.location) {
      props.onLocationChange(location);
    }
  }, [location, props.onLocationChange]);

  const { mutateAsync: updateLocation, isPending: isPendingUpdate } =
    useUpdateLocation();
  const { mutateAsync: createLocation, isPending: isPendingCreate } =
    useCreateLocation();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs>
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth={true}
                    autoComplete="off"
                    disabled={props.disabled}
                    label={I18n.t("activerecord.attributes.location.lat")}
                    value={defaultTo(location?.lat, "")}
                    inputProps={{ type: "number", min: -180, max: 180 }}
                    onChange={(el) => {
                      el.stopPropagation();
                      setLocation({
                        ...location,
                        lat: toNumber(el.target.value),
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth={true}
                    disabled={props.disabled}
                    autoComplete="off"
                    label={I18n.t("activerecord.attributes.location.lon")}
                    value={defaultTo(location?.lon, "")}
                    inputProps={{ type: "number", min: -180, max: 180 }}
                    onChange={(el) => {
                      el.stopPropagation();
                      setLocation({
                        ...location,
                        lon: toNumber(el.target.value),
                      });
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth={true}
                    disabled={props.disabled}
                    autoComplete="off"
                    label={I18n.t("activerecord.attributes.location.zip")}
                    value={defaultTo(location?.zip, "")}
                    onChange={(el) => {
                      el.stopPropagation();
                      setLocation({
                        ...location,
                        zip: el.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextField
                    fullWidth={true}
                    disabled={props.disabled}
                    autoComplete="off"
                    label={I18n.t("activerecord.attributes.location.city")}
                    value={defaultTo(location?.city, "")}
                    onChange={(el) => {
                      setLocation({
                        ...location,
                        city: el.target.value,
                      });
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth={true}
                  disabled={props.disabled}
                  autoComplete="off"
                  label={I18n.t("activerecord.attributes.location.street")}
                  value={defaultTo(location?.street, "")}
                  onChange={(el) => {
                    el.stopPropagation();
                    setLocation({
                      ...location,
                      street: el.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth={true}
                    disabled={props.disabled}
                    autoComplete="off"
                    label={I18n.t("activerecord.attributes.location.level")}
                    value={defaultTo(location?.level, "")}
                    onChange={(el) => {
                      el.stopPropagation();
                      setLocation({
                        ...location,
                        level: el.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth={true}
                    disabled={props.disabled}
                    autoComplete="off"
                    label={I18n.t("activerecord.attributes.location.room")}
                    value={defaultTo(location?.room, "")}
                    onChange={(el) => {
                      el.stopPropagation();
                      setLocation({
                        ...location,
                        room: el.target.value,
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <LocationPicker
            saveLocationOnChange={false}
            location={location}
            onLocationSelected={(newLocation) => {
              setLocation(newLocation);
            }}
            searchResultApplied={(searchResult: GeocoderResultAddress) => {
              setLocation(() => {
                const newLocation = { ...location };
                return mapGeoSearchAddressToLocation(searchResult, newLocation);
              });
            }}
          />
        </Grid>
      </Grid>
      {allowSave ? (
        <FixedBottomArea>
          <FloatingButtons
            submitBtnIcon={
              isLoading || isPendingUpdate || isPendingCreate ? (
                <CircularProgress />
              ) : null
            }
            onCancel={() => {
              if (props.localizableType === "asset") {
                redirectTo(asset_path(props.localizableId));
              } else if (props.localizableType === "organization") {
                redirectTo(organization_path(props.localizableId));
              } else {
                redirectTo("back");
              }
            }}
            onSubmit={() => {
              if (location.id) {
                void updateLocation(location)
                  .then(() => {
                    if (props.onLocationChange) {
                      props.onLocationChange(location);
                    }
                    success(I18n.t("base.successfully_updated"));
                  })
                  .catch((e) => {
                    logger.error(e);
                    error(I18n.t("frontend.error"), e.message);
                  });
              } else {
                const theCreateLocation = {
                  ...location,
                };
                if (props.localizableType === "asset") {
                  theCreateLocation.asset = { id: props.localizableId };
                }

                if (props.localizableType === "organization") {
                  theCreateLocation.organization = { id: props.localizableId };
                }
                void createLocation(theCreateLocation)
                  .then((newLocation) => {
                    if (props.onLocationChange) {
                      props.onLocationChange(newLocation);
                    }
                    success(I18n.t("base.successfully_created"));
                  })
                  .catch((e) => {
                    logger.error(e);
                    error(I18n.t("frontend.error"), e.message);
                  });
              }
            }}
          ></FloatingButtons>
        </FixedBottomArea>
      ) : null}
    </>
  );
};
