import {
  jsonApiResourceCollectionToFlatObjects,
  loadItemResultForJsonApiCollectionResourceDoc,
  LoadItemsResult,
} from "../json_api/jsonapi_tools";
import {
  MANUFACTURER_JSONAPI_RESOURCE_TYPE,
  ManufacturerJSONObject,
} from "../json_api/manufacturer";
import { loadDataFromUrl } from "../utils/jquery_helper";
import { IDType, ParamsType } from "../utils/urls/url_utils";
import { api_manufacturers_path } from "../routes";
import { CollectionResourceDoc } from "jsonapi-typescript";
import { toInteger } from "lodash";
import { createQuery } from "react-query-kit";
import { defaultTo } from "lodash";

type LoadManufacturersResult = LoadItemsResult<ManufacturerJSONObject>;

async function loadManufacturers(
  pageNumber: number,
  pageSize: number,
): Promise<LoadManufacturersResult> {
  const jsonApiResponse = await loadDataFromUrl<
    CollectionResourceDoc<string, ManufacturerJSONObject>
  >(
    api_manufacturers_path({
      page: { number: defaultTo(pageNumber, 1), size: defaultTo(pageSize, 20) },
    }),
  );

  return loadItemResultForJsonApiCollectionResourceDoc<ManufacturerJSONObject>(
    jsonApiResponse,
  );
}

interface LoadManufacturerParams {
  pageNumber: number;
  pageSize: number;
}

export const useLoadManufacturersQuery = createQuery<
  LoadManufacturersResult,
  LoadManufacturerParams
>({
  queryKey: [MANUFACTURER_JSONAPI_RESOURCE_TYPE],
  placeholderData: { items: [], totalItems: 0, totalPages: 0 },
  fetcher: async ({ pageNumber, pageSize }) => {
    const result = await loadManufacturers(pageNumber, pageSize);
    return result;
  },
});
