import { Close, Search } from "@mui/icons-material";
import {
  Autocomplete,
  Grid,
  IconButton,
  Skeleton,
  TextField,
  Tooltip,
} from "@mui/material";
import { defaultTo, isEmpty, isNil, map } from "lodash";
import * as React from "react";

import { AssetJSONObject } from "../../json_api/asset";
import { EventSeverityLevel } from "../../models/event_notification";
import { IDType } from "../../utils/urls/url_utils";
import { AssetEventTypeSelect } from "../asset_event_types/asset_event_type_select";
import { AssetsAutocomplete } from "../assets/assets_autocomplete";
import { MaterialUiDatePicker } from "../common/date_picker";
import { EventSeveritySelect } from "./event_severity_selection";
import { useDebounce } from "@uidotdev/usehooks";

export interface AssetEventFilter {
  typeId: IDType;
  assetIds: IDType[];
  from: Date;
  to: Date;
  search?: string;
  eventPatternId?: IDType;
  severity_level?: EventSeverityLevel;
}
export interface AssetEventFilterProps {
  assetId: IDType;
  filter: AssetEventFilter;
  filterShowConfig?: Partial<Record<keyof AssetEventFilter, boolean>>;
  selectableAssets: AssetJSONObject[];
  useAssetAutocomplete?: boolean;
  assetAutocompleteRootsOnly?: boolean;
  onFilterChange: (filter: AssetEventFilter) => void;
}

export const AssetEventFilter: React.FunctionComponent<
  AssetEventFilterProps
> = ({ assetAutocompleteRootsOnly = true, filterShowConfig, ...props }) => {
  const [searchText, setSearchText] = React.useState<string>(
    props.filter.search,
  );

  const [assetFromAutocomplete, setAssetFromAutocomplete] =
    React.useState<AssetJSONObject>(null);

  if (props.useAssetAutocomplete) {
    React.useEffect(() => {
      const assetIds = isNil(assetFromAutocomplete)
        ? props.selectableAssets?.map((a) => a.id)
        : assetAutocompleteRootsOnly
          ? assetFromAutocomplete.subtree_ids
          : [assetFromAutocomplete.id];
      props.onFilterChange({
        ...props.filter,
        assetIds: assetIds,
      });
    }, [assetFromAutocomplete]);
  }

  const debounceSearch = useDebounce(searchText, 1000);

  React.useEffect(() => {
    props.onFilterChange({
      ...props.filter,
      search: !debounceSearch ? null : debounceSearch,
    });
  }, [debounceSearch]);

  return (
    <Grid container spacing={1}>
      {filterShowConfig?.assetIds !== false && (
        <Grid item xs={12} lg={3} xl={3} minWidth={150}>
          {isNil(props.selectableAssets) && !props.useAssetAutocomplete ? (
            <Skeleton variant="rectangular" height={50} />
          ) : isEmpty(props.selectableAssets) ? (
            props.useAssetAutocomplete ? (
              <AssetsAutocomplete
                size="small"
                width="100%"
                rootsOnly={assetAutocompleteRootsOnly}
                label={I18n.t(
                  "frontend.asset_events.asset_event_filter.asset_autocomplete_label",
                )}
                onSelect={(a) => {
                  setAssetFromAutocomplete(a);
                }}
                helperText={I18n.t(
                  "frontend.asset_events.asset_event_filter.asset_filter_helper_text",
                )}
              />
            ) : null
          ) : (
            <Autocomplete<AssetJSONObject | null, true>
              options={props.selectableAssets}
              multiple
              getOptionLabel={(o) => o.name}
              getOptionKey={(o) => o.id}
              size="small"
              renderInput={(props) => (
                <TextField
                  {...props}
                  size="small"
                  label={I18n.t(
                    "frontend.asset_events.asset_event_filter.assets",
                    {
                      count: 2,
                    },
                  )}
                  helperText={I18n.t(
                    "frontend.asset_events.asset_event_filter.asset_filter_helper_text",
                  )}
                />
              )}
              onChange={(event, assets) => {
                if (isEmpty(assets)) {
                  props.onFilterChange({
                    ...props.filter,
                    assetIds: map(props.selectableAssets, (a) => a.id),
                  });
                } else {
                  props.onFilterChange({
                    ...props.filter,
                    assetIds: map(assets as AssetJSONObject[], (a) => a.id),
                  });
                }
              }}
            />
          )}
        </Grid>
      )}
      {filterShowConfig?.typeId !== false && (
        <Grid item xs={12} lg={3} xl={2}>
          <AssetEventTypeSelect
            selectedTypeId={props.filter.typeId}
            assetId={defaultTo(props.assetId, assetFromAutocomplete?.id)}
            includeSystemEventsOnLoad
            helperText={I18n.t(
              "frontend.asset_events.asset_event_filter.asset_event_type_filter_helper_text",
            )}
            onTypeSelect={(type) => {
              props.onFilterChange({ ...props.filter, typeId: type?.id });
            }}
          />
        </Grid>
      )}

      {filterShowConfig?.severity_level !== false && (
        <Grid item xs={12} lg={3} xl={2}>
          <EventSeveritySelect
            required={false}
            severity={props.filter.severity_level}
            onSeveritySelect={(es) =>
              props.onFilterChange({ ...props.filter, severity_level: es })
            }
          />
        </Grid>
      )}
      {filterShowConfig?.search !== false && (
        <Grid item xs={12} lg={3} xl={2}>
          <TextField
            fullWidth
            type="search"
            size="small"
            label={I18n.t("frontend.asset_events.asset_event_list.search_text")}
            onChange={(event) => {
              setSearchText(event.target.value);
            }}
            onReset={() => {
              setSearchText(null);
              props.onFilterChange({ ...props.filter, search: null });
            }}
            onKeyUp={(e) => {
              if (e.key == "Enter") {
                e.stopPropagation();
                props.onFilterChange({
                  ...props.filter,
                  search: searchText,
                });
              }
            }}
            helperText={I18n.t(
              "frontend.asset_events.asset_event_filter.search_text_filter_helper_text",
            )}
            InputProps={{
              startAdornment: <Search />,
            }}
          />
        </Grid>
      )}
      {(filterShowConfig?.from !== false || filterShowConfig?.to !== false) && (
        <Grid item xs={12} lg="auto">
          <Grid container spacing={2}>
            {filterShowConfig?.from !== false && (
              <Grid item xs={6} minWidth={150}>
                <MaterialUiDatePicker
                  type="datetime"
                  noValueLabel={"---"}
                  value={
                    isNil(props.filter.from) ? null : moment(props.filter.from)
                  }
                  dateFormat="L LT"
                  autoApply
                  onChange={(date) => {
                    props.onFilterChange({
                      ...props.filter,
                      from: date?.toDate(),
                    });
                  }}
                  maxDate={
                    isNil(props.filter.to) ? null : moment(props.filter.to)
                  }
                  label={I18n.t("frontend.from")}
                  helperText={I18n.t(
                    "frontend.asset_events.asset_event_filter.from_helper_text",
                  )}
                  endAdornment={
                    <IconButton
                      size="small"
                      onClick={() => {
                        props.onFilterChange({ ...props.filter, from: null });
                      }}
                    >
                      <Close fontSize="inherit" />
                    </IconButton>
                  }
                  size="small"
                />
              </Grid>
            )}
            {filterShowConfig?.to !== false && (
              <Grid item xs={6} minWidth={150}>
                <MaterialUiDatePicker
                  dateFormat="L LT"
                  noValueLabel={"---"}
                  type="datetime"
                  value={
                    isNil(props.filter.to) ? null : moment(props.filter.to)
                  }
                  label={I18n.t("frontend.to")}
                  helperText={I18n.t(
                    "frontend.asset_events.asset_event_filter.to_helper_text",
                  )}
                  minDate={
                    isNil(props.filter.from) ? null : moment(props.filter.from)
                  }
                  onChange={(date) => {
                    props.onFilterChange({
                      ...props.filter,
                      to: date?.toDate(),
                    });
                  }}
                  endAdornment={
                    <IconButton
                      size="small"
                      onClick={() => {
                        props.onFilterChange({ ...props.filter, to: null });
                      }}
                    >
                      <Close fontSize="inherit" />
                    </IconButton>
                  }
                  size="small"
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
