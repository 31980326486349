import { createQuery } from "react-query-kit";
import { IDType } from "../utils/urls/url_utils";
import {
  LOCATION_CREATABLE_ATTRIBUTES,
  LOCATION_JSON_API_TYPE,
  LocationJSONObject,
} from "../json_api/location";
import { api_location_path, api_locations_path } from "../routes";
import { loadDataFromUrl, sendJsonApiData } from "../utils/jquery_helper";
import { SingleResourceDoc } from "jsonapi-typescript";
import {
  addRelatedObjectsToSingleObject,
  jsonApiSingleResourceToFlatObject,
} from "../json_api/jsonapi_tools";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  addHasOneRelationToJsonApiSubmitData,
  buildJsonApiSubmitData,
} from "../utils/jsonapi_form_tools";
import { re } from "mathjs";
import { ASSET_JSONAPI_RESOURCE_TYPE } from "../json_api/asset";
import { ORGANIZATION_JSONAPI_RESOURCE_TYPE } from "../json_api/organization";
import { each, isArray, isEmpty, map } from "lodash";
import { GeocoderResultAddress } from "../components/locations/location_picker";

interface LoadLocationVariables {
  id?: IDType;
}

export const useLoadLocation = createQuery<
  LocationJSONObject,
  LoadLocationVariables
>({
  queryKey: [LOCATION_JSON_API_TYPE],
  fetcher: async ({ id }) => {
    const url = api_location_path(id);
    const doc =
      await loadDataFromUrl<SingleResourceDoc<string, LocationJSONObject>>(url);

    return jsonApiSingleResourceToFlatObject(doc);
  },
});

export const useUpdateLocation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (location: LocationJSONObject) => {
      const submitData = buildJsonApiSubmitData(
        location,
        LOCATION_JSON_API_TYPE,
        LOCATION_CREATABLE_ATTRIBUTES,
      );

      if (location.asset?.id) {
        addHasOneRelationToJsonApiSubmitData(
          submitData.submitData,
          "asset",
          ASSET_JSONAPI_RESOURCE_TYPE,
          location.asset.id,
          false,
        );
      }
      if (location.organization?.id) {
        addHasOneRelationToJsonApiSubmitData(
          submitData.submitData,
          "organization",
          ORGANIZATION_JSONAPI_RESOURCE_TYPE,
          location.organization.id,
          false,
        );
      }
      const rest = await sendJsonApiData<
        unknown,
        SingleResourceDoc<string, LocationJSONObject>
      >(api_location_path(location.id), submitData.submitData, "PATCH");

      return jsonApiSingleResourceToFlatObject(rest);
    },
    onSuccess: (data, sourceData) => {
      queryClient.invalidateQueries({
        queryKey: [LOCATION_JSON_API_TYPE],
      });
      // merge the location so we do not loose the included items
      return { ...sourceData, ...data };
    },
  });
};

/**
 * Custom hook to create a new location using a mutation.
 *
 * This hook uses the `useMutation` hook from `react-query` to handle the creation of a new location.
 * It sends a POST request with the location data to the API and invalidates the location query cache upon success.
 *
 * @returns {MutationResult} The mutation result object from `useMutation`.
 *
 * @example
 * const { mutate, isLoading, isError, data } = useCreateLocation();
 *
 * const handleCreateLocation = (newLocation) => {
 *   mutate(newLocation, {
 *     onSuccess: (data) => {
 *       console.log('Location created successfully:', data);
 *     },
 *     onError: (error) => {
 *       console.error('Error creating location:', error);
 *     },
 *   });
 * };
 */
export const useCreateLocation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (location: LocationJSONObject) => {
      const submitData = buildJsonApiSubmitData(
        location,
        LOCATION_JSON_API_TYPE,
        LOCATION_CREATABLE_ATTRIBUTES,
      );

      if (location.asset?.id) {
        addHasOneRelationToJsonApiSubmitData(
          submitData.submitData,
          "asset",
          ASSET_JSONAPI_RESOURCE_TYPE,
          location.asset.id,
          false,
        );
      }
      if (location.organization?.id) {
        addHasOneRelationToJsonApiSubmitData(
          submitData.submitData,
          "organization",
          ORGANIZATION_JSONAPI_RESOURCE_TYPE,
          location.organization.id,
          false,
        );
      }

      const rest = await sendJsonApiData<
        unknown,
        SingleResourceDoc<string, LocationJSONObject>
      >(api_locations_path(), submitData.submitData, "POST");

      return jsonApiSingleResourceToFlatObject(rest);
    },
    onSuccess: (data, sourceData) => {
      queryClient.invalidateQueries({
        queryKey: [LOCATION_JSON_API_TYPE],
      });

      return { ...sourceData, ...data };
    },
  });
};

/**
 * Maps a geocoded address to a location object by copying relevant fields.
 *
 * @param address - The geocoded address object containing various address components.
 * @param targetLocation - The target location object to which the address components will be mapped.
 * @returns The updated target location object with the mapped address components.
 *
 * The mapping is done based on the following rules:
 * - "postcode" from `address` is mapped to "zip" in `targetLocation`.
 * - "city" from `address` is mapped to "city" in `targetLocation`.
 * - "town" from `address` is mapped to "city" in `targetLocation`.
 * - "road" and "house_number" from `address` are concatenated and mapped to "street" in `targetLocation`.
 * - "country_code" from `address` is mapped to "country_code_iso_2" in `targetLocation`.
 *
 * If a field in `targetLocation` is already populated, it will not be overwritten.
 */
export function mapGeoSearchAddressToLocation<
  T extends Pick<
    LocationJSONObject,
    "city" | "street" | "country_code_iso_2" | "zip"
  >,
>(address: GeocoderResultAddress, targetLocation: T): T {
  each(
    [
      ["postcode", "zip"],
      ["city", "city"],
      ["town", "city"],
      [["road", "house_number"], "street"],
      ["country_code", "country_code_iso_2"],
    ] as [
      keyof GeocoderResultAddress | [keyof GeocoderResultAddress],
      keyof T,
    ][],
    ([geocodeAtt, locationatt]) => {
      const value = targetLocation[locationatt];
      if (isEmpty(value)) {
        if (isArray(geocodeAtt)) {
          (targetLocation[locationatt] as string) = map(
            geocodeAtt,
            (att) => address[att],
          ).join(" ");
        } else {
          (targetLocation[locationatt] as string) = address[geocodeAtt];
        }
      }
    },
  );

  return targetLocation;
}
