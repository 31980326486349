import JSON from "json-typescript";
import * as JSONAPI from "jsonapi-typescript";
import { isEmpty, isNil, toString } from "lodash";
import { AssetEvent } from "../models/asset_event";
import { EventSeverityLevel } from "../models/event_notification";
import { api_asset_event_path, api_asset_events_path } from "../routes";
import { dialog } from "../utils/dialog";
import { sendJsonApiData } from "../utils/jquery_helper";
import {
  buildJsonApiSubmitData,
  JSONApiFormRequestMode,
} from "../utils/jsonapi_form_tools";
import { error, success } from "../utils/toasts";
import { IDType } from "../utils/urls/url_utils";
import { AssetJSONObject } from "./asset";
import { AssetEventTypeJSONObject } from "./asset_event_type";
import { EventPatternJSONObject } from "./event_pattern";
import {
  ModelErrors,
  jsonApiSingleResourceToFlatObject,
} from "./jsonapi_tools";
import { UserJSONObject } from "./user";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const ASSET_EVENT_JSONAPI_RESOURCE_TYPE = "asset_events";

interface AssetEventJsonApiAttributes extends AssetEvent {
  asset?: AssetJSONObject;
  root_asset?: AssetJSONObject;
  event_type?: AssetEventTypeJSONObject;
  user?: UserJSONObject;
  event_pattern?: EventPatternJSONObject;
  ack_at?: "string";
  ack_by_id?: IDType;
  additional_attributes?: JSON.Object;
}

export type AssetEventJsonApiInclude =
  | "event_type"
  | "asset"
  | "root_asset"
  | "user"
  | "event_pattern";
export const ASSET_EVENT_INCLUDES: AssetEventJsonApiInclude[] = [
  "event_type",
  "asset",
  "root_asset",
  "user",
  "event_pattern",
];

export const DEFAULT_ASSET_EVENT_INCLUDES: AssetEventJsonApiInclude[] = [
  "event_type",
  "asset",
  "root_asset",
  "user",
  "event_pattern",
];

export interface AssetEventJSONObject
  extends AssetEventJsonApiAttributes,
    JSON.Object {}

export type AssetEventJSONAPIAttributes =
  JSONAPI.AttributesObject<AssetEventJSONObject>;

export interface AssetEventJsonApiFilter {
  event_type?: IDType | IDType[];
  asset?: IDType | IDType[];
  asset_id?: IDType | IDType[];
  event_type_id?: IDType | IDType[];
  event_pattern?: IDType | IDType[];
  except_type?: IDType | IDType[];
  start_after?: Date;
  start_before?: Date;
  end_after?: Date;
  end_before?: Date;
  created_after?: Date;
  created_before?: Date;
  search?: string;
  subtree_of?: IDType;
  name?: string;
  severity_level?: EventSeverityLevel | EventSeverityLevel[];
  acknowledged?: boolean;
}

export const ASSET_EVENT_JSON_API_SUBMIT_ATTRIBUTES = [
  "description",
  "name",
  "from",
  "to",
  "item_id",
  "item_type",
  "severity_level",
  "action",
  "message",
  "code",
];

export function validateAssetEvent(
  event: AssetEventJSONAPIAttributes,
): ModelErrors<AssetEventJSONAPIAttributes> {
  const errors: ModelErrors<AssetEventJSONAPIAttributes> = {};

  if (isNil(event)) return errors;
  if (isNil(event.event_type?.id) && isNil(event.event_type_id)) {
    errors.event_type = [I18n.t("errors.messages.blank")];
  }

  if (isNil(event.asset?.id) && isNil(event.asset_id)) {
    errors.asset = [I18n.t("errors.messages.blank")];
  }

  if (isNil(event.from)) {
    errors.from = [I18n.t("errors.messages.blank")];
  } else {
    if (!isEmpty(event.to)) {
      if (moment(event.from).isAfter(moment(event.to))) {
        const err = I18n.t("errors.messages.after");
        if (errors.from) {
          errors.from.push(err);
        } else {
          errors.from = [err];
        }
      }
    }
  }
  if (isEmpty(event.name)) {
    errors.name = [I18n.t("errors.messages.blank")];
  }

  if (isEmpty(event.severity_level)) {
    errors.severity_level = [I18n.t("errors.messages.blank")];
  }
  return errors;
}
