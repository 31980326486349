/* eslint-disable @typescript-eslint/no-unused-vars */
import { find, flatten, isEmpty, isNil, map } from "lodash";
import * as React from "react";

import { Box, Divider, Grid, Paper, Skeleton, Typography } from "@mui/material";
import { DateRange } from "moment-range";
import {
  loadSensors,
  SENSOR_JSONAPI_RESOURCE_TYPE,
  SensorJSONAPIAttributes,
} from "../../json_api/sensor";
import { LineDiagramWidget } from "../widgets/line_diagram_widget";
import { SensorValueWidget } from "../widgets/sensor_value_widget";
import { SensorInfo } from "./sensor_info";

import { useQuery } from "@tanstack/react-query";
import { LineShape } from "../../charting/plotly_time_series_line_diagram_base.types";
import {
  SamplingRate,
  SensorDataSamplingMode,
  SensorLineType,
} from "../../models/sensor";
import { logger } from "../../utils/logger";
import { SensorIncludes } from "../../utils/urls";
import { IDType } from "../../utils/urls/url_utils";
import { SamplingMode } from "../../charting/chart_data/chart_data_loader.types";

interface SensorDisplayProps {
  sensors?: SensorJSONAPIAttributes[];
  sensorIds?: IDType[];
  timeRange?: DateRange;
  fullHeight?: boolean;
  samplingRate?: SamplingRate;
  samplingMode?: SensorDataSamplingMode;
  onChangeTimeRange?: (range: DateRange) => void;
  onChangeSamplingRate?: (
    samplingRate: SamplingRate,
    samplingMode: SensorDataSamplingMode,
  ) => void;
}
export function lineShapeForValueLineType(type: SensorLineType): LineShape {
  switch (type) {
    case "binary":
      return "hv";
    case "step":
      return "hv";
    case "enumerated_value":
      return "hv";
    default:
      return "linear";
  }
}

const SENSOR_INCLUDES: SensorIncludes[] = ["sensor_type", "asset"];
export const SensorDisplay: React.FunctionComponent<SensorDisplayProps> = (
  props,
) => {
  const [sensors, setSensors] = React.useState<SensorJSONAPIAttributes[]>(
    props.sensors,
  );

  React.useEffect(() => {
    if (props.sensors) {
      setSensors(props.sensors);
    }
  }, [props.sensors]);

  const {
    data: loadedSensors,
    isLoading: sensorsLoading,
    isSuccess: sensorLoadSuccess,
    error: sensorsError,
  } = useQuery({
    queryKey: [
      SENSOR_JSONAPI_RESOURCE_TYPE,
      { sensor_ids: props.sensorIds, include: SENSOR_INCLUDES },
    ],
    queryFn: (data) => {
      return loadSensors(props.sensorIds, null, SENSOR_INCLUDES);
    },
    placeholderData: sensors,
    enabled: !isEmpty(props.sensorIds),
  });

  React.useEffect(() => {
    if (sensorLoadSuccess && loadedSensors) {
      setSensors(loadedSensors);
    }
  }, [loadedSensors]);

  React.useEffect(() => {
    if (sensorsError) {
      logger.logError(sensorsError);
    }
  }, [sensorsError]);

  const samplingRate = React.useMemo(() => {
    if (props.samplingRate) {
      return props.samplingRate;
    }

    const srs = find(
      sensors,
      (s) => !isNil(s.sampling_rate_unit) && !isNil(s.sampling_rate_value),
    );

    return srs
      ? {
          value: srs.sampling_rate_value,
          unit: srs.sampling_rate_unit,
        }
      : null;
  }, [sensors]);

  return (
    <Grid container spacing={2}>
      {sensorsError ? (
        <Grid item xs={12}>
          <Paper>
            <Box m={2}>
              <Typography variant="h5">{I18n.t("frontend.error")}</Typography>
              <Typography>
                {I18n.t("frontend.sensors.sensor_display.error_loading")}
              </Typography>
              <Typography variant="body2">{sensorsError.message}</Typography>
            </Box>
          </Paper>
        </Grid>
      ) : null}
      <Grid item xs={12} md={8} lg={9}>
        {sensorsLoading ? (
          <Skeleton variant="rectangular" height={600} />
        ) : (
          sensors && (
            <LineDiagramWidget
              allowFullscreen
              sensorIds={map(sensors, (s) => s.id)}
              dataUrls={null}
              showStateSelection
              showStatistics
              oneYAxisPerUnitOnly
              samplingMode={props.samplingMode}
              contextStateMachineIds={flatten(
                sensors?.map((s) =>
                  map(
                    s.asset?.asset_states,
                    (stateInfo, state_name) => stateInfo?.csm_id,
                  ),
                ),
              )}
              lineOptions={{
                lineMode: "lines+markers",
                lineShape: map(sensors, (s) =>
                  lineShapeForValueLineType(s.line_shape),
                ),
              }}
              timeRange={isNil(props.timeRange) ? null : props.timeRange}
              onChangeTimeRange={props.onChangeTimeRange}
              diagramHeight={props.fullHeight ? "calc(100vh - 250px)" : 600}
              samplingRate={samplingRate}
              storeSettingsInAnchor={false}
              disableSettings={false}
              hideSettings={false}
              activeContextStateMachineId={null}
              onChangeSamplingRate={props.onChangeSamplingRate}
            />
          )
        )}
      </Grid>
      <Grid item xs={12} md={4} lg={3}>
        <Paper>
          <Box m={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">
                  {I18n.t("frontend.sensors.sensor_display.values_header")}
                </Typography>
              </Grid>
              {sensorsLoading ? (
                <Grid item xs={12}>
                  <Skeleton variant="rectangular" height={300} />
                </Grid>
              ) : (
                sensors &&
                map(sensors, (s, index) => (
                  <Grid item container xs={12} key={index}>
                    <Grid item xs={12}>
                      <SensorValueWidget
                        sensor={s}
                        mode="rows"
                        useValueRange
                        noLinks
                        updateEnabled
                        sensorId={""}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SensorInfo sensor={s} collapsible />
                      <Divider className="my-2" />
                    </Grid>
                  </Grid>
                ))
              )}
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};
