import AddIcon from "@mui/icons-material/Add";
import PrintIcon from "@mui/icons-material/Print";
import {
  Box,
  ButtonGroup,
  Fab,
  Grid,
  IconButton,
  MenuItem,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import {
  Dictionary,
  chain,
  clone,
  isEmpty,
  isNil,
  map,
  sortBy,
  uniq,
} from "lodash";
import * as React from "react";

import { Compress, Expand, Refresh } from "@mui/icons-material";
import { useLocalStorage } from "@uidotdev/usehooks";
import MeasurementChannel from "../../../channels/measurement_channel";
import { MeasurementJsonObject } from "../../../json_api/measurement";
import { ActiveStorageStoredFile } from "../../../models/active_storage_stored_file";
import { MeasurementValue } from "../../../models/measurement";
import { MeasurementCategory } from "../../../models/measurement_category";
import { MeasurementTypeDiagramType } from "../../../models/measurement_type";
import { MeasurementValueDefinition } from "../../../models/measurement_value_definition";
import {
  useLoadMeasurement,
  useLoadMeasurements,
} from "../../../queries/measurements_data";
import { useLoadMeasurementPlan } from "../../../queries/measurements_plan_data";
import { getLocalStorageKey } from "../../../storage/storage_keys";
import { computeValuePercent } from "../../../utils/calculate_percentage";
import { redirectTo } from "../../../utils/redirection";
import { newAssetMeasurementPath } from "../../../utils/urls";
import { IDType } from "../../../utils/urls/url_utils";
import { FilePreviewModal } from "../../common/file_preview_modal";
import { FixedBottomArea } from "../../common/fixed_bottom_area";
import { FloatingButtons } from "../../common/floating_buttons";
import { IBox, IBoxContent, IBoxFooter, IBoxTitle } from "../../common/ibox";
import { PageSizeSelect } from "../../common/page_size_select";
import { MeasurementFilter, MeasurementFilterView } from "./measurement_filter";
import { MeasurementGraphTypeSelect } from "./measurement_graph_type_select";
import { MeasurementItem } from "./measurement_item";
import { MeasurementValueDiagram } from "./measurement_value_diagram";
import {
  buildMeasurementReferenceValues,
  ReferenceMeasurementValue,
} from "../utils/measurement_reference_eval";

import { error as errorToast, success } from "../../../utils/toasts";
import { logger } from "../../../utils/logger";

interface MeasurementListProperties {
  assetId?: string | number;
  measurementPlanId?: string | number;
  currentPage?: number;
  pageSize?: number;
}

interface CollapsibleMeasurementItem extends MeasurementJsonObject {
  collapsed: boolean;
}

export const MeasurementList: React.FC<MeasurementListProperties> = ({
  measurementPlanId,
  assetId,
  ...props
}) => {
  const [fileToPreview, setFileToPreview] =
    React.useState<ActiveStorageStoredFile>();

  const [pageSettings, setPageSettings] = React.useState({
    number: props.currentPage || 1,
    pageSize: props.pageSize || 10,
  });

  const pageSizes = React.useMemo(() => {
    const sizes = [5, 10, 20, 50, 100];
    sizes.push(props.pageSize || 10);
    return sortBy(uniq(sizes));
  }, [props.pageSize]);
  const [measurementValues, setMeasurementValues] = React.useState<
    MeasurementValue[]
  >([]);

  const [groupMeasurementValues, setGroupMeasurementValues] =
    React.useState(false);

  const [diagramMode, setDiagramMode] = useLocalStorage<
    "linePerTime" | "linePerValue"
  >(
    getLocalStorageKey("measurementPlan", measurementPlanId, "diagramMode"),
    "linePerValue",
  );

  const [measurements, setMeasurements] = React.useState<
    CollapsibleMeasurementItem[]
  >([]);

  const [newMeasurements, setNewMeasurements] = React.useState([]);

  const [measurementCategoriesById, setMeasurementCategoriesById] =
    React.useState<Dictionary<MeasurementCategory>>({});

  const {
    data: measurementPlan,
    isLoading: measurementPlanLoading,
    error: measurementPlanLoadError,
  } = useLoadMeasurementPlan({
    variables: {
      id: measurementPlanId,
      include: [
        "measurement_type",
        "measurement_value_definitions",
        "measurement_categories",
        "asset",
      ],
    },
  });

  const [measurementValueDefinitions, setMeasurementValueDefinitions] =
    React.useState<MeasurementValueDefinition[]>([]);

  // extract categories and value definitions from measurement plan
  React.useEffect(() => {
    if (measurementPlan) {
      const categoriesById = chain(measurementPlan?.measurement_categories)
        .keyBy("id")
        .value();
      setMeasurementCategoriesById(categoriesById);

      // sort by position
      const measurementValueDefinitions =
        measurementPlan?.measurement_value_definitions;
      setMeasurementValueDefinitions(
        sortBy(measurementValueDefinitions, "position"),
      );
    }
  }, [measurementPlan]);

  const [filter, setFilter] = React.useState<MeasurementFilter>({});
  const {
    data: loadedMeasurements,
    isLoading,
    error,
  } = useLoadMeasurements({
    variables: {
      measurementPlanId: measurementPlanId,
      filter: filter,
      page: pageSettings.number,
      pageSize: pageSettings.pageSize,
    },
    enabled: !isNil(measurementPlanId),
  });

  React.useEffect(() => {
    if (loadedMeasurements?.items && measurementPlan) {
      let theMeasurements = loadedMeasurements.items;
      if (
        measurementPlan.measurement_type_type ===
        "distribution_measurement_type"
      ) {
        theMeasurements.forEach((measurement) => {
          measurement.measurement_values = computeValuePercent(
            measurement.measurement_values,
          );
        });
      }

      theMeasurements = map(theMeasurements, (measurement) => ({
        ...measurement,

        collapsed: true,
      }));
      // apply a collapsed flag to each measurement
      setMeasurements(theMeasurements as CollapsibleMeasurementItem[]);

      let allValues = chain(theMeasurements)
        .flatMap("measurement_values")
        .sortBy("time")
        .value();

      setMeasurementValues(allValues);

      if (
        loadedMeasurements.totalItems > 0 &&
        pageSettings.number > 1 &&
        loadedMeasurements.totalPages < pageSettings.number
      ) {
        // jump to first page if the last page is empty
        setPageSettings((ps) => ({
          ...ps,
          number: loadedMeasurements.totalPages || 1,
        }));
      }
    }
  }, [loadedMeasurements, measurementPlan]);

  React.useEffect(() => {
    const measurementChannel = new MeasurementChannel(true);
    const measurementSubscriptions = measurementChannel.addEventListener(
      {
        handleMeasurement(
          measurementId,
          assetId,
          rootAssetId,
          time,
          measurementValues,
          measurementPlanId,
          measurementPlanKey,
        ) {
          setNewMeasurements((s) => {
            return [
              ...s,
              {
                id: measurementId as number,
                asset_id: assetId as number,
                measurement_plan_id: measurementPlanId as string,
                time: time.toISOString(),
              },
            ];
          });
        },
      },
      assetId as number,
    );
    return () => {
      if (measurementSubscriptions) {
        measurementChannel.unsubscribeAll();
      }
    };
  }, []);

  const renderContent = !measurementPlanLoadError && !error;

  const [selectedMeasurements, setSelectedMeasurements] = React.useState<
    MeasurementJsonObject[]
  >([]);

  const [singleMeasurementDiagramType, setSingleMeasurementDiagramType] =
    useLocalStorage(
      getLocalStorageKey("measurementPlan", measurementPlanId, "diagramType"),
      measurementPlan?.measurement_type?.diagram_type || "line",
    );

  const {
    data: referenceMeasurement,
    isLoading: referenceMeasurementLoading,
    error: referenceMeasurementLoadError,
  } = useLoadMeasurement({
    variables: {
      id: measurementPlan?.reference_measurement_id,
      include: [
        "measurement_plan",
        "measurement_type",
        "measurement_value_definitions",
        "asset",
      ],
    },
    enabled: Boolean(measurementPlan?.reference_measurement_id),
  });
  const [referenceMeasurementValues, setReferenceMeasurementValues] =
    React.useState<ReferenceMeasurementValue[] | null>(null);

  React.useEffect(() => {
    if (referenceMeasurementLoadError) {
      errorToast(referenceMeasurementLoadError.message);
      return;
    }

    if (referenceMeasurement) {
      try {
        const refValues = buildMeasurementReferenceValues(
          referenceMeasurement,
          measurementValueDefinitions,
          referenceMeasurement.measurement_value_definitions,
          measurementPlan.measurement_type,
          measurementCategoriesById,
        );
        setReferenceMeasurementValues(refValues);
      } catch (error) {
        logger.error("Error building reference values", error);
        setReferenceMeasurementValues(null);
      }
    }
  }, [
    referenceMeasurement,
    referenceMeasurementLoadError,
    measurementCategoriesById,
  ]);

  if (measurementPlanLoading || isLoading) {
    return (
      <>
        <IBox>
          <IBoxContent>
            <Skeleton variant="rectangular" height={400} />
          </IBoxContent>
        </IBox>
        <IBox>
          <IBoxContent>
            <Skeleton variant="rectangular" height={600} />
          </IBoxContent>
        </IBox>
      </>
    );
  }

  return (
    <>
      <IBox>
        <IBoxContent>
          <MeasurementFilterView filter={filter} onFilterChange={setFilter} />
          {(measurementPlanLoadError || error) && (
            <Box>
              <Typography variant="h5">
                {I18n.t("frontend.measurement_list.error_loading_data")}
              </Typography>
              <Typography>
                {I18n.t("frontend.measurement_list.error_loading_data_message")}
              </Typography>
            </Box>
          )}
        </IBoxContent>
      </IBox>

      {renderContent && (
        <>
          <IBox>
            <IBoxTitle
              tools={
                <>
                  <Box displayPrint="none">
                    <TextField
                      select
                      size="small"
                      label={I18n.t(
                        "frontend.measurement_list.value_mapping_select",
                      )}
                      value={diagramMode || "linePerValue"}
                      onChange={(e) => {
                        setDiagramMode(
                          e.target.value as "linePerValue" | "linePerTime",
                        );
                      }}
                    >
                      <MenuItem value="linePerValue">
                        {I18n.t(
                          "frontend.measurement_list.show_line_per_value",
                        )}
                      </MenuItem>
                      <MenuItem value="linePerTime">
                        {I18n.t("frontend.measurement_list.show_line_per_time")}
                      </MenuItem>
                    </TextField>
                    {measurementPlan.measurement_type_type !==
                    "distribution_measurement_type" ? null : (
                      <>
                        <TextField
                          select
                          size="small"
                          label={I18n.t(
                            "frontend.measurement_list.value_grouping_select",
                          )}
                          value={groupMeasurementValues ? "true" : "false"}
                          onChange={(e) => {
                            setGroupMeasurementValues(
                              e.target.value === "true",
                            );
                          }}
                        >
                          <MenuItem value="false">
                            {I18n.t("frontend.measurement_list.show_ungrouped")}
                          </MenuItem>
                          <MenuItem
                            value="true"
                            disabled={isEmpty(
                              measurementPlan.measurement_categories,
                            )}
                          >
                            {I18n.t(
                              "frontend.measurement_list.show_grouped_by_category",
                            )}
                          </MenuItem>
                        </TextField>
                      </>
                    )}
                  </Box>

                  {newMeasurements.length > 0 ? (
                    <>
                      <Typography variant="caption">
                        {I18n.t("frontend.measurement_list.new_measurements")}
                      </Typography>
                      <IconButton
                        size="small"
                        onClick={() => {
                          window.location.reload();
                        }}
                      >
                        <Refresh />
                      </IconButton>
                    </>
                  ) : null}
                </>
              }
            >
              <Typography variant="h5">
                {measurementPlan?.measurement_type_title}
              </Typography>
            </IBoxTitle>
            <IBoxContent>
              {isEmpty(measurements) ? (
                <Box textAlign="center">
                  {I18n.t("frontend.measurement_list.no_measurements")}
                </Box>
              ) : (
                <MeasurementValueDiagram
                  diagramMode={diagramMode}
                  measurementValueDefinitions={measurementValueDefinitions}
                  measurementCategories={measurementPlan.measurement_categories}
                  measurementValues={measurementValues}
                  referenceMeasurementValues={referenceMeasurementValues}
                  mvdIntervalUnit={
                    measurementPlan?.measurement_type?.interval_unit
                  }
                  measurementType={measurementPlan?.measurement_type}
                  groupMeasurementValues={groupMeasurementValues}
                />
              )}
            </IBoxContent>
          </IBox>
          <IBox>
            <IBoxTitle
              tools={
                <Box displayPrint="none">
                  <MeasurementGraphTypeSelect
                    measurementType={measurementPlan.measurement_type}
                    graphType={singleMeasurementDiagramType}
                    onChangeGraphType={setSingleMeasurementDiagramType}
                  />
                  <ButtonGroup size="small">
                    <IconButton
                      size="small"
                      onClick={() => {
                        const newMeasurements = map(measurements, (m) => ({
                          ...m,
                          collapsed: true,
                        }));
                        setMeasurements(newMeasurements);
                      }}
                    >
                      <Compress />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => {
                        const newMeasurements = map(measurements, (m) => ({
                          ...m,
                          collapsed: false,
                        }));
                        setMeasurements(newMeasurements);
                      }}
                    >
                      <Expand />
                    </IconButton>
                  </ButtonGroup>
                </Box>
              }
            />
            <IBoxContent>
              {map(measurements, (measurement, index) => {
                return (
                  <MeasurementItem
                    diagramType={
                      singleMeasurementDiagramType as MeasurementTypeDiagramType
                    }
                    measurement={measurement}
                    measurementValues={measurement.measurement_values}
                    measurementPlan={measurementPlan}
                    measurementType={measurementPlan.measurement_type}
                    orderedMeasurementValueDefinitions={
                      measurementValueDefinitions
                    }
                    collapsed={measurement.collapsed}
                    measurementCategoryById={measurementCategoriesById}
                    referenceMeasurementValues={referenceMeasurementValues}
                    onToggle={(collapsed) => {
                      setMeasurements((m) => {
                        const newMeasurements = clone(m);
                        newMeasurements[index] = {
                          ...newMeasurements[index],
                          collapsed: collapsed,
                        };
                        return newMeasurements;
                      });
                    }}
                    key={`measurement-${measurement.id}`}
                    onShowFilePreview={(file) => setFileToPreview(file)}
                  />
                );
              })}
            </IBoxContent>

            <IBoxFooter>
              <Grid container>
                <Grid item container xs={12} justifyContent="space-between">
                  <Grid item>
                    <Box height="100%" display="flex" alignItems="center">
                      {I18n.t("frontend.total")}:{" "}
                      {loadedMeasurements.totalItems}
                    </Box>
                  </Grid>
                  <Grid item xs="auto">
                    {loadedMeasurements && loadedMeasurements.totalPages > 1 ? (
                      <Pagination
                        page={pageSettings.number}
                        count={loadedMeasurements.totalPages}
                        onChange={(event, pageNumber) => {
                          setPageSettings((ps) => ({
                            ...ps,
                            number: pageNumber,
                          }));
                        }}
                      />
                    ) : null}
                  </Grid>
                  <Grid item>
                    <PageSizeSelect
                      pageSize={pageSettings.pageSize}
                      availablePageSizes={pageSizes}
                      onSelectPageSize={(newSize) => {
                        setPageSettings((ps) => ({ ...ps, pageSize: newSize }));
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </IBoxFooter>
          </IBox>
          <FilePreviewModal
            url={fileToPreview?.url}
            contentType={fileToPreview?.content_type}
            fileName={fileToPreview?.filename}
            isOpen={!isNil(fileToPreview)}
            onClose={() => setFileToPreview(null)}
          />
        </>
      )}
      {renderFixedBottomArea(assetId, measurementPlanId)}
    </>
  );
};

function renderFixedBottomArea(
  assetId: IDType,
  measurementPlanId: IDType,
): React.ReactNode {
  return (
    <FixedBottomArea>
      <FloatingButtons
        showScrollToTopBtn={true}
        isProcessing={false}
        submitBtnIcon={<AddIcon />}
        saveTitle={I18n.t("frontend.measurement_list.add_new_measurement")}
        onSubmit={
          !isNil(assetId)
            ? () => {
                redirectTo(newAssetMeasurementPath(assetId, measurementPlanId));
              }
            : null
        }
      >
        <Fab
          aria-label={I18n.t("frontend.print")}
          title={I18n.t("frontend.print")}
          color="default"
          size="medium"
          onClick={() => window.print()}
        >
          <PrintIcon />
        </Fab>
      </FloatingButtons>
    </FixedBottomArea>
  );
}
