import { CollectionResourceDoc } from "jsonapi-typescript";
import { concat, isEmpty, uniq } from "lodash";
import { createQuery } from "react-query-kit";
import {
  loadItemResultForJsonApiCollectionResourceDoc,
  LoadItemsResult,
} from "../json_api/jsonapi_tools";
import {
  SUBSCRIPTION_PERIOD_JSONAPI_RESOURCE_TYPE,
  SubscriptionPeriodJSONObject,
} from "../json_api/subscription_period";
import { api_organization_subscription_subscription_periods_path } from "../routes";
import { loadDataFromUrl } from "../utils/jquery_helper";
import { IDType } from "../utils/urls/url_utils";

export interface LoadSubscriptionPeriodsVariables {
  orgId: IDType;
  subscriptionId: IDType;
  pageSettings: { pageSize: number; page: number };
  include?: string[];
}

export const useLoadSubscriptionPeriodsQuery = createQuery<
  LoadItemsResult<SubscriptionPeriodJSONObject>,
  LoadSubscriptionPeriodsVariables
>({
  queryKey: [SUBSCRIPTION_PERIOD_JSONAPI_RESOURCE_TYPE],
  fetcher: async ({ orgId, subscriptionId, pageSettings, include }) => {
    const options = {
      _options: true,
    };
    const includes = uniq(concat(include || [])).join(",");
    if (!isEmpty(includes)) {
      (options as any)["include"] = includes;
    }

    if (pageSettings?.page || pageSettings?.pageSize) {
      (options as any).page = {
        number: pageSettings.page || 1,
        size: pageSettings.pageSize || 12,
      };
    }

    const jsonApiResponse = await loadDataFromUrl<
      CollectionResourceDoc<string, SubscriptionPeriodJSONObject>
    >(
      api_organization_subscription_subscription_periods_path(
        orgId,
        subscriptionId,
        options,
      ),
    );

    const items =
      loadItemResultForJsonApiCollectionResourceDoc<SubscriptionPeriodJSONObject>(
        jsonApiResponse,
      );
    return items;
  },
});
