import React, { useContext, useMemo } from "react";
import { IDType } from "../../utils/urls/url_utils";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { ConfiguredKpiJSONObject } from "../../json_api/configured_kpi";
import { useLoadConfiguredKpis } from "../../queries/configured_kpi_data";
import { Box } from "@mui/material";
import { AdminPanelSettings, StackedLineChart } from "@mui/icons-material";
import { redirectTo } from "../../utils/redirection";
import {
  rails_admin_edit_path,
  show_configured_kpi_kpis_path,
} from "../../routes";
import { AppContext } from "../common/app_context/app_context_provider";
interface ConfiguredKpiListProps {
  assetId: IDType;
  onSelect?: (configuredKpi: ConfiguredKpiJSONObject) => void;
}

export const ConfiguredKpiList: React.FC<ConfiguredKpiListProps> = ({
  assetId,
  onSelect,
}) => {
  const [pageSettings, setPageSettings] = React.useState({
    number: 1,
    size: 10,
  });

  const { data: loadedConfiguredKpis, isLoading } = useLoadConfiguredKpis({
    variables: { assetId, pageSettings },
  });

  const appCtx = useContext(AppContext);

  const gridColDef: GridColDef<ConfiguredKpiJSONObject>[] = useMemo(
    () => [
      { field: "id", type: "number", headerName: "ID", width: 70 },
      { field: "name", type: "string", width: 200 },
      { field: "description", type: "string", width: 200 },
      { field: "kpi_type", type: "string", width: 150 },
      { field: "diagram_type", type: "string" },
      { field: "color", type: "string" },
      { field: "created_at", type: "dateTime" },
      { field: "updated_at", type: "dateTime" },
      {
        field: "actions",
        type: "actions",
        getActions: ({ row }) => [
          <GridActionsCellItem
            key="edit"
            label="Diagram"
            icon={<StackedLineChart />}
            onClick={() => {
              onSelect
                ? onSelect(row)
                : redirectTo(show_configured_kpi_kpis_path(row.id));
            }}
          />,
          appCtx.user.isAdmin ? (
            <GridActionsCellItem
              showInMenu
              icon={<AdminPanelSettings />}
              label={"Edit"}
              onClick={() => {
                redirectTo(rails_admin_edit_path("configured_kpi", row.id));
              }}
            />
          ) : null,
        ],
      },
    ],
    [appCtx.user, onSelect],
  );

  return (
    <Box display="flex" flexDirection="column">
      <DataGrid
        pageSizeOptions={[10, 25, 50]}
        rows={loadedConfiguredKpis?.items || []}
        columns={gridColDef}
        paginationMode="server"
        paginationModel={{
          page: pageSettings.number - 1,
          pageSize: pageSettings.size,
        }}
        rowCount={loadedConfiguredKpis?.totalItems || -1}
        loading={isLoading}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
              diagram_type: false,
              created_at: false,
              updated_at: false,
            },
          },
        }}
        autoHeight
        onPaginationModelChange={(pm) => {
          setPageSettings({
            number: pm.page + 1,
            size: pm.pageSize,
          });
        }}
      />
    </Box>
  );
};
