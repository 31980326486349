import { defaultTo, first, isEmpty, isNil, method, toNumber } from "lodash";
import {
  generateLinkForItemActionRoute,
  ItemAction,
} from "../../models/item_action";
import { Organization } from "../../models/organization";
import { ItemActionButton } from "../common/item_action_button";
import {
  remove_asset_api_organization_path,
  switch_current_organization_path,
} from "../../routes";

import * as React from "react";
import { RequestMethod } from "../../utils/jquery_helper";

export function buttonForOrganizationAction(
  action: ItemAction,
  organization: Organization,
  key: string | number,
  onComplete?: () => void,
): React.ReactElement {
  const link = linkForOrganizationAction(action, organization);
  const title = defaultTo(
    action.title,
    I18n.t(`frontend.organizations.list.actions.${action.action}`),
  );
  if (!isNil(link)) {
    return (
      <ItemActionButton
        key={key}
        action={action}
        method={action.method || link?.method}
        link={link?.route}
        title={title}
        onComplete={onComplete}
      />
    );
  } else {
    return null;
  }
}

export function linkForOrganizationAction(
  action: ItemAction,
  organization: Organization,
): { route: string; method: RequestMethod } | null {
  if (!isNil(action.action)) {
    if (action.action === "switch")
      return {
        route: switch_current_organization_path(organization.id),
        method: "PATCH",
      };
    if (action.action === "remove_asset") {
      const assetId = toNumber(first(action.params));
      return isNil(assetId) || isNaN(assetId)
        ? null
        : {
            route: remove_asset_api_organization_path(organization.id, assetId),
            method: "POST",
          };
    }
  } else if (!isEmpty(action.route)) {
    generateLinkForItemActionRoute(
      action.route,
      action.params,
      organization.id,
    );
  }
  return null;
}
