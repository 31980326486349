/// <reference types="../definitions/index" />;
import {
  clone,
  defaultTo,
  find,
  isArray,
  isEmpty,
  isEqual,
  isNil,
  isString,
  sumBy,
  throttle,
  toNumber,
  values,
} from "lodash";

import { DateRange } from "moment-range";

const germanLocale = require("plotly.js-locales/de");

import { Moment } from "moment";
import { Dash, PlotDatum, SelectionRange } from "plotly.js";
import { logger } from "../utils/logger";
import { convertToUnit, unitDisplayString } from "../utils/unit_conversion";
import { IDType } from "../utils/urls/url_utils";
import {
  BinaryChartDataLoadResult,
  StateData,
  TimeSeriesDataParameter,
  ValueTrendData,
} from "./chart_data/chart_data_loader.types";
import { ChartStatistics } from "./chart_data/chart_data_statistics";
import {
  DIAGRAM_DEFAULT_HOVER_LABEL_SETTINGS,
  DIAGRAM_POINT_HOVER_BORDER_COLOR,
  getDiagramFillColor,
  getDiagramLineColor,
  hoverDistanceForDataCount,
  hoverModeForDataCount,
} from "./diagram_constants";
import { PlotlyDefaultFontHover } from "./plotly_fonts";
import { Plotly, PlotlyLayoutYaxisNames } from "./plotly_package";
import { PlotlyFont } from "./plotly_styling";
import { PlotlyTimeSeriesLineDiagramBase } from "./plotly_time_series_line_diagram_base";
import {
  AxisOptions,
  ChartDataSourceOptions,
  LineDiagramElementRefs,
  LineMode,
  LineShape,
  ScatterLineShape,
  StateMachineOptions,
  ZoomMode,
} from "./plotly_time_series_line_diagram_base.types";

(Plotly as any).register(germanLocale);

export interface LineChartStatistics {
  chartStatistics: ChartStatistics[];
  stateInfo: StateData[];
}

/** Configuration for a single Line in the diagram
 *
 *
 * @interface LineConfig
 * @extends {TimeSeriesDataParameter}
 */
export interface LineConfig extends TimeSeriesDataParameter {
  /** The Base url to retrieve the data for the time series. Should not have parameters already defined in URL
   *
   *
   * @type {string}
   * @memberof LineConfig
   */
  baseUrl: string;
  lineDash?: Dash;
  lineShape?: ScatterLineShape;
  /** Groups the series within the legend by the given string
   *
   *
   * @type {string}
   * @memberof LineConfig
   */
  legendGroup?: string;
  color?: string;
  mode?: LineMode;
  // Pixel width
  width?: number;
  simplifyLine?: boolean;
}

export class PlotlyLineChart extends PlotlyTimeSeriesLineDiagramBase {
  static margins = {
    t: 40,
    b: 40,
    l: 10,
    r: 10,
  };

  static modeBarButtons:
    | Plotly.ModeBarDefaultButtons[][]
    | Plotly.ModeBarButton[][] = [
    ["resetScale2d", "zoom2d", "zoomIn2d", "zoomOut2d", "pan2d", "autoScale2d"],
    ["hoverClosestCartesian", "hoverCompareCartesian", "toggleSpikelines"],
    ["toImage"],
  ];

  modeBarButtons: Plotly.ModeBarDefaultButtons[][] | Plotly.ModeBarButton[][];

  stateTransitionTraces: Partial<Plotly.PlotData>[];
  /** Size of the partial diagram to display states. In normalized coordinates [0..1].
   *
   *
   * @type {number}
   * @memberof PlotlyLineChart
   */
  stateDomainSize: number;
  stateDiagramDomainOffset: number;

  /**
   * Mode for drawing chart plots: lines, markers or lines and markers.
   */
  lineMode: LineMode | LineMode[] = "lines+markers";

  /**
   * Interpolation mode used for lines
   */
  lineShape: LineShape | LineShape[] = "linear";

  onPlotlyElementHover?: (event: Plotly.PlotHoverEvent) => void;
  onDataSelect?: (
    range: {
      from: Date;
      to: Date;
      yMin: number;
      yMax: number;
    },
    selectedPoints: PlotDatum[],
  ) => void;

  /**
   * Defines the zoom mode for the line diagram.
   *  'xy' provides rectangular zoom on both axis
   *  'x' provides x axis zoom only
   */
  private zoomMode: ZoomMode = "xy";

  constructor(
    elementRefs: LineDiagramElementRefs,
    urls: ChartDataSourceOptions,
    stateMachineOptions?: StateMachineOptions,
    axisOptions?: Partial<AxisOptions>,
    showStatistics:
      | boolean
      | ((
          stats: LineChartStatistics,
          colorFun: (index: number) => string,
        ) => void) = true,
    allowSelect = false,
  ) {
    super(elementRefs, urls, stateMachineOptions, axisOptions, showStatistics);

    this.stateDomainSize = 0.9;
    this.stateDiagramDomainOffset = 0.01;

    this.zoomMode = defaultTo(axisOptions?.zoomMode, "xy");
    if (allowSelect) {
      this.modeBarButtons = clone(PlotlyLineChart.modeBarButtons);
      this.modeBarButtons.push(["select2d"] as any);
    } else {
      this.modeBarButtons = PlotlyLineChart.modeBarButtons;
    }
    // apply resize before switching to print
    /* this.printListener = (print) => {
      if (print.matches) {
        console.log("resize");
        this.resize();
      }
    };
    window.matchMedia("print").addEventListener("change", this.printListener);*/
  }

  /** Selects a context state machine to display the state changes in diagram
   *
   *
   * @param {IDType} id
   * @return {*}  {Promise<void>}
   * @memberof PlotlyLineChart
   */
  setActiveContextStateMachineId(id: IDType): Promise<void> {
    this.activeContextStateMachineId = id;
    return this.loadData();
  }

  /** Sets new line data configs
   *
   *
   * @param {LineConfig[]} lineConfigs
   * @memberof PlotlyLineChart
   */
  setLineConfigs(lineConfigs: LineConfig[]) {
    this.lineConfigs = lineConfigs;
    this.loadData();
  }

  setData(chartDatasets: BinaryChartDataLoadResult<LineConfig>[]) {
    super.setData(chartDatasets);
    this.yAxes = [];
    this.data = [];
    chartDatasets.forEach((chartData, index) => {
      const yAxisIndex = this.getOrCreateAxis(
        chartData.options?.label?.title ?? chartData.data.series_name,
        unitDisplayString(chartData.data.unit),
        [chartData.data.minvalue, chartData.data.maxvalue],
      );
      const addInfo = chartData.additionalInfo;
      this.data.push({
        ...chartData.data,
        name: PlotlyLineChart.getDatasetLabel(chartData),

        uid: `chart_trace_${index}`,
        type: "scattergl",

        mode: addInfo?.mode ?? this.getLineMode(index),
        customdata: [],
        line: {
          color: addInfo?.color ?? getDiagramLineColor(index),
          simplify: addInfo?.simplifyLine ?? false,
          dash: addInfo?.lineDash ?? "solid",
          width: addInfo?.width ?? 1.1,
          shape: addInfo?.lineShape ?? this.getLineShape(index),
        },

        hoverinfo: "x+y+name",

        hoverlabel: {
          font: PlotlyDefaultFontHover,
          bordercolor: DIAGRAM_POINT_HOVER_BORDER_COLOR,
          bgcolor: getDiagramLineColor(index),
        },
        marker: {
          color: addInfo?.color ?? getDiagramFillColor(index),
          size: 3,
        },
        yaxis: yAxisIndex === 0 ? "y" : `y${yAxisIndex + 1}`,
      });
    });

    this.setStatistics({
      chartStatistics: this.computeChartStatistics(this.zoomRange, this.data),
      stateInfo: this.stateData,
    });
  }

  setStatistics(statistics: LineChartStatistics) {
    if (
      !isEqual(this.statistics, statistics) ||
      !isEqual(this.statistics, statistics)
    ) {
      this.statistics = statistics;
      this.statisticsUpdated();
    }
  }

  /**
   * Live update diagram data
   * @param attributeKeyId Attribute key of the incomming value
   * @param sensorId Sensor id of the incomming value
   * @param timestamp Timestamp of incomming value
   * @param value
   * @param unit Unit of incomming value(may differ from dataset value)
   */
  addValue(
    attributeKeyId: number,
    sensorId: number,
    timestamp: Moment,
    value: number,
    unit?: string,
  ): void {
    // skip if chart is not initialized or destroyed
    if (isNil(this.chart)) {
      return;
    }

    // skip if value is outside of time range
    if (!isNil(this.timeRange) && !this.timeRange.contains(timestamp)) {
      return;
    }

    const dataset = find(this.data, (data) => data.key_id === attributeKeyId);

    if (isNil(dataset)) {
      return;
    }

    if (!isNil(unit) && dataset.unit !== unit) {
      value = convertToUnit(value, unit, dataset.unit);
    }

    // if unit coversion fails null may be returned
    if (isNil(value)) {
      return;
    }

    const timestampDate = timestamp.toDate();
    let updateNoData = false;
    if (isEmpty(dataset.x)) {
      dataset.x = [timestampDate];
      dataset.y = [value];
      updateNoData = true;
    } else {
      // find place to insert new value
      let insertIndex: number = null;
      for (let i = dataset.x.length - 1; i >= 0; i--) {
        if ((dataset.x[i] as Date).getTime() < timestampDate.getTime()) {
          insertIndex = i + 1;
          break;
        }
      }

      if (isNil(insertIndex)) {
        return;
      }

      (dataset.x as Date[]).splice(insertIndex, 0, timestamp.toDate());
      (dataset.y as number[]).splice(insertIndex, 0, value);
    }
    this.newDataRevision();

    void this.updateChart();
    if (updateNoData) {
      void this.updateNoData();
    }
  }

  private buildAnnotations(
    trendData: ValueTrendData[],
  ): Partial<Plotly.Annotations>[] {
    return trendData.map((trend) => {
      return {
        visible: this.annotationOptions.showTrend,
        text: `Trend: ${trend.slope?.toFixed(5)} \u0394 ${trend.unit} / h`,
        xref: "paper",
        yref: "paper",
        x: 0,
        y: 1,
        showarrow: false,
        font: {
          family: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
          size: 13,
          color: "rgb(103, 106, 108)",
        },
        bgcolor: "#ffffff",
      } as Plotly.Annotations;
    });
  }

  private showsStateDiagram() {
    return !isNil(this.activeContextStateMachineId) && !isNil(this.stateData);
  }

  public updateChart(): Promise<void | HTMLElement> {
    if (isNil(this.chartElement)) {
      return Promise.resolve();
    }

    this.statisticsUpdated();
    const dataCount = sumBy(this.data, (d) => d.x.length);

    const layout: Partial<Plotly.Layout> = {
      hoverlabel: DIAGRAM_DEFAULT_HOVER_LABEL_SETTINGS,

      margin: PlotlyLineChart.margins,
      //  automargin: true,
      datarevision: toNumber(this.dataRevision),
      uirevision: this.uiRevision,
      autosize: true,
      showlegend: true,

      // Position the legend outside of the chart centered horizontally
      legend: {
        orientation: "h",
        yanchor: "bottom",
        xanchor: "center",
        x: 0.5,
        y: 1,
      },

      xaxis: this.getXAxisLayout(),
      font: PlotlyFont,
      shapes: this.shapes,
      hovermode: hoverModeForDataCount(dataCount),
      // use default hover distance
      hoverdistance: hoverDistanceForDataCount(dataCount),

      annotations: this.buildAnnotations(this.trendData),
      ...this.getYAxisLayout(),
    };

    if (this.showsStateDiagram()) {
      layout.grid = {
        rows: 2,
        pattern: "independent",
      };

      const key =
        this.data?.length === 0
          ? "yaxis"
          : (`yaxis${this.getStateYAxisNumber()}` as PlotlyLayoutYaxisNames);

      layout[key] = {
        domain: [this.stateDomainSize, 1],
        tickvals: [0.5],
        fixedrange: true,
        range: [0, 1],
        showgrid: false,
        ticktext: [this.stateData[0].state_context.name],
      };

      // show state machine diagram
    }

    return Plotly.react(
      this.chartElement.get(0),
      [...this.data, ...this.minMaxLines, ...this.valueTrends],
      layout,
      {
        responsive: true,
        displaylogo: false,

        modeBarButtons: this.modeBarButtons,

        locale: I18n.locale,
        toImageButtonOptions: {
          filename: "diagram",
          format: "png",
          scale: 2,
        },

        doubleClick: false,
      },
    )
      .then((chart) => {
        if (isNil(this.chart)) {
          // attach listener only once
          chart.on("plotly_doubleclick", () => {
            void this.updateChart();
          });
          chart.on("plotly_selected", (event) =>
            this.handleDataSelect(event.points, event.range),
          );

          chart.on("plotly_relayout", (event) =>
            this.throttleOnChartZoom(event),
          );

          /*chart.on("plotly_hover", (event) => {
            if (!isNil(this.onPlotlyElementHover)) {
              this.onPlotlyElementHover(event);
            }
          });*/
          this.chart = chart;
        }

        return this.resize();
      })
      .catch((err) => {
        logger.log(err);
      });
  }

  private getXAxisLayout(): Partial<Plotly.LayoutAxis> {
    const numAxisLeft = Math.floor(this.yAxes.length / 2 + 0.5);
    const numAxisRight = Math.floor(this.yAxes.length / 2);

    const layout: Partial<Plotly.LayoutAxis> = {
      title: I18n.t("widgets.line_diagram_widget.x_axis_label"),
      linewidth: 1,
      spikethickness: 2,

      domain: [
        PlotlyTimeSeriesLineDiagramBase.axisPadding * numAxisLeft,
        1.0 - PlotlyTimeSeriesLineDiagramBase.axisPadding * numAxisRight,
      ],
      // state diagrams break autoscale as there are artificial traces included
      autorange: !this.showsStateDiagram(),
    };

    if (!isNil(this.timeRange)) {
      layout.range = [
        this.timeRange.start.toDate(),
        this.timeRange.end.toDate(),
      ];
    }

    return layout;
  }

  private getYAxisLayout(): Partial<Plotly.Layout> {
    const axisLayout: Partial<Plotly.Layout> = {};

    values(this.yAxes).forEach((axis, index) => {
      const key =
        index === 0 ? "yaxis" : (`yaxis${index + 1}` as PlotlyLayoutYaxisNames);

      const range = isNil(axis.range)
        ? null
        : [this.beginAtZero ? 0.0 : axis.range[0], axis.range[1] * 1.1];

      axisLayout[key] = {
        ...axis,
        range: range,
      };
      if (this.showsStateDiagram()) {
        // Adjust the yAxis Domain if state diagram is shown
        axisLayout[key].domain = [
          0,
          this.stateDomainSize - this.stateDiagramDomainOffset,
        ];
      }
    });

    return axisLayout;
  }

  private getLineMode(index: number): LineMode {
    if (isArray(this.lineMode)) {
      return this.lineMode[index];
    } else {
      return this.lineMode;
    }
  }

  private getLineShape(index: number): LineShape {
    if (isArray(this.lineShape)) {
      return this.lineShape[index];
    } else {
      return this.lineShape;
    }
  }

  private handleDataSelect(points: PlotDatum[], range: SelectionRange) {
    if (range) {
      let from: number | Date = range?.x[0];
      let to: number | Date = range.x[1];
      if (isString(from)) {
        from = new Date(from);
      }
      if (isString(to)) {
        to = new Date(to);
      }

      if (this.onDataSelect) {
        this.onDataSelect(
          {
            from: from as Date,
            to: to as Date,
            yMin: range.y[0],
            yMax: range.y[1],
          },
          points,
        );
      }
    }
  }

  private throttleOnChartZoom = throttle((event) => {
    this.onChartZoom(event);
  });
  private onChartZoom(event: Plotly.PlotRelayoutEvent): void {
    // update zoom range on zoom events or autoscales
    if (!isNil(event["xaxis.range[0]"]) && !isNil(event["xaxis.range[1]"])) {
      this.zoomRange = new DateRange(
        new Date(event["xaxis.range[0]"]),
        new Date(event["xaxis.range[1]"]),
      );
    } else if (event["xaxis.autorange"]) {
      this.zoomRange = this.timeRange;
    }

    // update chart statistics
    if (!isNil(this.data) && !isNil(this.zoomRange)) {
      this.setStatistics({
        chartStatistics: this.computeChartStatistics(this.zoomRange, this.data),
        stateInfo: this.stateData,
      });
    }
  }
}
