import { Timeline } from "@knight-lab/timelinejs";
import { Grid } from "@mui/material";
import { each, isEmpty, isEqual, isNil, map, merge } from "lodash";
import React, { useContext, useEffect, useRef } from "react";
import { AssetMaintenanceTimelineWidgetConfigSerialized } from "../../widgets/asset_maintenance_timeline_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import { AppContext } from "../common/app_context/app_context_provider";
import { AssetMaintenanceTimelineWidgetProps } from "./asset_maintenance_timeline_widget.types";
import { SialogicWidgetDefinition } from "./sialogic_widget_component";
import { WidgetBox } from "./widget_box";

export const AssetMaintenanceTimelineWidget: React.FunctionComponent<
  AssetMaintenanceTimelineWidgetProps
> = (props) => {
  const context = useContext(AppContext);
  const chartRefs = useRef<React.RefObject<HTMLDivElement>[]>(
    map(props.urls, () => React.createRef<HTMLDivElement>()),
  );
  const timelines = useRef<Timeline[]>([]);

  useEffect(() => {
    initTimelineDiagram();

    return () => {
      each(timelines, (timeline) => {
        timeline;
      });
    };
  }, []);

  useEffect(() => {
    if (!isNil(props) && !isEqual(props, props)) {
      initTimelineDiagram();
    }
  }, [props]);

  const initTimelineDiagram = () => {
    const momentLocale = moment.localeData();
    each(props.urls, (url, index) => {
      const timelineElement = $(chartRefs.current[index]?.current);
      if (!timelineElement) {
        return;
      }
      const timeline = new Timeline(timelineElement[0], url, {
        language: context.locale,
        timenav_position: "top",
        scale_factor: 1.0,
        marker_width_min: 500,
        optimal_tick_width: 500,
        timenav_height_percentage: 55,
        start_at_end: true,
        duration: 700,
        zoom_sequence: [0.3, 0.5, 1, 2, 3, 5, 8, 13, 21],
      });

      timeline.language = merge<object, object>(
        timeline.language,
        fetchLanguage(),
      );
      timelines.current.push(timeline);
    });
  };

  const fetchLanguage = (): object => {
    if (
      isEmpty(
        (I18n.translations as Record<string, Record<string, string>>)[
          context.locale
        ]["timelineJS3"],
      )
    ) {
      return {};
    } else {
      return I18n.translations[context.locale]?.["timelineJS3"];
    }
  };

  return (
    <WidgetBox {...props}>
      <Grid container spacing={2}>
        {chartRefs.current.map((ref, index) => (
          <Grid item xs={12} key={index}>
            <div className="timeline" ref={ref} />
          </Grid>
        ))}
      </Grid>
    </WidgetBox>
  );
};
function serializedConfigToProps(
  config: AssetMaintenanceTimelineWidgetConfigSerialized,
): AssetMaintenanceTimelineWidgetProps {
  return merge(widgetBoxPropsFromSerializedConfig(config), {
    urls: config.urls,
  } as AssetMaintenanceTimelineWidgetProps);
}

export const AssetMaintenanceTimelineWidgetDefinition: SialogicWidgetDefinition<
  typeof AssetMaintenanceTimelineWidget,
  typeof serializedConfigToProps
> = {
  Component: AssetMaintenanceTimelineWidget,
  serializedConfigToProps: serializedConfigToProps,
};
