import { isNil } from "lodash";
import {
  MappedSensor,
  SensorMappingAttributes,
} from "../../models/svg_animation_widget_config";
import { BaseMapping } from "./mapping_base";
import { Action } from "../../actions/actions.types";

export interface ClickableMappingConfig extends SensorMappingAttributes {
  action: Action;
}

export class ClickableMapping extends BaseMapping<SVGElement> {
  constructor(
    clickableMapping: ClickableMappingConfig,
    onClick?: (
      event: MouseEvent,
      element: SVGElement,
      action: Action,
      config: SensorMappingAttributes,
    ) => void,
  ) {
    super(clickableMapping, (event, element, action, config) => {
      this.handleClick(
        event,
        element,
        clickableMapping.action,
        config,
        onClick,
      );
    });
  }

  handleClick(
    event: MouseEvent,
    element: SVGElement,
    action: Action,
    config: SensorMappingAttributes,
    fallback: (
      event: MouseEvent,
      element: SVGElement,
      action: Action,
      config: SensorMappingAttributes,
    ) => void = null,
  ): void {
    if (isNil(this.element)) return;

    fallback(event, element, action, config);
  }

  allowBindMultipleElements(): boolean {
    // we override the default behavior to allow multiple elements to be bound and clicked with the same action
    return true;
  }

  applyValueToSvg(sensorConfig: MappedSensor, svgElement: SVGSVGElement): void {
    // nothing to do here, clickable is clickable
    return;
  }
}
