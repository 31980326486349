import { CopyAll } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  ButtonGroup,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";

import { isNil, isString, toInteger } from "lodash";
import * as React from "react";
import MqttForwardChannel from "../../channels/mqtt_forward_channel";
import { info, warn } from "../../utils/toasts";
import { IDType } from "../../utils/urls/url_utils";
import { CopyValueIcon } from "../common/copy_value";
import { useDebounce, useThrottle } from "@uidotdev/usehooks";
import { messages } from "../../utils/logger";

export interface MqttMessageViewerProps {
  assetId: IDType;

  enabled?: boolean;
}

interface MqttMessage {
  topic: string;
  message: unknown;
  time: Date;
}

export const MqttMessageViewer: React.FC<MqttMessageViewerProps> = ({
  assetId,
  enabled = true,
}) => {
  const [limit, setLimit] = React.useState(100);
  const [isEnabled, setIsEnabled] = React.useState(enabled);

  React.useEffect(() => {
    setIsEnabled(enabled);
  }, [enabled]);

  const [mqttMessages, setMqttMessages] = React.useState<MqttMessage[]>([]);
  const [filteredMessages, setFilteredMessages] =
    React.useState<MqttMessage[]>(mqttMessages);

  const [topics, setTopics] = React.useState<string[]>([]);
  const [selectedTopics, setSelectedTopics] = React.useState<string[]>([]);

  // adjust message limit
  React.useEffect(() => {
    if ((mqttMessages?.length || 0) > limit) {
      setMqttMessages(mqttMessages.slice(0, limit));
    }
  }, [limit]);

  const channel = React.useRef<MqttForwardChannel | null>(null);

  React.useEffect(() => {
    let subscriptionId: number;

    if (!assetId || !isEnabled) {
      return;
    }
    if (!channel.current) {
      channel.current = new MqttForwardChannel();
    }
    // Subscribe to the MQTT forward channel
    // This will allow us to receive MQTT messages from the server
    // and display them in the UI

    subscriptionId = channel.current.addEventListener(
      {
        handleMqttMessage(topic, message) {
          setTopics((prevTopics) => {
            if (!prevTopics.includes(topic)) {
              return [...prevTopics, topic];
            }
            return prevTopics;
          });
          setMqttMessages((prevMessages) => {
            if (prevMessages.length >= limit) {
              return [
                { topic, message, time: new Date() },
                ...prevMessages.slice(0, limit - 1),
              ];
            } else {
              return [{ topic, message, time: new Date() }, ...prevMessages];
            }
          });
        },
        onReject(modelId: number) {
          warn(I18n.t("frontend.mqtt_viewer.subscribe_rejected"));
          setIsEnabled(false);
        },
        onConnect(modelId: number) {
          info(I18n.t("frontend.mqtt_viewer.subscribed"));
        },
      },
      toInteger(assetId),
    );
    return () => {
      if (assetId && !isNil(subscriptionId))
        channel.current.removeEventListenerId(subscriptionId);
    };
  }, [assetId, isEnabled, limit]);

  const debouncedMessages = useThrottle(mqttMessages, 1000);

  React.useEffect(() => {
    if (debouncedMessages.length > 0) {
      const messages = debouncedMessages.filter((message) => {
        if (selectedTopics.length === 0) {
          return true;
        }
        return selectedTopics.includes(message.topic);
      });
      setFilteredMessages(messages);
    }
  }, [debouncedMessages, selectedTopics]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} marginY={2}>
        <Grid container justifyContent={"space-between"}>
          <Grid item xs flexGrow={1} flex={1}>
            <Typography variant="h6">MQTT Messages</Typography>
          </Grid>
          <Grid item xs="auto">
            <TextField
              select
              label={I18n.t("frontend.mqtt_viewer.message_limit")}
              value={limit}
              onChange={(e) => setLimit(toInteger(e.target.value) as number)}
            >
              {[20, 30, 50, 100].map((l) => (
                <MenuItem key={l} value={l}>
                  {l}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Divider />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          multiple
          options={topics}
          value={selectedTopics}
          onChange={(event, newValue) => {
            setSelectedTopics(newValue);
          }}
          renderInput={(params) => <TextField {...params} label="Topics" />}
        />
      </Grid>

      {filteredMessages.map((message, index) => {
        return (
          <Grid container item xs={12} key={index}>
            <Grid item xs={12}>
              <Typography>
                <Box component="span" fontWeight="bold">
                  {I18n.t("frontend.mqtt_viewer.topic")}: {message.topic}
                  <CopyValueIcon value={message.topic} />
                </Box>
                <Box component={"span"} marginLeft={2}>
                  {I18n.t("frontend.mqtt_viewer.time")}:{" "}
                  {message.time?.toLocaleString()}
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <pre>{JSON.stringify(message.message, null, 2)}</pre>
            </Grid>
            <Grid item xs={2}>
              <ButtonGroup>
                <IconButton
                  onClick={() => {
                    isString(message.message)
                      ? navigator.clipboard.writeText(message.message as string)
                      : navigator.clipboard.writeText(
                          JSON.stringify(message.message),
                        );
                    info(I18n.t("base.copied"));
                  }}
                >
                  <CopyAll />
                </IconButton>
              </ButtonGroup>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};
