import {
  Dictionary,
  chain,
  each,
  find,
  findKey,
  groupBy,
  isEmpty,
  isNil,
  keyBy,
  keys,
  last,
  map,
  sortBy,
  toString,
  trim,
  uniq,
  uniqBy,
  values,
} from "lodash";
import moment from "moment";
import { MeasurementValue } from "../../models/measurement";
import { MeasurementCategory } from "../../models/measurement_category";
import {
  MeasurementValueDefinition,
  MeasurementValueDefinitionAttributes,
  mvdRangeString,
} from "../../models/measurement_value_definition";
import { ColorUtils } from "../../utils/colors";
import { unitDisplayString } from "../../utils/unit_conversion";
import { getFormattedStringForValue } from "../../utils/value_format";
import {
  createAxisLayout,
  getAxisChartDataNameFromProperty,
  getAxisPropertyName,
} from "../chart_axis_helper";
import { Plotly } from "../plotly_package";
import { Ref } from "react";
import { ReferenceMeasurementValue } from "../../components/measurements/utils/measurement_reference_eval";
import {
  createReferenceLineData,
  createReferenceLineDataGroupedByCategory,
} from "./reference_lines";
import {
  ChartStatistics,
  computeChartStatisticsForData,
} from "../chart_data/chart_data_statistics";

interface PlotDataWithUnitAndPosition extends Plotly.PlotData {
  unit?: string;
  position: number;
}
type PlotDataById = Dictionary<Partial<PlotDataWithUnitAndPosition>>;

export function xValueForMeasurementDef(
  def: MeasurementValueDefinitionAttributes,
): string {
  if (!def) return "";

  if (def.unit) {
    return def.title + " " + def.unit;
  } else {
    return def.title;
  }
}

function baseDataForIndex(index: number): Partial<Plotly.Data> {
  return {
    type: "scattergl",
    mode: "lines+markers",
    hoverinfo: "x+y+text",
    hoveron: "points" as any,
    line: {
      color: getLineColor(index),
      simplify: false,
      dash: "dash",
      width: 1.1,
    },
    marker: {
      color: getFillColor(index),

      size: 5,
    },

    x: [],
    y: [],
    text: [],
  };
}

/**
 * Creates plotly chart data and axis layout from measurements
 */
export class MeasurementChartDataCreator {
  private data: PlotDataById = {};
  private statistics: ChartStatistics[];
  private yAxes: { [index: string]: Partial<Plotly.LayoutAxis> } = {};

  /**
   * Creates chart data from measuremetn value definitions and values.
   * @param measurementValueDefinitions The measurement value defintions
   * @param measurementValues The measurement values
   * @param groupByCategory Groups measurement values by measurement category. Defaults to false
   * @param mvdIntervalUnit Inteval unit for the measurement value defintion intervals.
   * @param usePercentage Flag to indicate a percentage unit. if false the measurementValueDefinition unit will be used. Defaults to false.
   */
  createChartData(
    measurementValueDefinitions: MeasurementValueDefinition[],
    measurementCategories: MeasurementCategory[],
    measurementValues: MeasurementValue[],
    mvdIntervalUnit: string,
    referenceMeasurementValues: ReferenceMeasurementValue[],
    usePercentage = false,
  ): void {
    this.reset();
    this.createLineDefinitions(
      measurementValueDefinitions,
      measurementCategories,
      usePercentage ? "percentage" : "measurementUnit",
      mvdIntervalUnit,
    );

    this.insertMeasurementValuesIntoLine(
      measurementValueDefinitions,
      measurementValues,
      usePercentage,
    );
  }

  /**
   * Creates chart data for a category-based chart by processing measurement value definitions,
   * categories, and values. Optionally, the data can be represented as percentages.
   *
   * @param measurementValueDefinitions - An array of measurement value definitions that describe
   *                                       the properties of the measurements.
   * @param measurementCategories - An array of measurement categories to group the measurements.
   * @param measurementValues - An array of measurement values to be included in the chart data.
   * @param mvdIntervalUnit - The interval unit for the measurement value definitions (e.g., time unit).
   * @param referenceMeasurementValues - An array of reference measurement values used for comparison.
   * @param usePercentage - A boolean indicating whether to represent the data as percentages
   *                        instead of using the measurement unit. Defaults to `false`.
   */
  createCategoryChartData(
    measurementValueDefinitions: MeasurementValueDefinition[],
    measurementCategories: MeasurementCategory[],
    measurementValues: MeasurementValue[],
    mvdIntervalUnit: string,
    referenceMeasurementValues: ReferenceMeasurementValue[],
    usePercentage = false,
  ) {
    this.reset();

    this.createCategoryLineDefinitions(
      measurementValueDefinitions,
      measurementCategories,
      usePercentage ? "percentage" : "measurementUnit",
      mvdIntervalUnit,
    );

    this.insertMeasurementCategoryValuesIntoLine(
      measurementValueDefinitions,
      measurementValues,
      usePercentage,
    );
  }

  /**
   * Creates chart data with lines per time by processing measurement value definitions,
   * categories, and values, along with reference measurement values. Optionally, the
   * values can be represented as percentages.
   *
   * @param measurementValueDefinitions - An array of measurement value definitions
   *   that describe the structure and metadata of the measurement values.
   * @param measurementCategories - An array of measurement categories that group
   *   the measurement values into logical categories.
   * @param measurementValues - An array of measurement values to be processed and
   *   inserted into the chart data.
   * @param mvdIntervalUnit - A string representing the interval unit for the
   *   measurement value definitions.
   * @param referenceMeasurementValues - An array of reference measurement values
   *   used for comparison or reference in the chart data.
   * @param usePercentage - A boolean flag indicating whether the measurement values
   *   should be represented as percentages. Defaults to `false`.
   */
  createChartDataWithLinesPerTime(
    measurementValueDefinitions: MeasurementValueDefinition[],
    measurementCategories: MeasurementCategory[],
    measurementValues: MeasurementValue[],
    mvdIntervalUnit: string,
    referenceMeasurementValues: ReferenceMeasurementValue[],
    usePercentage = false,
  ) {
    this.reset();
    this.createLineDefinitionsForValueAxis(
      measurementValues,
      measurementValueDefinitions,
      usePercentage,
    );
    this.insertMeasurementValuesIntoValueAxis(
      measurementValueDefinitions,
      measurementValues,
      usePercentage,
    );

    if (referenceMeasurementValues && referenceMeasurementValues.length > 0) {
      const referenceLineData = createReferenceLineData(
        referenceMeasurementValues,
        (v) => v.mvd?.title,
        usePercentage,
      );
      each(referenceLineData, (data, key) => {
        this.data[key] = data as unknown;
      });
    }
    this.statistics = [];

    const s = computeChartStatisticsForData(
      null,
      values(this.data),
      "string",
      "numeric",
      "y",
    );
  }

  createChartDataWithLinesPerTimeAndGrouped(
    measurementValueDefinitions: MeasurementValueDefinition[],
    measurementCategories: MeasurementCategory[],
    measurementValues: MeasurementValue[],
    mvdIntervalUnit: string,
    referenceMeasurementValues: ReferenceMeasurementValue[],
    usePercentage = false,
  ) {
    this.reset();
    this.createLineDefinitionsForValueAxis(
      measurementValues,
      measurementValueDefinitions,
    );
    this.insertMeasurementValuesIntoValueAxisWithGrouping(
      measurementValueDefinitions,
      measurementValues,
      measurementCategories,
      mvdIntervalUnit,
      usePercentage,
    );

    if (referenceMeasurementValues && referenceMeasurementValues.length > 0) {
      const referenceLineData = createReferenceLineDataGroupedByCategory(
        referenceMeasurementValues,
        (mc) => this.xValueForCategory(mc),
        usePercentage,
      );
      each(referenceLineData, (data, key) => {
        this.data[key] = data as unknown;
      });
    }
  }
  /**
   * Returns chart data. `createChartData()` needs to be called first.
   */
  getChartData(): Partial<Plotly.PlotData>[] {
    return sortBy(values(this.data), "position");
  }

  /**
   * Returns axis layout. `createChartData()` needs to be called first.
   */
  getAxisLayout(): Partial<Plotly.Layout> {
    return this.yAxes;
  }

  private reset(): void {
    this.data = {};
    this.yAxes = {};
    this.statistics = [];
  }

  private createLineDefinitionsForValueAxis(
    measurementValues: MeasurementValue[],
    measurementValueDefinitions: MeasurementValueDefinition[] = [],
    useAsPercent = false,
  ) {
    const uniqUnits = uniqBy(measurementValueDefinitions, "unit");
    let unit = useAsPercent ? "%" : "";
    if (!useAsPercent && uniqUnits.length === 1) {
      unit = uniqUnits[0].unit;
    }

    const timestamps = uniq(map(measurementValues, (mv) => mv.time)).sort();

    // Create a line per measurement value definition
    timestamps.forEach((timestamp, index) => {
      this.data[timestamp] = {
        ...baseDataForIndex(index),
        name: moment(timestamp).format("L LTS"),
        yaxis: this.getOrCreateYAxes(I18n.t("frontend.value"), unit, "number"),
        position: index,
        legendgroup: "measurements",
        legendgrouptitle: {
          text: I18n.t("activerecord.models.measurement", { count: 2 }),
        },
        unit,
      } as PlotDataWithUnitAndPosition;
    });
  }

  xValueForValueDef(mvd: MeasurementValueDefinition, mc: MeasurementCategory) {
    return `${mvd?.title} (${mc?.title})`;
  }

  xValueForCategory(mc: MeasurementCategory) {
    return mc ? mc.title : "";
  }

  private insertMeasurementValuesIntoValueAxisWithGrouping(
    measurementValueDefinitions: MeasurementValueDefinition[],
    measurementValues: MeasurementValue[],
    measurementCategories: MeasurementCategory[],
    unit: string,
    usePercentage = false,
  ): void {
    const valueDefinitionsByCategory = groupBy(
      measurementValueDefinitions,
      "measurement_category_id",
    );

    const timeGroupedValues = groupBy(measurementValues, "time");
    each(keys(timeGroupedValues).sort(), (timestamp: string) => {
      const measurementValuesForTime = timeGroupedValues[timestamp];
      const measurementValuesByDefinitionId = keyBy(
        measurementValuesForTime,
        "measurement_value_definition_id",
      );
      each(measurementValuesForTime, (timestampMeasurementValues) => {
        const data = this.data[timestamp];
        // threre should be only one value per timestamp and measurement value definition

        const x = data.x as string[];
        const y = data.y as number[];
        const text = data.text as string[];

        // insert the data points in the correct order of measurement value definitions
        measurementCategories.forEach((measurementCategory) => {
          const measurementDefinitions =
            valueDefinitionsByCategory[measurementCategory.id];
          if (isNil(measurementDefinitions)) {
            return;
          }

          const aggValue = chain(
            measurementDefinitions.map(
              (mvd) =>
                measurementValuesByDefinitionId[mvd.id]?.[
                  usePercentage ? "percent" : "value"
                ] || 0,
            ),
          )
            .sum()
            .value();

          const mvdTexts = chain(
            measurementDefinitions.map(
              (mvd) =>
                `${mvd.title} ${getFormattedStringForValue(measurementValuesByDefinitionId[mvd.id]?.value, 2)} ${toString(mvd.unit)}`,
            ),
          )
            .join("<br>")
            .value();

          if (isNil(data)) {
            return;
          }

          x.push(this.xValueForCategory(measurementCategory));
          y.push(aggValue);
          text.push(
            `${getFormattedStringForValue(aggValue)} ${unit} ${measurementCategory.title}<br><br>${mvdTexts}`,
          );
        });
      });
    });
  }

  /**
   * Inserts measurement values into the value axis of the chart data.
   *
   * @param measurementValueDefinitions - An array of measurement value definitions.
   * @param measurementValues - An array of measurement values.
   * @param usePercentage - A boolean indicating whether to use percentage values. Defaults to false.
   * @returns void
   */
  private insertMeasurementValuesIntoValueAxis(
    measurementValueDefinitions: MeasurementValueDefinition[],
    measurementValues: MeasurementValue[],
    usePercentage = false,
  ): void {
    each(
      groupBy(measurementValues, "time"),
      (timestampMeasurementValues, timestamp) => {
        const data = this.data[timestamp];
        // threre should be only one value per timestamp and measurement value definition
        const measurementValuesByDefinitionId = keyBy(
          timestampMeasurementValues,
          "measurement_value_definition_id",
        );
        const x = data.x as string[];
        const y = data.y as number[];
        const text = data.text as string[];

        // insert the data points in the correct order of measurement value definitions
        measurementValueDefinitions.forEach((measurementValueDefinition) => {
          const mValue =
            measurementValuesByDefinitionId[measurementValueDefinition.id];

          if (isNil(data) || isNil(mValue)) {
            return;
          }

          x.push(measurementValueDefinition.title);

          if (usePercentage) {
            y.push(mValue.percent);
            text.push(
              `${moment(timestamp).format("L LTS")} | ${mValue.value}${measurementValueDefinition.unit} | ${toString(mValue.note)}`,
            );
          } else {
            y.push(mValue.value);
            text.push(
              `${moment(timestamp).format("L LTS")}: ${toString(mValue.note)}`,
            );
          }
        });
      },
    );
  }

  private createCategoryLineDefinitions(
    measurementValueDefinitions: MeasurementValueDefinition[],
    measurementCategories: MeasurementCategory[],
    valueUnitType: "measurementUnit" | "percentage" = "measurementUnit",
    intervalUnit?: string,
  ) {
    measurementCategories.forEach((measurementCategory, index) => {
      const measurementValueDefinition = find(
        measurementValueDefinitions,
        (mvd) => mvd.measurement_category_id == measurementCategory.id,
      );
      const unitToUse =
        valueUnitType === "percentage"
          ? "%"
          : unitDisplayString(measurementValueDefinition.unit);
      this.data[measurementCategory.id] = {
        ...baseDataForIndex(index),
        name: trim(
          getMeasurementCategoryName(measurementCategory, intervalUnit),
        ),
        yaxis: this.getOrCreateYAxes(unitToUse, unitToUse, "number"),
        unit: unitToUse,
        position: index,
      } as PlotDataWithUnitAndPosition;
    });
  }
  private createLineDefinitions(
    measurementValueDefinitions: MeasurementValueDefinition[],
    measurementCategories: MeasurementCategory[],
    valueUnitType: "measurementUnit" | "percentage" = "measurementUnit",
    intervalUnit?: string,
  ): void {
    // Create a line per measurement value definition
    measurementValueDefinitions.forEach((measurementValueDefinition, index) => {
      const measurementCategory = find(
        measurementCategories,
        (mc) => mc.id == measurementValueDefinition.measurement_category_id,
      );
      const unitToUse =
        valueUnitType === "percentage" ? "%" : measurementValueDefinition.unit;
      this.data[measurementValueDefinition.id] = {
        ...baseDataForIndex(index),
        name: trim(
          getMeasurementValueName(
            measurementValueDefinition,
            measurementCategory,
            intervalUnit,
          ),
        ),
        yaxis: this.getOrCreateYAxes(unitToUse, unitToUse, "number"),
        unit: unitToUse,
        position: measurementValueDefinition.position,
      } as Partial<PlotDataWithUnitAndPosition>;
    });
  }

  private insertMeasurementCategoryValuesIntoLine(
    measurementValueDefinitions: MeasurementValueDefinition[],
    measurementValues: MeasurementValue[],
    usePercentage = false,
  ) {
    const valueDefinitionIdToCategoryId = chain(measurementValueDefinitions)
      .keyBy("id")
      .mapValues("measurement_category_id")
      .value();

    measurementValues.forEach((measurementValue) => {
      const categoryId =
        valueDefinitionIdToCategoryId[
          measurementValue.measurement_value_definition_id
        ];
      if (isNil(categoryId)) {
        return;
      }

      const line = this.data[categoryId];
      if (isNil(line)) {
        return;
      }

      const x = line.x as Date[];
      const y = line.y as number[];
      const text = line.text as string[];
      const valueProperty: "percent" | "value" = usePercentage
        ? "percent"
        : "value";
      const timestamp = moment(measurementValue.time).toDate();
      if (last(x)?.getTime() === timestamp.getTime()) {
        // Aggregate existing value
        y[y.length - 1] += measurementValue[valueProperty];
        const prevText = text[text.length - 1];
        if (isNil(prevText) && !isNil(measurementValue.note)) {
          text[text.length - 1] = measurementValue.note;
        } else if (!isNil(prevText) && !isNil(measurementValue.note)) {
          text[text.length - 1] = `${text[text.length - 1]}, ${
            measurementValue.note
          }`;
        } else if (isNil(prevText) && isNil(measurementValue.note)) {
          text[text.length - 1] = undefined;
        }
      } else {
        // Insert new value
        x.push(timestamp);
        y.push(measurementValue[valueProperty]);
        text.push(measurementValue.note);
      }
    });
  }

  private insertMeasurementValuesIntoLine(
    measurementValueDefinitions: MeasurementValueDefinition[],
    measurementValues: MeasurementValue[],
    usePercentage = false,
  ): void {
    const valueDefinitionsById = chain(measurementValueDefinitions)
      .keyBy("id")
      .value();

    measurementValues.forEach((measurementValue) => {
      const valueDefinition =
        valueDefinitionsById[measurementValue.measurement_value_definition_id];

      const line = this.data[measurementValue.measurement_value_definition_id];
      if (isNil(line) || isNil(valueDefinition)) {
        return;
      }

      const x = line.x as Date[];
      const y = line.y as number[];
      const text = line.text as string[];

      x.push(moment(measurementValue.time).toDate());
      if (usePercentage) {
        y.push(measurementValue.percent);
        text.push(
          `${measurementValue.value} ${toString(valueDefinition.unit)} ${toString(measurementValue.note)}`,
        );
      } else {
        y.push(measurementValue.value);
        text.push(measurementValue.note);
      }
    });
  }

  private getOrCreateYAxes(
    axisTitle: string,
    axisUnit: string,
    yAxesDataType: "time" | "string" | "number",
  ): string {
    let axisName = findKey(this.yAxes, (axis) => axis.title === axisTitle);
    let axis = this.yAxes[axisName];

    if (isNil(axisName) || isNil(axis)) {
      const axisIndex = values(this.yAxes).length;
      axisName = getAxisPropertyName("y", axisIndex);
      axis = createAxisLayout(
        axisIndex,
        axisTitle,
        axisUnit,
        null,
        yAxesDataType == "string"
          ? "category"
          : yAxesDataType === "time"
            ? "date"
            : "linear",
      );

      this.yAxes[axisName] = axis;
    }

    return getAxisChartDataNameFromProperty(axisName);
  }
}

const FillColors = ColorUtils.getColorsRgba();
const LineColors = ColorUtils.getColorsRgba();

function getLineColor(index: number): string {
  return FillColors[index % LineColors.length];
}

function getFillColor(index: number): string {
  return FillColors[index % FillColors.length];
}

function getMeasurementValueName(
  measurementValueDefinition: MeasurementValueDefinition,
  measurementCategory?: MeasurementCategory,
  intervalUnit?: string,
): string {
  const rangeString = mvdRangeString(measurementValueDefinition, intervalUnit);
  if (!isNil(measurementCategory)) {
    return `${measurementValueDefinition.title} (${measurementCategory.title})${
      isEmpty(rangeString) ? "" : "<br />" + rangeString
    }`;
  }

  return (
    measurementValueDefinition.title +
    (isEmpty(rangeString) ? "" : "\n" + rangeString)
  );
}

function getMeasurementCategoryName(
  measurementCategory: MeasurementCategory,
  intervalUnit: string = null,
): string {
  return `${measurementCategory.title} ${mvdRangeString(
    measurementCategory,
    intervalUnit,
  )}`;
}
