import { Delete, Edit, Key, Password } from "@mui/icons-material";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridPaginationModel,
  GridRowParams,
  GridSortModel,
} from "@mui/x-data-grid";
import { useDebounce } from "@uidotdev/usehooks";
import { isEmpty, isNil, toString } from "lodash";
import { useConfirm } from "material-ui-confirm";
import * as React from "react";
import { MqttUserJSONObject } from "../../json_api/mqtt_user";
import { ResourcePermission } from "../../models/resource_permission";
import { success } from "../../utils/toasts";
import { IDType } from "../../utils/urls/url_utils";
import { PageSettings } from "../common/page_size";
import { SialogicDialog } from "../common/sialogic_dialog";
import { MqttUserForm } from "./mqtt_user_form";
import {
  useDeleteMqttUser,
  useLoadMqttUsers,
  MqttUsersFilter,
} from "../../queries/mqtt_users_data";
import { RegeneratePasswordForm } from "./regenerate_password_form";
import { ResetMqttUserPasswordForm } from "./reset_mqtt_user_password_form";

interface MqttUserListProps {
  deviceId: IDType;
  organizationId?: IDType;
  userId?: IDType;
  assetId?: IDType;
  actions?: (
    params: GridRowParams<MqttUserJSONObject>,
  ) => (typeof GridActionsCellItem)[];
  permissions?: ResourcePermission;
}

export const MqttUserList: React.FunctionComponent<MqttUserListProps> = ({
  deviceId,
  organizationId,
  userId,
  assetId,
  actions,
  permissions,
}: MqttUserListProps) => {
  const confirm = useConfirm();

  const [filter, setFilter] = React.useState<MqttUsersFilter>({
    deviceId,
    assetId,
    organizationId,
    userId,
  });

  const [pageSettings, setPageSettings] = React.useState<PageSettings>({
    number: 1,
    size: 10,
  });

  const [sortModel, setSortModel] = React.useState<GridSortModel>(null);

  const {
    data: { items: mqttUsers, totalItems, totalPages },
    isLoading,
    error,
  } = useLoadMqttUsers({
    variables: {
      ...filter,
      pageSettings,
      sort: sortModel?.[0]
        ? `${sortModel[0].sort == "desc" ? "-" : ""}${sortModel[0].field}`
        : "username",
    },

    placeholderData: { items: [], totalItems: -1, totalPages: -1 },
  });

  const [generatePasswordMqttUser, setGeneratePasswordMqttUser] =
    React.useState<MqttUserJSONObject | null>(null);

  const [resetMqttUserPasswordMqttUser, setResetMqttUserPasswordMqttUser] =
    React.useState<MqttUserJSONObject | null>(null);

  const [editMqttUser, setEditMqttUser] =
    React.useState<MqttUserJSONObject | null>(null);
  const { mutateAsync: deleteUser } = useDeleteMqttUser();

  const gridDef = React.useMemo<GridColDef<MqttUserJSONObject>[]>(
    () => [
      {
        field: "id",
        headerName: "#",
        type: "number",
      },
      {
        field: "username",
        headerName: I18n.t("activerecord.attributes.mqtt_user.username"),
        type: "string",
        flex: 1,
      },
      {
        field: "enabled",
        headerName: I18n.t("activerecord.attributes.mqtt_user.enabled"),
        type: "boolean",
      },
      {
        field: "superuser",
        headerName: I18n.t("activerecord.attributes.mqtt_user.superuser"),
        type: "boolean",
      },
      {
        field: "mqtt_acls",
        flex: 1,
        sortable: false,
        headerName: I18n.t("activerecord.attributes.mqtt_user.acls"),
        type: "string",

        renderCell: (params) => {
          return isEmpty(params.row.mqtt_acls) ? (
            "---"
          ) : (
            <List dense>
              {params.row.mqtt_acls.map((acl, i) => (
                <ListItem
                  key={i}
                  onClick={() => {
                    void navigator.clipboard.writeText(acl.topic).then(() => {
                      void success(
                        I18n.t("frontend.copied_to_clipboard", {
                          text: acl.topic,
                        }),
                      );
                    });
                  }}
                >
                  <ListItemText primary={acl.topic} secondary={acl.access} />
                </ListItem>
              ))}
            </List>
          );
        },
      },
      {
        flex: 0.3,
        field: "created_at",
        headerName: I18n.t("activerecord.attributes.base.created_at"),
        type: "dateTime",
        valueGetter: (value, row) =>
          row.created_at ? new Date(row.created_at) : null,
        valueFormatter: (value, row) =>
          value ? moment(value).format("L LT") : null,
      },
      {
        flex: 0.3,
        field: "updated_at",
        headerName: I18n.t("activerecord.attributes.base.updated_at"),
        type: "dateTime",
        valueGetter: (value, row) =>
          row.updated_at ? new Date(row.updated_at) : null,
        valueFormatter: (value, row) =>
          isNil(value) ? null : moment(value as Date).format("L LT"),
      },
      {
        flex: 0.3,
        field: "actions",
        type: "actions",
        headerName: "Actions",
        minWidth: 150,
        getActions: actions
          ? actions
          : (params) => {
              return [
                <GridActionsCellItem
                  icon={<Edit />}
                  label={I18n.t("frontend.edit")}
                  onClick={() => {
                    setEditMqttUser(params.row);
                  }}
                />,
                <GridActionsCellItem
                  icon={<Delete />}
                  label={I18n.t("frontend.mqtt_users.regenerate_password")}
                  color="error"
                  onClick={() => {
                    confirm({
                      description: I18n.t("frontend.mqtt_users.confirm_delete"),
                    }).then(async () => {
                      await deleteUser(params.id);
                      success(
                        I18n.t("frontend.mqtt_users.delete_success", {
                          username: params.row.username,
                        }),
                      );
                    });
                  }}
                />,
                <GridActionsCellItem
                  icon={<Key />}
                  label={I18n.t("frontend.mqtt_users.regenerate_password")}
                  onClick={() => {
                    setGeneratePasswordMqttUser(params.row);
                  }}
                />,
                <GridActionsCellItem
                  icon={<Password />}
                  showInMenu
                  label={I18n.t("frontend.mqtt_users.set_password")}
                  onClick={() => {
                    setResetMqttUserPasswordMqttUser(params.row);
                  }}
                />,
              ];
            },
      },
    ],
    [actions],
  );

  const [gridPageModel, setGridPageModel] = React.useState<GridPaginationModel>(
    { page: pageSettings.number - 1, pageSize: pageSettings.size },
  );

  React.useEffect(() => {
    setPageSettings({
      number: gridPageModel.page + 1,
      size: gridPageModel.pageSize,
    });
  }, [gridPageModel.page, gridPageModel.pageSize]);

  const [searchText, setSearchText] = React.useState<string | null>(null);

  const searchValue = useDebounce(searchText, 1000);

  React.useEffect(() => {
    if (isEmpty(toString(searchValue).trim())) {
      setFilter({ ...filter, search: null });
    } else {
      setFilter({ ...filter, search: searchValue });
    }
  }, [searchValue]);
  return (
    <Paper>
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">
              {I18n.t("activerecord.models.mqtt_user", { count: 2 })}
            </Typography>
          </Grid>
          {error ? <Grid item xs={12}></Grid> : null}

          {!error && (
            <>
              <Grid item xs={12} container justifyContent="flex-end">
                <Grid item xs md={4} lg={3} minWidth={200}>
                  <TextField
                    type="search"
                    size="small"
                    fullWidth
                    label={I18n.t("frontend.search")}
                    onChange={(event) => {
                      setSearchText(event.target.value);
                    }}
                    onReset={() => {
                      setSearchText(null);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <DataGrid
                  rows={mqttUsers}
                  getRowHeight={({ model, densityFactor }) => {
                    if (isEmpty(model.mqtt_acls)) {
                      return 50 * densityFactor;
                    }

                    return model?.mqtt_acls.length * 60 * densityFactor;
                  }}
                  loading={isLoading}
                  initialState={{
                    pagination: { paginationModel: gridPageModel },
                  }}
                  columns={gridDef}
                  rowCount={totalItems}
                  paginationMode="server"
                  onPaginationModelChange={(pm) => setGridPageModel(pm)}
                  onSortModelChange={(model) => setSortModel(model)}
                  pageSizeOptions={[10, 25, 50, 100]}
                />
              </Grid>
            </>
          )}
        </Grid>

        {generatePasswordMqttUser && (
          <SialogicDialog
            maxWidth="sm"
            open={Boolean(generatePasswordMqttUser)}
            title={I18n.t("frontend.mqtt_users.regenerate_password_heading", {
              username: generatePasswordMqttUser.username,
            })}
            onClose={() => setGeneratePasswordMqttUser(null)}
          >
            <RegeneratePasswordForm
              showTitle={false}
              mqttUserId={generatePasswordMqttUser.id as IDType}
            />
          </SialogicDialog>
        )}
        {resetMqttUserPasswordMqttUser && (
          <SialogicDialog
            maxWidth="sm"
            open={Boolean(resetMqttUserPasswordMqttUser)}
            title={I18n.t("frontend.mqtt_users.reset_password_heading", {
              username: resetMqttUserPasswordMqttUser.username,
            })}
            onClose={() => setResetMqttUserPasswordMqttUser(null)}
          >
            <ResetMqttUserPasswordForm
              showTitle={false}
              mqttUserId={resetMqttUserPasswordMqttUser.id as IDType}
              onFinish={() => {
                setResetMqttUserPasswordMqttUser(null);
              }}
            />
          </SialogicDialog>
        )}
        {editMqttUser && (
          <SialogicDialog
            open={Boolean(editMqttUser)}
            onClose={() => setEditMqttUser(null)}
          >
            <MqttUserForm
              mqttUserId={editMqttUser.id as IDType}
              editMode="edit"
              mqttUser={editMqttUser}
              onSaved={() => {
                setEditMqttUser(null);
              }}
              onCancel={() => {
                setEditMqttUser(null);
              }}
            />
          </SialogicDialog>
        )}
      </Box>
    </Paper>
  );
};
