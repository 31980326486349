import JSON from "json-typescript";
import * as JSONAPI from "jsonapi-typescript";
import { AssetType } from "../models/asset_type";
import { AssetPropertyDefinitionJSONObject } from "./asset_property_definition";
import { IDType } from "../utils/urls/url_utils";

export const ASSET_TYPE_JSONAPI_RESOURCE_TYPE = "asset_types";
export interface AssetTypeJSONObject extends AssetType, JSON.Object {
  asset_property_definitions?: AssetPropertyDefinitionJSONObject[];
}

export type AssetTypeJSONAPIAttributes =
  JSONAPI.AttributesObject<AssetTypeJSONObject>;

export interface AssetTypeJsonApiFilter {
  root_only?: boolean;
  children_of?: IDType;
  children_of_or_generic?: IDType;
  search?: string;
}
