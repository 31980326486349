/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Moment } from "moment";
import moment from "../initializers/moment";

import { defaults, isNil } from "lodash";

/**
 * Configuration of business hours
 */
interface BusinessHoursOptions {
  /**
   * Start of the business day in the 24h format HH:mm
   */
  startOfBusinessDay?: string;
  /**
   * End of the business day in the 24h format HH:mm.
   * If the endOfBusinessDay <= startOfBusinessDay the business hours a configured as a night shift.
   */
  endOfBusinessDay?: string;
  /**
   * The timezone of the configured business hours.
   */
  timezone?: string;
}

/**
 * Class to compute start and end of business days for given dates
 */
export class BusinessHours {
  private _startOfBusinessDay: string;
  private _endOfBusinessDay: string;
  private _timezone: string;
  private _startTime: Moment;
  private _endTime: Moment;
  private _nightShift: boolean;

  /**
   * Create busines hours object
   * @param options Configuration for business hours with start, end and timezone
   */
  constructor(options: BusinessHoursOptions = {}) {
    defaults(options, {
      startOfBusinessDay: "00:00",
      endOfBusinessDay: "00:00",
      timezone: "Europe/Berlin",
    });

    this._timezone = options.timezone;
    this._startOfBusinessDay = options.startOfBusinessDay;
    this._endOfBusinessDay = options.endOfBusinessDay;

    this._startTime = moment.tz(
      `1970-01-01 ${this._startOfBusinessDay}`,
      this._timezone,
    );
    this._endTime = moment.tz(
      `1970-01-01 ${this._endOfBusinessDay}`,
      this._timezone,
    );
    this._nightShift = this._endTime.isSameOrBefore(this._startTime);
  }

  /**
   * Returns the start of business for the given date.
   * The date is expected to be in the local timezone set in gon.timezone
   * @param date A date to get the start of the business day for
   * @return The start of the business day in the local timezone set in gon.timezone
   */
  startOfBusinessDay(date?: Date | Moment): Moment {
    if (isNil(date)) {
      return null;
    }

    const dateInTimezone = moment.tz(date, gon.timezone).tz(this._timezone);

    const outDate = moment.tz(
      [
        dateInTimezone.year(),
        dateInTimezone.month(),
        dateInTimezone.date(),
        this._startTime.hour(),
        this._startTime.minutes(),
        this._startTime.seconds(),
      ],
      this._timezone,
    );

    return (outDate as any).tz(gon.timezone) as Moment;
  }

  /**
   * Returns the end of business for the given date. If the business hours are configured as a night shift
   * the returned date can be a day after.
   * The date is expected to be in the local timezone set in gon.timezone.
   * @param date A date to get the end of the business day for
   * @return The end of the business day in the local timezone set in gon.timezone
   */
  endOfBusinessDay(date?: Date | Moment): Moment {
    if (isNil(date)) {
      return null;
    }

    const dateInTimezone = moment.tz(date, gon.timezone).tz(this._timezone);
    const dstBefore = dateInTimezone.isDST();

    const outDate = moment.tz(
      [
        dateInTimezone.year(),
        dateInTimezone.month(),
        dateInTimezone.date(),
        this._endTime.hour(),
        this._endTime.minutes(),
        this._endTime.seconds(),
      ],
      this._timezone,
    );

    if (this._nightShift) {
      outDate.add(1, "day");
    }

    /** commented out as  */
    /*if (outDate.isDST() != dstBefore) {
      // changed day light savings time
      if (outDate.isDST()) {
        outDate.add(1, 'hour');
      } else {
        outDate.subtract(1, 'hour');
      }
    }*/

    return (outDate as any).tz(gon.timezone) as Moment;
  }
}
