import { CircularProgress } from "@mui/material";
import { defaultTo, isNil, map } from "lodash";
import * as React from "react";
export type FaSizeType = "lg" | "1x" | "2x" | "3x" | "4x" | "5x" | "6x";
export interface IconProps {
  icon: string;
  fixedWidth?: boolean;
  className?: string;
  faIconStyle?: "s" | "r" | "b";

  color?: string;
  title?: string;
  onClick?: () => void;
}
export interface SizeableIconProps extends IconProps {
  size?: FaSizeType;
}

function getIconSizeClass(size: FaSizeType | undefined): string {
  if (isNil(size)) {
    return "";
  }

  return `fa-${size}`;
}

/**
 * A font awesome icon
 * @param props
 */
export const Icon: React.FunctionComponent<SizeableIconProps> = function ({
  className = "",
  ...props
}) {
  return (
    <i
      className={`fa${defaultTo(props.faIconStyle, "")} fa-${props.icon} ${
        className
      } ${getIconSizeClass(props.size)} ${
        props.fixedWidth ? "fa-fw" : ""
      }`.trim()}
      style={isNil(props.color) ? null : { color: props.color }}
      title={props.title}
      onClick={props.onClick}
    />
  );
};

export interface IconStackProps {
  icons?: string[];
  size?: FaSizeType;
  color?: string;
  title?: string;
  className?: string;
  onClick?: () => void;
}

/**
 * A font awesome icon stack
 * @param props
 */
export const IconStack: React.FunctionComponent<IconStackProps> = function ({
  className = "",
  icons = [],
  size = null,
  color,
  ...props
}) {
  return (
    <span
      className={`fa-stack fa-${size} ${className}`}
      title={props.title}
      onClick={props.onClick}
    >
      {map(icons, (icon, index) => (
        <Icon
          icon={icon}
          className={`fa-fw fa-stack-1x`}
          color={color}
          key={index}
        />
      ))}
    </span>
  );
};

export interface LoadingIconProps {
  size: FaSizeType;
}
/**
 * A spinning loading icon
 */
export const LoadingIcon: React.FunctionComponent<LoadingIconProps> = function (
  props,
) {
  const size = props.size?.charAt(0);

  return <CircularProgress size={isNil(size) ? null : `${size}rem`} />;
};
