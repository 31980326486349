import { Avatar, Grid } from "@mui/material";
import { defaultTo, isNil } from "lodash";
import * as React from "react";
import { getStateColor } from "../../utils/colors";
import { FaSizeType } from "../common/icon";
import { getIconForName } from "../common/icon_for_name";

interface StateAvatarProps {
  imageUrl?: string;
  iconName?: string;
  color?: string;
  iconSize?: FaSizeType;
  name?: string;
  size?: string | number;
}

export const StateAvatar: React.FC<StateAvatarProps> = ({
  imageUrl,
  iconName,
  color,
  iconSize,
  name,
  size = "4em",
}) => {
  return isNil(imageUrl) ? (
    <Avatar
      color={color || undefined}
      variant="square"
      sx={{
        width: size,
        height: size,
        background: "transparent",
        color,
      }}
      className="m-auto"
      alt={name}
    >
      {getIconForName(iconName, defaultTo(iconSize, "2x"))}
    </Avatar>
  ) : (
    <Avatar
      variant="square"
      style={{
        height: size,
        width: size,
        borderColor: color,
        borderWidth: 2,
      }}
      src={imageUrl}
      alt={name}
      className="m-auto"
    >
      {getIconForName(iconName, defaultTo(iconSize, "2x"))}
    </Avatar>
  );
};
