import * as JSONAPI from "jsonapi-typescript";
import * as JSON from "json-typescript";
import { ReportSpecModelAttributes } from "../models/report_spec";
import { AssetTypeJSONObject } from "./asset_type";

export const REPORT_SPEC_JSONAPI_RESOURCE_TYPE = "report_specs";

export type REPORT_SPEC_INCLUDES = "asset_type";

interface ReportSpecJsonObject extends JSON.Object, ReportSpecModelAttributes {}

export const REPORT_SPEC_JSONAPI_CREATE_ATTRIBUTES: (keyof ReportSpecJSONAPIAttributes)[] =
  ["name", "spec", "default_rrule"];

export const REPORT_SPEC_JSONAPI_UPDATE_ATTRIBUTES: (keyof ReportSpecJSONAPIAttributes)[] =
  REPORT_SPEC_JSONAPI_CREATE_ATTRIBUTES;

export type ReportSpecJSONAPIAttributes =
  JSONAPI.AttributesObject<ReportSpecJsonObject>;

export interface ReportSpecJSONAPIObject
  extends ReportSpecJSONAPIAttributes,
    JSON.Object {
  asset_type?: AssetTypeJSONObject;
}
