import moment, { Moment } from "moment";
import {
  EventSubscriberBase,
  EventSubscription,
  ModelDataChannel,
} from "./model_data_channel";
import { EventNotification } from "../models/event_notification";
import { each, isNil } from "lodash";

import { IDType } from "../utils/urls/url_utils";
import { AssetProperties } from "../json_api/asset";

export interface AssetCableValue<T> {
  asset_id: IDType;
  action: string;
  message: T;
}
export interface EventDataCableValue
  extends AssetCableValue<EventNotification> {
  action: "asset_event";
  event_id: IDType;
}

export interface AssetPropertyUpdateCableValue
  extends AssetCableValue<AssetPropertyUpdateMessage> {
  action: "asset_properties_update";
}

interface AssetPropertyUpdateMessage {
  time: string;
  asset_properties: AssetProperties;
}
export interface AssetEventSubscriber extends EventSubscriberBase {
  handleNewEvent?(
    event: EventNotification,
    time: Moment,
    assetId: IDType,
    eventId: IDType,
  ): void;

  handleAssetPropertyUpdate?(
    assetId: IDType,
    newProperties: AssetProperties,
    time: Moment,
  ): void;
}

const WILDCARD_ATTRIBUTE_ID = -1;

export class AssetNotificationChannel extends ModelDataChannel<
  AssetEventSubscriber,
  EventDataCableValue | AssetPropertyUpdateCableValue
> {
  protected includeSubtreeInSubscription: boolean;
  constructor(includeSubtree = true) {
    super();
    this.includeSubtreeInSubscription = includeSubtree;
  }
  protected getChannelNameWithParams(
    modelId: number,
  ): string | ActionCable.ChannelNameWithParams {
    return {
      channel: "AssetNotificationChannel",
      asset_id: modelId,
      include_subtree: this.includeSubtreeInSubscription,
    };
  }

  public subscribe(modelId: number) {
    return super.subscribe(modelId);
  }
  protected handleDataMessage(
    data: EventDataCableValue | AssetPropertyUpdateCableValue,
    listeners: EventSubscription<AssetEventSubscriber>[],
  ): void {
    if (isNil(listeners)) return;
    if (data.action == "asset_event") {
      const time = moment(data.message.from);
      each(listeners, (listener) => {
        listener.f.handleNewEvent?.(
          data.message,
          time,
          data.asset_id,
          data.event_id || data.message.event_id,
        );
      });
    } else if (data.action == "asset_properties_update") {
      const time = data.message.time ? moment(data.message.time) : moment();
      each(listeners, (listener) => {
        listener.f.handleAssetPropertyUpdate?.(
          data.asset_id,
          data.message.asset_properties,
          time,
        );
      });
    }
  }
}

export default AssetNotificationChannel;
