import { Chip, Grid, Typography } from "@mui/material";
import { first, isNil, isNumber, merge } from "lodash";
import { Moment } from "moment";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { WidgetController } from "../../controller/widget_controller";
import { SensorLoader } from "../../json_api/sensor_loader";
import { SensorValueType } from "../../models/sensor";
import { logger } from "../../utils/logger";
import { getTimeString } from "../../utils/time_strings";
import { AssetMaintenanceInfoWidgetConfigSerialized } from "../../widgets/asset_maintenance_info_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import { PercentageBar } from "../common/percentage_bar";
import { AssetMaintenanceInfoWidgetProps } from "./asset_maintenance_info_widget.types";
import { SialogicWidgetDefinition } from "./sialogic_widget_component";
import { WidgetBox } from "./widget_box";

export const AssetMaintenanceInfoWidget: React.FunctionComponent<
  AssetMaintenanceInfoWidgetProps
> = (props) => {
  const barRef = useRef<HTMLDivElement>(null);

  const [sensor, setSensor] = useState(null);
  const [sensorStatus, setSensorStatus] = useState(props.sensorStatus);
  const [sensorValue, setSensorValue] = useState(props.sensorValue);
  const [status, setStatus] = useState(props.status ? [...props.status] : []);

  const handleSensorValueUpdate = React.useCallback(
    (
      attributeKeyId: number,
      sensorId: number,
      value: SensorValueType,
      time: Moment,
      unit?: string,
    ): void => {
      if (!isNumber(value) && !isNil(value)) {
        return;
      }

      updateValue(attributeKeyId, sensorId, value, unit, time);
    },
    [sensorValue, status],
  );

  const subscriber = { handleSensorValueUpdate };
  useEffect(() => {
    SensorLoader.getInstance()
      .getSensors([props.sensorValueId])
      .then((s) => {
        setSensor(first(s));
      })
      .catch((e) => {
        logger.error(e);
      });

    WidgetController.getInstance().sensorDataChannel.addEventListener(
      subscriber,
      props.sensorValueId,
    );

    return () => {
      WidgetController.getInstance().sensorDataChannel.removeEventListener(
        subscriber,
        props.sensorValueId,
      );
    };
  }, [props.sensorValueId]);

  const updateValue = React.useCallback(
    (
      attributeKeyId: number,
      sensorId: number,
      value: number,
      unit: string,
      time: Moment,
    ): void => {
      if (!props.updateEnabled) {
        return;
      }
      if (sensorId === props.sensorValueId) {
        setSensorValue(isNil(value) ? 0 : value);
      }

      const statusValueIndex = props.status?.findIndex(
        (s) => s.sensor_id == sensorId,
      );
      if (!isNil(statusValueIndex) && statusValueIndex != -1) {
        if (!isNil(value)) {
          const newStatus = [...status];
          newStatus[statusValueIndex].current_value = value;
          setStatus(newStatus);
        }
      }
    },
    [props.sensorValueId, props.status],
  );

  return (
    <WidgetBox
      {...props}
      cardAvatar={
        props.currentMaintenanceUrgencyLevel ? (
          <Chip label={props.currentMaintenanceUrgencyLevel} size="small" />
        ) : null
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography>{sensor?.name}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{props.maintenancePlanName}</Typography>
        </Grid>
        <Grid item xs={6}>
          <PercentageBar
            value={sensorValue}
            max={props.sensorValueMax}
            min={props.sensorValueMin}
            unit={props.sensorValueUnit}
            timestamp={getTimeString(props.timeScopeName, sensor?.timestamp)}
          />
        </Grid>
        <Grid item xs={6}>
          {props.status?.map((status, index) => (
            <PercentageBar
              key={index}
              value={status.current_value}
              min={status.start_value}
              max={status.critical_value}
              status={
                status.urgency_level == "maintenance_due"
                  ? "critical"
                  : "normal"
              }
              unit={status.unit}
            />
          ))}
        </Grid>
      </Grid>
    </WidgetBox>
  );
};

function serializedConfigToProps(
  config: AssetMaintenanceInfoWidgetConfigSerialized,
): AssetMaintenanceInfoWidgetProps {
  return merge(widgetBoxPropsFromSerializedConfig(config), {
    assetId: config.asset_id,
    assetName: config.asset_name,
    sensorValue: config.sensor_value,
    sensorValueId: config.sensor_id,
    sensorValueUnit: config.sensor_value_unit,
    sensorValueMax: config.sensor_value_max,
    sensorValueMin: config.sensor_value_min,
    status: config.status,
    maintenancePlanName: config.maintenance_plan_name,
    sensorStatus: config.sensor_status,
    currentMaintenanceUrgencyLevel: config.current_maintenance_urgency_level,
  } as AssetMaintenanceInfoWidgetProps);
}

export const AssetMaintenanceInfoWidgetDefinition: SialogicWidgetDefinition<
  typeof AssetMaintenanceInfoWidget,
  typeof serializedConfigToProps
> = {
  Component: AssetMaintenanceInfoWidget,
  serializedConfigToProps: serializedConfigToProps,
};
