import * as React from "react";

import { Close } from "@mui/icons-material";
import { Button, Grid, MenuItem, TextField } from "@mui/material";
import { cloneDeep, map, toInteger, toString } from "lodash";
import { AssetTemplateJSONObject } from "../../json_api/asset_template";
import { extractErrorsFromJsonApi } from "../../json_api/jsonapi_tools";
import { ProductModelAssetTemplateSettingJSONObject } from "../../json_api/product_model_asset_template_settings";
import {
  useCreateProductModelAssetTemplateSetting,
  useUpdateProductModelAssetTemplateSetting,
} from "../../queries/product_model_asset_template_settings_data";
import { DocWithErrors } from "jsonapi-typescript";
import { IDType } from "../../utils/urls/url_utils";

interface ProductModelAssetTemplateSettingsFormProps {
  productModelAssetTemplateSettings: ProductModelAssetTemplateSettingJSONObject;
  productModelId: IDType;
  availableAssetTemplates: AssetTemplateJSONObject[];
  onCancel: () => void;
  onSave: (
    productModelAssetTemplateSettings: ProductModelAssetTemplateSettingJSONObject,
  ) => void;
}

export const ProductModelAssetTemplateSettingsForm: React.FC<
  ProductModelAssetTemplateSettingsFormProps
> = ({
  productModelAssetTemplateSettings,
  productModelId,
  availableAssetTemplates,
  onCancel,
  onSave,
}) => {
  const { mutateAsync: createProductModelAssetTemplateSetting } =
    useCreateProductModelAssetTemplateSetting();

  const { mutateAsync: updateProductModelAssetTemplateSetting } =
    useUpdateProductModelAssetTemplateSetting((errs: DocWithErrors) =>
      setErrors(() =>
        extractErrorsFromJsonApi<ProductModelAssetTemplateSettingJSONObject>(
          errs,
        ),
      ),
    );
  const [editableSettings, setEditableSettings] =
    React.useState<ProductModelAssetTemplateSettingJSONObject>(() =>
      productModelAssetTemplateSettings
        ? cloneDeep(productModelAssetTemplateSettings)
        : { product_model_id: productModelId },
    );

  const [selectedAssetTemplate, setSelectedAssetTemplate] = React.useState(() =>
    availableAssetTemplates?.find(
      (t) => t.id === editableSettings?.asset_template_id,
    ),
  );

  React.useEffect(() => {
    setSelectedAssetTemplate(
      availableAssetTemplates?.find(
        (t) => t.id === editableSettings?.asset_template_id,
      ),
    );
  }, [editableSettings?.asset_template_id]);

  const [errors, setErrors] = React.useState<
    Record<keyof ProductModelAssetTemplateSettingJSONObject, string[]>
  >({});
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          select
          label={I18n.t("activerecord.models.asset_template.one")}
          fullWidth
          value={toString(editableSettings?.asset_template_id)}
          onChange={(e) => {
            e.target.value
              ? setEditableSettings({
                  ...editableSettings,
                  asset_template_id: toInteger(e.target.value),
                })
              : setEditableSettings({
                  ...editableSettings,
                  asset_template_id: null,
                });
          }}
        >
          <MenuItem value="">{I18n.t("frontend.none")}</MenuItem>
          {map(availableAssetTemplates, (assetTemplate) => (
            <MenuItem key={assetTemplate.id} value={assetTemplate.id}>
              {assetTemplate.asset_type?.name} - {assetTemplate?.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label={
            selectedAssetTemplate
              ? `${selectedAssetTemplate.name} ${I18n.t(
                  "activerecord.attributes.product_model_asset_template_setting.count",
                )}`
              : undefined
          }
          fullWidth
          type="number"
          helperText={
            errors?.count
              ? errors.conut.join(", ")
              : I18n.t(
                  "frontend.product_model_asset_template_setting_form.count_help",
                )
          }
          onChange={(e) =>
            setEditableSettings({
              ...editableSettings,
              count: e.target.value ? toInteger(e.target.value) : null,
            })
          }
          inputProps={{
            min: selectedAssetTemplate?.min_count,
            max: selectedAssetTemplate?.max_count,
          }}
          value={editableSettings?.count}
        />
      </Grid>
      <Grid item xs={12}>
        <Button key="cancel" onClick={() => onCancel()} startIcon={<Close />}>
          {I18n.t("frontend.cancel")}
        </Button>
        <Button
          key="save"
          onClick={() => {
            if (!editableSettings.id) {
              void createProductModelAssetTemplateSetting(
                editableSettings,
              ).then((response) => {
                if (response.data) {
                  onSave({
                    ...editableSettings,
                    id: response.data.id,
                  });
                }
              });
            } else {
              void updateProductModelAssetTemplateSetting(
                editableSettings,
              ).then((response) => {
                if (response.data) {
                  onSave(editableSettings);
                }
              });
            }
          }}
          startIcon={<Close />}
        >
          {I18n.t("frontend.save")}
        </Button>
      </Grid>
    </Grid>
  );
};
