import * as React from "react";

import * as JSONType from "json-typescript";
import { SingleResourceDoc } from "jsonapi-typescript";
import { Root, createRoot } from "react-dom/client";
import { jsonApiSingleResourceToFlatObject } from "../../json_api/jsonapi_tools";
import { ReportPlanJSONAPIAttributes } from "../../json_api/report_plan";
import { AppRoot } from "../common/app_root";
import {
  ReportSpecEditForm,
  ReportSpecIdentifiableItem,
} from "./report_spec_editor";
import { Box, Paper } from "@mui/material";

const reportSpecEditFormRoots: Root[] = [];
/**
 * Initialize react component WidgetEditorFor within all elements with data-toggle="widget-editor-form".
 * Initial state is loaded from "data-role-definition" and "data-form-url".
 * State is expected to be in JSON format.
 */
export function initializeReportSpecEditorForm(
  selector: JQuery = $('[data-toggle="report-spec-editor"]'),
): void {
  selector.each((_i, element) => {
    const jqElement = $(element);

    const reportSpecId = jqElement.data("report-spec-id") as number;
    const assetTypeId = jqElement.data("asset-type-id") as number;
    const reportSpec = jqElement.data("spec") as SingleResourceDoc<
      string,
      ReportPlanJSONAPIAttributes
    >;
    const schemaJson = JSON.parse(
      element.getAttribute("data-spec-schema"),
    ) as JSONType.Object;

    const sensorTypes = jqElement.data(
      "sensor-types",
    ) as ReportSpecIdentifiableItem[];
    const assetTypes = jqElement.data(
      "asset-types",
    ) as ReportSpecIdentifiableItem[];
    const attributeKeyStrings = jqElement.data(
      "attribute-key-strings",
    ) as string[];
    const organizationId = jqElement.data("organization-id") as number;
    const referrer = jqElement.data("referer") as string;
    const root = createRoot(element);

    root.render(
      <AppRoot>
        <Paper>
          <Box p={2}>
            <ReportSpecEditForm
              referer={referrer}
              organizationId={organizationId}
              sensorTypes={sensorTypes}
              assetTypes={assetTypes}
              schema={schemaJson}
              spec={jsonApiSingleResourceToFlatObject(reportSpec)}
            />
          </Box>
        </Paper>
      </AppRoot>,
    );
    reportSpecEditFormRoots.push(root);
  });
}

/**
 * Remove react component from all ellements
 */
export function destroyReportSpecEditorForm(): void {
  reportSpecEditFormRoots.forEach((root) => {
    root.unmount();
  });
  reportSpecEditFormRoots.length = 0;
}
