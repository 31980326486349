import { createQuery } from "react-query-kit";
import {
  ASSET_EVENT_TYPE_JSONAPI_RESOURCE_TYPE,
  AssetEventTypeFilter,
  AssetEventTypeJsonApiFilter,
  AssetEventTypeJSONObject,
  loadEventTypesForAsset,
} from "../../json_api/asset_event_type";
import { TreeMode } from "../../json_api/tree_items";
import { IDType } from "../../utils/urls/url_utils";
import { LoadItemsResult } from "../../json_api/jsonapi_tools";

interface LoadAssetEventTypesQueryVariables {
  assetId: IDType;
  loadingTreeMode?: TreeMode;
  includeSystemEventsOnLoad?: boolean;
  filter?: AssetEventTypeFilter;
}

export const useLoadAssetEventTypes = createQuery<
  LoadItemsResult<AssetEventTypeJSONObject>,
  LoadAssetEventTypesQueryVariables
>({
  queryKey: [ASSET_EVENT_TYPE_JSONAPI_RESOURCE_TYPE],
  fetcher: async ({
    assetId,
    loadingTreeMode,
    includeSystemEventsOnLoad,
    filter,
  }) => {
    const eventTypes = await loadEventTypesForAsset(
      assetId,
      loadingTreeMode,
      includeSystemEventsOnLoad,
      filter,
    );
    return eventTypes;
  },
});

/*

  initialData: props.availableTypes
    ? sortBy(props.availableTypes, "name")
    : undefined,
  enabled: !props.disabled,
*/
