import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  MenuItem,
  Skeleton,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import JSONSchemaValidator from "@rjsf/validator-ajv8";
import * as JSONType from "json-typescript";
import {
  defaultTo,
  find,
  first,
  isEmpty,
  isNil,
  isNumber,
  keys,
  map,
  toString,
  upperFirst,
} from "lodash";
import * as React from "react";
import { logger } from "../../../utils/logger";
import { error, success } from "../../../utils/toasts";
import { Form as MuiForm } from "../../common/json_schema_form/form";

import { WidgetJSONObject } from "../../../json_api/widget";
import { ActiveStorageStoredFile } from "../../../models/active_storage_stored_file";
import { ASFileUpload, UploadDelegate } from "../../../utils/file_uploader";
import { AppContext } from "../../common/app_context/app_context_provider";
import { Dropzone } from "../../common/file_dropzone/dropzone";
import { FixedBottomArea } from "../../common/fixed_bottom_area";
import { FloatingButtons } from "../../common/floating_buttons";

import { RJSFSchema } from "@rjsf/utils";
import {
  useCreateWidget,
  useLoadWidget,
  useLoadWidgetSchema,
  useLoadWidgetSensorInfo,
  useLoadWidgetSensorTypes,
  useLoadWidgetTypes,
  useSaveBlobAsWidgetAttachment,
  useSaveWidgetConfig,
} from "./widget_config_editor_data";
import {
  processWidgetSchema,
  widgetNavigateBack,
} from "./widget_config_editor_utils";
import {
  WidgetEditFormProps,
  WidgetTypeInfo,
} from "./widget_contfig_editor_types";

export const WidgetEditor: React.FC<WidgetEditFormProps> = ({
  config,
  attachments: propsAttachments,
  widgetId,
  widgetTypes: propsWidgetTypes,
  sensorTypes: propsSensorTypes,
  availableSensors: propsAvailableSensors,
  schema: propsSchema,
  assetId,
  assetTypeId,
  dashboardId,
  dashboardType,

  widgetTypeIdentifier,
  ...props
}) => {
  const appContext = React.useContext(AppContext);
  const [attachments, setAttachments] =
    React.useState<Record<string, ActiveStorageStoredFile>>(propsAttachments);
  const [uploads, setUploads] = React.useState<
    Record<string, { file: File; progress: number }>
  >({});

  const [widget, setWidget] = React.useState<WidgetJSONObject>(() => {
    if (widgetId) {
      // load
      return null;
    } else {
      let widget: WidgetJSONObject = {};
      if (config) {
        widget = { config: config };
      }
      if (attachments) {
        widget ||= {};
        widget.attachments = attachments;
      }
      return widget;
    }
  });

  const [currentTab, setCurrentTab] = React.useState<
    "base" | "config" | "attachments"
  >("base");

  const [dataLoaded, setDataLoaded] = React.useState(false);

  const [widgetType, setWidgetType] = React.useState<WidgetTypeInfo>(null);
  const [valid, setValid] = React.useState(false);

  const [schema, setSchema] = React.useState<RJSFSchema>(null);

  const {
    data: sensorTypes,
    isLoading: sensorTypesLoading,
    isSuccess: sensorTypesLoaded,
  } = useLoadWidgetSensorTypes({
    initialData: propsSensorTypes || undefined,
    enabled: isEmpty(propsSensorTypes),
  });

  const {
    data: availableSensors,
    isLoading: loadingSensors,
    isSuccess: availableSensorsLoaded,
  } = useLoadWidgetSensorInfo({
    variables: { assetId: assetId },
    initialData: propsAvailableSensors || undefined,
  });

  const {
    data: widgetTypes,
    isLoading: widgetTypesLoading,
    isSuccess: widgetTypesLoaded,
  } = useLoadWidgetTypes({
    initialData: propsWidgetTypes || undefined,
  });

  React.useEffect(() => {
    if (attachments) {
      setWidget(() => ({ ...widget, attachments: attachments }));
    }
  }, [attachments]);

  const widgetTypeString = isEmpty(widgetType?.identifier)
    ? widgetTypeIdentifier
    : widgetType?.identifier;

  const { data: loadedWidget, isLoading: widgetLoading } = useLoadWidget({
    variables: { id: widgetId },
    enabled: Boolean(widgetId),
    placeholderData: widget,
  });

  React.useEffect(() => {
    if (loadedWidget) {
      setWidget(loadedWidget);
      setAttachments(loadedWidget.attachments);
    }
  }, [loadedWidget]);

  const { mutateAsync: saveConfig, isPending: savePending } =
    useSaveWidgetConfig();
  const { mutateAsync: createWidget, isPending: createPending } =
    useCreateWidget();
  const { mutateAsync: saveBlobAsAttachment, isPending: isSavingBlob } =
    useSaveBlobAsWidgetAttachment();
  const [formErrors, setFormErrors] = React.useState(null);

  const [formValid, setFormValid] = React.useState(false);

  React.useEffect(() => {
    if (isEmpty(formErrors) && !isEmpty(widgetType)) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [formErrors]);

  React.useEffect(() => {
    if (sensorTypesLoaded && availableSensorsLoaded && widgetTypesLoaded) {
      setDataLoaded(true);
    }
  }, [sensorTypesLoaded, availableSensorsLoaded, widgetTypesLoaded]);

  const [loading, setLoading] = React.useState(true);

  const [selectedWidgetTypeIdentifier, setSelectedWidgetTypeIdentifier] =
    React.useState(widgetTypeString);

  React.useEffect(() => {
    if (widgetTypes && selectedWidgetTypeIdentifier) {
      const selectedWidgetType = find(
        widgetTypes,
        (wt) => wt.identifier === selectedWidgetTypeIdentifier,
      );
      setWidgetType(selectedWidgetType);
      if (isNil(selectedWidgetType)) {
        setValid(false);
        return;
      } else {
        setValid(true);
      }
      setFormValid(false);
    }
  }, [widgetTypes, selectedWidgetTypeIdentifier]);

  const { data: loadedWidgetSchema, isLoading: widgetSchemaLoading } =
    useLoadWidgetSchema({
      variables: {
        type: selectedWidgetTypeIdentifier,
      },
      initialData: propsSchema || undefined,
      enabled: !isEmpty(widgetTypeString),
    });

  React.useEffect(() => {
    if (loadedWidgetSchema && sensorTypes && availableSensors) {
      const schema = processWidgetSchema(
        loadedWidgetSchema,
        sensorTypes,
        availableSensors,
      );
      setSchema(schema);
    }
  }, [loadedWidgetSchema, loadedWidget, sensorTypes, availableSensors]);

  React.useEffect(() => {
    setLoading(
      widgetTypesLoading ||
        sensorTypesLoading ||
        loadingSensors ||
        widgetSchemaLoading ||
        widgetLoading,
    );
  }, [
    widgetTypesLoading,
    sensorTypesLoading,
    loadingSensors,
    widgetSchemaLoading,
    widgetLoading,
  ]);

  if (widgetTypesLoading) {
    return (
      <Card>
        <CardHeader title="Loading ..." />
        <CardContent>
          <Skeleton variant="rounded" height={300} />
          <Skeleton variant="rounded" height={200} />
          <Skeleton variant="rounded" height={400} />
          <Skeleton variant="rounded" height={100} />
        </CardContent>
      </Card>
    );
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={currentTab}
              onChange={(e, newValue) => {
                setCurrentTab(newValue);
              }}
              aria-label="basic tabs example"
            >
              <Tab label={"Base"} value="base" />
              <Tab
                label={"Config"}
                value="config"
                disabled={isEmpty(widgetType)}
              />
              {!isEmpty(attachments) ? (
                <Tab
                  label={"Attachments"}
                  value="attachments"
                  disabled={isEmpty(widgetType)}
                />
              ) : null}
            </Tabs>
          </Box>
        </Grid>

        {currentTab == "base" ? (
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title={I18n.t(
                  "frontend.widgets.widget_editor.general_settings",
                )}
              ></CardHeader>
              <CardContent>
                {loading ? (
                  <Skeleton variant="rounded" height={300} />
                ) : (
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label={"Widget Name"}
                        value={widget?.name ?? ""}
                        onChange={(event) => {
                          setWidget((w) => ({
                            ...w,
                            name: event.target.value,
                          }));
                        }}
                      />
                    </Grid>
                    {isEmpty(widgetTypes) ? null : (
                      <Grid item xs={12}>
                        <TextField
                          select
                          label={I18n.t(
                            "frontend.widgets.widget_editor.widget_type_label",
                          )}
                          fullWidth
                          value={toString(widgetType?.identifier)}
                          onChange={(event) => {
                            setSelectedWidgetTypeIdentifier(event.target.value);
                          }}
                        >
                          {widgetTypes?.map((t, index) => (
                            <MenuItem key={index} value={t.identifier}>
                              {t.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    )}

                    {isEmpty(widgetType?.description) ? null : (
                      <Grid item xs={12}>
                        <Typography variant="h5">
                          {I18n.t("frontend.description")}
                        </Typography>
                        <Box marginY={1}>
                          <Typography variant="body1">
                            {widgetType.description}
                          </Typography>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                )}
              </CardContent>
            </Card>
          </Grid>
        ) : null}
        {!isEmpty(schema) && currentTab === "config" && (
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title={I18n.t(
                  "frontend.widgets.widget_editor.widget_config_heading",
                )}
              />

              <CardContent>
                <MuiForm
                  validator={JSONSchemaValidator}
                  schema={schema}
                  experimental_defaultFormStateBehavior={{
                    constAsDefaults: "skipOneOf",
                    emptyObjectFields: "populateRequiredDefaults",
                  }}
                  uiSchema={schema["x-ui-schema"] || {}}
                  formData={widget?.config || {}}
                  disabled={loading}
                  onChange={(event) =>
                    setWidget((w) => {
                      return {
                        ...w,
                        config: event.formData as JSONType.Object,
                      };
                    })
                  }
                  showErrorList={"top"}

                  //disabled={!this.props.editable  }
                >
                  <div />
                </MuiForm>
              </CardContent>
            </Card>
          </Grid>
        )}
        {!isEmpty(attachments) && currentTab === "attachments" && (
          <Grid item xs={12} container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h3">
                {I18n.t("frontend.widgets.widget_editor.attachments_heading")}
              </Typography>
            </Grid>

            {map(keys(attachments).sort(), (name) => {
              const attachment = attachments[name];

              return (
                <Grid item xs={12} lg={4} key={name}>
                  <Card>
                    <CardHeader
                      titleTypographyProps={{ variant: "h5" }}
                      title={upperFirst(name)}
                    />

                    <CardContent>
                      <Dropzone
                        filesLimit={1}
                        initialFiles={
                          isNil(attachment) ? null : [attachment.url]
                        }
                        uploading={!isNil(uploads[name])}
                        acceptedFileTypes={[
                          "image/png",
                          "image/jpeg",
                          "image/svg+xml",
                          "application/pdf",
                          "model/vnd.gltf+json",
                          "model/gltf-binary",
                          "model/gltf-json",
                        ]}
                        onDelete={(file) => {
                          void saveBlobAsAttachment({
                            widgetId: widget.id,
                            blob: null,

                            attachmentName: name,
                          }).then((res) => {
                            setAttachments(res);
                            void success(
                              I18n.t("frontend.success"),
                              I18n.t(
                                "frontend.widgets.widget_editor.file_removed",
                                { file: file.name },
                              ),
                            );
                            if (props.onSaved) {
                              props.onSaved(
                                {
                                  ...widget,
                                  attachments: res,
                                },
                                false,
                              );
                            }
                          });
                        }}
                        uploadProgressPercent={uploads[name]?.progress}
                        fileSelectionChanged={(newFile) => {
                          const theFile = first(newFile);
                          if (
                            isEmpty(theFile) ||
                            theFile?.name === attachment?.filename
                          ) {
                            return;
                          }

                          // add upload status
                          setUploads((u) => {
                            const theUploads = {
                              ...defaultTo(u, {}),
                            };

                            theUploads[name] = {
                              file: theFile,
                              progress: 0,
                            };

                            return theUploads;
                          });

                          // create delegate
                          const deletegate: UploadDelegate = {
                            onProgress: (progress, asFileUpload) => {
                              setUploads((u) => {
                                return {
                                  ...u,
                                  [name]: { ...u[name], progress },
                                };
                              });
                            },
                          };
                          const fileUpload = new ASFileUpload(
                            first(newFile),
                            deletegate,
                          );

                          fileUpload
                            .upload()
                            .then((blob) =>
                              saveBlobAsAttachment({
                                attachmentName: name,
                                blob,
                                widgetId: widget?.id,
                              }).then((res) => {
                                setAttachments(res);
                                void success(
                                  I18n.t("frontend.success"),
                                  I18n.t(
                                    "frontend.widgets.widget_editor.file_saved",
                                    {
                                      attachmentName: name,
                                      file: theFile.name,
                                    },
                                  ),
                                );
                                if (props.onSaved) {
                                  props.onSaved(
                                    {
                                      ...widget,
                                      attachments: attachments,
                                    },
                                    false,
                                  );
                                }
                              }),
                            )
                            .catch((theError) => {
                              void error(
                                I18n.t("frontend.error"),
                                "Error uploading file",
                              );
                              console.log(theError);
                            })
                            .finally(() => {
                              setUploads((u) => {
                                const newUploads = { ...u };
                                delete newUploads[name];
                                return newUploads;
                              });
                            });
                        }}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        )}

        <FixedBottomArea id="fixed-bottom-area">
          <FloatingButtons
            isProcessing={loading || savePending || createPending}
            onSubmit={() => {
              const arg = {
                id: widget?.id,
                assetId,
                config: widget.config,
                assetTypeId: assetTypeId,
                attachments: attachments,
                dashboardId: dashboardId,
                dashboardType: dashboardType,
                name: widget.name,
                widgetTypeIdentifier: widgetType.identifier,
              };
              (widget?.id ? saveConfig(arg) : createWidget(arg))
                .then(() => {
                  success("Widget saved!", "Widget was updated successfully.");
                  if (props.onSaved) {
                    props.onSaved(widget, true);
                  } else {
                    widgetNavigateBack();
                  }
                })
                .catch((err) => {
                  error(
                    "Problems saving the widget!",
                    "Widget could not be saved.",
                  );
                  logger.error(err);
                });
            }}
            onCancel={() => {
              setLoading(false);
              if (props.onCancel) {
                props.onCancel();
              } else {
              }
            }}
            disableSave={loading || !valid}
            showScrollToTopBtn={true}
            saveTitle={I18n.t("frontend.sensors.form.submit_title")}
          />
        </FixedBottomArea>
      </Grid>
    </>
  );
};
