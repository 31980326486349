import * as JSONAPI from "jsonapi-typescript";
import * as JSON from "json-typescript";

import { MeasurementTypeAttributes } from "../models/measurement_type";

export const MEASUREMENT_TYPE_JSON_API_TYPE = "measurement_types";

export const MeasurementTypeUpdatableFields: (keyof MeasurementTypeAttributes)[] =
  [
    "ref_measurement_comp_mode",
    "ref_measurement_comp_high",
    "ref_measurement_comp_low",
    "ref_measurement_type_key",
    "diagram_type",
    "description",
    "interval_unit",
    "key",
    "title",
  ];

export interface MeasurementTypeJsonAttributes
  extends MeasurementTypeAttributes {
  meta_info?: JSON.Object;
}

export interface MeasurementTypeJsonObject
  extends JSON.Object,
    MeasurementTypeJsonAttributes {}

export type MeasurementTypeJSONAPIAttributesObject =
  JSONAPI.AttributesObject<MeasurementTypeJsonObject>;
