import JSON from "json-typescript";
import * as JSONAPI from "jsonapi-typescript";
import { ContextStateMachineAttributes } from "../models/context_state_machine";
import { loadDataFromUrl } from "../utils/jquery_helper";
import { IDType } from "../utils/urls/url_utils";

import { toArray } from "lodash";
import { api_asset_context_state_machines_path } from "../routes";
import { CSMIncludes, apiContextStateMachinePath } from "../utils/urls";
import {
  jsonApiResourceCollectionToFlatObjects,
  jsonApiSingleResourceToFlatObject,
} from "./jsonapi_tools";
import { StateJSONObject } from "./state";
import { StateContextJSONObject } from "./state_contexts";
import { SialogicQueryClient } from "../components/common/sialogic_query_client";
export interface ContextStateMachineJsonApiAttributes
  extends ContextStateMachineAttributes {
  current_state_since: string;
}

export type ContextStateMachineJsonApiIncludes =
  | "possible_states"
  | "state_context"
  | "state_context.states"
  | "current_state";

export const CONTEXT_STATE_MACHINE_JSONAPI_RESOURCE_TYPE =
  "context_state_machines";
export interface ContextStateMachineJSONObject<
  StatefulItemType extends JSON.Object = JSON.Object,
> extends ContextStateMachineJsonApiAttributes,
    JSON.Object {
  possible_states?: StateJSONObject[];
  state_context?: StateContextJSONObject;
  stateful_item?: StatefulItemType;
  current_state?: StateJSONObject;
}

export async function loadContextStateMachine(
  contextStateMachineId: IDType,
  additionalIncludes: CSMIncludes[] = [],
): Promise<ContextStateMachineJSONObject> {
  return SialogicQueryClient.fetchQuery({
    queryKey: [
      CONTEXT_STATE_MACHINE_JSONAPI_RESOURCE_TYPE,
      contextStateMachineId,
      additionalIncludes,
    ],
    queryFn: async () => {
      return loadDataFromUrl<
        JSONAPI.SingleResourceDoc<string, ContextStateMachineJSONObject>
      >(
        apiContextStateMachinePath(
          contextStateMachineId,
          (
            [
              "possible_states",
              "state_context",
              "current_state",
            ] as CSMIncludes[]
          ).concat(toArray(additionalIncludes)),
        ),
      ).then((result) => {
        return jsonApiSingleResourceToFlatObject(result);
      });
    },
  });
}

export async function loadContextStateMachinesForAsset(
  assetId: IDType,
  additionalIncludes: CSMIncludes[] = [],
): Promise<ContextStateMachineJSONObject[]> {
  const options = {
    asset_id: assetId,
    format: "json",
    _options: true,
    include: additionalIncludes?.join(","),
  };
  const url: string = api_asset_context_state_machines_path(assetId, options);

  const result =
    await loadDataFromUrl<
      JSONAPI.CollectionResourceDoc<string, ContextStateMachineJSONObject>
    >(url);

  return jsonApiResourceCollectionToFlatObjects(result);
}
