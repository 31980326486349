import { RJSFSchema } from "@rjsf/utils";
import { JSONSchema7 } from "json-schema";
import JSON from "json-typescript";
import { cloneDeep, defaultTo, isEmpty, isNil, map, sortBy } from "lodash";
import { ColorUtils } from "../../../utils/colors";
import { redirectTo } from "../../../utils/redirection";
import { assetDashboardPath } from "../../../utils/urls/dashboard_urls";
import {
  WidgetEditorAvailableSensor,
  WidgetEditorSensorType,
} from "./widget_contfig_editor_types";

export function processWidgetSchema(
  sourceSchema: JSONSchema7,
  sensorTypes: WidgetEditorSensorType[],
  availableSensors: WidgetEditorAvailableSensor[],
): RJSFSchema | null {
  if (isNil(sourceSchema)) {
    return null;
  }

  const newSchema = cloneDeep(sourceSchema);
  // support both "definitions" and "$defs" for JSON schema
  const definitions: Record<string, JSONSchema7> =
    (newSchema?.definitions as Record<string, JSONSchema7>) ||
    ((newSchema as any)?.$defs as Record<string, JSONSchema7>);
  // populate "sensor_type" list
  if (
    !isEmpty(sensorTypes) &&
    definitions &&
    (definitions as JSON.Object)?.["sensor_type"]
  ) {
    const sensorTypeDefinition = definitions?.["sensor_type"];
    if (
      sensorTypeDefinition.type === "string" &&
      isEmpty(sensorTypeDefinition.oneOf) &&
      isEmpty(sensorTypeDefinition.enum)
    ) {
      sensorTypeDefinition.oneOf = map(sensorTypes, (st) => ({
        const: st.identifier,
        title: st.name,
      }));
    }
  }

  // populate "sensor_type" list
  if (
    !isEmpty(availableSensors) &&
    definitions &&
    ((definitions as JSON.Object)?.["attribute_key"] ||
      (definitions as JSON.Object)?.["sensor_attribute_key"])
  ) {
    const attributeKeyDefinition = defaultTo(
      definitions?.["attribute_key"],
      definitions?.["sensor_attribute_key"],
    );

    if (
      attributeKeyDefinition.type === "string" &&
      isEmpty(attributeKeyDefinition.enum) &&
      isEmpty(attributeKeyDefinition.oneOf)
    ) {
      attributeKeyDefinition.oneOf = map(
        sortBy(availableSensors, (s) => s.key),
        (st) => ({
          const: st.key,
          title: `${st.key} - ${st.asset_name} - ${st.name} - ${st.sensor_type}`,
        }),
      );
    }
  }
  // populate "color_scheme" list
  if ((definitions as JSON.Object)?.["color_scheme"]) {
    const colorSchemeDefinition = definitions?.["color_scheme"];
    if (
      colorSchemeDefinition.type === "string" &&
      isEmpty(colorSchemeDefinition.enum)
    ) {
      colorSchemeDefinition.oneOf = map(ColorUtils.colorSchemes, (scheme) => ({
        const: scheme.id,
        title: scheme.title,
      }));
    }
  }
  return newSchema;
}

export function widgetNavigateBack() {
  if (!isNil(this.context.referrer)) {
    redirectTo(this.context.referrer);
  } else {
    if (!isNil(this.props.assetId) && !isNil(this.props.dashboardId))
      redirectTo(
        assetDashboardPath(this.props.assetId, this.props.dashboardId),
      );
  }
}
