import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { find, isEmpty, isNil, toString } from "lodash";
import * as React from "react";

import { StateJSONObject } from "../../json_api/state";
import { ContextStateMachine } from "../../models/context_state_machine";
import { State } from "../../models/state";
import { sendData } from "../../utils/jquery_helper";
import * as toast from "../../utils/toasts";

import { update_state_api_context_state_machine_path } from "../../routes";
import { SialogicDialog } from "../common/sialogic_dialog";
import { ContextStateMachineJSONObject } from "../../json_api/context_state_machines";

export interface StateModifierProps {
  id: string;
  contextStateMachine: ContextStateMachineJSONObject;
  states: StateJSONObject[];
  currentState: StateJSONObject;
  onStateSaved: (savedState: StateJSONObject) => void;
}

interface StateModifierState {
  selectedState: StateJSONObject;
  dialogOpen: boolean;

  isSaving: boolean;
}

export class StateModifier extends React.Component<
  StateModifierProps,
  StateModifierState
> {
  constructor(props: StateModifierProps) {
    super(props);
    this.state = {
      dialogOpen: false,
      selectedState: props.currentState,
      isSaving: false,
    };
  }

  render(): React.ReactNode {
    if (isEmpty(this.props.states)) {
      return null;
    }
    return (
      <>
        <Button size="small" onClick={() => this.handleOpenDialog()}>
          {this.state.isSaving ? (
            <CircularProgress />
          ) : (
            I18n.t("frontend.state_modifier.update_state")
          )}
        </Button>
        <SialogicDialog
          disableEscapeKeyDown
          open={this.state.dialogOpen}
          onClose={() => this.handleDialogClose(false)}
          title={I18n.t("frontend.state_modifier.select_state_heading")}
          buttons={
            <>
              <Button
                onClick={() => this.handleDialogClose(false)}
                color="primary"
              >
                {I18n.t("frontend.cancel")}
              </Button>
              <Button
                onClick={() => this.handleDialogClose(true)}
                color="primary"
              >
                {I18n.t("frontend.state_modifier.set_state")}
              </Button>
            </>
          }
        >
          <form>
            <FormControl className="w-100">
              <InputLabel id={this.props.id + "-label"}>
                {I18n.t("frontend.state_modifier.select_state_label")}
              </InputLabel>
              <Select
                className="w-100"
                value={toString(
                  this.state.selectedState?.id ||
                    this.props.contextStateMachine?.current_state?.id,
                )}
                onChange={(event) =>
                  this.handleStateSelect(Number(event.target.value))
                }
              >
                {this.props.states?.map((state) => {
                  return (
                    <MenuItem value={state.id} key={state.id}>
                      {state.name}
                    </MenuItem>
                  );
                })}
                ;
              </Select>
            </FormControl>
          </form>
        </SialogicDialog>
      </>
    );
  }

  handleOpenDialog(): void {
    if (!this.state.isSaving) {
      this.setState({ dialogOpen: true });
    }
  }

  handleDialogClose(saveState: boolean): void {
    if (
      saveState &&
      this.props.contextStateMachine.current_state?.id !=
        this.state.selectedState?.id
    ) {
      this.setState({
        dialogOpen: false,
      });
      this.updateContestStateMachineState(this.state.selectedState);
    } else {
      this.setState({ dialogOpen: false });
    }
  }

  handleStateSelect(stateId: string | number): void {
    const state = find(this.props.states, (s) => s.id == stateId);
    if (!isNil(state)) {
      this.setState({ selectedState: state });
    }
  }

  /** Puts request to backend. Update of state not necessary as the message will arrive through action cable
   *
   *
   * @param {State} newState
   * @returns
   * @memberof StateModifier
   */
  updateContestStateMachineState(newState: State): void {
    if (isNil(newState?.id)) {
      return;
    }

    const url = update_state_api_context_state_machine_path(
      this.props.contextStateMachine.id,
    );
    sendData(url, { current_state_id: newState.id }, "PATCH")
      .then((resp) => {
        this.setState({
          isSaving: false,
        });
        return toast.success(
          I18n.t("frontend.state_modifier.state_changed_success", {
            state_name: newState.name,
          }),
        );
      })
      .catch((err) => {
        this.setState({
          isSaving: false,
        });
        return toast.error(
          I18n.t("frontend.state_modifier.error_on_update"),
          I18n.t("frontend.state_modifier.error_message"),
        );
      });

    this.setState({
      isSaving: true,
    });
  }
}
