import { Device } from "../models/device";
import JSON from "json-typescript";
import * as JSONAPI from "jsonapi-typescript";

import { AssetJSONObject } from "./asset";
import { ProductModelJSONObject } from "./product_model";

export const DEVICE_JSONAPI_RESOURCE_TYPE = "devices";

export type DeviceJsonApiIncludes = "asset" | "product_model" | "organization";

export interface DeviceJsonApiAttributes extends Device {
  pairing_start: string;
  pairing_end: string;
}

export interface DeviceJSONObject extends DeviceJsonApiAttributes, JSON.Object {
  asset?: AssetJSONObject;
  productModel?: ProductModelJSONObject;
}

export type DeviceJSONAPIAttributes =
  JSONAPI.AttributesObject<DeviceJSONObject>;

export const ASSET_JSONAPI_UPDATE_ATTRIBUTES = [
  "name",
  "uid",
  "description",
  "pairing_start",
  "pairing_end",
  "enabled",
  "hw_ver",
  "sw_ver",
  "os_ver",
  "middleware_ver",
];
