import { createQuery } from "react-query-kit";
import {
  filterValueToJsonApiFilterValue,
  jsonApiFilterParamsArgumentsFromFilterObject,
  jsonApiSingleResourceToFlatObject,
  loadItemResultForJsonApiCollectionResourceDoc,
  LoadItemsResult,
} from "../../json_api/jsonapi_tools";
import {
  REPORT_SPEC_INCLUDES,
  REPORT_SPEC_JSONAPI_RESOURCE_TYPE,
  REPORT_SPEC_JSONAPI_UPDATE_ATTRIBUTES,
  ReportSpecJSONAPIAttributes,
  ReportSpecJSONAPIObject,
} from "../../json_api/report_spec";
import { loadDataFromUrl, sendJsonApiData } from "../../utils/jquery_helper";
import { api_report_spec_path, api_report_specs_path } from "../../routes";
import { CollectionResourceDoc, SingleResourceDoc } from "jsonapi-typescript";
import { IDType } from "../../utils/urls/url_utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  addHasOneRelationToJsonApiSubmitData,
  buildJsonApiSubmitData,
} from "../../utils/jsonapi_form_tools";
import { ASSET_TYPE_JSONAPI_RESOURCE_TYPE } from "../../json_api/asset_type";

interface LoadReportSpecVariables {
  assetTypeId: number;
  page: { number: number; size: number };
  includes?: REPORT_SPEC_INCLUDES[];
}

export const useLoadReportSpecs = createQuery<
  LoadItemsResult<ReportSpecJSONAPIObject>,
  LoadReportSpecVariables
>({
  queryKey: [REPORT_SPEC_JSONAPI_RESOURCE_TYPE],
  fetcher: async ({ assetTypeId, page }, { signal }) => {
    const filter = jsonApiFilterParamsArgumentsFromFilterObject({
      asset_type_id: assetTypeId,
    });
    const url = api_report_specs_path({
      _options: true,
      page,
      filter,
    });
    const doc = await loadDataFromUrl<
      CollectionResourceDoc<string, ReportSpecJSONAPIObject>
    >(url, "json", signal);

    return loadItemResultForJsonApiCollectionResourceDoc(doc);
  },
});

export const useLoadReportSpec = createQuery<
  ReportSpecJSONAPIObject,
  { id: IDType; includes?: REPORT_SPEC_INCLUDES[] }
>({
  queryKey: [REPORT_SPEC_JSONAPI_RESOURCE_TYPE],
  fetcher: async ({ id, includes }, { signal }) => {
    const url = api_report_spec_path(id, {
      _options: true,
      include: includes.join(","),
    });

    const doc = await loadDataFromUrl<
      SingleResourceDoc<string, ReportSpecJSONAPIObject>
    >(url, "json", signal);

    return jsonApiSingleResourceToFlatObject(doc);
  },
});

interface UpdateReportSpecOptions {
  reportSpec: ReportSpecJSONAPIObject;
}
export const useUpdateReportSpec = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ reportSpec }: UpdateReportSpecOptions) => {
      const { submitData, mode } = buildJsonApiSubmitData(
        reportSpec,
        REPORT_SPEC_JSONAPI_RESOURCE_TYPE,
        REPORT_SPEC_JSONAPI_UPDATE_ATTRIBUTES,
      );

      if (reportSpec.asset_type_id || reportSpec.asset_type) {
        addHasOneRelationToJsonApiSubmitData(
          submitData,
          "asset_type",
          ASSET_TYPE_JSONAPI_RESOURCE_TYPE,
          reportSpec.asset_type?.id || reportSpec.asset_type_id,
          false,
        );
      }

      if (mode !== "update") {
        throw new Error("Invalid mode - expected update");
      }

      return await sendJsonApiData<
        unknown,
        SingleResourceDoc<string, ReportSpecJSONAPIObject>
      >(api_report_spec_path(reportSpec.id), submitData, "PATCH");
    },

    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [REPORT_SPEC_JSONAPI_RESOURCE_TYPE],
      });
      return res;
    },
  });
};

interface CreateReportSpecOptions {
  reportSpec: ReportSpecJSONAPIObject;
}
export const useCreateReportSpec = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ reportSpec }: CreateReportSpecOptions) => {
      const { submitData, mode } = buildJsonApiSubmitData(
        reportSpec,
        REPORT_SPEC_JSONAPI_RESOURCE_TYPE,
        REPORT_SPEC_JSONAPI_UPDATE_ATTRIBUTES,
      );

      if (reportSpec.asset_type_id || reportSpec.asset_type) {
        addHasOneRelationToJsonApiSubmitData(
          submitData,
          "asset_type",
          ASSET_TYPE_JSONAPI_RESOURCE_TYPE,
          reportSpec.asset_type?.id || reportSpec.asset_type_id,
          false,
        );
      }

      if (mode !== "update") {
        throw new Error("Invalid mode - expected update");
      }

      return await sendJsonApiData<
        unknown,
        SingleResourceDoc<string, ReportSpecJSONAPIObject>
      >(api_report_spec_path(reportSpec.id), submitData, "POST");
    },

    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [REPORT_SPEC_JSONAPI_RESOURCE_TYPE],
      });
      return res;
    },
  });
};
