import { defaultTo, isNil } from "lodash";
import { AssetFeatureType } from "./map.types";
import * as L from "leaflet";
import {
  ColorUtils,
  getColorForSensorValueRange,
  getStateColor,
} from "../utils/colors";
import { MarkerMappingMode } from "../widgets/asset_map_widget.types";
import { getIconForAsset } from "./asset";

export function getMarkerIconForFeature(
  feature: AssetFeatureType,
  markerMappingMode: MarkerMappingMode,
  markerMappingStateContextIdentifier: string,
  sensorKey: string,
  mapIconUrl?: string,
): L.Icon | L.DivIcon {
  let icon: L.Icon | L.DivIcon;

  if (markerMappingMode === "sensor") {
    const iconName = getIconForAsset(
      feature,
      markerMappingMode,
      markerMappingStateContextIdentifier,
      sensorKey,
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    icon = (L.icon as any).glyph({
      prefix: "fa",
      glyph: defaultTo(iconName, null),
      //iconUrl: null,
      glyphColor: getColorForAsset(
        feature,
        markerMappingMode,
        markerMappingStateContextIdentifier,
        sensorKey,
      ),
      // Glyph color. Value can be any string with a CSS color definition.
      glyphSize: "15px",
      // Size of the glyph, in CSS units
    });
  } else if (markerMappingMode === "state") {
    const iconName = getIconForAsset(
      feature,
      markerMappingMode,
      markerMappingStateContextIdentifier,
      sensorKey,
    );
    const color = getColorForAsset(
      feature,
      markerMappingMode,
      markerMappingStateContextIdentifier,
      sensorKey,
    );
    const icons = iconName
      ?.trim()
      ?.split(" ")
      ?.map(
        (i) =>
          `<i class="fa-solid fa-${i} fa-stack-1x fa-inverse" style="bottom: 0.3em;"></i>`,
      )
      .join("");
    icon = new L.DivIcon({
      html: `<span class="fa-stack fa-2x"><i class="fa fa-location-pin fa-stack-2x" style="color: ${color}"></i>${icons ? icons : ""}</span>`,
      iconSize: [60, 48],
      iconAnchor: [30, 48],
      popupAnchor: [0, -48],
      iconUrl: mapIconUrl,

      className: "map-icon",
    });
  } else {
    icon = new L.DivIcon({
      html: `<i class="fas fa-map-marker fa-3x" style="color: ${getColorForAsset(
        feature,
        markerMappingMode,
        markerMappingStateContextIdentifier,
        sensorKey,
      )}"></i>`,
      iconSize: [27, 36],
      iconAnchor: [13.5, 36],
      popupAnchor: [0, -36],
      className: "map-icon",
    });
  }
  return icon;
}

export function getColorForAsset(
  feature: AssetFeatureType,
  markerMappingMode: MarkerMappingMode,
  markerMappingStateContextIdentifier: string,
  markerMappingSensorKey: string,
): string {
  if (
    markerMappingMode === "state" &&
    feature.properties?.states?.[markerMappingStateContextIdentifier]
  ) {
    const state =
      feature.properties?.states?.[markerMappingStateContextIdentifier];
    return getStateColor(state);
  } else if (
    markerMappingMode === "sensor" &&
    !isNil(feature.properties?.sensors?.[markerMappingSensorKey])
  ) {
    const sensor = feature.properties?.sensors?.[markerMappingSensorKey];
    if (!isNil(sensor?.range)) {
      return getColorForSensorValueRange(sensor.range);
    } else {
      return ColorUtils.getRedGreenColor(sensor.value as number);
    }
  } else {
    return "#337ab7";
  }
}
