import { createQuery } from "react-query-kit";
import {
  ExternalReferenceJsonApiIncludes,
  ExternalReferenceJSONObject,
} from "../json_api/external_reference";
import { EXTERNAL_REFERENCE_TARGET_JSONAPI_RESOURCE_TYPE } from "../json_api/external_reference_target";
import { ExternalReferenceItemType } from "../models/external_reference";
import { IDType } from "../utils/urls/url_utils";
import { jsonApiSingleResourceToFlatObject } from "../json_api/jsonapi_tools";
import { SingleResourceDoc } from "jsonapi-typescript";
import { loadDataFromUrl } from "../utils/jquery_helper";
import {
  api_asset_external_reference_path,
  api_organization_external_reference_path,
  api_product_model_external_reference_path,
} from "../routes";

interface LoadExternalReferenceVariables {
  id: IDType;
  organizationId: IDType;
  referencedItemType: ExternalReferenceItemType;
  referencedItemId: IDType;
  include?: ExternalReferenceJsonApiIncludes[];
}

export const loadExternalReference = createQuery<
  ExternalReferenceJSONObject,
  LoadExternalReferenceVariables
>({
  queryKey: [EXTERNAL_REFERENCE_TARGET_JSONAPI_RESOURCE_TYPE],
  fetcher: async (
    { referencedItemType, include, id, referencedItemId, organizationId },
    { signal },
  ) => {
    const pathFun: (
      id1: IDType,
      id2: IDType,
      options: Record<string, any>,
    ) => string =
      referencedItemType == "Asset"
        ? api_asset_external_reference_path
        : referencedItemType == "ProductModel"
          ? api_product_model_external_reference_path
          : api_organization_external_reference_path;
    const urlOptions: Record<string, any> = {
      id,
      locale: I18n.locale,
      format: "json",
      include: include?.join(","),
      _options: true,
    };

    urlOptions[
      referencedItemType == "Organization"
        ? "organization_id"
        : referencedItemType == "ProductModel"
          ? "product_model_id"
          : "asset_id"
    ] = referencedItemId;
    const url = pathFun(referencedItemId, id, urlOptions);
    const doc = await loadDataFromUrl<
      SingleResourceDoc<string, ExternalReferenceJSONObject>
    >(url, "json", signal);
    return jsonApiSingleResourceToFlatObject<ExternalReferenceJSONObject>(doc);
  },
});
