import { find, isEmpty, max, min, sortBy } from "lodash";
import * as React from "react";
import { MeasurementJSONAPIAttributesObject } from "../../../json_api/measurement";

import { Box, Typography } from "@mui/material";
import { DIAGRAM_FILL_COLORS } from "../../../charting/diagram_constants";
import { xValueForMeasurementDef } from "../../../charting/measurements/measurement_chart_data_creator";
import { createReferenceLineData } from "../../../charting/measurements/reference_lines";
import { PlotlyBarChart } from "../../../charting/plotly_bar_chart";
import { PlotlyPlot } from "../../../charting/plotly_package";
import { MeasurementTypeDiagramType } from "../../../models/measurement_type";
import { MeasurementValueDefinitionAttributes } from "../../../models/measurement_value_definition";
import { ReferenceMeasurementValue } from "../utils/measurement_reference_eval";

interface MeasurementGraphProperties {
  measurement: MeasurementJSONAPIAttributesObject;
  measurementValueDefinitions?: MeasurementValueDefinitionAttributes[];
  referenceMeasurementValues?: ReferenceMeasurementValue[];
  diagramType?: MeasurementTypeDiagramType;
}

const commonLayout: Partial<Plotly.Layout> = {
  autosize: true,
  font: PlotlyBarChart.font,

  margin: {
    l: 20,
    r: 20,
    b: 30,
    t: 30,
  },
};

function radarDataForMeasurement(
  measurement: MeasurementJSONAPIAttributesObject,
  valueDefinitions: MeasurementValueDefinitionAttributes[],
): { data: Plotly.Data[]; layout: Partial<Plotly.Layout> } {
  const sortedDefs = sortBy(valueDefinitions, "position");
  const r: number[] = [];
  const theta: string[] = [];

  sortedDefs.forEach((def) => {
    const value = find(
      measurement.measurement_values,
      (v) => v.measurement_value_definition_id == def.id,
    );
    if (value) {
      r.push(value.value);
      if (def.unit) {
        theta.push(def.title + " " + def.unit);
      } else {
        theta.push(def.title);
      }
    }
  });
  if (isEmpty(r)) {
    return { data: null, layout: null };
  }
  let theMin = min(r);
  if (theMin > 0) {
    theMin = 0;
  }
  return {
    data: [
      {
        type: "scatterpolar",
        r,
        theta,
        fill: "toself",
        connectgaps: true,
      },
    ] as Plotly.Data[],
    layout: {
      ...commonLayout,

      polar: {
        radialaxis: {
          visible: true,

          range: [, max(r) * 1.1],
        },
      },
    } as Partial<Plotly.Layout>,
  };
}

function lineDataForMeasurement(
  measurement: MeasurementJSONAPIAttributesObject,
  valueDefinitions: MeasurementValueDefinitionAttributes[],
  referenceMeasurementValues: ReferenceMeasurementValue[],
): { data: Plotly.Data[]; layout: Partial<Plotly.Layout> } {
  const sortedDefs = sortBy(valueDefinitions, "position");
  const y: number[] = [];
  const x: string[] = [];

  sortedDefs.forEach((def) => {
    const value = find(
      measurement.measurement_values,
      (v) => v.measurement_value_definition_id == def.id,
    );
    if (value) {
      y.push(value.value);
    }

    x.push(xValueForMeasurementDef(def));
  });
  if (isEmpty(y)) {
    return { data: null, layout: null };
  }
  let theMin = min(y);
  if (theMin > 0) {
    theMin = 0;
  }

  const data = [
    {
      type: "scatter",

      x: x,
      y: y,
      fill: "none",
      marker: {
        color: DIAGRAM_FILL_COLORS[0],
        width: 6,
      },

      line: {
        width: 1,
        dash: "dash",
        color: "rgb(0, 0, 0)",
      },
      mode: "lines+markers",
      showlegend: true,
    },
  ] as Plotly.Data[];
  if (referenceMeasurementValues && referenceMeasurementValues.length !== 0) {
    const { refLine, refLineLow, refLineHigh } = createReferenceLineData(
      referenceMeasurementValues,
    );
    data.push(refLineLow, refLine, refLineHigh);
  }

  return {
    data,
    layout: {
      ...commonLayout,
      yaxis: {
        autorange: true,
        autorangeoptions: {
          include: 0,
        },
      },
      "xaxis.autorange": true,

      showlegend: false,
      margin: {
        l: 30,
        r: 10,
        t: 20,
        b: 35,
      },
    } as Partial<Plotly.Layout>,
  };
}

function barDataForMeasurement(
  measurement: MeasurementJSONAPIAttributesObject,
  valueDefinitions: MeasurementValueDefinitionAttributes[],
): { data: Plotly.Data[]; layout: Partial<Plotly.Layout> } {
  const sortedDefs = sortBy(valueDefinitions, "position");
  const dataPoint: number[] = [];
  const labels: string[] = [];

  sortedDefs.forEach((def) => {
    const value = find(
      measurement.measurement_values,
      (v) => v.measurement_value_definition_id == def.id,
    );
    if (value) {
      dataPoint.push(value.value);
      if (def.unit) {
        labels.push(def.title + " " + def.unit);
      } else {
        labels.push(def.title);
      }
    }
  });
  if (isEmpty(dataPoint)) {
    return { data: null, layout: null };
  }
  let theMin = min(dataPoint);
  if (theMin > 0) {
    theMin = 0;
  }
  return {
    data: [
      {
        type: "bar",
        x: labels,
        y: dataPoint,
        text: dataPoint.map(String),
        marker: {
          color: DIAGRAM_FILL_COLORS[0],
          line: {
            width: 1,
            color: "#000000",
          },
        },
      },
    ] as Plotly.Data[],
    layout: {
      ...commonLayout,
      "xaxis.autorange": true,
      "yaxis.autorange": true,
    },
  };
}

function pieDataForMeasurement(
  measurement: MeasurementJSONAPIAttributesObject,

  valueDefinitions: MeasurementValueDefinitionAttributes[],
): { data: Plotly.Data[]; layout: Partial<Plotly.Layout> } {
  const sortedDefs = sortBy(valueDefinitions, "position");
  const dataPoint: number[] = [];
  const labels: string[] = [];

  sortedDefs.forEach((def) => {
    const value = find(
      measurement.measurement_values,
      (v) => v.measurement_value_definition_id == def.id,
    );
    if (value) {
      dataPoint.push(value.value);
      if (def.unit) {
        labels.push(def.title + " " + def.unit);
      } else {
        labels.push(def.title);
      }
    }
  });
  if (isEmpty(dataPoint)) {
    return { data: null, layout: null };
  }
  let theMin = min(dataPoint);
  if (theMin > 0) {
    theMin = 0;
  }
  return {
    data: [
      {
        values: dataPoint,
        labels,

        type: "pie",
        textinfo: "label+percent",
        textposition: "outside",
        hoverinfo: "label+percent",
        automargin: true,
        marker: {
          colors: dataPoint.map(
            (v, i) => DIAGRAM_FILL_COLORS[i % DIAGRAM_FILL_COLORS.length],
          ),
          line: {
            color: "#000000",
            width: 1,
          },
        },
      },
    ] as Plotly.Data[],
    layout: {
      ...commonLayout,
      "xaxis.autorange": true,
      "yaxis.autorange": true,
      showlegend: true,
    },
  };
}

export const MeasurementGraph: React.FC<MeasurementGraphProperties> = ({
  measurement,
  measurementValueDefinitions: valueDefinitionsFromProps,
  referenceMeasurementValues,
  diagramType,
}) => {
  const { data, layout, error } = React.useMemo((): {
    data: Plotly.Data[];
    layout: Partial<Plotly.Layout>;
    error?: Error;
  } => {
    const type = diagramType || measurement.measurement_type?.diagram_type;
    const valueDefinitions =
      measurement.measurement_value_definitions || valueDefinitionsFromProps;
    try {
      switch (type) {
        case "bar":
          return barDataForMeasurement(measurement, valueDefinitions);
        case "line":
          return lineDataForMeasurement(
            measurement,
            valueDefinitions,
            referenceMeasurementValues,
          );
        case "radar":
          return radarDataForMeasurement(measurement, valueDefinitions);
        case "pie":
          return pieDataForMeasurement(measurement, valueDefinitions);
        default:
          return radarDataForMeasurement(measurement, valueDefinitions);
      }
    } catch (error) {
      return { data: null, layout: null, error };
    }
  }, [
    measurement,
    diagramType,
    valueDefinitionsFromProps,
    referenceMeasurementValues,
  ]);

  const plotId = React.useId();

  if (error) {
    return (
      <Box>
        <Typography variant="h6">{I18n.t("frontend.error")}</Typography>
        <Typography>{error.message}</Typography>
      </Box>
    );
  }

  return data ? (
    <PlotlyPlot
      data={data}
      layout={layout}
      divId={`measurement-graph-plot-${plotId}`}
      config={{ responsive: true, locale: I18n.locale }}
      style={{ minHeight: 300, width: "100%", breakInside: "avoid" }}
    />
  ) : null;
};
