import { Box, Paper, Typography } from "@mui/material";
import { isNil } from "lodash";
import * as React from "react";
import { EventPatternJSONObject } from "../../json_api/event_pattern";
import { IDType } from "../../utils/urls/url_utils";

import { Add, KeyboardArrowLeft } from "@mui/icons-material";
import { useConfirm } from "material-ui-confirm";
import { AssetEventDetails } from "../../components/asset_events/asset_event_details";
import { AssetEventList } from "../../components/asset_events/asset_event_list";
import { AppContext } from "../../components/common/app_context/app_context_provider";
import { FixedBottomArea } from "../../components/common/fixed_bottom_area";
import { FloatingButtons } from "../../components/common/floating_buttons";
import { SialogicDialog } from "../../components/common/sialogic_dialog";
import { useDeleteEventPattern } from "../../queries/event_pattern_data";
import { EventPatternForm } from "../../components/event_patterns/event_pattern_form";
import {
  EventPatternList,
  EventPatternListItemAction,
} from "../../components/event_patterns/event_pattern_list";
import { ResourcePermission } from "../../models/resource_permission";
import { redirectTo } from "../../utils/redirection";
import { error, success } from "../../utils/toasts";

interface EventPatternIndexProps {
  sensorId: IDType;
  permissions?: ResourcePermission;
}

export const EventPatternIndexPage: React.FunctionComponent<
  EventPatternIndexProps
> = ({ sensorId, permissions }) => {
  const [selectedPattern, setEditPattern] =
    React.useState<EventPatternJSONObject>();
  const [editMode, setEditMode] = React.useState<
    EventPatternListItemAction | "new"
  >(null);

  const [sensorIdNewPattern, setSensorForNewPattern] =
    React.useState<IDType>(null);
  const appCtx = React.useContext(AppContext);
  const confirm = useConfirm();

  const {
    mutateAsync: deleteEventPattern,
    isPending: deletePending,
    isSuccess: deleteSuccess,
    error: deleteError,
  } = useDeleteEventPattern();

  React.useEffect(() => {
    if (deleteSuccess) {
      void success(
        I18n.t("frontend.event_patterns.index.delete_success"),
        I18n.t("frontend.event_patterns.index.delete_success"),
      );
    }
  }, [deleteSuccess, deleteError]);

  React.useEffect(() => {
    if (deleteError) {
      void error(
        I18n.t("frontend.event_patterns.index.delete_error"),
        deleteError.message,
      );
    }
  }, [deleteError]);

  const requestItemAction = React.useCallback(
    (e: EventPatternJSONObject, mode: EventPatternListItemAction) => {
      if (mode === "delete") {
        confirm({
          allowClose: true,
          description: I18n.t(
            "frontend.event_patterns.index.delete_event_pattern_confirm",
          ),
        })
          .then(() => {
            deleteEventPattern(e.id);
          })
          .catch(() => {
            // do nothing if not confirmed
          });
      } else {
        setEditMode(mode);
        setEditPattern(e);
      }
    },
    [deleteEventPattern],
  );
  return (
    <Paper>
      <Box padding={2}>
        <Typography variant="h6">
          {I18n.t("activerecord.models.event_patterns/event_pattern.other")}
        </Typography>
        <EventPatternList
          sensorId={sensorId}
          requestItemAction={requestItemAction}
        />
      </Box>

      {selectedPattern || sensorIdNewPattern || editMode ? (
        <SialogicDialog
          open={!isNil(selectedPattern) || !isNil(sensorIdNewPattern)}
          onClose={() => {
            setSensorForNewPattern(null);
            setEditPattern(null);
          }}
          title={
            editMode === "new"
              ? I18n.t("frontend.event_patterns.index.new_event_pattern")
              : editMode === "edit"
                ? I18n.t("frontend.event_patterns.index.edit_event_pattern", {
                    name: selectedPattern?.name,
                  })
                : editMode === "show"
                  ? I18n.t("frontend.event_patterns.index.show_event_pattern", {
                      name: selectedPattern?.name,
                    })
                  : editMode === "showLastEvent"
                    ? I18n.t(
                        "frontend.event_patterns.index.last_event_details",
                        { name: selectedPattern?.name },
                      )
                    : editMode === "eventList"
                      ? I18n.t("frontend.event_patterns.index.show_events", {
                          name: selectedPattern?.name,
                        })
                      : null
          }
          maxWidth="xl"
        >
          {editMode === "showLastEvent" && selectedPattern?.last_event && (
            <AssetEventDetails assetEventId={selectedPattern.last_event?.id} />
          )}
          {editMode === "eventList" && selectedPattern && (
            <Box minWidth={500}>
              <AssetEventList
                eventPatternId={selectedPattern.id}
                displayFilter={{
                  search: false,
                  from: true,
                  to: true,
                  eventPatternId: false,
                  severity_level: false,
                  assetIds: false,
                  typeId: false,
                }}
              />
            </Box>
          )}
          {(editMode === "new" ||
            editMode === "edit" ||
            editMode == "show") && (
            <EventPatternForm
              eventPatternId={selectedPattern?.id}
              sensorId={sensorIdNewPattern}
              assetId={selectedPattern?.asset_id}
              readonly={editMode !== "edit" && editMode !== "new"}
              onSave={(pattern) => {
                setEditPattern(null);
                setSensorForNewPattern(null);
              }}
              onCancel={() => {
                setEditPattern(null);
              }}
            />
          )}
        </SialogicDialog>
      ) : null}
      <FixedBottomArea>
        <FloatingButtons
          showScrollToTopBtn
          cancelIcon={<KeyboardArrowLeft />}
          onCancel={() => {
            redirectTo("back");
          }}
          submitBtnIcon={<Add />}
          submitBtnColor="primary"
          onSubmit={
            permissions?.create
              ? () => {
                  setEditPattern(null);
                  setSensorForNewPattern(sensorId);
                  setEditMode("new");
                }
              : null
          }
          saveTitle={I18n.t("frontend.event_patterns.index.add_event_pattern")}
        />
      </FixedBottomArea>
    </Paper>
  );
};
