import * as JSONAPI from "jsonapi-typescript";
import { isEmpty, isNil } from "lodash";
import { createQuery } from "react-query-kit";
import {
  loadItemResultForJsonApiCollectionResourceDoc,
  LoadItemsResult,
} from "../../json_api/jsonapi_tools";
import {
  ORGANIZATION_JSONAPI_RESOURCE_TYPE,
  OrganizationJSONAPIAttributes,
} from "../../json_api/organization";
import { api_organizations_path } from "../../routes";
import { loadDataFromUrl } from "../../utils/jquery_helper";
import { OrganizationIncludes } from "../../utils/urls";
import { applyParamsToBaseUrl } from "../../utils/urls/url_utils";
import { PageSettings } from "../common/page_size";
import { SialogicQueryClient } from "../common/sialogic_query_client";

interface OrganizationsQueryVariables {
  pageSettings: PageSettings;
  searchTerm?: string;
  baseUrl?: string;
}

export const useLoadOrganizationsQuery = createQuery<
  LoadItemsResult<OrganizationJSONAPIAttributes>,
  OrganizationsQueryVariables
>({
  queryKey: [ORGANIZATION_JSONAPI_RESOURCE_TYPE],
  fetcher: async ({ pageSettings, searchTerm, baseUrl }, { signal }) => {
    const path = getUrl(baseUrl, pageSettings, searchTerm);
    const jsonApiResponse = await loadDataFromUrl<
      JSONAPI.CollectionResourceDoc<string, OrganizationJSONAPIAttributes>
    >(path, "json", signal);
    return loadItemResultForJsonApiCollectionResourceDoc(jsonApiResponse);
  },
});

export function useReloadOrganizations() {
  return () => {
    SialogicQueryClient.invalidateQueries({
      queryKey: [ORGANIZATION_JSONAPI_RESOURCE_TYPE],
    });
  };
}
function getUrl(
  organizationsBaseUrl: string,
  pageSettings: PageSettings,
  searchTerm: string,
) {
  const includes: OrganizationIncludes[] = [];
  let path: string;
  if (isNil(organizationsBaseUrl)) {
    const options = {
      _options: true,
      page: {
        number: pageSettings.number || 1,
        size: pageSettings.size || 20,
      },
    };
    if (!isEmpty(includes)) {
      (options as any)["include"] = includes.join(",");
    }
    api_organizations_path(options);
  } else {
    const params: [string, string][] = [];
    if (searchTerm) {
      params.push(["filter[search]", searchTerm]);
    }
    path = applyParamsToBaseUrl(
      organizationsBaseUrl,
      pageSettings.number,
      pageSettings.size,
      includes,
      params,
    );
  }

  return path;
}
