import geojson from "geojson";

import L from "leaflet";
import "leaflet.markercluster";
import { isEmpty, isNil, toInteger } from "lodash";
import { WidgetController } from "../controller/widget_controller";
import { getAssetMarker } from "../map/asset";
import { addFeaturePopup } from "../map/feature_popup";
import { getFeatureIdFromSensorFeature } from "../map/geojson_tools";
import { addLocationDataFeaturePopup } from "../map/location_data_popup";
import { AssetFeatureType, LocationFeatureProperties } from "../map/map.types";
import { ColorUtils } from "../utils/colors";
import { loadDataFromUrl } from "../utils/jquery_helper";
import { assetTypeLocationsPath, assetsLocationsPath } from "../utils/urls";
import { IDType } from "../utils/urls/url_utils";
import { SialogicQueryClient } from "../components/common/sialogic_query_client";
import { AssetMapWidgetProps } from "../components/widgets/asset_map_widget.types";

export const loadAssetLocations = (
  props: AssetMapWidgetProps,
  assetIdMarkerMap: Map<number, L.Marker>,
  assetSensorIdMarkerMap: Map<number, L.Marker>,
  assetContextStateMachineIdMarkerMap: Map<number, L.Marker>,
  assetLayer: L.FeatureGroup,
  map: L.Map,
  handleMarkerClick: (
    marker: L.Marker,
    feature: AssetFeatureType,
  ) => void = defaultHandleMarkerClick,
): Promise<{
  sensorIds: number[];
  csmIds: number[];
  assetLayer: L.FeatureGroup;
}> => {
  if (isEmpty(props.assetIds)) {
    return;
  }
  const csmIds: number[] = [];
  const sensorIds: number[] = [];
  let queryKey: [
    "assetTypeLocations" | "assetLocations",
    {
      assetIds?: IDType[];
      assetTypeId?: IDType;
      sensorTypes?: string[];
      assetStates?: boolean;
    },
  ];
  if (props.assetTypeId) {
    queryKey = [
      "assetTypeLocations",
      {
        assetTypeId: props.assetTypeId,
        sensorTypes: props.loadSensorWithTypes,
        assetStates: props.loadAssetStates,
      },
    ];
  } else if (!isEmpty(props.assetIds)) {
    queryKey = [
      "assetLocations",
      {
        assetIds: props.assetIds,
        sensorTypes: props.loadSensorWithTypes,
        assetStates: props.loadAssetStates,
      },
    ];
  }
  assetIdMarkerMap.clear();
  assetSensorIdMarkerMap.clear();
  assetContextStateMachineIdMarkerMap.clear();
  return SialogicQueryClient.fetchQuery({
    queryKey,
    queryFn: ({ queryKey }) => {
      const url =
        queryKey[0] == "assetLocations"
          ? assetsLocationsPath(
              queryKey[1].assetIds,
              queryKey[1].sensorTypes,
              queryKey[1].assetStates,
            )
          : assetTypeLocationsPath(
              queryKey[1].assetTypeId,
              queryKey[1].sensorTypes,
              queryKey[1].assetStates,
            );
      return loadDataFromUrl<
        geojson.FeatureCollection<geojson.Point, LocationFeatureProperties>
      >(url);
    },
  }).then((data) => {
    if (isNil(map)) return;

    if (!isNil(assetLayer)) {
      assetLayer.remove();
    }

    const assetLayerInstance: L.FeatureGroup = props.enableAssetClustering
      ? (L as any).markerClusterGroup()
      : L.featureGroup();
    const assetMarkers = L.geoJSON(data, {
      pointToLayer: (feature) => {
        const marker = getAssetMarker(
          feature,
          props.markerMappingMode,
          props.markerMappingStateContextIdentifier,
          props.markerMappingSensorKey,
          () => handleMarkerClick(marker, feature),
        );
        assetIdMarkerMap.set(toInteger(feature.id), marker);
        const sensorId = getSensorIdToUpdateForFeature(feature, props);
        const contextStateMachineId =
          getContextStateMachineIdToUpdateForFeature(feature, props);
        if (contextStateMachineId) {
          csmIds.push(contextStateMachineId);
          assetContextStateMachineIdMarkerMap.set(
            contextStateMachineId,
            marker,
          );
        }
        if (sensorId) {
          sensorIds.push(sensorId);
          assetSensorIdMarkerMap.set(sensorId, marker);
        }

        return marker;
      },
      onEachFeature: addFeaturePopup,
    });

    assetLayerInstance.addLayer(assetMarkers);
    assetLayer = assetLayerInstance;
    assetLayer.addTo(map);

    return { sensorIds, csmIds, assetLayer };
  });
};

export const loadLocationSensorData = (
  props: AssetMapWidgetProps,
  sensorsLayer: L.Realtime,
  map: L.Map,
): Promise<any> => {
  if (isEmpty(props.sensorIds)) {
    return;
  }
  const lineColors: string[] = ColorUtils.getColorsRgba(1.0);
  const markerColors: string[] = ColorUtils.getColorsRgba(0.8);

  const sensorIdToColors: {
    [sensorId: string]: { lineColor: string; markerColor: string };
  } = {};
  props.sensorIds.forEach((sensorId, index) => {
    sensorIdToColors[sensorId] = {
      lineColor: lineColors[index % lineColors.length],
      markerColor: markerColors[index % markerColors.length],
    };
  });

  if (sensorsLayer) {
    sensorsLayer.remove();
  }
  sensorsLayer = L.realtime("", {
    start: false,
    getFeatureId: getFeatureIdFromSensorFeature,
    onEachFeature: addLocationDataFeaturePopup,
    style: function (feature: GeoJSON.Feature) {
      return {
        color:
          sensorIdToColors[feature.properties.sensor_id as string].lineColor,
        weight: 2,
      };
    },
    pointToLayer: function (feature: GeoJSON.Feature, latlng) {
      const color =
        sensorIdToColors[feature.properties.sensor_id as string].markerColor;
      if (feature.properties.isLastFeature) {
        return L.marker(latlng);
      } else {
        return L.circleMarker(latlng, {
          radius: 4,
          fillColor: color,
          color: color,
          weight: 1,
          fillOpacity: 0.8,
          opacity: 0.8,
        });
      }
    },
  });
  sensorsLayer.addTo(map);
};

export const getContextStateMachineIdToUpdateForFeature = (
  feature: AssetFeatureType,
  props: AssetMapWidgetProps,
): number => {
  if (
    props.markerMappingMode === "state" &&
    !isEmpty(props.markerMappingStateContextIdentifier)
  ) {
    const contextStateMachineId =
      feature.properties.states?.[props.markerMappingStateContextIdentifier]
        ?.csm_id;
    if (!isNil(contextStateMachineId)) {
      return contextStateMachineId;
    }
    return null;
  } else {
    return null;
  }
};

export const getSensorIdToUpdateForFeature = (
  feature: AssetFeatureType,
  props: AssetMapWidgetProps,
): number => {
  if (props.markerMappingMode === "sensor") {
    const sensorId =
      feature.properties?.sensors?.[props.markerMappingSensorKey]?.sensor_id;
    if (!isNil(sensorId)) {
      return sensorId;
    }
    return null;
  } else {
    return null;
  }
};

export const defaultHandleMarkerClick = (
  marker: L.Marker,
  feature: AssetFeatureType,
) => {
  void WidgetController.getInstance().handleItemSelection({
    itemType: "Asset",
    itemId: toInteger(feature.id),
    item: feature,
  });
};
