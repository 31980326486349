import * as React from "react";

import { TranslateOptions } from "i18n-js";
import {
  MaterialUiDatePicker,
  MaterialUiDatePickerProps,
} from "../date_picker";

import ReactRRuleGenerator from "../../../3rd_party/react_rrule_generator/lib";

export interface RRuleFormProps {
  onChange: (rrule: string) => void;
  rrule: string;
}

// Component to wrap the MaterialUiDatePicker and convert the on change argument to the one expected by the ReactRuleGenerator
const ReactRRuleDatePicker: React.FunctionComponent<
  Exclude<MaterialUiDatePickerProps, "onChange"> & {
    onChange: (event: { target: { value: string } }) => void;
  }
> = ({ onChange, ...props }) => {
  return (
    <MaterialUiDatePicker
      {...props}
      dateFormat={props.dateFormat}
      type="datetime"
      onChange={(date) => {
        onChange({
          target: { value: date?.format(props.dateFormat) },
        });
      }}
    />
  );
};

export const RRuleForm: React.FunctionComponent<RRuleFormProps> = ({
  onChange,
  rrule,
}) => {
  return (
    <ReactRRuleGenerator
      onChange={(rrule: string) => {
        onChange(rrule);
      }}
      value={rrule}
      config={{
        hideStart: false,
        hideEnd: true,
      }}
      calendarComponent={ReactRRuleDatePicker}
      translations={(key: string, replacements: TranslateOptions) =>
        I18n.t(`frontend.rrule_generator.${key}`, replacements)
      }
      dateTimeFormat={I18n.t("frontend.rrule_generator.date_time_format")}
    />
  );
};
