import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CollectionResourceDoc, SingleResourceDoc } from "jsonapi-typescript";
import { isEmpty, isNil } from "lodash";
import { createQuery } from "react-query-kit";
import { ASSET_JSONAPI_RESOURCE_TYPE } from "../json_api/asset";
import { DEVICE_JSONAPI_RESOURCE_TYPE } from "../json_api/device";
import {
  jsonApiFilterParamsArgumentsFromFilterObject,
  jsonApiSingleResourceToFlatObject,
  loadItemResultForJsonApiCollectionResourceDoc,
  LoadItemsResult,
} from "../json_api/jsonapi_tools";
import {
  MQTT_USER_JSON_API_CREATE_ATTRIBUTES,
  MQTT_USER_JSON_API_TYPE,
  MQTT_USER_JSON_API_UPDATE_ATTRIBUTES,
  MqttUserIncludes,
  MqttUserJSONObject,
} from "../json_api/mqtt_user";
import { USER_JSON_API_TYPE } from "../json_api/user";
import { api_mqtt_user_path, api_mqtt_users_path } from "../routes";
import { loadDataFromUrl, sendJsonApiData } from "../utils/jquery_helper";
import {
  addHasOneRelationToJsonApiSubmitData,
  buildJsonApiSubmitData,
} from "../utils/jsonapi_form_tools";
import { IDType } from "../utils/urls/url_utils";
import { PageSettings } from "../components/common/page_size";

export const useLoadMqttUser = createQuery<
  MqttUserJSONObject,
  { id: IDType; includes?: MqttUserIncludes[] }
>({
  queryKey: [MQTT_USER_JSON_API_TYPE],
  fetcher: async ({ id, includes }) => {
    const response = await loadDataFromUrl<
      SingleResourceDoc<string, MqttUserJSONObject>
    >(api_mqtt_user_path(id, { include: includes?.join(",") }));

    return jsonApiSingleResourceToFlatObject(response);
  },
});

export interface MqttUsersFilter {
  assetId?: IDType | IDType[];
  deviceId?: IDType | IDType[];
  userId?: IDType | IDType[];
  search?: string;
  organizationId: IDType;
}
export const useLoadMqttUsers = createQuery<
  LoadItemsResult<MqttUserJSONObject>,
  MqttUsersFilter & {
    pageSettings: PageSettings;
    sort?: string;
    includes?: MqttUserIncludes[];
  }
>({
  queryKey: [MQTT_USER_JSON_API_TYPE],
  fetcher: async ({
    organizationId,
    assetId,
    deviceId,
    userId,
    sort,
    search,
    pageSettings,
    includes,
  }) => {
    const filter = {};

    if (assetId) {
      (filter as any)["asset_id"] = assetId;
    }
    if (deviceId) {
      (filter as any)["device_id"] = deviceId;
    }
    if (userId) {
      (filter as any)["user_id"] = userId;
    }

    if (!isEmpty(search)) {
      (filter as any)["search"] = search;
    }
    if (organizationId) {
      (filter as any)["organization_id"] = organizationId;
    }

    pageSettings ||= { number: 1, size: 10 };
    pageSettings.number ||= 1;

    let options: Record<string, string | number | boolean | PageSettings>;

    if (!isEmpty(filter)) {
      options = jsonApiFilterParamsArgumentsFromFilterObject(filter);
    } else {
      options = {};
    }
    options["_options"] = true;
    options["page"] = pageSettings;
    options["sort"] = sort || "username";
    options["includes"] = includes?.join(",");

    const response = await loadDataFromUrl<
      CollectionResourceDoc<string, MqttUserJSONObject>
    >(api_mqtt_users_path(options as any));

    return loadItemResultForJsonApiCollectionResourceDoc(response);
  },
});

export const useDeleteMqttUser = () => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      mutationFn: async (id: IDType) => {
        return await sendJsonApiData<unknown, MqttUserJSONObject>(
          api_mqtt_user_path(id),
          null,
          "DELETE",
        );
      },
      onSuccess: (data, sourceData) => {
        queryClient.invalidateQueries({
          queryKey: [MQTT_USER_JSON_API_TYPE],
        });
        // merge the sensor so we do not loose the included items
        return { ...data };
      },
    },
    queryClient,
  );
};

export const useCreateMqttUser = () => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      mutationFn: async (options: {
        mqttUser: MqttUserJSONObject;
        rels?: { deviceId?: IDType; assetId?: IDType; userId?: IDType };
      }) => {
        const mode = isNil(options.mqttUser.id) ? "create" : "update";
        const submitData = buildJsonApiSubmitData(
          options.mqttUser,
          MQTT_USER_JSON_API_TYPE,
          MQTT_USER_JSON_API_CREATE_ATTRIBUTES,
        );

        if (options.rels?.deviceId) {
          addHasOneRelationToJsonApiSubmitData(
            submitData.submitData,
            "device",
            DEVICE_JSONAPI_RESOURCE_TYPE,
            options.rels.deviceId,
            false,
          );
        }

        if (options.rels?.assetId) {
          addHasOneRelationToJsonApiSubmitData(
            submitData.submitData,
            "asset",
            ASSET_JSONAPI_RESOURCE_TYPE,
            options.rels.assetId,
            false,
          );
        }

        if (options.rels?.userId) {
          addHasOneRelationToJsonApiSubmitData(
            submitData.submitData,
            "user",
            USER_JSON_API_TYPE,
            options.rels.userId,
            false,
          );
        }

        const url = api_mqtt_users_path();
        const response = await sendJsonApiData<
          unknown,
          SingleResourceDoc<string, MqttUserJSONObject>
        >(url, submitData.submitData, "POST");
        return jsonApiSingleResourceToFlatObject(response);
      },
      onSuccess: (data, sourceData) => {
        queryClient.invalidateQueries({
          queryKey: [MQTT_USER_JSON_API_TYPE],
        });
        // merge the sensor so we do not loose the included items
        return { ...data };
      },
    },
    queryClient,
  );
};

export const useUpdateMqttUser = () => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      mutationFn: async (
        mqttUser: MqttUserJSONObject,
        rels?: { deviceId?: IDType; assetId?: IDType; userId?: IDType },
      ) => {
        const mode = "update";
        const submitData = buildJsonApiSubmitData(
          mqttUser,
          MQTT_USER_JSON_API_TYPE,
          MQTT_USER_JSON_API_UPDATE_ATTRIBUTES,
        );

        if (rels?.deviceId) {
          addHasOneRelationToJsonApiSubmitData(
            submitData.submitData,
            "organization",
            DEVICE_JSONAPI_RESOURCE_TYPE,
            rels.deviceId,
            false,
          );
        }

        if (rels?.assetId) {
          addHasOneRelationToJsonApiSubmitData(
            submitData.submitData,
            "asset",
            ASSET_JSONAPI_RESOURCE_TYPE,
            rels.assetId,
            false,
          );
        }

        if (rels?.userId) {
          addHasOneRelationToJsonApiSubmitData(
            submitData.submitData,
            "user",
            USER_JSON_API_TYPE,
            rels.userId,
            false,
          );
        }
        const url = api_mqtt_user_path(mqttUser.id);
        const response = await sendJsonApiData<
          unknown,
          SingleResourceDoc<string, MqttUserJSONObject>
        >(url, submitData.submitData, "PATCH");
        return jsonApiSingleResourceToFlatObject(response);
      },
      onSuccess: (data, sourceData) => {
        queryClient.invalidateQueries({
          queryKey: [MQTT_USER_JSON_API_TYPE],
        });
        // merge the sensor so we do not loose the included items
        return { ...data };
      },
    },
    queryClient,
  );
};
