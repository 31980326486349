import { flatten, isNil, toString, trim, values } from "lodash";
import { MeasurementCategory } from "./measurement_category";

export interface MeasurementValueDefinitionAttributes {
  id?: string | number;
  is_interval: boolean;
  min?: number;
  max?: number;
  position: number;
  title: string;
  unit: string;
  key?: string;
  value?: number;
  value_type: string;
  measurement_type_id?: number;
  measurement_category_id?: number;
}
export interface MeasurementValueDefinition
  extends MeasurementValueDefinitionAttributes {
  measurement_category?: MeasurementCategory;
}

export const GROUPED_MEASUREMENT_VALUE_UNITS: {
  [groupName: string]: string[];
} = {};

GROUPED_MEASUREMENT_VALUE_UNITS["weight"] = ["mg", "g", "kg", "t"];
GROUPED_MEASUREMENT_VALUE_UNITS["length"] = ["µm", "mm", "cm", "m", "km"];
GROUPED_MEASUREMENT_VALUE_UNITS["volume"] = ["ml", "l", "cm³", "dm³", "m³"];
GROUPED_MEASUREMENT_VALUE_UNITS["area"] = ["mm²", "cm²", "m²", "km²", "ha"];
GROUPED_MEASUREMENT_VALUE_UNITS["power_consumption"] = ["kW h", "MW h", "GW h"];
GROUPED_MEASUREMENT_VALUE_UNITS["temperature"] = ["°C", "K", "°F"];
GROUPED_MEASUREMENT_VALUE_UNITS["angle"] = ["°"];
GROUPED_MEASUREMENT_VALUE_UNITS["time"] = ["ms", "s", "min", "h", "d"];
GROUPED_MEASUREMENT_VALUE_UNITS["pressure"] = ["bar", "Pa", "hPa", "MPa"];
GROUPED_MEASUREMENT_VALUE_UNITS["settings"] = ["level", "program"];
GROUPED_MEASUREMENT_VALUE_UNITS["other"] = [
  "%",
  "N/m",
  "mN/m",
  "kg/s²",
  "µS/cm",
  "",
];

export const MEASUREMENT_VALUE_UNITS: string[] = flatten(
  values(GROUPED_MEASUREMENT_VALUE_UNITS),
);

/** Creates a string for (possible open) ranges
 *
 *
 * @export
 * @param {{ min?: number, max?: number }} An object providing optional min and max number properties
 * @param {string} intervalUnit Unit to display as the interval
 * @returns {string} A strig describing the range. Falls back to empty string.
 */
export function mvdRangeString(
  mvd: { min?: number; max?: number },
  intervalUnit: string,
): string {
  if (isNil(mvd) || (isNil(mvd.min) && isNil(mvd.max))) {
    return "";
  } else if (!isNil(mvd.min) && isNil(mvd.max)) {
    return `> ${mvd.min} ${toString(intervalUnit)}`;
  } else if (isNil(mvd.min) && !isNil(mvd.max)) {
    return `< ${mvd.max} ${toString(intervalUnit)}`;
  } else {
    return trim(`${mvd.min} - ${mvd.max} ${toString(intervalUnit)}`);
  }
}
