import { Event } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { defaultTo, find, isEmpty, isNil, sortBy } from "lodash";
import * as React from "react";
import {
  AssetEventTypeFilter,
  AssetEventTypeJSONAPIAttributes,
} from "../../json_api/asset_event_type";
import { TreeMode } from "../../json_api/tree_items";
import { IDType } from "../../utils/urls/url_utils";
import { Icon } from "../common/icon";
import { useLoadAssetEventTypes } from "./asset_event_type_data";

interface AssetEventTypeSelectProps {
  multiple?: boolean;
  selectedTypeId: IDType;
  selectedTypeSlug?: string;

  disabled?: boolean;
  // Asset ID to select the asset event types for, required for loading if no available types are provided
  assetId?: IDType;
  // tree mode to load asset event types for assets
  loadingTreeMode?: TreeMode;
  // flag to include system event types on load
  includeSystemEventsOnLoad?: boolean;
  eventTypeLoadFilter?: AssetEventTypeFilter;
  availableTypes?: AssetEventTypeJSONAPIAttributes[];
  onTypeSelect(newType: AssetEventTypeJSONAPIAttributes): void;
  error?: string;
  helperText?: string;
  readonly?: boolean;
  required?: boolean;
}

export const AssetEventTypeSelect: React.FunctionComponent<
  AssetEventTypeSelectProps
> = ({
  multiple = false,
  includeSystemEventsOnLoad = false,
  loadingTreeMode = "root-subtree",
  ...props
}) => {
  const [assetEventTypes, setAssetEventTypes] = React.useState<
    AssetEventTypeJSONAPIAttributes[]
  >(props.availableTypes);

  const [assetEventType, setAssetEventType] =
    React.useState<AssetEventTypeJSONAPIAttributes>(null);

  React.useEffect(() => {
    props.onTypeSelect(assetEventType);
  }, [assetEventType]);
  React.useEffect(() => {
    if (isEmpty(assetEventTypes)) return;

    if (props.selectedTypeId) {
      setAssetEventType(
        find(assetEventTypes, (t) => t.id == props.selectedTypeId),
      );
    } else if (props.selectedTypeSlug) {
      setAssetEventType(
        find(assetEventTypes, (t) => t.slug == props.selectedTypeSlug),
      );
    }
  }, [props.selectedTypeId, assetEventTypes, props.selectedTypeSlug]);

  const assetEventTypesQuery = useLoadAssetEventTypes({
    variables: {
      assetId: props.assetId,
      loadingTreeMode,
      includeSystemEventsOnLoad,
      filter: props.eventTypeLoadFilter,
    },
    initialData: props.availableTypes
      ? {
          items: sortBy(props.availableTypes, "name"),
          totalItems: props.availableTypes.length,
          totalPages: 1,
        }
      : undefined,
    enabled: !props.disabled,
  });

  React.useEffect(() => {
    setAssetEventTypes(sortBy(assetEventTypesQuery.data?.items, "name"));
  }, [assetEventTypesQuery.data]);

  return assetEventTypesQuery.isLoading ? (
    <Skeleton variant="rectangular" height={50} />
  ) : isNil(assetEventTypes) ? null : (
    <Autocomplete<AssetEventTypeJSONAPIAttributes | null, true | false>
      loading={assetEventTypesQuery.isLoading}
      disabled={props.disabled}
      renderInput={(params) => (
        <TextField
          required={props.required}
          {...params}
          disabled={props.readonly}
          title={I18n.t("frontend.asset_event_types.select.select_event_type")}
          label={I18n.t("frontend.asset_event_types.select.select_event_type")}
          error={!isEmpty(props.error)}
          InputProps={{
            ...params.InputProps,
            readOnly: props.readonly,
            startAdornment: isNil(assetEventType?.icon) ? null : (
              <Icon icon={assetEventType?.icon} color={assetEventType?.color} />
            ),
          }}
          helperText={isEmpty(props.error) ? props.helperText : props.error}
        />
      )}
      readOnly={props.readonly}
      multiple={multiple}
      noOptionsText={I18n.t("frontend.none")}
      size="small"
      options={assetEventTypes || null}
      getOptionKey={(et) => (typeof et == "string" ? et : et?.id)}
      getOptionLabel={(et) => (isEmpty(et) ? I18n.t("frontend.none") : et.name)}
      isOptionEqualToValue={(o1, o2) => {
        if (isNil(o1) || isNil(o2)) return true;

        return o1.id == o2.id;
      }}
      renderOption={(props, et) => {
        let name: string;
        let icon;
        if (typeof et == "string") {
          name = et;
          icon = <Event fontSize="inherit" />;
        } else {
          name = et.name;
          icon = isEmpty(et.icon) ? (
            <Event fontSize="inherit" htmlColor={et.color} />
          ) : (
            <Icon icon={et.icon} color={et.color} />
          );
        }

        return (
          <li {...props} key={props.key}>
            <Box component="span" mr={1}>
              {icon}
            </Box>
            <Typography component="span">{name}</Typography>
          </li>
        );
      }}
      fullWidth
      value={defaultTo(assetEventType, null)}
      title={I18n.t("frontend.asset_event_types.select.select_event_type")}
      onChange={(e, newType: AssetEventTypeJSONAPIAttributes) => {
        setAssetEventType(newType);
      }}
    />
  );
};
