import MaterialTable, { Column, Options } from "@material-table/core";

import { Box, Grid, TextField } from "@mui/material";
import * as JSONAPI from "jsonapi-typescript";
import { defaultTo, each, findIndex, isEmpty, isNil, last } from "lodash";
import * as React from "react";

import { IBox, IBoxContent, IBoxTitle } from "../common/ibox";

import { SensorJSONObject } from "../../json_api/sensor";
import {
  buildSensorValueRangeCreateRequestPayload,
  buildSensorValueRangeDeleteRequestPayload,
  buildSensorValueRangeUpdateRequestPayload,
  SensorValueRangeJSONObject,
} from "../../json_api/sensor_value_range";

import { HttpError, RequestMethod, sendData } from "../../utils/jquery_helper";
import { logger } from "../../utils/logger";
import { error, success } from "../../utils/toasts";

import { jsonApiSingleResourceToFlatObject } from "../../json_api/jsonapi_tools";
import { unitDisplayString } from "../../utils/unit_conversion";
import { getIconForName } from "../common/icon_for_name";
import tableIcons from "./table_icons";
import {
  api_sensor_value_range_path,
  api_sensor_value_ranges_path,
} from "../../routes";

type SensorValueRangeErrors = Record<
  keyof SensorValueRangeJSONObject,
  string[]
>;

type SensorValueRangeFormMode = "update" | "create" | "delete";

type RowData = SensorValueRangeJSONObject;

export interface SensorValueRangesFormProps {
  readonly?: boolean;
  ibox?: boolean;
  sensor: SensorJSONObject;
  sensorValueRanges: SensorValueRangeJSONObject[];
  onCancel?: () => void;
  onSubmit?: (sensor: SensorValueRangeJSONObject) => Promise<unknown>;
}

enum ACTION {
  CREATE = "create",
  UPDATE = "update",
  DELETE = "delete",
}

interface Action {
  type?: ACTION;
  data?: {
    index?: number;
    id?: number;
    newData?: SensorValueRangeJSONObject;
    oldData?: SensorValueRangeJSONObject;
  };
}

interface SensorValueRangesFormState {
  sensorValueRangesData: RowData[];
}
const reducer: React.Reducer<SensorValueRangesFormState, Action> = (
  state: SensorValueRangesFormState,
  action: Action,
) => {
  let dataUpdate = undefined;
  let index;
  switch (action.type) {
    case ACTION.CREATE:
      dataUpdate = [...state.sensorValueRangesData, action.data.newData];
      return {
        ...state,
        sensorValueRangesData: dataUpdate,
      };
      break;

    case ACTION.UPDATE:
      dataUpdate = [...state.sensorValueRangesData];
      index = findIndex(dataUpdate, (item) => item.id == action.data.id);
      if (index === -1) {
        return state;
      }
      dataUpdate[index] = action.data.newData;
      return {
        ...state,
        sensorValueRangesData: dataUpdate,
      };
      break;

    case ACTION.DELETE:
      dataUpdate = [...state.sensorValueRangesData];
      dataUpdate = dataUpdate.filter((item) => {
        return item.id != action.data.id;
      });
      return {
        ...state,
        sensorValueRangesData: dataUpdate,
      };
      break;

    default:
      throw new Error("Unknown Action");
  }
};

const formStyles = {
  errorStyle: {
    bgcolor: "#FFCCCC",
    color: "#666",
    fontSize: "0.8rem",
    border: 1,
    borderRadius: 5,
  },
  iconStyle: {
    fontSize: "1.5em",
  },
  colorStyle: {
    fontSize: "1.5em",
  },
};

function withUnitString(
  value: number | string | boolean,
  unit = "",
  unitInBrackets = false,
): string {
  if (value && unit && unit != "") {
    return unitInBrackets
      ? `${value.toString()} (${unitDisplayString(unit)})`
      : `${value.toString()} ${unitDisplayString(unit)}`;
  } else {
    return value.toString();
  }
}

export const SensorValueRangesForm: React.FunctionComponent<
  SensorValueRangesFormProps
> = ({
  readonly = false,
  ibox = true,
  ...props
}: SensorValueRangesFormProps) => {
  const initialState = {
    sensorValueRangesData: getRowData(
      props.sensorValueRanges || props.sensor.value_ranges || [],
    ),
  };

  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [formErrors, setFormErrors] = React.useState<SensorValueRangeErrors>(
    {},
  );
  const [isProcessing, setIsProcessing] = React.useState(false);

  const unit = defaultTo(props.sensor?.attribute_key_unit, "");

  //const COLOR_REGEX = /^#(?:[0-9a-fA-F]{3}){1,2}$/

  const tableColumns: Column<SensorValueRangeJSONObject>[] = [
    {
      field: "icon_name",
      title: "",
      editable: "never",
      sorting: false,
      render: (item: SensorValueRangeJSONObject) => {
        return !item.icon_name ? null : getIconForName(item.icon_name, "1x");
      },
      cellStyle: {
        minWidth: 20,
        maxWidth: 50,
      },
    },
    {
      field: "color",
      title: "",
      editable: "never",
      sorting: false,
      render: (item: SensorValueRangeJSONObject) => {
        const style = {
          ...formStyles.colorStyle,
          color: item.color !== null ? `${item.color}` : "",
        };
        return !item.color ? null : (
          <i className="fa fa-square" aria-hidden="true" style={style}></i>
        );
      },
      cellStyle: {
        minWidth: 20,
        maxWidth: 50,
      },
    },
    {
      field: "name",
      title: I18n.t("activerecord.attributes.sensor_value_range.name"),
      cellStyle: {
        minWidth: 80,
      },
    },
    {
      field: "status",
      title: I18n.t("frontend.sensor_value_ranges.form.column_name_status"),
      lookup: {
        optimal: "optimal",
        normal: "normal",
        critical: "critical",
        fatal: "fatal",
      },
      cellStyle: {
        minWidth: 50,
      },
    },
    {
      field: "min",
      type: "numeric",
      title: withUnitString(
        I18n.t("frontend.sensor_value_ranges.form.column_name_min"),
        unit,
        true,
      ),
      render: (item: SensorValueRangeJSONObject) => {
        return item.min ? (
          <div>{withUnitString(item.min, unit)}</div>
        ) : (
          <div>{item.min}</div>
        );
      },
    },
    {
      field: "max",
      type: "numeric",
      title: withUnitString(
        I18n.t("frontend.sensor_value_ranges.form.column_name_max"),
        unit,
        true,
      ),
      render: (item: SensorValueRangeJSONObject) => {
        return item.max ? (
          <div>{withUnitString(item.max, unit)}</div>
        ) : (
          <div>{item.max}</div>
        );
      },
    },
    {
      field: "icon_name",
      title: I18n.t("frontend.sensor_value_ranges.form.column_name_icon_name"),
    },
    {
      field: "color",
      title: I18n.t("frontend.sensor_value_ranges.form.column_name_color"),
    },
    {
      field: "description",
      title: I18n.t(
        "frontend.sensor_value_ranges.form.column_name_description",
      ),
      editComponent: ({ value, onChange }) => (
        <TextField
          onChange={(e) => onChange(e.target.value)}
          value={value as string}
          multiline
        />
      ),
      render: (item: SensorValueRangeJSONObject) => {
        return item.description ? item.description.slice(0, 25) + "..." : "";
      },
    },
  ];
  const tableOptions: Options<SensorValueRangeJSONObject> = {
    actionsColumnIndex: -1,
    emptyRowsWhenPaging: false, // To avoid of having empty rows
    pageSizeOptions: [10, 25, 50, 100],
    tableLayout: "auto",
    search: false,
    columnsButton: false,
    toolbar: !readonly,
    paging: false,
    addRowPosition: "first",

    //pageSize: 10,       // make initial page size
    //padding: "dense",
    //detailPanelType: "single",
  };

  let content = (
    <Grid container spacing={2}>
      {isEmpty(formErrors) ? null : (
        <Grid item xs={12}>
          <Box sx={{ ...formStyles.errorStyle }} p={1}>
            {I18n.t("base.errors")}: {I18n.t("base.please_check_entries")}
            <ul>
              {Object.keys(formErrors).map((valueKey) =>
                formErrors[valueKey].map((error, index) => (
                  <li key={`${valueKey}_${index}`}>{error}</li>
                )),
              )}
            </ul>
          </Box>
        </Grid>
      )}

      <Grid item xs={12}>
        <MaterialTable<RowData>
          title={null}
          isLoading={isProcessing}
          columns={tableColumns}
          data={state.sensorValueRangesData}
          editable={
            readonly
              ? null
              : {
                  onRowAdd: async (newData: SensorValueRangeJSONObject) => {
                    newData = {
                      ...newData,
                      sensor_id: props.sensor.id,
                    };
                    const createdSensorValueRange = await handleAction(
                      props,
                      newData,
                      setIsProcessing,
                      setFormErrors,
                      "create",
                    );
                    if (!isNil(createdSensorValueRange)) {
                      dispatch({
                        type: ACTION.CREATE,
                        data: { newData: createdSensorValueRange },
                      });
                    }
                  },
                  onRowUpdate: (newData, oldData) => {
                    return new Promise((resolve, reject) => {
                      const id: number = oldData.id as number;
                      //return updateSensorValueRange(
                      handleAction(
                        props,
                        newData,
                        setIsProcessing,
                        setFormErrors,
                        "update",
                      )
                        .then((newSavedData) => {
                          if (!isNil(newSavedData)) {
                            dispatch({
                              type: ACTION.UPDATE,
                              data: { id: id, newData: newData },
                            });
                            resolve(newSavedData);
                          } else {
                            reject(newSavedData);
                          }
                        })
                        .catch((e) => reject(e));
                    });
                  },
                  onRowDelete: async (oldData: SensorValueRangeJSONObject) => {
                    const id: number = oldData.id as number;
                    const deletedSensorValueRange = await handleAction(
                      props,
                      oldData,
                      setIsProcessing,
                      setFormErrors,
                      "delete",
                    );
                    if (!isNil(deletedSensorValueRange)) {
                      dispatch({
                        type: ACTION.DELETE,
                        data: { id: id },
                      });
                    }
                  },
                }
          }
          icons={tableIcons}
          localization={{
            header: {
              actions: I18n.t(
                "frontend.sensor_value_ranges.form.column_name_actions",
              ),
            },
            body: {
              addTooltip: I18n.t(
                "frontend.sensor_value_ranges.form.addSensorValueRangeTooltip",
              ),
              editTooltip: I18n.t(
                "frontend.sensor_value_ranges.form.updateSensorValueRangeTooltip",
              ),
              deleteTooltip: I18n.t(
                "frontend.sensor_value_ranges.form.deleteSensorValueRangeTooltip",
              ),
            },
          }}
          options={tableOptions}
        />
      </Grid>
    </Grid>
  );

  if (ibox) {
    content = (
      <IBox>
        <IBoxTitle>
          <h5>{I18n.t("frontend.sensor_value_ranges.form.header")}</h5>
        </IBoxTitle>
        <IBoxContent>{content}</IBoxContent>
      </IBox>
    );
  }
  return content;
};

function getRowData(sensorValueRanges: SensorValueRangeJSONObject[]) {
  return sensorValueRanges.map((vr) => {
    return {
      ...vr,
      min: defaultTo(vr.min, undefined),
      max: defaultTo(vr.max, undefined),
      color: defaultTo(vr.color, undefined),
      icon_name: defaultTo(vr.icon_name, undefined),
      status: defaultTo(vr.status, undefined),
      id: defaultTo(vr.id, undefined),
      name: defaultTo(vr.name, undefined),
      description: defaultTo(vr.description, undefined),
    } as RowData;
  });
}
async function handleAction(
  props: SensorValueRangesFormProps,
  sensorValueRangeData: SensorValueRangeJSONObject,
  setIsProcessing?: (proc: boolean) => void,
  setFormErrors?: (errs: SensorValueRangeErrors) => void,
  mode?: SensorValueRangeFormMode,
): Promise<SensorValueRangeJSONObject> {
  setIsProcessing(true);
  setFormErrors({});
  let sensorValueRange: SensorValueRangeJSONObject = undefined;
  try {
    if (props.onSubmit) {
      (await props.onSubmit(
        sensorValueRangeData,
      )) as SensorValueRangeJSONObject;
    } else {
      sensorValueRange = await submitChanges(sensorValueRangeData, mode);
    }
    void success(
      I18n.t("frontend.success"),
      I18n.t(`frontend.sensor_value_ranges.form.successful_${mode}`),
    );
    return sensorValueRange;
  } catch (e) {
    void error(
      I18n.t("base.error"),
      I18n.t(`frontend.sensor_value_ranges.form.error_during_${mode}`),
    );
    setFormErrors(
      handleErrors(
        (e as HttpError).request?.responseJSON as JSONAPI.DocWithErrors,
      ),
    );
    return null;
  } finally {
    setIsProcessing(false);
  }
}

function handleErrors(e: JSONAPI.DocWithErrors) {
  logger.error(e);
  const errors: Record<keyof SensorValueRangeJSONObject, string[]> = {};
  each(e.errors, (e) => {
    const attributePointer = e.source?.pointer as string;
    if (!isNil(attributePointer)) {
      const attributeName = last(
        attributePointer.split("/"),
      ) as keyof SensorValueRangeJSONObject;
      const detail = e.detail;
      let errs = errors[attributeName];
      if (isNil(errs)) {
        errs = [];
        errors[attributeName] = errs;
      }
      errs.push(detail);
    }
  });
  return errors;
}

async function submitChanges(
  sensorValueRangeData: SensorValueRangeJSONObject,
  mode: SensorValueRangeFormMode,
): Promise<SensorValueRangeJSONObject> {
  let jsonApiSubmitData = null;

  let httpMethod: RequestMethod = "PATCH";
  let url: string;
  let data: SensorValueRangeJSONObject = null;

  switch (mode) {
    case "create":
      httpMethod = "POST";
      url = api_sensor_value_ranges_path();
      data = { ...sensorValueRangeData };
      delete data.id;
      jsonApiSubmitData = buildSensorValueRangeCreateRequestPayload(data);
      jsonApiSubmitData.submitData.data.relationships = {
        sensor: {
          data: {
            type: "sensors",
            id: sensorValueRangeData.sensor_id as string,
          },
        },
      };
      break;
    case "update":
      httpMethod = "PATCH";

      url = api_sensor_value_range_path(sensorValueRangeData.id);
      jsonApiSubmitData =
        buildSensorValueRangeUpdateRequestPayload(sensorValueRangeData);
      break;
    case "delete":
      httpMethod = "DELETE";
      url = api_sensor_value_range_path(sensorValueRangeData.id);
      jsonApiSubmitData =
        buildSensorValueRangeDeleteRequestPayload(sensorValueRangeData);
      break;
  }

  const receivedData = await sendData<
    JSONAPI.SingleResourceDoc<string, SensorValueRangeJSONObject>,
    JSONAPI.SingleResourceDoc<string, SensorValueRangeJSONObject>
  >(url, jsonApiSubmitData.submitData, httpMethod, "application/vnd.api+json");

  if (isNil(receivedData)) {
    // for DELETE nothing is responded
    return sensorValueRangeData;
  } else if (receivedData instanceof HttpError) {
    // Errors
    throw receivedData;
  } else {
    // successful CREATE or UPDATE
    return jsonApiSingleResourceToFlatObject(receivedData);
  }
}
