/// <reference types="../../../definitions/index" />;

import { Add } from "@mui/icons-material";
import ArrowDown from "@mui/icons-material/ArrowDownward";
import ArrowUp from "@mui/icons-material/ArrowUpward";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import { find, isEmpty, isNil, map, toInteger, toString } from "lodash";
import * as React from "react";
import { MeasurementCategorization } from "../../../models/measurement_categorization";
import { MeasurementTypes } from "../../../models/measurement_type";
import { MeasurementValueDefinition } from "../../../models/measurement_value_definition";
import { TypedErrorMap } from "../../../utils/error_map";
import { IBox, IBoxContent } from "../../common/ibox";
import { Icon } from "../../common/icon";
import { MeasurementUnitSelect } from "../../common/measurement_unit_select";
import { on } from "events";

interface MeasurementValueDefinitionListProperties {
  typeOfMeasurement: MeasurementTypes;
  unit?: string;
  measurementValueDefinitions: MeasurementValueDefinition[];
  categorization: MeasurementCategorization;
  interval_unit?: string;

  allowDelete: boolean;
  allowEdit?: boolean;

  errors?: TypedErrorMap<MeasurementValueDefinition>[];
  onMoveMeasurementValueDefinition?: (index: number, direction: -1 | 1) => void;
  onAddMeasurementValueDefinition?: () => void;
  onRemoveMeasurementValueDefinition?: (
    mvd: MeasurementValueDefinition,
    index: number,
  ) => void;

  onUpdateMeasurementValueDefinition?: (
    updatedMvd: MeasurementValueDefinition,
    index: number,
  ) => void;
}
export const MeasurementValueDefinitionList: React.FunctionComponent<
  MeasurementValueDefinitionListProperties
> = ({
  allowEdit,
  allowDelete,
  typeOfMeasurement,
  measurementValueDefinitions,
  categorization,
  errors,
  interval_unit,
  onUpdateMeasurementValueDefinition,
  onAddMeasurementValueDefinition,
  onRemoveMeasurementValueDefinition,
  onMoveMeasurementValueDefinition,
}) => {
  const handleCategorySelection = React.useCallback(
    (
      valueDefinition: MeasurementValueDefinition,
      index: number,
      categoryId: number | string,
    ) => {
      if (isNil(categorization)) return;

      const category = find(
        categorization.measurement_categories,
        (cat) => cat.id == categoryId,
      );
      if (!isNil(category) && !isNil(onUpdateMeasurementValueDefinition)) {
        onUpdateMeasurementValueDefinition(
          {
            ...valueDefinition,
            measurement_category_id: toInteger(category.id),
            measurement_category: category,
          },
          index,
        );
      }
    },
    [categorization, onUpdateMeasurementValueDefinition],
  );

  return (
    <Grid container spacing={2}>
      {allowEdit === false || isNil(onAddMeasurementValueDefinition) ? null : (
        <Grid item xs={12} key="btn-row">
          <Button
            className="float-right"
            variant="contained"
            onClick={onAddMeasurementValueDefinition}
            size="small"
            color="primary"
            startIcon={<Add />}
          >
            <span className="d-xs-none d-md-inline">
              {typeOfMeasurement ==
              "MeasurementTypes::IndependentMeasurementType"
                ? I18n.t(
                    "frontend.measurement_value_definition_list.add_definition",
                  )
                : I18n.t(
                    "frontend.measurement_value_definition_list.add_interval_definition",
                  )}
            </span>
          </Button>
          <div className="clearfix"></div>
        </Grid>
      )}
      {map(measurementValueDefinitions, (valueDefinition, index) => (
        <Grid item xs={12} key={`value-def-${index}`}>
          <IBox>
            <IBoxContent>
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item xs>
                  <TextField
                    size="small"
                    fullWidth={true}
                    name="mvd.title"
                    label={I18n.t(
                      "activerecord.attributes.measurement_value_definition.title",
                    )}
                    error={!isNil(errors?.[index]?.title)}
                    helperText={errors?.[index]?.title}
                    required={true}
                    inputProps={{
                      readOnly: allowEdit === false,
                    }}
                    value={toString(valueDefinition?.title)}
                    onChange={(changeEvent) => {
                      if (!isNil(onUpdateMeasurementValueDefinition))
                        onUpdateMeasurementValueDefinition(
                          {
                            ...valueDefinition,
                            title: changeEvent.target.value,
                          },
                          index,
                        );
                    }}
                  />
                </Grid>
                <Grid item xs flex={0.5} container justifyContent="flex-end">
                  <Grid item xs hidden={!allowEdit} width={100}>
                    <Tooltip title={I18n.t("frontend.change_order_down")}>
                      <TextField
                        size="small"
                        name="mvd.key"
                        label={I18n.t(
                          "activerecord.attributes.measurement_value_definition.key",
                        )}
                        error={!isNil(errors?.[index]?.key)}
                        helperText={errors?.[index]?.key}
                        required={false}
                        inputProps={{
                          readOnly: allowEdit === false,
                        }}
                        value={toString(valueDefinition?.key)}
                        onChange={(changeEvent) => {
                          if (!isNil(onUpdateMeasurementValueDefinition))
                            onUpdateMeasurementValueDefinition(
                              {
                                ...valueDefinition,
                                key: changeEvent.target.value,
                              },
                              index,
                            );
                        }}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid
                    item
                    width={100}
                    hidden={!allowEdit}
                    textAlign={"right"}
                  >
                    {measurementValueDefinitions?.length > 1 ? (
                      <>
                        <IconButton
                          size="small"
                          title={I18n.t("frontend.change_order_down")}
                          hidden={
                            index === measurementValueDefinitions.length - 1 ||
                            isNil(onMoveMeasurementValueDefinition)
                          }
                          onClick={() => {
                            if (onMoveMeasurementValueDefinition)
                              onMoveMeasurementValueDefinition(index, 1);
                          }}
                        >
                          <ArrowDown fontSize="inherit" />
                        </IconButton>

                        <IconButton
                          size="small"
                          title={I18n.t("frontend.change_order_up")}
                          hidden={
                            index === 0 ||
                            isNil(onMoveMeasurementValueDefinition)
                          }
                          onClick={() => {
                            onMoveMeasurementValueDefinition(index, -1);
                          }}
                        >
                          <ArrowUp fontSize="inherit" />
                        </IconButton>
                      </>
                    ) : null}
                    {isNil(onRemoveMeasurementValueDefinition) ||
                    !allowDelete ? null : (
                      <IconButton
                        size="small"
                        title={I18n.t("frontend.delete")}
                        onClick={() => {
                          onRemoveMeasurementValueDefinition(
                            valueDefinition,
                            index,
                          );
                        }}
                      >
                        <DeleteIcon fontSize="inherit" />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {typeOfMeasurement ===
                  "MeasurementTypes::DistributionMeasurementType"
                    ? fieldsForDistributionType(
                        valueDefinition,
                        index,
                        errors?.[index],
                        categorization,
                        allowEdit,
                        interval_unit,
                        onUpdateMeasurementValueDefinition,
                        handleCategorySelection,
                      )
                    : fieldsForIndividualMeasurements(
                        valueDefinition,
                        index,
                        errors?.[index],
                        allowEdit,
                        onUpdateMeasurementValueDefinition,
                      )}
                </Grid>
              </Grid>
            </IBoxContent>
          </IBox>
        </Grid>
      ))}
    </Grid>
  );
};

function fieldsForDistributionType(
  valueDefinition: MeasurementValueDefinition,
  index: number,
  error: TypedErrorMap<MeasurementValueDefinition>,
  categorization: MeasurementCategorization,
  allowEdit: boolean,
  intervalUnit: string,
  onUpdateMeasurementValueDefinition: (
    updatedMvd: MeasurementValueDefinition,
    index: number,
  ) => void,
  handleCategorySelection: (
    updatedMvd: MeasurementValueDefinition,
    index: number,
    categoryId: number | string,
  ) => void,
) {
  return [
    <Grid container key={`${index}-cat`} spacing={1}>
      {isEmpty(categorization?.measurement_categories) ? null : (
        <Grid item xs={12}>
          <TextField
            select
            fullWidth={true}
            error={!isEmpty(error?.measurement_category)}
            label={I18n.t(
              "activerecord.attributes.measurement_value_definition.measurement_category",
            )}
            size="small"
            disabled={isEmpty(categorization?.measurement_categories)}
            value={toString(valueDefinition.measurement_category_id)}
            contentEditable={allowEdit === false}
            onChange={(event) => {
              handleCategorySelection(
                valueDefinition,
                index,
                event.target.value,
              );
            }}
          >
            {categorization?.measurement_categories?.map((cat, index) => (
              <MenuItem key={index} value={cat.id}>
                {cat.title}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      )}
      <Grid item xs={4}>
        <TextField
          label={I18n.t(
            "activerecord.attributes.measurement_value_definition.min",
          )}
          type="number"
          value={toString(valueDefinition.min)}
          error={!isEmpty(error?.min)}
          helperText={error?.min}
          size="small"
          fullWidth={true}
          InputProps={{
            readOnly: allowEdit === false,
            startAdornment: (
              <InputAdornment position="start">
                <Icon icon="arrow-circle-o-down" />
              </InputAdornment>
            ),
            endAdornment: isEmpty(intervalUnit) ? null : (
              <InputAdornment position="end">{intervalUnit}</InputAdornment>
            ),
          }}
          onChange={(changeEvent) =>
            onUpdateMeasurementValueDefinition(
              {
                ...valueDefinition,
                min: parseFloat(changeEvent.target.value),
              },
              index,
            )
          }
        />
      </Grid>
      <Grid
        item
        xs={4}
        className="align-self-center text-center"
        style={{ fontSize: "large" }}
      >
        &#8804;&nbsp;
        {I18n.t(
          "activerecord.attributes.measurement_value_definition.value",
        )}{" "}
        &nbsp;&#60;
      </Grid>
      <Grid item xs={4}>
        <TextField
          label={I18n.t(
            "activerecord.attributes.measurement_value_definition.max",
          )}
          type="number"
          value={toString(valueDefinition.max)}
          size="small"
          error={!isEmpty(error?.max)}
          helperText={error?.max}
          fullWidth={true}
          InputProps={{
            readOnly: allowEdit === false,
            startAdornment: (
              <InputAdornment position="start">
                <Icon icon="arrow-circle-o-up" />
              </InputAdornment>
            ),
            endAdornment: isEmpty(intervalUnit) ? null : (
              <InputAdornment position="end">{intervalUnit}</InputAdornment>
            ),
          }}
          onChange={(changeEvent) => {
            if (!isNil(onUpdateMeasurementValueDefinition))
              onUpdateMeasurementValueDefinition(
                {
                  ...valueDefinition,
                  max: parseFloat(changeEvent.target.value),
                },
                index,
              );
          }}
        />
      </Grid>
    </Grid>,
  ];
}

function fieldsForIndividualMeasurements(
  valueDefinition: MeasurementValueDefinition,
  index: number,
  error: TypedErrorMap<MeasurementValueDefinition>,
  allowEdit: boolean,
  onUpdateMeasurementValueDefinition?: (
    updatedMvd: MeasurementValueDefinition,
    index: number,
  ) => void,
) {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <MeasurementUnitSelect
          error={error?.unit}
          allowEdit={allowEdit}
          unit={valueDefinition?.unit}
          id={`select-unit-label-${index}`}
          onChange={(newUnit) => {
            if (!isNil(onUpdateMeasurementValueDefinition)) {
              onUpdateMeasurementValueDefinition(
                { ...valueDefinition, unit: newUnit },
                index,
              );
            }
          }}
        />
      </Grid>
    </Grid>
  );
}
