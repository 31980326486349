import { Box, Button, ButtonGroup, Grid, Paper } from "@mui/material";
import { DateRange } from "moment-range";
import React, { useContext, useEffect, useMemo } from "react";
import { LineDiagramWidget } from "../../components/widgets/line_diagram_widget";
import { ConfiguredKpiJSONObject } from "../../json_api/configured_kpi";
import { ResourcePermission } from "../../models/resource_permission";
import { SamplingRate } from "../../models/sensor";
import { IDType } from "../../utils/urls/url_utils";
import { buildLineDiagramDataUrls } from "../../widgets/line_diagram_widget";
import { AppContext } from "../../components/common/app_context/app_context_provider";
import { configured_kpi_time_series_api_configured_kpis_path } from "../../routes";
import { List } from "@mui/icons-material";

interface ConfiguredKpiPageProps {
  id: IDType;
  configuredKpi?: ConfiguredKpiJSONObject;
  permissions?: ResourcePermission;
  fullHeight?: boolean;
  onChangeTimeRange?: (range: DateRange, samplingRate: SamplingRate) => void;
  samplingRate?: SamplingRate;
  timeRange?: DateRange;
} /**
 *
 *
 * @param {*} {
 *   assetId,
 * }
 * @return {*}
 */
export const ConfiguredKpiPage: React.FC<ConfiguredKpiPageProps> = ({
  id,
  configuredKpi,
  onChangeTimeRange,
  samplingRate: initialSamplingRate,
  timeRange: initialTimeRange,
}) => {
  const dataUrls = useMemo(() => {
    return buildLineDiagramDataUrls(null, null, null, [
      { id: id, timespan: 86400 },
    ]);
  }, [id]);

  const [samplingRate, setSamplingRate] = React.useState<SamplingRate>({
    value: 1,
    unit: "days",
  });

  const [timeRange, setTimeRange] = React.useState<DateRange>(
    () =>
      initialTimeRange ||
      new DateRange(moment().startOf("week"), moment().endOf("week")),
  );

  useEffect(() => {
    if (
      samplingRate?.unit !== initialSamplingRate?.unit ||
      samplingRate?.value !== initialSamplingRate?.value ||
      timeRange?.start?.valueOf() !== initialTimeRange?.start?.valueOf() ||
      timeRange?.end?.valueOf() !== initialTimeRange?.end?.valueOf()
    ) {
      onChangeTimeRange?.(timeRange, samplingRate);
    }
  }, [
    samplingRate?.unit,
    samplingRate?.value,
    timeRange?.start?.valueOf(),
    timeRange?.end?.valueOf(),
  ]);

  const appCtx = useContext(AppContext);

  return (
    <Paper>
      <Box padding={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LineDiagramWidget
              encloseInIBox={false}
              dataUrls={dataUrls}
              timeRange={timeRange}
              diagramHeight={500}
              onChangeTimeRange={(range) => {
                setTimeRange(range);
              }}
              onChangeSamplingRate={(samplingRate) =>
                setSamplingRate(samplingRate)
              }
              samplingRate={samplingRate}
            />
          </Grid>
          <Grid item xs={12}>
            <Box padding={2}>
              {appCtx.user.isAdmin && (
                <ButtonGroup>
                  <Button
                    startIcon={<List />}
                    variant="contained"
                    color="primary"
                    href={configured_kpi_time_series_api_configured_kpis_path(
                      id,
                      {
                        _options: true,
                        format: "csv",
                        min_time: timeRange.start.toISOString(),
                        max_time: timeRange.end.toISOString(),
                        sampling_rate: samplingRate.value,
                        sampling_rate_unit: samplingRate.unit,
                      },
                    )}
                  >
                    {I18n.t("base.download_csv")}
                  </Button>
                </ButtonGroup>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};
