import { DateRange } from "moment-range";
import {
  createTimeRanges,
  TranslatedTimeScopeWithReverseMap,
} from "../utils/time_scopes";
import {
  DashboardColumns,
  DashboardConfig,
  DashboardConfigSerialized,
  DashboardRootConfigSerialized,
  DashboardsWidgetClientConfig,
  WidgetRow,
} from "../components/dashboard/dashboard.types";
import { IDType } from "../utils/urls/url_utils";
import { createQuery } from "react-query-kit";
import { flatten, groupBy, isEmpty, isNil, map, mapValues } from "lodash";
import { loadDataFromUrl } from "../utils/jquery_helper";
import {
  dashboard_api_asset_asset_type_path,
  dashboard_api_asset_path,
  dashboard_time_scope_asset_asset_type_path,
  dashboard_time_scope_asset_path,
} from "../routes";
import { DashboardArea } from "../widgets/widget.types";
import { sortedRowsFromItems } from "../utils/dashboard_layout_tools";

interface DashboardWidgetsQueryVariables {
  assetId: IDType;
  dashboardId: IDType;
  assetTypeId: IDType;
  trStart: string;
  trEnd: string;
  timeScopeName: string;
}

interface DashboardWidgetsQueryReturn {
  configs: DashboardConfig[];
  availableTimeScopes: TranslatedTimeScopeWithReverseMap;
  loadedDateRange: DateRange;
  loadedTimeScopeName: string;
}
export const useDashboardWidgetsQuery = createQuery<
  DashboardWidgetsQueryReturn,
  DashboardWidgetsQueryVariables
>({
  queryKey: ["dashboardConfigs"],
  fetcher: async (variables) => {
    const {
      configs,
      availableTimeScopes,
      currentTimeScopeName: loadedTimeScopeName,
      currentTimeRange: loadedDateRange,
    } = await loadDashboardConfigs(
      variables.assetId,
      variables.dashboardId,
      variables.assetTypeId,
      moment.range(moment(variables.trStart), moment(variables.trEnd)),
      variables.timeScopeName,
    );

    return {
      configs,
      availableTimeScopes,
      loadedDateRange,
      loadedTimeScopeName,
    };
  },
});

/** Builds the dashboard config from a serialized dashboard config
 *
 *
 * @param {DashboardConfigSerialized} dashboardConfigSerialized
 * @return {*}  {DashboardConfig}
 */
function dashboardConfigFromSerialized(
  dashboardConfigSerialized: DashboardConfigSerialized,
): DashboardConfig {
  return {
    asset_id: dashboardConfigSerialized.asset_id,
    title: dashboardConfigSerialized.title,
    columns: buildDashboardColumns(dashboardConfigSerialized.widgets),
    column_sizes: dashboardConfigSerialized.column_sizes,
  };
}

/** Loads the dashboard config from the backend
 *
 *
 * @param {IDType} assetId
 * @param {IDType} dashboardId
 * @param {DateRange} selectedTimeRange
 * @return {*}  {Promise<{
 *   configs: DashboardConfig[];
 *   availableTimeScopes: TimeRanges;
 *   dateRange: DateRange;
 *   timeScopeName: string;
 * }>}
 */
async function loadDashboardConfigs(
  assetId: IDType,
  dashboardId: IDType,
  assetTypeId: IDType,
  selectedTimeRange: DateRange,
  selectedTimeScopeName: string,
): Promise<{
  configs: DashboardConfig[];
  availableTimeScopes: TranslatedTimeScopeWithReverseMap;
  currentTimeRange: DateRange;
  currentTimeScopeName: string;
}> {
  const url = dashboardUrl(
    assetId,
    assetTypeId,
    dashboardId,
    selectedTimeRange,
    selectedTimeScopeName,
    true,
  );
  const dashboardConfig =
    await loadDataFromUrl<DashboardRootConfigSerialized>(url);

  const configs = map(dashboardConfig.dashboards, (dbc) =>
    dashboardConfigFromSerialized(dbc),
  );

  const availableTimeScopes = createTimeRanges(dashboardConfig.time_scopes);

  let currentTimeRange = null;
  let currentTimeScopeName = null;
  // only set this on initial load -> consecutive loads should be triggered by setting the range in client
  if (
    !isNil(dashboardConfig.time_range?.min) &&
    !isNil(dashboardConfig.time_range?.max)
  ) {
    currentTimeRange = new DateRange(
      moment(dashboardConfig.time_range.min),
      moment(dashboardConfig.time_range.max),
    );
    currentTimeScopeName = dashboardConfig.time_range.name;
  }
  return {
    configs,
    availableTimeScopes,
    currentTimeRange,
    currentTimeScopeName,
  };
}

function dashboardUrl(
  assetId: IDType,
  assetTypeId: IDType,
  dashboardId: IDType,
  selectedTimeRange: DateRange,
  selectedTimeScopeName: string,
  api = true,
): string {
  const options: {
    start_time: string;
    end_time: string;
    _options: boolean;
    time_scope_name?: string;
    format?: string;
  } = {
    start_time: selectedTimeRange?.start?.toISOString(),
    end_time: selectedTimeRange?.end?.toISOString(),
    _options: true,
  };

  if (api) {
    options.time_scope_name = selectedTimeScopeName;
    options.format = "json";
    return isNil(assetTypeId)
      ? dashboard_api_asset_path(assetId, dashboardId, options)
      : dashboard_api_asset_asset_type_path(
          assetId,
          assetTypeId,
          dashboardId,
          options,
        );
  } else {
    //options.time_scope_name = options.time_scope_name || "custom";

    return isNil(assetTypeId)
      ? dashboard_time_scope_asset_path(
          assetId,
          dashboardId,
          selectedTimeScopeName || "custom",
          options,
        )
      : dashboard_time_scope_asset_asset_type_path(
          assetId,
          assetTypeId,
          dashboardId,
          selectedTimeScopeName || "custom",
          options,
        );
  }
}

function applySortingToDashboardConfig(
  dashboardConfig: DashboardConfig,
): DashboardConfig {
  return {
    ...dashboardConfig,
    columns: {
      center: applySortingToDashboardWidgets(dashboardConfig.columns.center),
      left_sidebar: applySortingToDashboardWidgets(
        dashboardConfig.columns.left_sidebar,
      ),
      right_sidebar: applySortingToDashboardWidgets(
        dashboardConfig.columns.right_sidebar,
      ),
    },
  };
}

/** Builds the dashboard config from a set of widget configs by applying spearation by dashboard column and widget row
 *
 *
 * @param {DashboardsWidgetClientConfig[]} widgets
 * @return {*}  {DashboardColumns}
 */
export function buildDashboardColumns(
  widgets: DashboardsWidgetClientConfig[],
): DashboardColumns {
  const widgetsByArea = groupBy(widgets, (w) => w.area ?? "center") as Record<
    DashboardArea,
    DashboardsWidgetClientConfig[]
  >;

  return mapValues(widgetsByArea, (widgetsForArea) =>
    applySortingToDashboardWidgets(widgetsForArea),
  );
}

/** Applies sorting and ordering by dashboard row and position.
 * Converts a given array into a nested array representing rows and the widgets contained
 *
 * @param {(WidgetRow[] | WidgetRow)} dashboardRows
 * @return {*}  {WidgetRow[]}
 */
function applySortingToDashboardWidgets(
  dashboardRows: WidgetRow[] | WidgetRow,
): WidgetRow[] {
  if (isEmpty(dashboardRows)) return dashboardRows as WidgetRow[];

  const widgets = flatten(dashboardRows);
  return sortedRowsFromItems(widgets);
}
