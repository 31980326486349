import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CollectionResourceDoc, SingleResourceDoc } from "jsonapi-typescript";
import { isNil, omitBy } from "lodash";
import { createQuery } from "react-query-kit";
import { MeasurementFilter } from "../components/measurements/views/measurement_filter";
import {
  jsonApiSingleResourceToFlatObject,
  loadItemResultForJsonApiCollectionResourceDoc,
  LoadItemsResult,
} from "../json_api/jsonapi_tools";
import {
  MEASUREMENT_JSON_API_TYPE,
  MeasurementJSONAPIAttributesObject,
  MeasurementJsonApiIncludes,
} from "../json_api/measurement";
import {
  api_measurement_path,
  api_measurements_path,
  asset_measurement_plan_measurement_path,
} from "../routes";
import { computeValuePercent } from "../utils/calculate_percentage";
import { loadDataFromUrl, sendData } from "../utils/jquery_helper";
import { IDType } from "../utils/urls/url_utils";

export const useDeleteMeasurement = () => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: ({
      id,
      assetId,
      measurementPlanId,
    }: {
      id: IDType;
      measurementPlanId: IDType;
      assetId: IDType;
    }) => {
      return sendData(
        asset_measurement_plan_measurement_path(
          assetId,
          measurementPlanId,
          id,
          { _options: true, format: "json" },
        ),
        "",
        "DELETE",
      );
    },
    onSuccess: () => {
      client.invalidateQueries({ queryKey: [MEASUREMENT_JSON_API_TYPE] });
    },
  });
};

export const useLoadMeasurement = createQuery<
  MeasurementJSONAPIAttributesObject,
  { id: IDType; include?: MeasurementJsonApiIncludes[] }
>({
  queryKey: [MEASUREMENT_JSON_API_TYPE],
  fetcher: async ({ id, include }) => {
    const doc = await loadDataFromUrl<
      SingleResourceDoc<string, MeasurementJSONAPIAttributesObject>
    >(
      api_measurement_path(id, {
        include: include?.join(","),
        _options: true,
      }),
    );
    const data = jsonApiSingleResourceToFlatObject(
      doc,
      (plainObject, jsonAPIObject, includesByItemType) => {},
    );

    if (data?.measurement_type_type === "distribution_measurement_type") {
      // add percentage values for distributions
      data.measurement_values = computeValuePercent(data.measurement_values);
    }
    return data;
  },
});

export const useLoadMeasurements = createQuery<
  LoadItemsResult<MeasurementJSONAPIAttributesObject>,
  {
    include?: MeasurementJsonApiIncludes[];
    measurementPlanId?: IDType;
    filter?: MeasurementFilter;
    pageSize?: number;
    page?: number;
    sort?: string;
  }
>({
  queryKey: [MEASUREMENT_JSON_API_TYPE],
  fetcher: async ({
    include,
    measurementPlanId,

    page = 1,
    pageSize = 10,
    sort = "-time",
    filter,
  }) => {
    const theFilter = omitBy(
      {
        measurement_plan_id: measurementPlanId,
        to: filter?.to?.toISOString(),
        from: filter?.from?.toISOString(),
      },
      isNil,
    );
    const doc = await loadDataFromUrl<
      CollectionResourceDoc<string, MeasurementJSONAPIAttributesObject>
    >(
      api_measurements_path({
        include: include?.join(","),
        _options: true,
        filter: theFilter,
        sort: sort,
        page: {
          size: pageSize,
          number: page || 1,
        },
      }),
    );
    const data = loadItemResultForJsonApiCollectionResourceDoc(doc);
    return data;
  },
});
