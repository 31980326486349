import { AdminPanelSettings, Close } from "@mui/icons-material";
import {
  Button,
  Grid,
  IconButton,
  Link,
  Skeleton,
  Typography,
} from "@mui/material";
import {
  defaultTo,
  each,
  includes,
  isEmpty,
  isNil,
  merge,
  sortBy,
} from "lodash";
import { Moment } from "moment";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { WidgetController } from "../../controller/widget_controller";
import {
  AssetEventJSONObject,
  DEFAULT_ASSET_EVENT_INCLUDES,
} from "../../json_api/asset_event";
import { EventNotification } from "../../models/event_notification";
import { assetEventPath, assetEventsPath } from "../../utils/urls";
import { AssetEventsWidgetConfigSerialized } from "../../widgets/asset_events_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import { AssetEventDetails } from "../asset_events/asset_event_details";
import { AssetEventWidgetItem } from "../asset_events/asset_event_widget_item";
import { AssetEventWidgetItemCompact } from "../asset_events/asset_event_widget_item_compact";
import { WidgetBox } from "./widget_box";

import { AssetEventsWidgetProps } from "./asset_events_widget.types";
import { SialogicWidgetDefinition } from "./sialogic_widget_component";

import {
  useLoadAssetEventQuery,
  useLoadAssetEventsQuery,
} from "../../queries/asset_events_data";
import { SialogicDialog } from "../common/sialogic_dialog";
import { AppContext } from "../common/app_context/app_context_provider";
import { redirectTo } from "../../utils/redirection";
import { rails_admin_edit_path } from "../../routes";

const EVENT_INCLUDES = [
  "event_type",
  "asset",
  "root_asset",
  "user",
  "event_pattern",
];

export const AssetEventsWidget: React.FunctionComponent<
  AssetEventsWidgetProps
> = ({
  dataUpdateEnabled = true,
  encloseInWidgetBox = true,
  allowFullscreen = true,
  eventListSize = 5,
  displayMode = "detailed",
  ...props
}) => {
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [detailsEvent, setDetailsEvent] = useState<AssetEventJSONObject | null>(
    null,
  );
  const [title, setTitle] = useState(
    defaultTo(
      props.title as string,
      I18n.t("activerecord.models.asset_event.other"),
    ),
  );

  const [events, setEvents] = useState<AssetEventJSONObject[] | null>(
    props.events
      ? sortBy(props.events, (e) => new Date(e.from))?.reverse()
      : null,
  );

  const [titleLinkUrl, setTitleLinkUrl] = useState(
    defaultTo(props.titleLinkUrl, assetEventsPath(props.assetIds[0])),
  );
  const [contentLinkUrl, setContentLinkUrl] = useState(props.contentLinkUrl);

  // Update title if it changes
  useEffect(() => {
    setTitle(defaultTo(props.title as string, title));
  }, [props.title]);

  // Update titleLinkUrl if it changes
  useEffect(() => {
    setContentLinkUrl(props.contentLinkUrl);
  }, [props.contentLinkUrl]);

  useEffect(() => {
    setTitleLinkUrl(props.titleLinkUrl);
  }, [props.titleLinkUrl]);

  const {
    data: { items: loadedEvents },
    isLoading: eventsLoading,
    error: eventsError,
  } = useLoadAssetEventsQuery({
    variables: {
      filter: {
        asset_id: props.assetIds,
        start_after: props.timeRange?.start?.toDate(),
        end_before: props.timeRange?.end?.toDate(),
        severity_level: props.severityLevels,
        event_type_id: props.onlyEventTypeIds,
        except_type: props.exceptEventTypeIds,
      },
      pageOptions: { size: eventListSize, number: 1 },
      includes: DEFAULT_ASSET_EVENT_INCLUDES,
    },
    enabled: isNil(props.events),
    placeholderData: { items: null, totalItems: null, totalPages: null },
  });

  useEffect(() => {
    if (loadedEvents) {
      setEvents(loadedEvents);
    }
  }, [loadedEvents]);

  const handleNewEvent = useCallback(
    (
      event: EventNotification,
      time: Moment,
      assetId: number,
      eventId: number,
    ): void => {
      if (
        !isNil(props.onlyEventTypeIds) &&
        !isEmpty(props.onlyEventTypeIds) &&
        !includes(props.onlyEventTypeIds, event.event_type_id)
      ) {
        return;
      }

      if (
        !isNil(props.exceptEventTypeIds) &&
        !isEmpty(props.exceptEventTypeIds) &&
        includes(props.exceptEventTypeIds, event.event_type_id)
      ) {
        return;
      }

      if (
        !isNil(props.severityLevels) &&
        !isEmpty(props.severityLevels) &&
        !includes(props.severityLevels, event.severity_level)
      ) {
        return;
      }
      if (props.timeRange && !props.timeRange.contains(time)) {
        return;
      }

      setRequestEventId(eventId);
    },
    [
      props.onlyEventTypeIds,
      props.exceptEventTypeIds,
      props.severityLevels,
      props.timeRange,
    ],
  );

  useEffect(() => {
    if (isEmpty(props.assetIds)) return;
    //subcriber object ti handle new events
    let subscribeIds: number[] = [];
    const subscriber = { handleNewEvent };
    if (
      dataUpdateEnabled &&
      (isNil(props.timeRange) || props.timeRange.contains(moment()))
    ) {
      each(props.assetIds, (id) => {
        subscribeIds.push(
          WidgetController.getInstance().assetNotificationChannel.addEventListener(
            subscriber,
            id,
          ),
        );
      });
    }

    return () => {
      if (subscribeIds) {
        each(subscribeIds, (sId) => {
          WidgetController.getInstance().assetNotificationChannel.removeEventListenerId(
            sId,
          );
        });
      }
    };
  }, [props.timeRange, props.assetIds, dataUpdateEnabled, handleNewEvent]);

  const [requestEventId, setRequestEventId] = useState<number>(null);

  const { data: loadedAssetEvent } = useLoadAssetEventQuery({
    variables: {
      id: requestEventId,
      includes: DEFAULT_ASSET_EVENT_INCLUDES,
    },
    enabled: !isNil(requestEventId),
  });

  const appCtx = useContext(AppContext);
  // apply newly loaded event to events, possibly removing the oldest event
  useEffect(() => {
    if (loadedAssetEvent) {
      setEvents((events) => {
        defaultTo(events, []);
        const newLength = events.unshift(loadedAssetEvent);
        if (newLength > eventListSize) {
          events.pop();
        }
        return events;
      });
    }
  }, [loadedAssetEvent]);

  const openDetails = (eventNotification: AssetEventJSONObject) => {
    setDetailsEvent(eventNotification);
    setDetailsOpen(true);
  };

  const eventDetailsDialog = detailsEvent && (
    <SialogicDialog
      fullWidth={true}
      maxWidth="sm"
      onClose={() => {
        setDetailsOpen(false);
      }}
      open={detailsOpen}
      additionTitleActions={
        appCtx?.user?.isAdmin && (
          <IconButton
            title={I18n.t("frontend.edit_in_backend")}
            href={rails_admin_edit_path("asset_event", detailsEvent?.id)}
            color="primary"
          >
            <AdminPanelSettings />
          </IconButton>
        )
      }
      buttons={
        <Button
          autoFocus
          onClick={() => {
            setDetailsOpen(false);
          }}
          color="primary"
        >
          <Close className="mr-2" />
          {I18n.t("frontend.close")}
        </Button>
      }
      title={
        isNil(detailsEvent) ? (
          I18n.t("activerecord.models.asset_event", { count: 1 })
        ) : (
          <Link
            href={assetEventPath(
              detailsEvent?.asset_id,
              detailsEvent?.id as number,
            )}
          >
            {I18n.t("activerecord.models.asset_event", { count: 1 })}
          </Link>
        )
      }
    >
      <AssetEventDetails
        assetEvent={detailsEvent}
        assetEventId={detailsEvent.id}
      />
    </SialogicDialog>
  );

  const compact = displayMode === "compact";
  let content;

  if (eventsLoading) {
    content = <Skeleton variant="rectangular" height={200} />;
  } else if (isEmpty(events)) {
    content = (
      <Grid container spacing={2}>
        <Grid item xs="auto" className="text-center">
          <Typography p={3}>
            {I18n.t("frontend.asset_events.no_events")}
          </Typography>
        </Grid>
      </Grid>
    );
  } else {
    content = events.map((event) => {
      return compact ? (
        <AssetEventWidgetItemCompact
          event={event}
          key={event.id}
          onShowDetails={(event) => {
            openDetails(event);
          }}
        />
      ) : (
        <AssetEventWidgetItem
          event={event}
          key={event.id}
          onShowDetails={(event) => {
            openDetails(event);
          }}
        />
      );
    });
  }

  return (
    <>
      {!encloseInWidgetBox ? (
        content
      ) : (
        <WidgetBox
          {...props}
          contentLinkUrl={contentLinkUrl}
          titleLinkUrl={titleLinkUrl}
          title={title}
        >
          <Grid container spacing={2} display="flex">
            {content}
            <Grid item xs={12} textAlign="end">
              <a
                href={
                  props.assetId
                    ? assetEventsPath(props.assetId)
                    : assetEventsPath()
                }
              >
                {I18n.t("frontend.all")}
              </a>
            </Grid>
          </Grid>
          {eventDetailsDialog}
        </WidgetBox>
      )}
    </>
  );
};

function serializedConfigToProps(
  config: AssetEventsWidgetConfigSerialized,
): AssetEventsWidgetProps {
  return merge(widgetBoxPropsFromSerializedConfig(config), {
    assetIds: config.asset_ids,
    displayMode: config.display_mode,
    exceptEventTypeIds: config.except,
    onlyEventTypeIds: config.only,
    severityLevels: config.severity_levels,
    eventListSize: config.limit,
    events: config.events,
  } as AssetEventsWidgetProps);
}

export const AssetEventsWidgetDefinition: SialogicWidgetDefinition<
  typeof AssetEventsWidget,
  typeof serializedConfigToProps
> = {
  Component: AssetEventsWidget,
  serializedConfigToProps: serializedConfigToProps,
};
