import JSON from "json-typescript";
import * as JSONAPI from "jsonapi-typescript";
import { ConfiguredKpi } from "../models/configured_kpi";
import { AssetTypeJSONObject } from "./asset_type";
import { AssetJSONObject } from "./asset";

export const CONFIGURED_KPI_JSONAPI_RESOURCE_TYPE = "configured_kpis";
export interface ConfiguredKpiJSONObject extends ConfiguredKpi, JSON.Object {
  asset?: AssetJSONObject;
  asset_type?: AssetTypeJSONObject;
  kpi_config?: JSON.Object;
}

export type ConfiguredKpiJsonApiIncludes = "asset_type" | "asset";

export type ConfiguredKpiJSONAPIAttributes =
  JSONAPI.AttributesObject<ConfiguredKpiJSONObject>;
