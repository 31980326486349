import JQuery from "jquery";
import { toNumber } from "lodash";
import * as React from "react";

import { Root, createRoot } from "react-dom/client";
import { AppRoot } from "../common/app_root";
import { MeasurementItemContainer } from "./views/measurement_item_container";
import { MeasurementList } from "./views/measurement_list";

const measurementListRoots: Root[] = [];
export function initializeMeasurementList(
  itemOrSelector: JQuery | string = '[data-toggle="measurement-list"]',
): void {
  let item: JQuery;
  if (itemOrSelector instanceof JQuery) {
    item = itemOrSelector;
  } else {
    item = $(itemOrSelector as string);
  }

  item.each((index, element) => {
    const el = $(element);

    const assetId = toNumber(el.data("asset-id") as string);
    const measurementPlanId = toNumber(
      el.data("measurement-plan-id") as string,
    );
    const root = createRoot(element);
    measurementListRoots.push(root);
    root.render(
      <AppRoot>
        <MeasurementList
          assetId={assetId}
          measurementPlanId={measurementPlanId}
        />
      </AppRoot>,
    );
  });
}

export function destroyMeasurementList() {
  measurementListRoots.forEach((r) => r.unmount());
  measurementListRoots.length = 0;
}

const measurementItemRoots: Root[] = [];
export function initializeMeasurementItem(
  itemOrSelector: JQuery | string = '[data-toggle="measurement-item-view"]',
): void {
  let item: JQuery;
  if (itemOrSelector instanceof JQuery) {
    item = itemOrSelector;
  } else {
    item = $(itemOrSelector as string);
  }

  item.each((index, element) => {
    const el = $(element);

    const measurementId = el.data("measurement-id") as number;

    const root = createRoot(element);
    measurementItemRoots.push(root);
    root.render(
      <AppRoot>
        <MeasurementItemContainer measurementId={measurementId} />
      </AppRoot>,
    );
  });
}

export function destroyMeasurementItems() {
  measurementItemRoots.forEach((r) => r.unmount());
  measurementItemRoots.length = 0;
}
