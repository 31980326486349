import { groupBy, isFunction, isNil, keys, toString } from "lodash";
import * as React from "react";

import { Circle } from "@mui/icons-material";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import {
  ChartStatType,
  ChartStatistics,
  chartStatisticsTextLine,
} from "../chart_data/chart_data_statistics";

export interface ChartStatisticsDisplayProps {
  chartStatistics: ChartStatistics[];
  color?: string | ((index: number) => string);
}

interface StatsLineProps {
  statistics: ChartStatistics;
  statsName: ChartStatType;
  label: string;
  unit?: boolean;
  digits?: number;
  color?: string;
}
const StatsLine: React.FunctionComponent<StatsLineProps> = ({
  statistics,
  statsName,
  label,
  digits = 3,
  unit = true,
}) => {
  return (
    <Box component="span" ml={0.5}>
      <Box component="span" mr="2px" fontWeight="bold">
        {label}:
      </Box>
      {chartStatisticsTextLine(
        statsName,
        statistics,
        digits,
        unit ? statistics.unit : "",
      )}
    </Box>
  );
};

export const ChartStatisticsDisplay: React.FunctionComponent<
  ChartStatisticsDisplayProps
> = (props) => {
  const statisticsGroups = React.useMemo(() => {
    const groups = groupBy(props.chartStatistics, (s) =>
      toString(s.legendGroup),
    );

    let groupArr: [string, ChartStatistics[]][] = keys(groups).map(
      (groupName) => {
        return [!groupName ? "" : toString(groupName), groups[groupName]];
      },
    );
    groupArr = groupArr.sort((a, b) => a[0].localeCompare(b[0]));
    return groupArr;
  }, [props.chartStatistics]);
  return (
    <Grid container spacing={2}>
      {statisticsGroups.map(([groupName, statistics], index) => {
        return (
          <Grid
            item
            container
            xs={12}
            lg={statisticsGroups && statisticsGroups.length > 1 ? 6 : 12}
            key={index}
          >
            {groupName && (
              <Grid item xs={12}>
                <Typography variant="caption">{groupName}</Typography>
              </Grid>
            )}
            {statistics.map((statistics, index) => {
              if (isNil(statistics)) {
                return null;
              } else {
                return (
                  <Tooltip
                    title={I18n.t("frontend.charts.statistics.tooltip", {
                      name: statistics.name,
                    })}
                    key={index}
                  >
                    <Grid
                      item
                      xs={12}
                      lg={
                        statisticsGroups && statisticsGroups.length > 1 ? 12 : 6
                      }
                      key={index}
                    >
                      <Box component="span" ml={1}>
                        <Circle
                          htmlColor={
                            statistics.color ??
                            (isFunction(props.color)
                              ? props.color(index)
                              : props.color)
                          }
                          fontSize="inherit"
                        />
                      </Box>
                      <Box component="small" ml={1}>
                        <StatsLine
                          statistics={statistics}
                          statsName={"min"}
                          label={"Min"}
                        />
                        <StatsLine
                          statistics={statistics}
                          statsName={"max"}
                          label={"Max"}
                        />
                        <StatsLine
                          statistics={statistics}
                          statsName={"minMaxDiv"}
                          label={"∆"}
                        />
                        <StatsLine
                          statistics={statistics}
                          statsName={"average"}
                          label={"Ø"}
                        />
                        <StatsLine
                          statistics={statistics}
                          statsName={"count"}
                          label={"#"}
                          digits={0}
                          unit={false}
                        />
                      </Box>
                    </Grid>
                  </Tooltip>
                );
              }
            })}
          </Grid>
        );
      })}
    </Grid>
  );
};
