import { UIOptionsType } from "@rjsf/utils";
import { MeasurementCategorization } from "./measurement_categorization";
import { MeasurementValueDefinition } from "./measurement_value_definition";
import { Measurement } from "./measurement";
import { IDType } from "../utils/urls/url_utils";
export type MeasurementTypes =
  | "MeasurementTypes::IndependentMeasurementType"
  | "MeasurementTypes::DistributionMeasurementType";
export type MeasurementTypesUnderscore =
  | "independent_measurement_type"
  | "distribution_measurement_type";

export const AllMeasurementTypeTypes: MeasurementTypes[] = [
  "MeasurementTypes::IndependentMeasurementType",
  "MeasurementTypes::DistributionMeasurementType",
];

export const AllMeasurementTypeTypesUnderscore: MeasurementTypesUnderscore[] = [
  "independent_measurement_type",
  "distribution_measurement_type",
];

export enum MeasurementTypeVariants {
  independent,
  distribution,
}

export type MeasurementTypeDiagramType = "bar" | "line" | "radar" | "pie";

export type MeasurementTypeRefCompMode =
  | "ref_comp_percentage"
  | "ref_comp_absolute";
export interface MeasurementTypeAttributes {
  id?: IDType;
  type?: MeasurementTypes;
  type_short?: MeasurementTypesUnderscore;
  title?: string;
  key?: string;
  description?: string;
  allow_attachments?: boolean;
  allow_measurement_notes?: boolean;
  measurement_categorization_id?: IDType;
  interval_unit?: string;
  reference_measurement_id?: IDType;
  ref_measurement_comp_mode?: MeasurementTypeRefCompMode;
  ref_measurement_comp_high?: number;
  ref_measurement_comp_low?: number;
  ref_measurement_type_key?: string;
  meta_info?: UIOptionsType;
  diagram_type?: MeasurementTypeDiagramType;
}

export interface MeasurementType extends MeasurementTypeAttributes {
  measurement_value_definitions?: MeasurementValueDefinition[];
  measurement_categorization?: MeasurementCategorization;
  reference_measurement?: Measurement;
}

export interface MeasurementTypeSubmit extends MeasurementTypeAttributes {
  measurement_value_definitions_attributes?: MeasurementValueDefinition[];
  measurement_categorization?: MeasurementCategorization;
}
