import { Box, Grid, Skeleton } from "@mui/material";
import { Dictionary, chain, isEmpty, isNil, sortBy, uniq } from "lodash";
import * as React from "react";

import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import {
  MeasurementJSONApiAttributes,
  MeasurementJsonObject,
} from "../../../json_api/measurement";
import {
  MeasurementPlanJSONApiAttributes,
  MeasurementPlanJSONAPIAttributesObject,
} from "../../../json_api/measurement_plan";
import { MeasurementCategory } from "../../../models/measurement_category";
import { MeasurementValueDefinition } from "../../../models/measurement_value_definition";
import { useLoadMeasurements } from "../../../queries/measurements_data";
import { useLoadMeasurementPlan } from "../../../queries/measurements_plan_data";

import { getValueString } from "../../../utils/value_format";
import { MeasurementFilter, MeasurementFilterView } from "./measurement_filter";
import { LoadItemsResult } from "../../../json_api/jsonapi_tools";
import { IDType } from "../../../utils/urls/url_utils";

interface MeasurementItemAction {
  name: string;
  icon?: React.ReactElement;
  inDropdown?: boolean;
  action: (measurement: MeasurementJsonObject) => void;
}
interface MeasurementTableProperties {
  measurementPlanId?: string | number;
  measurementPlan?: MeasurementPlanJSONApiAttributes;
  measurements?: MeasurementJsonObject[];
  // the reference measurement may originate from a different measurement plan so we need to pass it explicitly
  referenceMeasurementId?: IDType | null;

  multiselect?: boolean;
  pageSize?: number;
  onSelect?: (measurements: MeasurementJsonObject[]) => void;
  itemActions?: MeasurementItemAction[];
}

function getGridColDef(
  measurementPlan: MeasurementPlanJSONAPIAttributesObject,
  measurementValueDefinitions: MeasurementValueDefinition[],
  referenceMeasurementId: IDType | null,
  actions: MeasurementItemAction[] = [],
): GridColDef<MeasurementJsonObject>[] {
  const defs = sortBy(measurementValueDefinitions, "position");

  const showPercentage =
    measurementPlan?.measurement_type_type ===
      "distribution_measurement_type" &&
    measurementPlan?.measurement_type?.interval_unit !== "%";

  const valueDefsById = chain(measurementValueDefinitions).keyBy("id").value();
  const cols: GridColDef<MeasurementJsonObject>[] = [
    {
      field: "id",
      headerName: "#",
    },
    {
      field: "is_reference",
      type: "boolean",
      headerName: I18n.t("activerecord.attributes.measurement.is_reference"),
      valueGetter: (value, row) => {
        return row.id == referenceMeasurementId;
      },
    },
    {
      field: "time",
      type: "dateTime",
      width: 150,
      headerName: I18n.t("activerecord.attributes.measurement.time"),
      valueGetter: (value, row) => {
        return new Date(row.time);
      },
    },
    {
      field: "created_by",

      type: "string",
      headerName: I18n.t("activerecord.attributes.measurement.created_by"),
    },
  ];

  measurementValueDefinitions.forEach((valueDef) => {
    cols.push({
      field: `value_${valueDef.id}`,
      headerName: valueDef.title,
      description: valueDef.measurement_category?.title,
      type: "number",

      valueGetter: (value, row) => {
        const v = row.measurement_values?.find(
          (v) => v.measurement_value_definition_id == valueDef.id,
        );
        return v ? v.value : null;
      },
      renderCell: ({ value }) => {
        return `${getValueString(value, 2)}${valueDef.unit ? " " + valueDef.unit : ""}`;
      },
    });
  });
  if (!isEmpty(actions)) {
    cols.push({
      field: "actions",
      type: "actions",
      headerName: I18n.t("base.actions"),

      getActions: ({ row }) => {
        return actions.map((action) => {
          return (
            <GridActionsCellItem
              key={action.name}
              onClick={() => action.action(row)}
              showInMenu={action.inDropdown}
              icon={action.icon}
              label={action.name}
            />
          );
        });
      },
    });
  }
  return cols;
}

export const MeasurementTable: React.FC<MeasurementTableProperties> = ({
  measurementPlanId,
  multiselect = true,
  measurementPlan: measurementPlanFromProps,
  measurements: measurementsFromProps,
  referenceMeasurementId,
  pageSize = 10,
  onSelect = null,
  itemActions = [],
}) => {
  const [pageSettings, setPageSettings] = React.useState({
    number: 1,
    pageSize: pageSize,
  });

  const pageSizes = React.useMemo(() => {
    const sizes = [5, 10, 20, 50, 100];
    sizes.push(pageSize || 10);
    return uniq(sizes).sort();
  }, [pageSize]);

  const {
    data: measurementPlan,
    isLoading: measurementPlanLoading,
    error: measurementPlanLoadError,
  } = useLoadMeasurementPlan({
    variables: {
      id: measurementPlanId,
      include: [
        "measurement_type",
        "measurement_value_definitions",
        "measurement_categories",
        "asset",
      ],
    },
    initialData:
      measurementPlanFromProps as MeasurementPlanJSONAPIAttributesObject,
  });

  const [measurementValueDefinitions, setMeasurementValueDefinitions] =
    React.useState<MeasurementValueDefinition[]>([]);
  const [measurementCategoriesById, setMeasurementCategoriesById] =
    React.useState<Dictionary<MeasurementCategory>>({});

  // extract categories and value definitions from measurement plan
  React.useEffect(() => {
    if (measurementPlan) {
      const categoriesById = chain(measurementPlan?.measurement_categories)
        .keyBy("id")
        .value();
      setMeasurementCategoriesById(categoriesById);

      // sort by position
      const measurementValueDefinitions =
        measurementPlan?.measurement_value_definitions;
      setMeasurementValueDefinitions(
        sortBy(measurementValueDefinitions, "position"),
      );
    }
  }, [measurementPlan]);

  const gridColDef: GridColDef<MeasurementJSONApiAttributes>[] =
    React.useMemo(() => {
      if (measurementPlan && measurementValueDefinitions) {
        return getGridColDef(
          measurementPlan,
          measurementValueDefinitions,
          referenceMeasurementId,
          itemActions,
        );
      } else {
        return null as GridColDef[];
      }
    }, [measurementPlan, measurementValueDefinitions, itemActions]);

  const [filter, setFilter] = React.useState<MeasurementFilter>({});

  const paginationMode = measurementsFromProps ? "client" : "server";
  const {
    data: loadedMeasurements,
    isLoading,
    error,
  } = useLoadMeasurements({
    variables: {
      measurementPlanId: measurementPlanId,
      filter,
      page: pageSettings.number,
      pageSize: pageSettings.pageSize,
    },
    enabled: !isNil(measurementPlanId) && paginationMode == "server",
    initialData: measurementsFromProps
      ? {
          totalItems: measurementsFromProps.length,
          items: measurementsFromProps,
          totalPages: 1,
        }
      : undefined,
  });

  const [measurements, setMeasurements] = React.useState<
    LoadItemsResult<MeasurementJsonObject>
  >(
    measurementsFromProps
      ? {
          totalItems: measurementsFromProps.length,
          items: measurementsFromProps,
          totalPages: 1,
        }
      : loadedMeasurements,
  );

  React.useEffect(() => {
    if (measurementsFromProps) {
      setMeasurements({
        totalItems: measurementsFromProps.length,
        items: measurementsFromProps,
        totalPages: 1,
      });
    } else {
      if (loadedMeasurements) {
        setMeasurements(loadedMeasurements);
      }
    }
  }, [loadedMeasurements, measurementsFromProps]);

  if (measurementPlanLoading || !gridColDef) {
    return <Skeleton variant="rectangular" height={400} />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MeasurementFilterView filter={filter} onFilterChange={setFilter} />
      </Grid>
      <Grid item xs={12}>
        <Box display={"flex"} flexDirection="column">
          <DataGrid
            density="compact"
            disableMultipleRowSelection={!multiselect}
            checkboxSelection={true}
            rows={measurements?.items || []}
            columns={gridColDef}
            pageSizeOptions={pageSizes}
            paginationMode={paginationMode}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                  created_by: false,
                  time: true,
                  is_reference: !isNil(referenceMeasurementId),
                },
              },
            }}
            loading={isLoading}
            rowCount={
              paginationMode == "client"
                ? undefined
                : measurements?.totalItems || 0
            }
            rowSelection={true}
            onRowSelectionModelChange={(selection) => {
              if (onSelect) {
                const selectedMeasurements = selection.map((i) =>
                  measurements?.items.find((m) => m.id == i),
                );
                onSelect(selectedMeasurements);
              }
            }}
            paginationModel={{
              pageSize: pageSettings.pageSize,
              page: (pageSettings.number || 1) - 1,
            }}
            onPaginationModelChange={(paginationModel) => {
              setPageSettings({
                number: paginationModel.page + 1,
                pageSize: paginationModel.pageSize,
              });
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
