import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Switch,
  Typography,
} from "@mui/material";

import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import AssignmentIcon from "@mui/icons-material/Assignment";
import EditIcon from "@mui/icons-material/Edit";

import PieIcon from "@mui/icons-material/PieChart";

import { isEmpty } from "lodash";
import moment from "moment";
import * as React from "react";
import { MeasurementPlanJSONAPIAttributesObject } from "../../../json_api/measurement_plan";
import { MeasurementPlan } from "../../../models/measurement_plan";
import { rruleToText } from "../../../utils/rotation_description";
import {
  assetMeasurementPlanMeasurementPath,
  assetMeasurementPlansMeasurementsPath,
  editAssetMeasurementPlanPath,
  newAssetMeasurementPath,
} from "../../../utils/urls";
import { IBox, IBoxContent } from "../../common/ibox";

import { AdminPanelSettings, Close, Settings } from "@mui/icons-material";
import { rails_admin_edit_path } from "../../../routes";
import { AppContext } from "../../common/app_context/app_context_provider";
import { EmailNotificationIcon } from "../../common/email_notification_icon";
import { SialogicDialog } from "../../common/sialogic_dialog";
import { MeasurementPlanSettings } from "./measurement_plan_settings";
import { ErrorBoundary } from "../../common/error_boundary";
import { error } from "../../../utils/toasts";

interface MeasurementPlanItemProperties {
  measurementPlan: MeasurementPlanJSONAPIAttributesObject;
  onSubscribe?: (plan: MeasurementPlanJSONAPIAttributesObject) => void;
  onUnsubscribe?: (plan: MeasurementPlanJSONAPIAttributesObject) => void;
}

export const MeasurementPlanItem: React.FunctionComponent<
  MeasurementPlanItemProperties
> = (props) => {
  const handleSubscriptionSwitch = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (props.measurementPlan.subscribed) {
        props.onUnsubscribe(props.measurementPlan);
      } else {
        props.onSubscribe(props.measurementPlan);
      }
    },
    [props.measurementPlan.subscribed],
  );

  const appCtx = React.useContext(AppContext);

  const [
    showMeasurementPlanSettingsDialog,
    setShowMeasurementPlanSettingsDialog,
  ] = React.useState(false);

  return (
    <>
      <IBox>
        <IBoxContent>
          <Grid
            container
            className="align-items-center w-100"
            spacing={2}
            justifyContent="space-between"
          >
            <Grid item xs={12} lg={3}>
              <Typography variant="h6" className="auto-hyphen">
                <Box component="span" marginRight={2}>
                  {props.measurementPlan.measurement_type_type ===
                  "distribution_measurement_type" ? (
                    <PieIcon />
                  ) : (
                    <AssignmentIcon />
                  )}
                </Box>
                <Link
                  href={assetMeasurementPlansMeasurementsPath(
                    props.measurementPlan.asset_id,
                    props.measurementPlan.id,
                    "html",
                  )}
                  title={
                    I18n.t(
                      "frontend.measurement_plan_item.show_measurement_list",
                    ) as string
                  }
                >
                  {props.measurementPlan.measurement_type_title}
                </Link>
              </Typography>
            </Grid>
            <Grid item container xs={12} lg="auto" spacing={2}>
              <Grid item xs={4}>
                <Typography component="div" variant="caption">
                  {I18n.t("activerecord.attributes.measurement_plan.rrule", {
                    count: 1,
                  })}
                </Typography>

                <Typography variant="subtitle2">
                  {rruleToText(props.measurementPlan.rrule)}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                {isEmpty(props.measurementPlan.next_measurement_at) ? null : (
                  <>
                    <Typography component="div" variant="caption">
                      {I18n.t(
                        "activerecord.attributes.measurement_plan.next_scheduled_at",
                      )}
                    </Typography>

                    <Typography variant="subtitle2">
                      {moment(props.measurementPlan.next_measurement_at).format(
                        "L LT",
                      )}
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid item xs={4}>
                <Typography component="div" variant="caption">
                  {I18n.t("frontend.measurement_plan_item.last_measurement")}
                </Typography>

                <Typography>
                  {isEmpty(props.measurementPlan.last_measurement_at) ? (
                    I18n.t("frontend.measurement_plan_item.no_last_measurement")
                  ) : (
                    <Link
                      href={assetMeasurementPlanMeasurementPath(
                        props.measurementPlan.asset_id,
                        props.measurementPlan.id,
                        props.measurementPlan.last_measurement_id,
                        "html",
                      )}
                      title={
                        I18n.t(
                          "frontend.measurement_plan_item.show_last_measurement",
                        ) as string
                      }
                    >
                      {moment(props.measurementPlan.last_measurement_at).format(
                        "L LT",
                      )}
                    </Link>
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} xl="auto">
              <Grid container alignItems="revert">
                <Grid item>
                  <Box my="auto">
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          checked={props.measurementPlan.subscribed}
                          onChange={(e) => handleSubscriptionSwitch(e)}
                        />
                      }
                      title={
                        props.measurementPlan.subscribed
                          ? I18n.t("frontend.subscribed")
                          : I18n.t("frontend.unsubscribed")
                      }
                      label={
                        <Typography sx={{ fontSize: "0.8rem" }}>
                          <EmailNotificationIcon
                            enabled={props.measurementPlan.subscribed}
                          />
                        </Typography>
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs textAlign="right">
                  <IconButton
                    aria-label={I18n.t("frontend.edit")}
                    title={I18n.t("frontend.edit")}
                    href={editAssetMeasurementPlanPath(
                      props.measurementPlan.asset_id,
                      props.measurementPlan.id,
                    )}
                    size="large"
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    aria-label={I18n.t(
                      "frontend.measurement_plan_item.new_measurement",
                    )}
                    title={I18n.t(
                      "frontend.measurement_plan_item.new_measurement",
                    )}
                    color="primary"
                    href={newAssetMeasurementPath(
                      props.measurementPlan.asset_id,
                      props.measurementPlan.id,
                    )}
                    size="large"
                  >
                    <AddCircleOutline />
                  </IconButton>
                  <IconButton
                    aria-label={I18n.t(
                      "frontend.measurement_plan_item.measurement_plan_settings",
                    )}
                    title={I18n.t(
                      "frontend.measurement_plan_item.measurement_plan_settings",
                    )}
                    size="large"
                    color="secondary"
                    onClick={() => {
                      setShowMeasurementPlanSettingsDialog(true);
                    }}
                  >
                    <Settings />
                  </IconButton>
                  {appCtx.user?.isAdmin ? (
                    <IconButton
                      className="backend-link toggleable"
                      title={
                        I18n.t("base.edit_in_backend") +
                        ` key: ${props.measurementPlan.key}`
                      }
                      href={rails_admin_edit_path(
                        "MeasurementPlan",
                        props.measurementPlan.id,
                      )}
                    >
                      <AdminPanelSettings />
                    </IconButton>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </IBoxContent>
      </IBox>
      {showMeasurementPlanSettingsDialog ? (
        <ErrorBoundary onError={(err) => error(err.message)}>
          <SialogicDialog
            title={I18n.t(
              "frontend.measurement_plan_item.measurement_plan_settings_title",
              { plan: props.measurementPlan.measurement_type_title },
            )}
            open={showMeasurementPlanSettingsDialog}
            onClose={() => {
              setShowMeasurementPlanSettingsDialog(false);
            }}
            buttons={
              <Button
                onClick={() => setShowMeasurementPlanSettingsDialog(false)}
                startIcon={<Close />}
              >
                {I18n.t("frontend.close")}
              </Button>
            }
          >
            <MeasurementPlanSettings
              measurementPlan={props.measurementPlan}
              onCancel={() => {
                setShowMeasurementPlanSettingsDialog(false);
              }}
              onMeasurementPlanChange={(mp) => {
                setShowMeasurementPlanSettingsDialog(false);
              }}
            />
          </SialogicDialog>
        </ErrorBoundary>
      ) : null}
    </>
  );
};
