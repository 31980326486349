import { TableChart } from "@mui/icons-material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ReplayIcon from "@mui/icons-material/Replay";
import {
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  Tooltip,
} from "@mui/material";
import { DataGrid, GridRowSelectionModel } from "@mui/x-data-grid";
import { defaultTo, isNil } from "lodash";
import * as React from "react";
import {
  SUBSCRIPTION_PERIOD_JSONAPI_RESOURCE_TYPE,
  SubscriptionPeriodJSONAPIAttributes,
} from "../../json_api/subscription_period";
import {
  rails_admin_edit_path,
  regenerate_stats_api_organization_subscription_subscription_period_path,
  stats_subscription_subscription_period_path,
} from "../../routes";
import { sendData } from "../../utils/jquery_helper";
import { error, success } from "../../utils/toasts";
import { IDType } from "../../utils/urls/url_utils";
import { AppContext } from "../common/app_context/app_context_provider";
import { LoadingIcon } from "../common/icon";
import { SialogicQueryClient } from "../common/sialogic_query_client";
import { useLoadSubscriptionPeriodsQuery } from "../../queries/subscription_period_data";
import { SubscriptionPeriodsActionsDropdown } from "./subscription_periods_actions_dropdown";
import { useConfirm } from "material-ui-confirm";

interface SubscriptionPeriodsListProps {
  subscriptionId: IDType;
  organizationId: IDType;
  pageNumber?: number;
  pageSize?: number;
}

export const SubscriptionPeriodList: React.FunctionComponent<
  SubscriptionPeriodsListProps
> = ({ pageNumber = 1, pageSize = 12, ...props }) => {
  const context = React.useContext(AppContext);

  const [selectedPeriods, setSelectedPeriods] =
    React.useState<GridRowSelectionModel>([]);

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize,
    page: pageNumber - 1,
  });

  const [totalPeriods, setTotalPeriods] = React.useState<number>(null);

  const confirm = useConfirm();

  const {
    isLoading: periodsLoading,
    data: {
      items: subscriptionPeriods,
      totalItems: fetchedTotalPeriods,
      totalPages: fetchedTotalPages,
    },
  } = useLoadSubscriptionPeriodsQuery({
    enabled: props.organizationId !== null && props.subscriptionId !== null,
    variables: {
      orgId: props.organizationId,
      subscriptionId: props.subscriptionId,
      pageSettings: paginationModel,
    },
    placeholderData: {
      items: [],
      totalItems: 0,
      totalPages: 0,
    },
  });

  React.useEffect(() => {
    setTotalPeriods(fetchedTotalPeriods);
  }, [fetchedTotalPeriods]);

  return (
    <Card>
      <CardHeader
        title={I18n.t("activerecord.models.subscription_period.other")}
      />
      <CardContent>
        {periodsLoading ? (
          <Grid container alignContent="center">
            <Grid item xs="auto">
              <LoadingIcon size="4x" />
            </Grid>
          </Grid>
        ) : isNil(subscriptionPeriods) ? null : (
          <Grid container alignContent="center" spacing={1}>
            <Grid
              item
              xs={12}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <DataGrid
                autoHeight
                rows={defaultTo(
                  subscriptionPeriods,
                  [] as SubscriptionPeriodJSONAPIAttributes[],
                )}
                rowSelectionModel={selectedPeriods}
                paginationMode="server"
                paginationModel={paginationModel}
                loading={periodsLoading}
                pageSizeOptions={[6, 12, 24]}
                onPaginationModelChange={(newModel) => {
                  setPaginationModel({ ...newModel });
                }}
                rowCount={defaultTo(totalPeriods, 0)}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      id: false,
                    },
                  },
                }}
                getRowId={(row) => row.id}
                checkboxSelection
                onRowSelectionModelChange={(model) => {
                  setSelectedPeriods(model);
                }}
                isRowSelectable={(params) => params.row.state === "closed"}
                columns={[
                  {
                    field: "id",

                    headerName: "#",

                    type: "number",
                    flex: 0.1,
                  },
                  {
                    field: "starts_at",
                    type: "date",

                    headerName: I18n.t(
                      "activerecord.attributes.subscription_period.starts_at",
                    ),
                    flex: 0.2,
                    valueGetter: (value, row) => new Date(row.starts_at),
                  },
                  {
                    field: "ends_at",
                    type: "date",

                    headerName: I18n.t(
                      "activerecord.attributes.subscription_period.ends_at",
                    ),
                    flex: 0.2,
                    valueGetter: (value, row) => new Date(row.ends_at),
                  },
                  {
                    field: "state",
                    type: "string",
                    headerName: I18n.t(
                      "activerecord.attributes.subscription_period.state",
                    ),
                    flex: 0.2,
                    renderCell: (params) => (
                      <Chip
                        size="small"
                        label={I18n.t(
                          `activerecord.attributes.subscription_period.states.${params.row.state}`,
                        )}
                        variant="outlined"
                        color="primary"
                      />
                    ),
                  },
                  {
                    field: "actions",
                    filterable: false,
                    width: 200,
                    type: "actions",

                    headerName: "",

                    renderCell: (params) =>
                      params.row.state != "closed" ? null : (
                        <ButtonGroup size="small">
                          <Tooltip
                            title={I18n.t(
                              "frontend.subscription_periods.list.actions.regenerate_stats.tooltip",
                            )}
                          >
                            <Button
                              startIcon={<ReplayIcon />}
                              size="small"
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                void confirm().then(() => {
                                  sendData<
                                    unknown,
                                    { success: boolean; error?: string }
                                  >(
                                    regenerate_stats_api_organization_subscription_subscription_period_path(
                                      props.organizationId,
                                      props.subscriptionId,
                                      params.row.id,
                                    ),
                                    null,
                                    "PATCH",
                                  )
                                    .then((resp) => {
                                      if (resp.success) {
                                        SialogicQueryClient.invalidateQueries({
                                          queryKey: [
                                            SUBSCRIPTION_PERIOD_JSONAPI_RESOURCE_TYPE,
                                          ],
                                        });
                                        void success(
                                          I18n.t(
                                            "frontend.subscription_periods.list.actions.regenerate_stats.success",
                                          ),
                                        );
                                      }
                                    })
                                    .catch((e) => {
                                      error(
                                        I18n.t(
                                          "frontend.subscription_periods.list.actions.regenerate_stats.error",
                                          e.message,
                                        ),
                                      );
                                    });
                                });
                              }}
                            />
                          </Tooltip>
                          <Tooltip
                            title={I18n.t(
                              "frontend.subscription_periods.list.actions.show_stats.tooltip",
                            )}
                          >
                            <Button
                              startIcon={<TableChart />}
                              size="small"
                              onClick={(event) => {
                                event.preventDefault();
                                window.open(
                                  stats_subscription_subscription_period_path(
                                    props.subscriptionId,
                                    params.row.id,
                                    { locale: I18n.locale, _options: true },
                                  ),
                                  "_blank",
                                );
                              }}
                            />
                          </Tooltip>
                          rails_admin_edit_path
                          {context.user?.isAdmin && (
                            <Tooltip
                              title={I18n.t(
                                "frontend.subscription_periods.list.actions.edit_in_backend.tooltip",
                              )}
                            >
                              <Button
                                startIcon={<AdminPanelSettingsIcon />}
                                size="small"
                                onClick={(event) => {
                                  event.preventDefault();
                                  window.open(
                                    rails_admin_edit_path(
                                      "SubscriptionPeriod",
                                      params.row.id,
                                    ),
                                    "_blank",
                                  );
                                }}
                              />
                            </Tooltip>
                          )}
                        </ButtonGroup>
                      ),
                  },
                ]}
              />
            </Grid>
          </Grid>
        )}
      </CardContent>
      <CardActions>
        <SubscriptionPeriodsActionsDropdown
          selectedPeriodIds={selectedPeriods as IDType[]}
          subscriptionId={props.subscriptionId}
          organizationId={props.organizationId || context.currentOrganizationId}
        />
      </CardActions>
    </Card>
  );
};
