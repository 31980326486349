import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CollectionResourceDoc, SingleResourceDoc } from "jsonapi-typescript";
import { createQuery } from "react-query-kit";
import {
  jsonApiSingleResourceToFlatObject,
  loadItemResultForJsonApiCollectionResourceDoc,
  LoadItemsResult,
} from "../json_api/jsonapi_tools";
import { MEASUREMENT_JSON_API_TYPE } from "../json_api/measurement";
import {
  MEASUREMENT_PLAN_JSON_API_TYPE,
  MeasurementPlanJSONAPIAttributesObject,
  MeasurementPlanJsonApiIncludes,
} from "../json_api/measurement_plan";
import {
  api_measurement_plan_path,
  api_measurement_plans_path,
  delete_reference_measurement_api_measurement_plan_path,
  set_reference_measurement_api_measurement_plan_path,
} from "../routes";
import { loadDataFromUrl, sendData } from "../utils/jquery_helper";
import { IDType } from "../utils/urls/url_utils";
import { castArray, isEmpty, isNil, omitBy, toArray } from "lodash";
import { MeasurementPlanAttributes } from "../models/measurement_plan";
import {
  assetMeasurementPlanSubscribePath,
  assetMeasurementPlanUnsubscribePath,
} from "../utils/urls";

export const useDeleteMeasurementPlan = () => {
  const client = useQueryClient();
  return useMutation({
    mutationFn: (id: number) => {
      return sendData(api_measurement_plan_path(id), "DELETE");
    },
    onSuccess: () => {
      client.invalidateQueries({ queryKey: [MEASUREMENT_PLAN_JSON_API_TYPE] });
    },
  });
};

export const useSetReferenceForMeasurementPlan = () => {
  const client = useQueryClient();
  return useMutation<
    unknown,
    Error,
    { id: IDType; referenceMeasurementId: IDType }
  >({
    mutationFn: ({ id, referenceMeasurementId }) => {
      if (referenceMeasurementId === null) {
        return sendData(
          delete_reference_measurement_api_measurement_plan_path(id, {
            format: "json",
          }),
          null,
          "DELETE",
        );
      } else {
        return sendData(
          set_reference_measurement_api_measurement_plan_path(
            id,
            referenceMeasurementId,
            { format: "json" },
          ),
          null,
          "PUT",
        );
      }
    },
    onSuccess: () => {
      client.invalidateQueries({ queryKey: [MEASUREMENT_PLAN_JSON_API_TYPE] });
    },
  });
};

export interface MeasurementPlanFilter {
  key?: string;
  asset_id?: IDType | IDType[];
  with_key?: boolean;
}

export type MeasurementPlanAvailableFields = keyof MeasurementPlanAttributes;

export const useLoadMeasurementPlan = createQuery<
  MeasurementPlanJSONAPIAttributesObject,
  {
    id: IDType;
    include?: MeasurementPlanJsonApiIncludes[];
    filter?: MeasurementPlanFilter;
  }
>({
  queryKey: [MEASUREMENT_PLAN_JSON_API_TYPE],
  fetcher: async ({ id, include }) => {
    const doc = await loadDataFromUrl<
      SingleResourceDoc<string, MeasurementPlanJSONAPIAttributesObject>
    >(
      api_measurement_plan_path(id, {
        include: include?.join(","),
        _options: true,
      }),
    );
    const data = jsonApiSingleResourceToFlatObject(doc);
    return data;
  },
});

export const useLoadMeasurementPlans = createQuery<
  LoadItemsResult<MeasurementPlanJSONAPIAttributesObject>,
  {
    include?: MeasurementPlanJsonApiIncludes[];
    filter?: MeasurementPlanFilter;
    fields?: (keyof MeasurementPlanAttributes)[];
    page?: { size: number; number: number };
  }
>({
  queryKey: [MEASUREMENT_PLAN_JSON_API_TYPE],
  fetcher: async ({ include, filter, page, fields }) => {
    const doc = await loadDataFromUrl<
      CollectionResourceDoc<string, MeasurementPlanJSONAPIAttributesObject>
    >(
      api_measurement_plans_path({
        include: include?.join(","),
        _options: true,
        filter: omitBy(
          {
            key: filter?.key,
            asset_id: castArray(filter?.asset_id).join(","),
            with_key: filter?.with_key,
          },
          (v, k) => !Boolean(v),
        ),
        page: page,
        fields: fields
          ? {
              measurement_plans: fields.join(","),
            }
          : undefined,
      }),
    );
    const data = loadItemResultForJsonApiCollectionResourceDoc(doc);
    return data;
  },
});

export const useMeasurementPlanChangeSubscriptionStatus = (
  invalidateOnSuccess: boolean = true,
) => {
  const client = useQueryClient();
  return useMutation<
    MeasurementPlanJSONAPIAttributesObject,
    Error,
    {
      measurementPlan: MeasurementPlanJSONAPIAttributesObject;
      subscribe: boolean;
    }
  >({
    mutationFn: async ({ measurementPlan, subscribe }) => {
      const result = await sendData<
        unknown,
        SingleResourceDoc<string, MeasurementPlanJSONAPIAttributesObject>
      >(
        subscribe
          ? assetMeasurementPlanSubscribePath(
              measurementPlan.asset_id,
              measurementPlan.id,
              "json",
            )
          : assetMeasurementPlanUnsubscribePath(
              measurementPlan.asset_id,
              measurementPlan.id,
              "json",
            ),
        "",
        "PATCH",
      );

      return jsonApiSingleResourceToFlatObject(result);
    },
    onSuccess: () => {
      if (invalidateOnSuccess) {
        client.invalidateQueries({
          queryKey: [MEASUREMENT_PLAN_JSON_API_TYPE],
        });
      }
    },
  });
};
