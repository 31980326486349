import React from "react";
import { IDType } from "../../utils/urls/url_utils";
import { Box, Grid, Paper } from "@mui/material";
import { ConfiguredKpiList } from "../../components/configured_kpis/configured_kpi_list";
import { ResourcePermission } from "../../models/resource_permission";
import { SialogicDialog } from "../../components/common/sialogic_dialog";
import { ConfiguredKpiPage } from "./configured_kpi_page";
import { ConfiguredKpiJSONObject } from "../../json_api/configured_kpi";
import { DateRange } from "moment-range";
import { SamplingRate } from "../../models/sensor";

interface ConfiguredKpiIndexPageProps {
  assetId: IDType;
  permissions?: ResourcePermission;
} /**
 *
 *
 * @param {*} {
 *   assetId,
 * }
 * @return {*}
 */
export const ConfiguredKpiIndexPage: React.FC<ConfiguredKpiIndexPageProps> = ({
  assetId,
}) => {
  const [selectedKpi, setSelectedKpi] =
    React.useState<ConfiguredKpiJSONObject>(null);

  const [selectedTimeRange, setSelectedTimeRange] = React.useState<DateRange>();
  const [selectedSamplingRate, setSelectedSamplingRate] =
    React.useState<SamplingRate>();
  return (
    <Paper>
      <Box padding={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ConfiguredKpiList
              assetId={assetId}
              onSelect={(cKpi) => setSelectedKpi(cKpi)}
            />
          </Grid>
        </Grid>
      </Box>
      {selectedKpi && (
        <SialogicDialog
          open={Boolean(selectedKpi)}
          onClose={() => setSelectedKpi(null)}
          maxWidth="xl"
        >
          <Box padding={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ConfiguredKpiPage
                  timeRange={selectedTimeRange}
                  samplingRate={selectedSamplingRate}
                  id={selectedKpi.id}
                  configuredKpi={selectedKpi}
                />
              </Grid>
            </Grid>
          </Box>
        </SialogicDialog>
      )}
    </Paper>
  );
};
