import { JSONSchema4 } from "json-schema";
import * as JSON from "json-typescript";
import * as JSONAPI from "jsonapi-typescript";
import { MeasurementPlanAttributes } from "../models/measurement_plan";
import { MeasurementTypeJsonObject } from "./measurement_type";
import { MeasurementValueDefinitionJsonApiObject } from "./measurement_value_definition";
import { MeasurementCategoryJsonObject } from "./measurement_category";
import { AssetJSONObject } from "./asset";
import {
  MeasurementJSONApiAttributes,
  MeasurementJsonObject,
} from "./measurement";

export const MEASUREMENT_PLAN_JSON_API_TYPE = "measurement_plans";

export type MeasurementPlanJsonApiIncludes =
  | "asset"
  | "measurement_type"
  | "measurement_value_definitions"
  | "measurement_categories"
  | "last_measurement"
  | "root_asset"
  | "reference_measurement"
  | "last_maintenance_job";

export interface MeasurementPlanJSONApiAttributes
  extends MeasurementPlanAttributes {
  measurement_type_title?: string;
  measurement_type_short?: string;

  meta_info_schema?: JSONSchema4;
  subscribed?: boolean;
  last_measurement_at?: string;
  next_measurement_at?: string;
  last_measurement_id?: string | number;
}

interface MeasurementPlanJsonObject
  extends JSON.Object,
    MeasurementPlanJSONApiAttributes {
  asset?: AssetJSONObject;
  measurement_type?: MeasurementTypeJsonObject;
  measurement_value_definitions?: MeasurementValueDefinitionJsonApiObject[];
  measurement_categories?: MeasurementCategoryJsonObject[];
}

export type MeasurementPlanJSONAPIAttributesObject =
  JSONAPI.AttributesObject<MeasurementPlanJsonObject>;
