// load action cable
import cable from "../channels/consumer";
import { isNil } from "lodash";
import "../definitions/index.d";
export function initializeActionCable(): void {
  const App = {
    cable,
  };

  // This is necessary to make the App object available globally
  // cast to any to avoid TS errors
  ([window, global] as any[]).forEach((object: { App?: typeof App }) => {
    if (isNil(object.App)) {
      object.App = App;
    } else {
      object.App.cable = cable;
    }
  });
}

export function destroyActionCable(): void {
  if (!isNil(App.cable)) {
    App.cable.disconnect();
  }
}
