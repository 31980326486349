import * as React from "react";
import { createRoot, Root } from "react-dom/client";
import { ResourcePermission } from "../../models/resource_permission";
import { DeviceEditPage } from "./device_edit_page";
import { AppRoot } from "../../components/common/app_root";
import { toInteger } from "lodash";
import { DeviceJsonApiAttributes } from "../../json_api/device";
import { redirectTo } from "../../utils/redirection";
import { DeviceShowPage } from "./device_show_page";

const deviceEditRoot: Root[] = [];
/**
 * Initialize react component EventPatternForm within all elements with data-toggle="event-pattern-form".
 * Initial state is loaded fron "data-event-pattern-form".
 * State is expected to be in JSON format.
 */
export function initializeDeviceEditPage(): void {
  $('[data-toggle="device-edit-page"]').each((index, element) => {
    const deviceId = $(element).data("device-id") as string;
    const assetId = $(element).data("asset-id") as string;
    const organizationId = $(element).data("organization-id") as string;
    const permissions = $(element).data("permissions") as ResourcePermission;
    const root = createRoot(element);
    deviceEditRoot.push(root);
    root.render(
      <AppRoot>
        <DeviceEditPage
          deviceId={deviceId}
          assetId={assetId}
          organizationId={organizationId}
          permissions={permissions}
          onSave={function (device: Partial<DeviceJsonApiAttributes>): void {
            redirectTo("back");
          }}
        />
      </AppRoot>,
    );
  });
}
/**
 * Remove react component from all elements with data-toggle="maintenance-form"
 */
export function destroyDeviceEditPage(): void {
  deviceEditRoot.forEach((root) => {
    root.unmount();
  });
  deviceEditRoot.length = 0;
}

const deviceShowRoot: Root[] = [];
/**
 * Initialize react component EventPatternForm within all elements with data-toggle="event-pattern-form".
 * Initial state is loaded fron "data-event-pattern-form".
 * State is expected to be in JSON format.
 */
export function initializeDeviceShowPage(): void {
  $('[data-toggle="device-show-page"]').each((index, element) => {
    const deviceId = $(element).data("device-id") as string;
    const permissions = $(element).data("permissions") as ResourcePermission;
    const root = createRoot(element);
    deviceShowRoot.push(root);
    root.render(
      <AppRoot>
        <DeviceShowPage deviceId={deviceId} permissions={permissions} />
      </AppRoot>,
    );
  });
}
/**
 * Remove react component from all elements with data-toggle="maintenance-form"
 */
export function destroyDeviceShowPage(): void {
  deviceShowRoot.forEach((root) => {
    root.unmount();
  });
  deviceShowRoot.length = 0;
}
