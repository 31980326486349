import { each, first, groupBy, sum } from "lodash";
import { Data, PlotData } from "plotly.js";
import { ReferenceMeasurementValue } from "../../components/measurements/utils/measurement_reference_eval";
import { MeasurementCategory } from "../../models/measurement_category";
import { xValueForMeasurementDef } from "./measurement_chart_data_creator";

export function createReferenceLineData(
  referenceMeasurementValues: ReferenceMeasurementValue[],
  getXValueForMeasurementDef: (value: ReferenceMeasurementValue) => string = (
    v,
  ) => xValueForMeasurementDef(v.mvd),
  percentage: boolean = false,
) {
  if (referenceMeasurementValues && referenceMeasurementValues.length > 0) {
    const { refLine, refLineLow, refLineHigh } = buildReferenceLineData(
      percentage ? "%" : referenceMeasurementValues[0].mvd?.unit,
    );
    return fillDataForReferenceLines(
      refLine,
      refLineLow,
      refLineHigh,
      referenceMeasurementValues,
      getXValueForMeasurementDef,
      percentage,
    );
  }

  return {
    refLineLow: null as Partial<Data>,
    refLine: null as Partial<Data>,
    refLineHigh: null as Partial<Data>,
  };
}

export function createReferenceLineDataGroupedByCategory(
  referenceMeasurementValues: ReferenceMeasurementValue[],
  getXValueForMeasurementDef: (mc: MeasurementCategory) => string = (v) =>
    v.title || "",
  percentage: boolean = false,
) {
  if (referenceMeasurementValues && referenceMeasurementValues.length > 0) {
    const { refLine, refLineLow, refLineHigh } = buildReferenceLineData(
      referenceMeasurementValues[0].mvd?.unit,
    );
    return fillDataForReferenceLineGroupedByCategory(
      refLine,
      refLineLow,
      refLineHigh,
      referenceMeasurementValues,
      getXValueForMeasurementDef,
      percentage,
    );
  }

  return {
    refLineLow: null as Partial<Data>,
    refLine: null as Partial<Data>,
    refLineHigh: null as Partial<Data>,
  };
}

function buildReferenceLineData(unit: string) {
  const refBorderLineSettings: Partial<Plotly.ScatterLine> = {
    dash: "longdashdot",
    width: 0.5,
    color: "rgba(225, 0, 0, 0.69)",
  };

  const refLineHigh: Plotly.Data & { unit: string; position: number } = {
    x: [] as string[],
    y: [] as number[],

    type: "scatter",
    mode: "lines",

    name: I18n.t("frontend.measurements.chart.reference_value_high"),
    legendgroup: "reference",
    legendgrouptitle: {
      text: I18n.t("frontend.measurements.chart.reference_value"),
    },
    line: refBorderLineSettings,
    hoverinfo: "skip",
    unit,
    position: 1,
  };

  const refLine: Plotly.Data & { unit: string; position: number } = {
    x: [],
    y: [],
    text: [],

    fillcolor: "rgba(115,115, 115, 0.1)",
    type: "scatter",

    line: {
      color: "rgba(115, 115, 115)",
      width: 0.5,
      dash: "longdashdot",
    },
    hoverinfo: "x+y+text",

    name: I18n.t("frontend.measurements.chart.reference_value"),
    legendgroup: "reference",
    legendgrouptitle: {
      text: I18n.t("frontend.measurements.chart.reference_value"),
    },
    marker: {
      color: "rgba(115, 115, 115)",
    },
    mode: "lines+markers",

    unit: unit,
    position: 1,
  };

  const refLineLow: Plotly.Data & { unit: string; position: number } = {
    x: [],
    y: [],

    fillcolor: "rgba(115, 115, 115, 0.1)",
    type: "scatter",
    mode: "lines",
    line: refBorderLineSettings,
    hoverinfo: "skip",
    name: I18n.t("frontend.measurements.chart.reference_value_low"),
    legendgroup: "reference",
    legendgrouptitle: {
      text: I18n.t("frontend.measurements.chart.reference_value"),
    },

    unit,
    position: 1,
  };
  return { refLineLow, refLine, refLineHigh };
}
function fillDataForReferenceLineGroupedByCategory(
  refLine: Partial<PlotData>,
  refLineLow: Partial<PlotData>,
  refLineHigh: Partial<PlotData>,
  referenceMeasurementValues: ReferenceMeasurementValue[],
  getXValueForMeasurementCategory: (mc: MeasurementCategory) => string = (v) =>
    v?.title || "",
  percentage: boolean = false,
) {
  each(
    groupBy(referenceMeasurementValues, (value) => value.mc?.id),
    (values, category_id) => {
      const mc = first(values).mc;

      const ref = sum(
        values.map((value) => (percentage ? value.percent : value.value)),
      );
      const refLow = sum(
        values.map((value) => (percentage ? value.lowPercent : value.low)),
      );
      const refHigh = sum(
        values.map((value) => (percentage ? value.highPercent : value.high)),
      );

      (refLine.x as string[]).push(getXValueForMeasurementCategory(mc));
      (refLine.y as number[]).push(ref);
      (refLineLow.x as string[]).push(getXValueForMeasurementCategory(mc));
      (refLineLow.y as number[]).push(refLow);
      (refLineHigh.x as string[]).push(getXValueForMeasurementCategory(mc));
      (refLineHigh.y as number[]).push(refHigh);
    },
  );

  return { refLineLow, refLine, refLineHigh };
}

function fillDataForReferenceLines(
  refLine: Partial<PlotData>,
  refLineLow: Partial<PlotData>,
  refLineHigh: Partial<PlotData>,
  referenceMeasurementValues: ReferenceMeasurementValue[],
  getXValueForMeasurement: (value: ReferenceMeasurementValue) => string = (v) =>
    xValueForMeasurementDef(v.mvd),
  percentage: boolean = false,
) {
  referenceMeasurementValues.forEach((value) => {
    (refLine.x as string[]).push(getXValueForMeasurement(value));
    (refLine.y as number[]).push(percentage ? value.percent : value.value);
    (refLine.text as string[]).push(`${value.value} ${value.mvd.unit}`);
    (refLineLow.x as string[]).push(getXValueForMeasurement(value));
    (refLineLow.y as number[]).push(percentage ? value.lowPercent : value.low);
    (refLineHigh.x as string[]).push(getXValueForMeasurement(value));
    (refLineHigh.y as number[]).push(
      percentage ? value.highPercent : value.high,
    );
  });

  return { refLineLow, refLine, refLineHigh };
}
