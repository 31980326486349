import { AssetEventsWidgetDefinition } from "../widgets/asset_events_widget";
import { AssetInfoWidgetDefinition } from "../widgets/asset_info_widget";
import { AssetListWidgetDefinition } from "../widgets/asset_list_widget";
import { AssetMaintenanceInfoWidgetDefinition } from "../widgets/asset_maintenance_info_widget";
import { AssetMaintenanceTimelineWidgetDefinition } from "../widgets/asset_maintenance_timeline_widget";
import { AssetMapWidgetDefinition } from "../widgets/asset_map_widget";
import { AssetPartsWidgetDefinition } from "../widgets/asset_parts_widget";
import { AssetPropertiesWidgetDefinition } from "../widgets/asset_properties_widget";
import { BarChartWidgetDefinition } from "../widgets/bar_chart_widget";
import { ImageWidgetDefinition } from "../widgets/image_widget";
import { KpiWidgetDefinition } from "../widgets/kpi_widget";
import { LevelIndicatorWidgetDefinition } from "../widgets/level_indicator_widget";
import { LineDiagramWidgetDefinition } from "../widgets/line_diagram_widget";
import { LinkWidgetDefinition } from "../widgets/link_widget";
import { ModelViewerWidget } from "../widgets/model_viewer_widget";
import { OffsetGaugeWidgetDefinition } from "../widgets/offset_gauge_widget";
import { OrganizationsWidgetDefinition } from "../widgets/organizations_widget";
import { PercentageWidgetDefinition } from "../widgets/percentage_widget";
import { SensorAggregationWidgetDefinition } from "../widgets/sensor_aggregation_widget";
import { SensorGroupWidgetDefinition } from "../widgets/sensor_group_widget";
import { SensorValueTableWidgetDefinition } from "../widgets/sensor_value_table_widget";
import { SensorValueWidgetDefinition } from "../widgets/sensor_value_widget";
import { SvgAnimationWidgetDefinition } from "../widgets/svg_animation_widget";
import { ValueDifferenceWidgetDefinition } from "../widgets/value_difference_widget";
import { WeatherWidget } from "../widgets/weather_widget";
import { CommentWidgetDefinition } from "../widgets/comment_widget";
import { MaintenancesWidgetDefinition } from "../widgets/maintenances_widget";
import {
  SerializedConfigToProps,
  SialogicWidgetComponent,
  SialogicWidgetDefinition,
} from "../widgets/sialogic_widget_component";

import { WidgetConfigSerialized } from "../../widgets/widget.types";
import { StateWidgetDefinition } from "../widgets/state_widget";
import { WidgetBoxProps } from "../widgets/widget_box.types";
import { ErrorWidgetDefinition } from "../widgets/error_widget";

function widgetDefinition<
  T extends SialogicWidgetComponent<P>,
  C extends WidgetConfigSerialized = WidgetConfigSerialized,
  P extends WidgetBoxProps = WidgetBoxProps,
>(component: T): SialogicWidgetDefinition<T, SerializedConfigToProps<C, P>> {
  return {
    Component: component,
    serializedConfigToProps: component.serializedConfigToProps,
  };
}

export const WidgetComponentsByType: Record<string, SialogicWidgetDefinition> =
  {
    "Widgets::AssetEventsWidget": AssetEventsWidgetDefinition,
    "Widgets::AssetInfoWidget": AssetInfoWidgetDefinition,
    "Widgets::AssetListWidget": AssetListWidgetDefinition,
    "Widgets::AssetPartsWidget": AssetPartsWidgetDefinition,
    "Widgets::AssetPropertiesWidget": AssetPropertiesWidgetDefinition,
    "Widgets::AssetMaintenanceInfoWidget": AssetMaintenanceInfoWidgetDefinition,
    "Widgets::AssetMaintenanceTimelineWidget":
      AssetMaintenanceTimelineWidgetDefinition,
    "Widgets::AssetMapWidget": AssetMapWidgetDefinition,
    "Widgets::BarChartWidget": BarChartWidgetDefinition,
    "Widgets::CommentWidget": CommentWidgetDefinition,
    "Widgets::ImageWidget": ImageWidgetDefinition,
    "Widgets::KpiWidget": KpiWidgetDefinition,
    "Widgets::LevelIndicatorWidget": LevelIndicatorWidgetDefinition,
    "Widgets::LineDiagramWidget": LineDiagramWidgetDefinition,
    "Widgets::LinkWidget": LinkWidgetDefinition,
    "Widgets::ModelViewerWidget": widgetDefinition(ModelViewerWidget),
    "Widgets::OffsetGaugeWidget": OffsetGaugeWidgetDefinition,
    "Widgets::PercentageWidget": PercentageWidgetDefinition,
    "Widgets::OrganizationsWidget": OrganizationsWidgetDefinition,
    "Widgets::SensorAggregationWidget": SensorAggregationWidgetDefinition,
    "Widgets::SensorGroupWidget": SensorGroupWidgetDefinition,
    "Widgets::SensorValueTableWidget": SensorValueTableWidgetDefinition,
    "Widgets::SensorValueWidget": SensorValueWidgetDefinition,
    "Widgets::StateWidget": StateWidgetDefinition,
    "Widgets::ValueDifferenceWidget": ValueDifferenceWidgetDefinition,
    "Widgets::SvgAnimationWidget": SvgAnimationWidgetDefinition,
    "Widgets::ValueWidget": SensorValueWidgetDefinition,
    "Widgets::WeatherWidget": widgetDefinition(WeatherWidget),
    "Widgets::MaintenancesWidget": MaintenancesWidgetDefinition,
    "Widgets::ErrorWidget": ErrorWidgetDefinition, //

    // We cannot use widget group widget here as it builds up a circular dependency. Dashboard widgets handle this case separately
    //"Widgets::WidgetGroupWidget": WidgetGroup,
  };
