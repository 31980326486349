import { each, isEmpty, isNil } from "lodash";
import { createUnit, unit as mathunit } from "mathjs";

const UnitAliases: Array<[string, string]> = [
  ["°", "deg"],
  ["°C", "degC"],
  ["°F", "degF"],
  ["°", "degree"],
  ["%", "percent"],
  ["‰", "permille"],
  ["€", "EUR"],
  ["$", "USD"],
  ["m³", "m^3"],
  ["m²", "m^2"],
];

const UnitReplacements: Map<string, string> = new Map<string, string>([
  ["t", "tonne"],
  ["€", "EUR"],
  ["$", "USD"],
  ["m³", "m^3"],
  ["m²", "m^2"],
  ["°", "deg"],
]);

const UnitDisplayReplacements: Record<string, string> = {
  tonne: "t",
  EUR: "€",
  USD: "$",
  "m^3": "m³",
  "m^2": "m²",
  deg: "°",
};

export function unitDisplayString(unit: string) {
  if (isEmpty(unit)) return "";
  const replacement = UnitDisplayReplacements[unit];
  if (isNil(replacement)) {
    return unit;
  } else {
    return replacement;
  }
}

// add new units aliases
createUnit({
  percent: {
    prefixes: "none",
    aliases: ["Prozent"],
  },
  permille: {
    definition: "0.1 percent",
    aliases: ["Promille"],
  },
  rssi: {
    aliases: ["RSSI"],
  },
  EUR: {
    aliases: ["Euro"],
  },
  USD: {
    aliases: ["Dollar"],
  },
});

function replaceUnitAlias(unit: string): string {
  let outputUnit = unit;

  each(UnitAliases, (alias) => {
    outputUnit = outputUnit.replace(alias[0], alias[1]);
  });

  if (UnitReplacements.has(outputUnit)) {
    outputUnit = UnitReplacements.get(outputUnit);
  }

  return outputUnit;
}

export function convertToUnit(
  value: number,
  unit: string,
  outputUnit: string,
): number {
  if (unit === outputUnit || isEmpty(unit) || isEmpty(outputUnit)) {
    return value;
  }

  try {
    return mathunit(value, replaceUnitAlias(unit)).toNumber(
      replaceUnitAlias(outputUnit),
    );
  } catch (error) {
    // unknown units return null
    return null;
  }
}
