import JSON from "json-typescript";
import * as JSONAPI from "jsonapi-typescript";
import {
  ASSET_EVENT_TYPE_SLUG_REGEXP,
  AssetEventTypeAttributes,
  AssetEventTypeCategory,
} from "../models/asset_event_type";
import {
  api_asset_asset_event_types_path,
  api_asset_event_type_path,
  api_asset_event_types_path,
  with_tree_mode_api_asset_asset_event_types_path,
} from "../routes";
import { loadDataFromUrl, sendJsonApiData } from "../utils/jquery_helper";
import { IDType } from "../utils/urls/url_utils";

import { isEmpty, isNil, map, merge, toString } from "lodash";
import { EventSeverityLevel } from "../models/event_notification";
import { buildJsonApiSubmitData } from "../utils/jsonapi_form_tools";
import {
  ASSET_JSONAPI_RESOURCE_TYPE,
  AssetJSONAPIAttributesObject,
} from "./asset";
import {
  ASSET_TYPE_JSONAPI_RESOURCE_TYPE,
  AssetTypeJSONAPIAttributes,
} from "./asset_type";
import {
  LoadItemsResult,
  ModelErrors,
  jsonApiFilterParamsArgumentsFromFilterObject,
  jsonApiResourceCollectionToFlatObjects,
  jsonApiSingleResourceToFlatObject,
  loadItemResultForJsonApiCollectionResourceDoc,
} from "./jsonapi_tools";
import { TreeMode } from "./tree_items";

export interface AssetEventTypeJSONObject
  extends AssetEventTypeAttributes,
    JSON.Object {}

export interface AssetEventTypeJSONAPIAttributes
  extends JSONAPI.AttributesObject<AssetEventTypeJSONObject> {
  asset_types?: AssetTypeJSONAPIAttributes[];
}

export const ASSET_EVENT_TYPE_JSONAPI_RESOURCE_TYPE = "asset_event_types";

export type AssetEventTypeIncludes = "asset_types";

export interface AssetEventTypeFilter {
  category?: (AssetEventTypeCategory | "none" | "" | null)[];
  exclude_category?: AssetEventTypeCategory[];
  slug?: string[];
  default_severity_level?: string[];
}

export interface AssetEventTypeJsonApiFilter {
  category?: string[];
  assets?: IDType | IDType[];
  asset_types?: IDType | IDType[];
  name?: string;
  slug?: string[];
  default_severity_level?: EventSeverityLevel;
}
export async function loadEventTypesForAsset(
  assetId: IDType,
  treeMode: TreeMode = null,
  includeSystemEventTypes = false,
  filter: AssetEventTypeFilter = null,
): Promise<LoadItemsResult<AssetEventTypeJSONObject>> {
  let url: string;
  const filterParams = jsonApiFilterParamsArgumentsFromFilterObject(filter);

  let urlOptions = {
    _options: true,
    format: "json",
    include: "asset_types",
    include_system_event_types: includeSystemEventTypes,
  };
  urlOptions = merge(urlOptions, filterParams);
  if (isNil(assetId)) {
    url = api_asset_event_types_path();
  } else {
    url = isNil(treeMode)
      ? api_asset_asset_event_types_path(assetId, urlOptions)
      : with_tree_mode_api_asset_asset_event_types_path(
          assetId,
          treeMode,
          urlOptions,
        );
  }
  const resp =
    await loadDataFromUrl<
      JSONAPI.CollectionResourceDoc<string, AssetEventTypeJSONObject>
    >(url);

  return loadItemResultForJsonApiCollectionResourceDoc<AssetEventTypeJSONObject>(
    resp,
  );
}

const ASSET_EVENT_TYPE_JSON_API_SUBMIT_ATTRIBUTES: (keyof AssetEventTypeAttributes)[] =
  [
    "name",
    "description",
    "code",
    "action",
    "message",
    "icon",
    "color",
    "slug",
    "default_severity_level",
    "category",
  ];
export async function saveAssetEventType(
  eventType: AssetEventTypeJSONAPIAttributes,
  assetIdForNew?: IDType,
  assetTypeIdForNew?: IDType,
): Promise<AssetEventTypeJSONAPIAttributes> {
  const { submitData, mode } = buildJsonApiSubmitData(
    eventType,
    ASSET_EVENT_TYPE_JSONAPI_RESOURCE_TYPE,
    ASSET_EVENT_TYPE_JSON_API_SUBMIT_ATTRIBUTES,
  );
  let url: string;

  const relationships: JSONAPI.RelationshipsObject = {};
  if (mode == "create") {
    if (eventType.asset_types) {
      relationships["asset_types"] = {
        data: map(eventType.asset_types, (a) => ({
          type: ASSET_TYPE_JSONAPI_RESOURCE_TYPE,
          id: toString(assetTypeIdForNew),
        })),
      };
    } else if (!isNil(assetTypeIdForNew)) {
      relationships["asset_types"] = {
        data: [
          {
            type: ASSET_TYPE_JSONAPI_RESOURCE_TYPE,
            id: toString(assetTypeIdForNew),
          },
        ],
      };
    }

    url = api_asset_event_types_path({ _options: true, format: "json" });
  } else {
    url = api_asset_event_type_path(eventType.id, {
      id: eventType.id,
      format: "json",
      _options: true,
    });
  }

  submitData.data.relationships = relationships;

  const resp = await sendJsonApiData(
    url,
    submitData,
    mode == "create" ? "POST" : "PATCH",
  );

  const resultEvent = jsonApiSingleResourceToFlatObject(
    resp as JSONAPI.SingleResourceDoc<string, AssetEventTypeJSONAPIAttributes>,
  );
  return resultEvent;
}

export function deleteAssetEventType(assetEventTypeId: IDType): Promise<void> {
  return Promise.resolve();
}

export function validateAssetEventType(
  assetEventType: AssetEventTypeJSONObject,
): ModelErrors<AssetEventTypeJSONObject> {
  const errors: ModelErrors<AssetEventTypeJSONObject> = {};

  if (isEmpty(assetEventType)) {
    errors;
  }

  if (isEmpty(assetEventType.slug)) {
    errors.slug = [I18n.t("errors.messages.blank")];
  } else {
    if (!ASSET_EVENT_TYPE_SLUG_REGEXP.test(assetEventType.slug)) {
      errors.slug = [
        I18n.t(
          "activerecord.errors.models.asset_event_type.attributes.slug.only_letters_numbers_and_dashes",
        ),
      ];
    }
  }
  if (isEmpty(assetEventType.name)) {
    errors.name = [I18n.t("errors.messages.blank")];
  }

  if (isEmpty(assetEventType.default_severity_level)) {
    errors.default_severity_level = [I18n.t("errors.messages.blank")];
  }

  if (
    isEmpty(assetEventType.category) ||
    (assetEventType.category as string) == "none"
  ) {
    errors.category = [I18n.t("errors.messages.blank")];
  }

  return errors;
}
