import { Cancel, Check, Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DocWithErrors } from "jsonapi-typescript";
import { isEmpty, isNil } from "lodash";
import * as React from "react";
import { AssetJSONObject } from "../../json_api/asset";
import {
  AssetEventJSONObject,
  validateAssetEvent,
} from "../../json_api/asset_event";
import {
  ModelErrors,
  extractErrorsFromJsonApi,
} from "../../json_api/jsonapi_tools";
import { HttpError } from "../../utils/jquery_helper";
import { error, success } from "../../utils/toasts";
import { IDType } from "../../utils/urls/url_utils";
import { LoadingIcon } from "../common/icon";
import { AssetEventForm } from "./asset_event_form";
import { SialogicDialog } from "../common/sialogic_dialog";
import { useSaveAssetEvent } from "../../queries/asset_events_data";

interface AssetEventFormDialogProps {
  assetId: IDType;
  asset?: AssetJSONObject;
  selectableAssets?: AssetJSONObject[];
  onClose?: () => void;
  onSave?: (event: AssetEventJSONObject) => void;
  open?: boolean;
  fullScreen?: boolean;
}
export const AssetEventFormDialog: React.FunctionComponent<
  AssetEventFormDialogProps
> = (props) => {
  const theme = useTheme();
  const dialogFullScreen = useMediaQuery(theme.breakpoints.down("md"));

  // asset event currently created/edited in form. Undefined will hide the dialog
  const [assetEventForForm, setAssetEventForForm] =
    React.useState<AssetEventJSONObject>(undefined);
  const [assetEventForFormErrors, setAssetEventForFormErrors] = React.useState<
    ModelErrors<AssetEventJSONObject>
  >({});

  const [submitEnabled, setSubmitEnabled] = React.useState(true);
  const { mutateAsync: saveAssetEvent, isPending: loading } =
    useSaveAssetEvent();

  React.useEffect(() => {
    setSubmitEnabled(isEmpty(assetEventForFormErrors) && !loading);
  }, [assetEventForFormErrors, loading]);

  if (isNil(props.assetId)) return null;

  return (
    <SialogicDialog
      open={props.open}
      onClose={props.onClose}
      fullScreen={props.fullScreen || dialogFullScreen}
      title={I18n.t("frontend.asset_events.asset_event_form.title_new")}
      buttons={
        <>
          <Button
            onClick={() => {
              setAssetEventForForm(undefined);
              props.onClose();
            }}
            startIcon={<Cancel />}
          >
            {I18n.t("frontend.cancel")}
          </Button>
          <Button
            color="primary"
            disabled={!submitEnabled}
            onClick={() => {
              if (!isNil(assetEventForForm)) {
                const errors = validateAssetEvent(assetEventForForm);
                if (!isEmpty(errors)) {
                  setAssetEventForFormErrors(errors);
                } else {
                  saveAssetEvent(assetEventForForm)
                    .then(() => {
                      void success(
                        I18n.t("frontend.success"),
                        I18n.t("frontend.saved_successfully"),
                      );
                      if (props.onSave) {
                        props.onSave(assetEventForForm);
                      }
                    })
                    .catch((e) => {
                      setAssetEventForFormErrors(
                        extractErrorsFromJsonApi<AssetEventJSONObject>(
                          (e as HttpError).request
                            .responseJSON as DocWithErrors,
                        ),
                      );
                    });
                }
              }
            }}
            startIcon={loading ? <LoadingIcon size="1x" /> : <Check />}
          >
            {I18n.t("frontend.save")}
          </Button>
        </>
      }
    >
      <Grid container spacing={2} m={2}>
        <AssetEventForm
          assetId={props.asset?.id ?? props.assetId}
          asset={props.asset}
          errors={assetEventForFormErrors}
          selectFromAssetTreeWithAssetId={props.asset?.id}
          selectableAssets={props.selectableAssets}
          withCard={false}
          withFloatingButtons={false}
          onCancel={() => setAssetEventForForm(undefined)}
          onChangeAssetEvent={(e) => setAssetEventForForm(e)}
        />
      </Grid>
    </SialogicDialog>
  );
};
