import L from "leaflet";

if (typeof globalThis != "undefined") {
  globalThis.L ||= L;
}

window.L ||= L;
import "./leaflet_resources";
import "leaflet-realtime";
import "leaflet.icon.glyph";
import "leaflet.markercluster";

export default window.L;
