import * as React from "react";

import { Add, Check, Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Grid,
  MenuItem,
  Paper,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { CollectionResourceDoc, SingleResourceDoc } from "jsonapi-typescript";
import { isEmpty, toString } from "lodash";
import {
  ASSET_TEMPLATE_JSONAPI_RESOURCE_TYPE,
  AssetTemplateJSONObject,
} from "../../json_api/asset_template";
import {
  jsonApiResourceCollectionToFlatObjects,
  jsonApiSingleResourceToFlatObject,
} from "../../json_api/jsonapi_tools";
import { ProductModelJSONObject } from "../../json_api/product_model";
import { ProductModelAssetTemplateSettingJSONObject } from "../../json_api/product_model_asset_template_settings";
import { api_asset_templates_path, api_product_model_path } from "../../routes";
import { loadDataFromUrl } from "../../utils/jquery_helper";
import { SialogicDialog } from "../common/sialogic_dialog";
import { ProductModelAssetTemplateSettingsForm } from "./product_model_asset_template_setting_form";
import {
  ProductModelAssetTemplateQuery,
  useDeleteProductModelAssetTemplateSetting,
  useFetchProductModelAssetTemplateSetting,
} from "../../queries/product_model_asset_template_settings_data";
import { IDType } from "../../utils/urls/url_utils";

interface ProductModelAssetTemplateSettingListProps {
  productModelId: IDType;
  canEdit?: boolean;
}

export const ProductModelAssetTemplateSettingList: React.FC<
  ProductModelAssetTemplateSettingListProps
> = ({ productModelId, canEdit }) => {
  const { isSuccess: productModelLoaded, data: productModel } = useQuery({
    queryKey: ["productModel", productModelId],
    queryFn: async () => {
      const result = await loadDataFromUrl<
        SingleResourceDoc<"string", ProductModelJSONObject>
      >(
        api_product_model_path(productModelId, {
          include: "asset_type",
          _options: true,
        }),
      );
      return jsonApiSingleResourceToFlatObject(result);
    },
  });

  const assetTypeId = productModel?.asset_type_id;
  const {
    data: assetTemplates,
    isLoading: assetTemplatesIsLoading,
    isSuccess: assetTemplatesIsSuccess,
  } = useQuery({
    queryKey: [
      ASSET_TEMPLATE_JSONAPI_RESOURCE_TYPE,
      { assetTypeId, include: "template_descendants.asset_type,asset_type" },
    ] as [string, { assetTypeId: number; include: string }],
    enabled: productModelLoaded,
    queryFn: async ({ queryKey }) => {
      const [, { assetTypeId }] = queryKey;
      const assetTemplateDoc = await loadDataFromUrl<
        CollectionResourceDoc<string, AssetTemplateJSONObject>
      >(
        api_asset_templates_path({
          filter: { asset_type: assetTypeId },
          include:
            "template_descendants,template_descendants.asset_type,asset_type",
          _options: true,
          page: { size: 100 },
        }),
      );

      return jsonApiResourceCollectionToFlatObjects(assetTemplateDoc);
    },
  });

  const [assetTemplatesForSelectedRoot, setAssetTemplateForSelectedRoot] =
    React.useState<AssetTemplateJSONObject[]>(null);

  React.useEffect(() => {
    if (assetTemplatesIsSuccess) {
      setAssetTemplateForSelectedRoot(assetTemplates[0]?.template_descendants);
    }
  }, [assetTemplatesIsSuccess]);

  const [selectedAssetTemplate, setSelectedAssetTemplate] =
    React.useState<AssetTemplateJSONObject>(null);

  const assetTemplatesForSelectedRootIds = assetTemplatesForSelectedRoot?.map(
    (at) => at.id,
  );

  // load existing product model asset template settings
  const {
    data: existingProductModelTemplateSettings,
    isSuccess: existingProductModelTemplateSettingsSuccess,
    isLoading: existingProductModelTemplateSettingsLoading,
  } = useFetchProductModelAssetTemplateSetting({
    variables: {
      productModelId,

      assetTemplateIds: assetTemplatesForSelectedRootIds,
    },
    enabled:
      productModelLoaded &&
      Boolean(selectedAssetTemplate?.id) &&
      Boolean(assetTemplatesForSelectedRootIds),
  });

  const [
    editProductModelAssetTemplateSettings,
    setEditProductModelAssetTemplateSettings,
  ] = React.useState<ProductModelAssetTemplateSettingJSONObject>(null);

  const [unconfiguredAssetTemplates, setUnconfiguredAssetTemplates] =
    React.useState<AssetTemplateJSONObject[]>(() =>
      assetTemplatesForSelectedRoot?.filter(
        (at) =>
          !existingProductModelTemplateSettings.find(
            (setting) => setting.asset_template_id == at.id,
          ),
      ),
    );

  const { mutateAsync: deleteProductModelAssetTemplateSetting } =
    useDeleteProductModelAssetTemplateSetting();

  // set the unconfigured asset templates if asset templates or existing product model template settings change
  React.useEffect(() => {
    if (isEmpty(assetTemplatesForSelectedRoot)) {
      setUnconfiguredAssetTemplates([]);
    } else {
      if (isEmpty(existingProductModelTemplateSettings)) {
        setUnconfiguredAssetTemplates(assetTemplatesForSelectedRoot);
      } else {
        setUnconfiguredAssetTemplates(
          assetTemplatesForSelectedRoot?.filter(
            (at) =>
              !existingProductModelTemplateSettings.find(
                (setting) => setting.asset_template_id == at.id,
              ),
          ),
        );
      }
    }
  }, [assetTemplatesForSelectedRoot, existingProductModelTemplateSettings]);

  return (
    <Paper>
      <Box padding={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">
              {I18n.t(
                "activerecord.models.product_model_asset_template_setting",
                { count: 2 },
              )}
            </Typography>
            <Typography variant="subtitle1">
              {I18n.t(
                "frontend.product_model_asset_template_setting_list.description",
              )}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              select
              label={I18n.t("activerecord.models.asset_template.one")}
              value={selectedAssetTemplate?.id || ""}
              onChange={(e) => {
                const id = e.target.value;
                if (!id) {
                  setSelectedAssetTemplate(null);
                } else {
                  setSelectedAssetTemplate(
                    assetTemplates.find(
                      (assetTemplate) =>
                        toString(assetTemplate.id) === toString(id),
                    ),
                  );
                }
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {assetTemplates?.map((assetTemplate) => (
                <MenuItem key={assetTemplate.id} value={assetTemplate.id}>
                  {assetTemplate.id}
                  {assetTemplate.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {existingProductModelTemplateSettingsLoading ? (
            <Skeleton height={500} />
          ) : null}
          {existingProductModelTemplateSettingsSuccess && (
            <Grid item xs={12} height={500}>
              <Typography variant="h5">
                {I18n.t(
                  "activerecord.models.product_model_asset_template_setting",
                  { count: 2 },
                )}
              </Typography>
              <DataGrid
                rows={existingProductModelTemplateSettings}
                columns={[
                  { field: "id", headerName: "ID", width: 100 },
                  {
                    field: "asset_template.name",
                    headerName: "Asset Template ",
                    valueGetter: (v, row) => row.asset_template?.name || "",
                  },
                  {
                    field: "asset_template.ancestry",
                    headerName: "Asset Template Path",
                    valueGetter: (v, row) => row.asset_template?.ancestry || "",
                  },
                  {
                    field: "count",
                    headerName: I18n.t(
                      "activerecord.attributes.product_model_asset_template_setting.count",
                    ),
                    type: "number",
                  },
                  {
                    field: "actions",
                    headerName: "Actions",
                    type: "actions",
                    getActions: ({ row }) => [
                      <GridActionsCellItem
                        label={I18n.t("frontend.edit")}
                        icon={<Edit />}
                        onClick={() => {
                          setEditProductModelAssetTemplateSettings(row);
                        }}
                        key="edit"
                      />,
                      <GridActionsCellItem
                        key="delete"
                        label={I18n.t("frontend.delete")}
                        icon={<Delete />}
                        onClick={() => {
                          deleteProductModelAssetTemplateSetting(row.id);
                        }}
                      />,
                    ],
                  },
                ]}
                checkboxSelection
              />
            </Grid>
          )}

          {selectedAssetTemplate && (
            <Grid item xs={12}>
              {canEdit && !isEmpty(unconfiguredAssetTemplates) && (
                <Button
                  startIcon={<Add />}
                  onClick={() => {
                    setEditProductModelAssetTemplateSettings({
                      id: null,
                      product_model_id: productModelId,
                    });
                  }}
                >
                  {I18n.t("frontend.add")}
                </Button>
              )}
              {isEmpty(unconfiguredAssetTemplates) && (
                <Chip
                  label={I18n.t(
                    "frontend.product_model_asset_template_setting_list.all_configured",
                  )}
                  color="success"
                  icon={<Check />}
                />
              )}
            </Grid>
          )}
          {}
        </Grid>
      </Box>
      {editProductModelAssetTemplateSettings ? (
        <SialogicDialog
          open={Boolean(editProductModelAssetTemplateSettings)}
          title={I18n.t(
            "activerecord.models.product_model_asset_template_setting.one",
          )}
          onClose={() => setEditProductModelAssetTemplateSettings(null)}
        >
          <ProductModelAssetTemplateSettingsForm
            productModelId={productModelId}
            productModelAssetTemplateSettings={
              editProductModelAssetTemplateSettings
            }
            onCancel={() => setEditProductModelAssetTemplateSettings(null)}
            onSave={() => {
              setEditProductModelAssetTemplateSettings(null);
            }}
            availableAssetTemplates={
              editProductModelAssetTemplateSettings.asset_template
                ? [editProductModelAssetTemplateSettings.asset_template]
                : unconfiguredAssetTemplates
            }
          />
        </SialogicDialog>
      ) : null}
    </Paper>
  );
};
