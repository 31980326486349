import { isEmpty, isNil, isNumber } from "lodash";
import { DateRange } from "moment-range";
import {
  api_device_device_logs_path,
  api_device_path,
  asset_devices_path,
  assign_asset_api_device_path,
  batch_create_devices_api_organization_path,
  organization_devices_path,
} from "../../routes";
import {
  IDType,
  ParamType,
  applyParamsToBaseUrl,
  localizedBasePath,
} from "./url_utils";

/********
 * DEVICES
 *******/

export type DeviceIncludes = "assets" | "organizations";

export function deviceJsonApiPath(deviceId: IDType): string {
  return `${localizedBasePath(true)}/devices/${deviceId}`;
}

export function devicesJsonApiPath(): string {
  return `${localizedBasePath(true)}/devices`;
}

export function devicesJsonApiSearchPath(
  searchTerm: string,
  assetId: IDType = null,
  pageNumber: number,
  pageSize: number,
  include: DeviceIncludes[] = [],
  params: ParamType[] = [],
): string {
  const url = !assetId
    ? `${localizedBasePath(true)}/devices/search`
    : `${localizedBasePath(true)}/${assetId}/devices/search`;
  return applyParamsToBaseUrl(
    url,
    pageNumber,
    pageSize,
    include,
    params.concat([["search", searchTerm]]),
  );
}

export function deviceStartPairingPath(deviceId: number) {
  return `${localizedBasePath(true)}/devices/${deviceId}/start_pairing`;
}

export function deviceEndPairingPath(deviceId: number) {
  return `${localizedBasePath(true)}/devices/${deviceId}/end_pairing`;
}
export function assignAssetDeviceJsonApiPath(
  deviceId: IDType,
  assetId: IDType,
) {
  return assign_asset_api_device_path(deviceId, assetId, {
    _options: true,
    format: "json",
    locale: I18n.locale,
  });
}

export function assetDevicesPath(assetId: IDType) {
  return asset_devices_path(assetId, { _options: true, locale: I18n.locale });
}

export function apiDevicePath(deviceId: IDType) {
  return api_device_path(deviceId, { format: "json", locale: I18n.locale });
}

export function organizationDevicesPath(organizationId: IDType) {
  return organization_devices_path(organizationId, {
    _options: true,
    format: "json",
    locale: I18n.locale,
  });
}

export function batchCreateOrganizationDevicesApiPath(organizationId: IDType) {
  return batch_create_devices_api_organization_path(organizationId, {
    _options: true,
    format: "json",
    locale: I18n.locale,
  });
}

export function apiDeviceLogPath(
  deviceId: IDType,
  dateRange?: DateRange,
  code: string = null,
  label: string = null,
) {
  const options: {
    from?: string;
    to?: string;
    code?: string;
    label?: string;
    _options: true;
  } = {
    _options: true,
  };
  if (!isNil(dateRange)) {
    options["from"] = dateRange.start.toISOString();
    options["to"] = dateRange.end.toISOString();
  }

  if (!isEmpty(code)) {
    options["code"] = code;
  }

  if (!isEmpty(label)) {
    options["label"] = label;
  }

  return api_device_device_logs_path(deviceId, options);
}
