import * as React from "react";
import { MeasurementPlan } from "../../../models/measurement_plan";

import { Box, Tab, Tabs } from "@mui/material";
import { useLocalStorage } from "@uidotdev/usehooks";
import { MeasurementPlanJSONAPIAttributesObject } from "../../../json_api/measurement_plan";
import { useSetReferenceForMeasurementPlan } from "../../../queries/measurements_plan_data";
import { error, success } from "../../../utils/toasts";
import { SelectReferenceMeasurement } from "./select_reference_measurement";
import { MeasurementPlanReferenceSettings } from "./measurement_plan_reference_settings";
import { margin } from "@mui/system";
interface MeasurementPlanSettingsProps {
  measurementPlan: MeasurementPlanJSONAPIAttributesObject;

  onMeasurementPlanChange?: (mp: MeasurementPlan) => void;
  onCancel?: () => void;
}

export const MeasurementPlanSettings: React.FunctionComponent<
  MeasurementPlanSettingsProps
> = ({ measurementPlan, onCancel }) => {
  const [tab, setTab] = useLocalStorage("measurementPlanSettingsTab", 0);

  const {
    mutate: setReferenceForMeasurementPlan,
    error: setReferenceMeasurementError,
    isPending,
    isSuccess: setReferenceMeasurementSuccess,
  } = useSetReferenceForMeasurementPlan();

  React.useEffect(() => {
    if (setReferenceMeasurementSuccess) {
      success(I18n.t("frontend.measurement_plan_item.reference_set"));
    }
  }, [setReferenceMeasurementSuccess]);

  React.useEffect(() => {
    if (setReferenceMeasurementError) {
      error(
        I18n.t("frontend.measurement_plan_item.reference_not_set"),
        setReferenceMeasurementError.message,
      );
      console.error(setReferenceMeasurementError);
    }
  }, [setReferenceMeasurementError]);
  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
        <Tabs
          value={tab}
          onChange={(e, v) => {
            setTab(v);
          }}
          indicatorColor="secondary"
          textColor="secondary"
          aria-label="basic tabs example"
        >
          <Tab
            label={I18n.t(
              "frontend.measurement_plans.measurement_plan_settings.general_settings",
            )}
            value={0}
          />
          <Tab
            label={I18n.t(
              "frontend.measurement_plans.measurement_plan_settings.select_reference",
            )}
            value={1}
          />
        </Tabs>
      </Box>
      <Box minHeight={400}>
        {tab == 0 && (
          <MeasurementPlanReferenceSettings
            measurementPlan={measurementPlan}
            onCancel={onCancel}
          />
        )}
        {tab == 1 && (
          <SelectReferenceMeasurement
            measurementPlan={measurementPlan}
            onSelect={(mp, refMeas) => {
              setReferenceForMeasurementPlan({
                id: mp.id,
                referenceMeasurementId: refMeas ? refMeas.id : null,
              });
            }}
          />
        )}
      </Box>
    </Box>
  );
};
