import { createQuery } from "react-query-kit";
import {
  CONTEXT_STATE_MACHINE_JSONAPI_RESOURCE_TYPE,
  ContextStateMachineJsonApiIncludes,
  ContextStateMachineJSONObject,
  loadContextStateMachinesForAsset,
} from "../json_api/context_state_machines";
import { CSMIncludes } from "../utils/urls";
import { IDType } from "../utils/urls/url_utils";
import { api_context_state_machine_path } from "../routes";
import { loadDataFromUrl } from "../utils/jquery_helper";
import { SingleResourceDoc } from "jsonapi-typescript";
import { jsonApiSingleResourceToFlatObject } from "../json_api/jsonapi_tools";
import { uniq } from "lodash";

interface LoadStateMachinesVariables {
  id?: IDType;
  itemType?: "Asset";
  itemId?: IDType;
  include?: CSMIncludes[];
}
export const useLoadStateMachines = createQuery<
  ContextStateMachineJSONObject[],
  LoadStateMachinesVariables
>({
  queryKey: [CONTEXT_STATE_MACHINE_JSONAPI_RESOURCE_TYPE],

  fetcher: ({ id, itemType, itemId, include }) => {
    if (itemType === "Asset") {
      return loadContextStateMachinesForAsset(itemId, include);
    }
  },
});

export const useLoadContextStateMachine = createQuery<
  ContextStateMachineJSONObject,
  { id: IDType; include?: ContextStateMachineJsonApiIncludes[] }
>({
  queryKey: [CONTEXT_STATE_MACHINE_JSONAPI_RESOURCE_TYPE],
  fetcher: async ({ id, include }, { signal }) => {
    const includes = uniq(
      (include || []).concat([
        "possible_states",
        "state_context",
        "current_state",
      ]),
    );

    const options = { format: "json", _options: true };
    if (includes.length > 0) {
      (options as any)["include"] = includes.join(",");
    }
    const url = api_context_state_machine_path(id, options);

    const res = await loadDataFromUrl<
      SingleResourceDoc<string, ContextStateMachineJSONObject>
    >(url, "json", signal);

    return jsonApiSingleResourceToFlatObject(res);
  },
});
