import { first, groupBy, has, isEmpty, isNil, map, omit, values } from "lodash";
import {
  DashboardWidgetSize,
  WidgetConfigSerialized,
} from "../widgets/widget.types";

import { WidgetBoxProps } from "../components/widgets/widget_box.types";
import { logger } from "./logger";
import { WidgetComponentsByType } from "../components/dashboard/widget_types";
import {
  WidgetGroup,
  WidgetGroupDefinition,
} from "../components/widgets/widget_group";
import { DashboardWidgetWithConfig } from "../models/dashboard_widget";
import { SialogicWidgetDefinition } from "../components/widgets/sialogic_widget_component";

/** Builds props for GridItem components depending on the widget size property
 *
 *
 * @export
 * @param {DashboardWidgetSize} size
 * @return {*}
 */
export function dashboardGridSizeProps(size: DashboardWidgetSize) {
  switch (size) {
    case "xxsmall":
      return { xs: 6, sm: 3, md: 2, xl: 1 };
    case "xsmall":
      return { xs: 12, md: 4, xl: 2 };
    case "small":
      return { xs: 12, sm: 6, xl: 3 };
    case "large":
      return { xs: 12, lg: 6, xl: 4 };
    case "xlarge":
      return { xs: 12, md: 8, xl: 10 };
    case "xxlarge":
      return { xs: 12 };

    default:
      return { xs: 12, md: 6, lg: 4 };
  }
}

/** Interface for items that can be layouted in rows and positions
 *
 *
 * @export
 * @interface RowPositionItem
 */
export interface RowPositionItem {
  row?: number;
  position?: number;
  size?: DashboardWidgetSize;
}

/**
 *
 *
 * @export
 * @param {RowPositionItem[]} widgets
 * @return [] r
 */

/** Builds a sorted array of rows of items sorted by their position attribute
 *
 *
 * @export
 * @template T Type to be sorted. Needs to be compatible with the RowPositionItem interface.
 * @param {T[]} widgets Items to group and sort
 * @return {*}  {Array<T[]>} Sorted Array of items representing rows and items
 */
export function sortedRowsFromItems<
  T extends RowPositionItem = RowPositionItem,
>(widgets: T[]): Array<T[]> {
  const rows = values(groupBy(widgets, (w) => w.row));
  const orderedWidgetRows = rows.sort((row1, row2) => {
    const row1Number = first(row1)?.row;
    const row2Number = first(row2)?.row;
    if (isNil(row1Number)) return 1;
    if (isNil(row2Number)) return -1;
    if (row1Number == row2Number) return 0;
    return row1Number < row2Number ? -1 : 1;
  });

  return map(orderedWidgetRows, (r) =>
    r.sort((w1, w2) => {
      if (isNil(w1.position)) return 1;
      if (isNil(w2.position)) return -1;
      if (w1.position == w2.position) return 0;
      return w1.position < w2.position ? -1 : 1;
    }),
  );
}

export function widgetComponentDefinitionForType(
  type: string,
): SialogicWidgetDefinition {
  const ComponentDefinition = WidgetComponentsByType[type];
  if (!isNil(ComponentDefinition)) {
    return ComponentDefinition;
  }

  if (type == "Widgets::WidgetGroup") {
    return {
      Component: WidgetGroup,
      serializedConfigToProps: WidgetGroupDefinition.serializedConfigToProps,
    };
  }
  return null;
}

/** Derives the props for the given widget component from serialized config
 *
 *
 * @export
 * @param {WidgetConfigSerialized} serializedConfig The serialized config for the widget to instanciate
 * @return {WidgetBoxProps} The props to provide to the widget component in order to render the widget component
 */
export function propsForWidgetType(
  serializedConfig: DashboardWidgetWithConfig<WidgetConfigSerialized>,
  key: string = null,
): WidgetBoxProps {
  const widgetComponentDefinition = widgetComponentDefinitionForType(
    serializedConfig.widget_type,
  );

  if (widgetComponentDefinition) {
    if (widgetComponentDefinition.serializedConfigToProps) {
      const theProps: WidgetBoxProps =
        widgetComponentDefinition.serializedConfigToProps(
          serializedConfig.config,
        );
      theProps.encloseInIBox = true;
      theProps.dashboardSettings = omit(serializedConfig, "config");
      if (theProps.dashboardSettings.widget_type == "Widgets::ErrorWidget") {
        theProps.dashboardSettings.widget_type =
          theProps.dashboardSettings.original_widget_type;
      }
      if (!isNil(key)) {
        theProps.key = key;
      }
      return theProps;
    } else {
      logger.warn(
        "Widget type without converter function",
        serializedConfig.widget_type,
        widgetComponentDefinition,
      );
    }
  } else {
    return null;
  }
}
