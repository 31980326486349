import { omit } from "lodash";
import {
  RequestFormat,
  formatString,
  localizedBasePath,
  objectToParams,
} from "./url_utils";
import { Moment } from "moment";
import {
  configured_kpi_time_series_api_configured_kpis_path,
  time_series_kpi_path,
} from "../../routes";

export interface KpiAttributes {
  id?: string;
  timespan?: number;
}

export function kpiDataUrl(
  kpi: KpiAttributes,
  format: RequestFormat = "json",
): string {
  return time_series_kpi_path(kpi.id, { format, _options: true });
}

export function configuredKpiDataUrl(
  kpi: KpiAttributes,
  format: RequestFormat = "json",
): string {
  return configured_kpi_time_series_api_configured_kpis_path(kpi.id, {
    _options: true,
    // default to one day timespan
    timespan: kpi.timespan || 86400,
    format,
  });
}

export interface KpiLastValueWithinParams extends KpiAttributes {
  min_time?: Moment;
  max_time?: Moment;
}

export function kpiLastValueWithin(
  params: KpiLastValueWithinParams,
  format: RequestFormat = "json",
): string {
  const queryParams = objectToParams(omit(params, "id"));

  return `${localizedBasePath()}/kpis/${
    params.id
  }/last_value_within${formatString(format)}${queryParams}`;
}

export interface KpiByTypeParams {
  kpi_type: string;
  asset_id?: number | string;
  sensor_a_id?: number;
  sensor_b_id?: number;

  min_time?: Moment;
  max_time?: Moment;
  limit?: number;
  offset_y_axis?: boolean;
}

export function kpiTimeSerieByType(
  params: KpiByTypeParams,
  format: RequestFormat = "json",
): string {
  const queryParams = objectToParams(omit(params, "type"));

  return `${localizedBasePath()}/kpis/by_type/${
    params.kpi_type
  }/time_series${formatString(format)}${queryParams}`;
}

export function kpiLastValueWithinByType(
  params: KpiByTypeParams,
  format: RequestFormat = "json",
): string {
  const queryParams = objectToParams(omit(params, "type"));

  return `${localizedBasePath()}/kpis/by_type/${
    params.kpi_type
  }/last_value_within${formatString(format)}${queryParams}`;
}
