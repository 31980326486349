import { invalid, Moment } from "moment";
import {
  MappedSensorConfig,
  SvgAnimationWidgetProps,
} from "./svg_animation_widget.types";
import { BaseMapping } from "../../charting/svg/mapping_base";
import {
  compact,
  defaultTo,
  each,
  isEmpty,
  isNil,
  map,
  toNumber,
} from "lodash";
import { createMappingFromConfig } from "../../charting/svg/mapping_factory";
import { compile, EvalFunction } from "mathjs";
import { logger } from "../../utils/logger";
import {
  SensorAttributesForSvgMapping,
  SensorMappingAttributes,
} from "../../models/svg_animation_widget_config";
import { SensorJSONAPIAttributes } from "../../json_api/sensor";
import { Action } from "../../actions/actions.types";
function getSensorIdFromConfig(config: MappedSensorConfig): number {
  if (isNil(config)) return null;
  return isNil(config.sensor)
    ? toNumber(config.sensorId)
    : toNumber(config.sensor.id);
}

const NONE_SENSOR_ID = "NONE";
export function buildMappingsFromProps(
  props: SvgAnimationWidgetProps,
  handleElementClick: (
    event: MouseEvent,
    element: SVGElement,
    action: Action,
    mappingConfig: SensorMappingAttributes,
    sensor?: SensorAttributesForSvgMapping,
  ) => void,
): {
  mostRecentTime: Moment;
  invalidSensorMappings: BaseMapping[];
  mappingsBySensorId: Record<string, BaseMapping[]>;
  sensorBySensorId: Record<string, MappedSensorConfig>;
} {
  let mostRecentTime: Moment;
  const mappingsToBeApplied = props.sensorMappings;
  const invalidSensorMappings: BaseMapping[] = [];
  const sensorBySensorId: Record<string, MappedSensorConfig> = {};
  const mappingsBySensorId: Record<string, BaseMapping[]> = {};

  each(mappingsToBeApplied, (sensorMapping, mappingIndex) => {
    const theSensorsMappings: BaseMapping[] = compact(
      map(sensorMapping.mappings, (mappingConfig) =>
        createMappingFromConfig(
          mappingConfig,
          (event: MouseEvent, element: SVGElement, action) => {
            handleElementClick(
              event,
              element,
              action,
              mappingConfig,
              sensorMapping.sensor,
            );
          },
        ),
      ),
    );
    // process and store the sensor information for the i-th sensor
    const sensorConfig = sensorMapping.sensor;
    const sensor = sensorConfig?.sensor;
    let sensorId: number;
    let compiledValueFormula: EvalFunction = null;
    let time: Moment;
    if (sensorConfig || sensor) {
      if (!isEmpty(sensorConfig?.formula)) {
        try {
          compiledValueFormula = compile(sensorConfig.formula);
        } catch (e) {
          logger.warn(e);
        }
      }
      sensorId = getSensorIdFromConfig(sensorConfig);
      const time = isNil(sensorConfig?.time)
        ? isNil(sensor?.last_value?.timestamp)
          ? null
          : moment(sensor?.last_value?.timestamp)
        : moment(sensorConfig.time);
      if (!isNil(time)) {
        if (isNil(mostRecentTime) || mostRecentTime?.isBefore(time)) {
          mostRecentTime = time;
        }
      }
    }
    if (!isNil(sensorId)) {
      const mappedSensorConfig: MappedSensorConfig = {
        sensor,
        sensorId,
        sensorType: sensorConfig.sensor_type,
        value: sensorConfig.value,
        valueFormula: sensorConfig.formula,
        compiledValueFormula: compiledValueFormula,
        range: defaultTo(
          sensorConfig.range,
          defaultTo(sensor?.total_value_range, null),
        ),
        time,
        unit: defaultTo(sensor?.attribute_key_unit, ""),
      };
      sensorBySensorId[sensorId.toString()] = mappedSensorConfig;
      mappingsBySensorId[sensorId.toString()] = theSensorsMappings;
    } else if (sensorConfig?.selection_type === "none") {
      mappingsBySensorId[NONE_SENSOR_ID] = theSensorsMappings;
    } else {
      invalidSensorMappings.push(...theSensorsMappings);
    }
  });
  return {
    mostRecentTime,
    invalidSensorMappings,
    sensorBySensorId,
    mappingsBySensorId,
  };
}

export function calculateValue(
  value: number,
  compiledValueFormula: EvalFunction = null,
  sensor: SensorJSONAPIAttributes = null,
): number {
  if (isNil(compiledValueFormula) || isNil(value)) {
    return value;
  } else {
    return compiledValueFormula.evaluate({
      value: value,
      min: sensor?.total_value_range?.min,
      max: sensor?.total_value_range?.min,
    }) as number;
  }
}

export function setImageReference(
  imageReference: {
    svgImageElementSelector: string;
    svgImageUrl: string;
  },
  svgElement: SVGSVGElement,
) {
  if (isEmpty(svgElement) || isNil(imageReference)) return;

  if (
    !isEmpty(imageReference.svgImageElementSelector) &&
    !isEmpty(imageReference.svgImageUrl)
  ) {
    const imageSelector = defaultTo(
      $(svgElement).data("image-selector") as string,
      imageReference.svgImageElementSelector,
    );
    const element = $(svgElement).find(imageSelector);
    if (element.length > 0) {
      (element[0] as any as SVGImageElement).setAttribute(
        "href",
        imageReference.svgImageUrl,
      );
    }
  }
}

export function initializeSvgElement(
  element: SVGSVGElement,
  imageReplacement: {
    svgImageUrl: string;
    svgImageElementSelector: string;
  },
  invalidSensorMappings: BaseMapping[],
  mappingsBySensorId: Record<string, BaseMapping[]>,
  sensorsBySensorId: Record<string, MappedSensorConfig>,
) {
  // set Svg Element Ref
  const svgElement = element;

  // process only for initialization or if svgElement has changed
  if (!isNil(element)) {
    initializeMappingSvgElements(mappingsBySensorId, element);
    // retrieve element info from SVG
    setElementInfoFromSvg(mappingsBySensorId, element);

    // set the background image
    setImageReference(imageReplacement, svgElement);
    each(invalidSensorMappings, (mapping) => {
      if (mapping.config.hide_on_missing) {
        mapping.hide(element);
      }
    });
    // initial application of transformation
    each(sensorsBySensorId, (sensorConfig) => {
      const sensorId = sensorConfig.sensorId;
      each(mappingsBySensorId[sensorId], (mapping) => {
        try {
          mapping.applyValueToSvg(sensorConfig, element);
        } catch (e) {
          logger.log(e);
        }
      });
    });
  }
}

function initializeMappingSvgElements(
  mappingsBySensorId: Record<string, BaseMapping[]>,
  svgElement: SVGSVGElement,
) {
  each(mappingsBySensorId, (mappings: BaseMapping[]) => {
    each(mappings, (mapping: BaseMapping) => {
      mapping.initElementBinding(svgElement);
    });
  });
}

function setElementInfoFromSvg(
  mappingsBySensorId: Record<string, BaseMapping[]>,
  svgElement: SVGSVGElement,
) {
  each(mappingsBySensorId, (mappings: BaseMapping[]) => {
    each(mappings, (mapping: BaseMapping) => {
      mapping.setElementInfoFromSvg(svgElement);
    });
  });
}
