import { isNil } from "lodash";
import { ColorUtils } from "../utils/colors";
import { PlotlyDefaultFontHover } from "./plotly_fonts";

export const DIAGRAM_FILL_COLORS: string[] = ColorUtils.getColorsRgba(1.0);
export const DIAGRAM_LINE_COLORS: string[] = ColorUtils.getColorsRgba(1.0);

export const DIAGRAM_TREND_TEXT_COLOR = "rgb(92,184,92)"; //"rgb(103,106,108)";
export const DIAGRAM_TREND_INVERSE_TEXT_COLOR = "rgb(234, 153, 153)";

export const DIAGRAM_TREND_LINE_COLOR = "rgba(92,184,92, .4)";
export const DIAGRAM_TREND_FILL_COLOR = "rgba(92,184,92, .5)";
export const DIAGRAM_TREND_INVERSE_FILL_COLOR = "rgba(234, 153, 153, .5)";

export const DIAGRAM_POINT_HOVER_BG_COLOR = "rgba(75,192,192,1)";
export const DIAGRAM_POINT_HOVER_BORDER_COLOR = "rgba(220,220,220,1)";

export const DIAGRAM_DEFAULT_HOVER_LABEL_GB_COLOR = "rgba(150,150,150,0.8)";
export const DIAGRAM_DEFAULT_HOVER_LABEL_SETTINGS = {
  bgcolor: "rgba(200,200,200,0.8)",

  font: {
    ...PlotlyDefaultFontHover,
    color: "rgb(103, 106, 108)",
  },
};
/**
 * Get a line color for a line index
 * @param index Line index
 * @return A color code
 */
export function getDiagramLineColor(index: number): string {
  return DIAGRAM_LINE_COLORS[index % DIAGRAM_LINE_COLORS.length];
}

/**
 * Get a fill color for a line index
 * @param index Line index
 * @return A color code
 */
export function getDiagramFillColor(index: number): string {
  return DIAGRAM_FILL_COLORS[index % DIAGRAM_FILL_COLORS.length];
}

export function hoverModeForDataCount(
  dataCount: number,
): "closest" | "x" | "y" | "x unified" | "y unified" | false {
  if (isNil(dataCount)) {
    return "closest"; // default
  }

  /*  if (dataCount > 50000) {
    return "x";
  }*/

  return "closest";
}

export function hoverDistanceForDataCount(dataCount: number): number {
  if (isNil(dataCount)) {
    return 20; // default
  }

  if (dataCount > 100000) {
    return 10;
  }

  if (dataCount > 200000) {
    return 5;
  }

  return 20;
}
