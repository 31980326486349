import * as JSON from "json-typescript";
import * as JSONAPI from "jsonapi-typescript";
import { SubscriptionPeriod } from "../models/subscription_period";
import { SubscriptionJSONAPIAttributes } from "./subscription";
export const SUBSCRIPTION_PERIOD_JSONAPI_RESOURCE_TYPE = "subscription_periods";
export interface SubscriptionPeriodJSONObject
  extends SubscriptionPeriod,
    JSON.Object {
  subscription?: SubscriptionJSONAPIAttributes;
}

export type SubscriptionPeriodJSONAPIAttributes =
  JSONAPI.AttributesObject<SubscriptionPeriodJSONObject>;
