import { Typography } from "@mui/material";
import * as React from "react";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import { WidgetConfigSerialized } from "../../widgets/widget.types";
import { SialogicWidgetDefinition } from "./sialogic_widget_component";
import { WidgetBox } from "./widget_box";
import { WidgetBoxProps } from "./widget_box.types";

export const ErrorWidget: React.FunctionComponent<ErrorWidgetProps> = (
  props,
) => {
  return (
    <WidgetBox {...props}>
      <Typography variant="h5">{I18n.t("base.error")}</Typography>
      {props.errors.map((error, index) => (
        <Typography p={2} variant="body1" key={index}>
          {error}
        </Typography>
      ))}
    </WidgetBox>
  );
};

interface ErrorWidgetProps extends WidgetBoxProps {
  errors: string[];
}

function serializedConfigToProps(
  config: WidgetConfigSerialized & {
    original_widget_type?: string;
    errors: string[];
  },
): ErrorWidgetProps {
  return {
    ...widgetBoxPropsFromSerializedConfig(config),
    // override widget type here so that the correct admin links are rendered
    errors: config.errors,
  };
}

export const ErrorWidgetDefinition: SialogicWidgetDefinition = {
  Component: ErrorWidget,
  serializedConfigToProps: serializedConfigToProps,
};
