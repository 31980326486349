import consumer from "./consumer";

import moment, { Moment } from "moment";
import {
  CableValueBase,
  EventSubscriberBase,
  EventSubscription,
  ModelDataChannel,
} from "./model_data_channel";
import { EventNotification } from "../models/event_notification";
import { each, isNil } from "lodash";

import { IDType } from "../utils/urls/url_utils";
import { AssetProperties } from "../json_api/asset";
import { AssetCableValue } from "./asset_notification_channel";

export interface MqttForwardChannelCableValue extends CableValueBase {
  message: unknown;
  topic: string;
}

export interface MqttForwardSubscriber extends EventSubscriberBase {
  handleMqttMessage?(topic: string, message: unknown): void;
}

const WILDCARD_ATTRIBUTE_ID = -1;

export class MqttForwardChannel extends ModelDataChannel<
  MqttForwardSubscriber,
  MqttForwardChannelCableValue
> {
  constructor() {
    super();
  }

  protected getChannelNameWithParams(
    modelId: number,
  ): string | ActionCable.ChannelNameWithParams {
    return {
      channel: "MqttForwardChannel",
      asset_id: modelId,
    };
  }

  public subscribe(modelId: number) {
    return super.subscribe(modelId);
  }
  protected handleDataMessage(
    data: MqttForwardChannelCableValue,
    listeners: EventSubscription<MqttForwardSubscriber>[],
  ): void {
    if (isNil(listeners)) return;
    each(listeners, (listener) => {
      listener.f.handleMqttMessage?.(data.topic, data.message);
    });
  }
}

export default MqttForwardChannel;
