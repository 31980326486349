import { CollectionResourceDoc, SingleResourceDoc } from "jsonapi-typescript";
import { concat, isEmpty, isNil, uniq } from "lodash";
import { createQuery } from "react-query-kit";
import {
  ASSET_TYPE_JSONAPI_RESOURCE_TYPE,
  AssetTypeJsonApiFilter,
  AssetTypeJSONObject,
} from "../json_api/asset_type";
import {
  jsonApiSingleResourceToFlatObject,
  loadItemResultForJsonApiCollectionResourceDoc,
  LoadItemsResult,
} from "../json_api/jsonapi_tools";
import { TreeMode } from "../json_api/tree_items";
import {
  api_asset_types_path,
  asset_type_path,
  asset_type_tree_items_api_asset_type_path,
} from "../routes";
import { loadDataFromUrl } from "../utils/jquery_helper";
import { IDType } from "../utils/urls/url_utils";
import { PageSettings } from "../components/common/page_size";
import { AssetTypeTreeItem } from "../components/asset_type/asset_type_tree_select";

export type LoadAssetTypesParams = {
  pageSettings: PageSettings;
  childrenOf?: IDType;
  rootOnly?: boolean;
  search?: string;
  includeGenericType?: boolean;
};

export const useLoadAssetTypesQuery = createQuery<
  LoadItemsResult<AssetTypeJSONObject>,
  LoadAssetTypesParams
>({
  queryKey: [ASSET_TYPE_JSONAPI_RESOURCE_TYPE],
  fetcher: async ({
    pageSettings,
    childrenOf,
    rootOnly,
    search,
    includeGenericType = true,
  }) => {
    const filter: AssetTypeJsonApiFilter = {};
    if (childrenOf) {
      if (includeGenericType) {
        filter.children_of_or_generic = childrenOf;
      } else {
        filter.children_of = childrenOf;
      }
    }
    if (rootOnly) {
      filter.root_only = true;
    }

    if (!isEmpty(search)) {
      filter.search = search;
    }
    const options = {
      //organizationId ? { organization: organizationId } : null,
      filter: isEmpty(filter) ? null : filter,
      _options: true,
    };

    if (pageSettings) {
      (options as any).page = {
        number: pageSettings.number,
        size: pageSettings.size,
      };
    }

    const url = api_asset_types_path(options as any);

    const resp =
      await loadDataFromUrl<CollectionResourceDoc<string, AssetTypeJSONObject>>(
        url,
      );
    return loadItemResultForJsonApiCollectionResourceDoc(resp);
  },
});

type LoadAssetTypeTreeParams = {
  assetTypeId: IDType;
  treeMode: TreeMode;
};

export const useLoadAssetTypeTreeQuery = createQuery<
  AssetTypeTreeItem[],
  LoadAssetTypeTreeParams
>({
  queryKey: [ASSET_TYPE_JSONAPI_RESOURCE_TYPE, "tree"],
  fetcher: async ({ assetTypeId, treeMode }, { signal }) => {
    if (!isNil(assetTypeId)) {
      return loadDataFromUrl<AssetTypeTreeItem[]>(
        asset_type_tree_items_api_asset_type_path(assetTypeId, {
          _options: true,
          tree_mode: treeMode,
        }),
        "json",
        signal,
      );
    }
  },
});

export interface LoadAssetTypeVariables {
  id: IDType;
  include?: string[];
}

export const useLoadAssetTypeQuery = createQuery<
  AssetTypeJSONObject,
  LoadAssetTypeVariables
>({
  queryKey: [ASSET_TYPE_JSONAPI_RESOURCE_TYPE],
  fetcher: async ({ id, include }, { signal }) => {
    const options = {
      _options: true,
    };
    const includes = uniq(concat(include || [])).join(",");
    if (!isEmpty(includes)) {
      (options as any)["include"] = includes;
    }

    const result = await loadDataFromUrl<
      SingleResourceDoc<string, AssetTypeJSONObject>
    >(asset_type_path(id, options), "json", signal);

    return jsonApiSingleResourceToFlatObject(result);
  },
});
