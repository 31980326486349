import { last, snakeCase } from "lodash";
import {
  IDType,
  applyParamsDataToBaseUrl,
  localizedBasePath,
} from "./url_utils";
import {
  asset_dashboard_widgets_path,
  asset_type_asset_type_dashboard_widgets_path,
  edit_widget_path,
  rails_admin_edit_path,
} from "../../routes";

export function assetDashboardWidgetsPath(
  assetId: IDType,
  dashboardId: IDType,
): string {
  return asset_dashboard_widgets_path(assetId, dashboardId, {
    _options: true,
    format: "json",
  });
}

export function assetTypesDashboardWidgetsPath(
  assetTypeId: IDType,
  dashboardId: IDType,
): string {
  return asset_type_asset_type_dashboard_widgets_path(
    assetTypeId,
    dashboardId,
    {
      _options: true,
      format: "json",
    },
  );
}

export function widgetEditUrl(widgetId: IDType, referrer?: string): string {
  let url = new URL(edit_widget_path(widgetId), window.location.origin);
  url = applyParamsDataToBaseUrl(url, [["referrer", referrer]]);
  return url.pathname + url.search;
}

/** Creates a link to backend edit view
 *
 *
 * @export
 * @param {string} widgetClassName Scoped or unscoped widget class name
 * @param {IDType} id Id of widget
 * @return {*}  {string}
 */
export function adminWidgetEditPath(
  widgetClassName: string,
  id: IDType,
): string {
  return rails_admin_edit_path(
    `Widgets~${last(widgetClassName.split("::"))}`,
    id,
  );
}

export type DashboardsWidgetActions =
  | "move_up"
  | "move_down"
  | "move_left"
  | "move_right"
  | "change_area"
  | "change_size"
  | "update_position"
  | "update_row"
  | "change_height"
  | "config_change";
export function dashboardsWidgetActionUrl(
  dashboardsWidgetId: IDType,
  action: DashboardsWidgetActions,
) {
  if (action == "config_change") return null;

  return `${localizedBasePath(
    true,
  )}/dashboards_widgets/${dashboardsWidgetId}/${action}`;
}
