/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { get, isEmpty, isNil, noop, startsWith } from "lodash";
import { WidgetController } from "../controller/widget_controller";

type CallbackFunctionType = (...args: any[]) => void;
/**
 * Find a callback from data attributes
 * @param widgetId
 * @param callbackName
 */
export function getCallback(
  widgetId: string,
  callbackName: string,
): CallbackFunctionType {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  let callback = noop;

  if (widgetId && !isEmpty(callbackName)) {
    const widget = WidgetController.getInstance().getWidget(widgetId);
    if (isNil(widget)) {
      throw new Error(`Widget ${widgetId} was not found.`);
    }
    callback = (widget as any as Record<string, CallbackFunctionType>)[
      callbackName
    ].bind(widget);
  } else if (!isEmpty(callbackName)) {
    if (startsWith(callbackName, "SIALOGIC.WidgetController.getInstance()")) {
      callbackName = callbackName.replace(
        "SIALOGIC.WidgetController.getInstance().",
        "",
      );
      const widgetController = WidgetController.getInstance() as any;

      if (isNil(widgetController[callbackName])) {
        throw new Error(`Callback: ${callbackName} was not found.`);
      }
      callback = (widgetController as Record<string, CallbackFunctionType>)[
        callbackName
      ].bind(widgetController);
    } else {
      callback = get(window, callbackName, noop);
    }
  }

  return callback;
}
