import * as Plotly from "plotly.js/lib/core";

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
Plotly.register([
  // traces
  require("plotly.js/lib/bar"),
  /*require('plotly.js/lib/box'),
  require('plotly.js/lib/heatmap'),
  require('plotly.js/lib/histogram'),
  require('plotly.js/lib/histogram2d'),
  require('plotly.js/lib/histogram2dcontour'),
  require('plotly.js/lib/contour'),
  require('plotly.js/lib/scatterternary'),
  require('plotly.js/lib/violin'),
  require('plotly.js/lib/funnel'),
  require('plotly.js/lib/waterfall'),
  require('plotly.js/lib/image'),*/
  require("plotly.js/lib/pie"),
  /*
  require('plotly.js/lib/sunburst'),
  require('plotly.js/lib/treemap'),
  require('plotly.js/lib/icicle'),
  require('plotly.js/lib/funnelarea'),
  require('plotly.js/lib/scatter3d'),
  require('plotly.js/lib/surface'),
  require('plotly.js/lib/isosurface'),
  require('plotly.js/lib/volume'),
  require('plotly.js/lib/mesh3d'),
  require('plotly.js/lib/cone'),
  require('plotly.js/lib/streamtube'),
  require('plotly.js/lib/scattergeo'),
  require('plotly.js/lib/choropleth'),*/
  require("plotly.js/lib/scatter"),
  require("plotly.js/lib/scattergl"),
  /*require('plotly.js/lib/splom'),
  require('plotly.js/lib/pointcloud'),
  require('plotly.js/lib/heatmapgl'),
  require('plotly.js/lib/parcoords'),
  require('plotly.js/lib/parcats'),
  require('plotly.js/lib/scattermapbox'),
  require('plotly.js/lib/choroplethmapbox'),
  require('plotly.js/lib/densitymapbox'),
  require('plotly.js/lib/sankey'),*/
  require("plotly.js/lib/indicator"),
  /*require('plotly.js/lib/table'),
  require('plotly.js/lib/carpet'),
  require('plotly.js/lib/scattercarpet'),
  require('plotly.js/lib/contourcarpet'),
  require('plotly.js/lib/ohlc'),
  require('plotly.js/lib/candlestick'),

  */
  require("plotly.js/lib/scatterpolar"),
  require("plotly.js/lib/scatterpolargl"),
  /*
  require('plotly.js/lib/barpolar'),
  */

  // transforms
  //require("plotly.js/lib/aggregate"),
  //require("plotly.js/lib/filter"),
  //require("plotly.js/lib/groupby"),
  //require("plotly.js/lib/sort"),

  // components
  require("plotly.js/lib/calendars"),
]);

export type PlotlyLayoutYaxisNames =
  | "yaxis"
  | "yaxis2"
  | "yaxis3"
  | "yaxis4"
  | "yaxis5"
  | "yaxis6"
  | "yaxis7"
  | "yaxis8"
  | "yaxis9";

// customizable method: use your own `Plotly` object
import createPlotlyComponent from "react-plotly.js/factory";

const Plot = createPlotlyComponent(Plotly);

export { Plotly as Plotly, Plot as PlotlyPlot };
