import { Search } from "@mui/icons-material";
import { Box, ButtonGroup, Grid, Link, TextField } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useDebounce } from "@uidotdev/usehooks";
import {
  compact,
  defaultTo,
  flatMap,
  isEmpty,
  isEqual,
  isNil,
  map,
  merge,
  sortedUniq,
  toInteger,
  toString,
  uniq,
  uniqBy,
} from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { AssetJsonApiFilter, AssetJSONObject } from "../../json_api/asset";
import { ItemAction } from "../../models/item_action";
import { State } from "../../models/state";
import { StateContext } from "../../models/state_context";
import { assetPath } from "../../utils/urls";
import { AssetListWidgetConfigSerialized } from "../../widgets/asset_list_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import { useLoadAssetsQuery } from "../../queries/asset_data";
import { StateByContextFilter } from "../states/state_by_context_filter";
import { AssetListWidgetProps } from "./asset_list_widget.types";
import {
  addStateContextColumns,
  buttonForAssetAction,
} from "./asset_list_widget_utils";
import { SialogicWidgetDefinition } from "./sialogic_widget_component";
import { WidgetBox } from "./widget_box";
import { props } from "bluebird";
import { AppContext } from "../common/app_context/app_context_provider";

const gridColDef = (
  actions: ItemAction[],
  stateContexts: StateContext[],
): GridColDef<AssetJSONObject>[] => {
  const defaultWidth = 130;
  const largeWidth = 200;
  const columns: GridColDef<AssetJSONObject>[] = [
    { field: "id", headerName: "#", type: "number" },
    {
      field: "name",
      headerName: I18n.t("activerecord.attributes.asset.name"),
      type: "string",
      width: largeWidth,
      renderCell: (params) => (
        <Link href={assetPath(params.row.id)}>{params.row.name}</Link>
      ),
    },
    {
      field: "asset_type_name",
      headerName: I18n.t("activerecord.attributes.asset.asset_type"),
      type: "string",
      width: defaultWidth,
    },
    {
      field: "description",
      headerName: I18n.t("activerecord.attributes.asset.description"),
      width: defaultWidth,
      type: "string",
    },
    {
      field: "serial",
      headerName: I18n.t("activerecord.attributes.asset.serial"),
      type: "string",
      width: defaultWidth,
    },
    {
      field: "operator",
      headerName: I18n.t("activerecord.attributes.asset.operator"),
      width: largeWidth,
      type: "string",
      valueGetter: (value, row) => {
        const orgID = row.operator_id;
        if (isNil(orgID)) return null;
        return row.operator_name;
      },
    },
    {
      field: "created_at",
      headerName: I18n.t("activerecord.attributes.base.created_at"),
      minWidth: defaultWidth,
      type: "dateTime",
      valueGetter: (value, row) => new Date(row.created_at),
      valueFormatter: (value, row) => moment(value).format("L LT"),
    },
    {
      field: "updated_at",
      headerName: I18n.t("activerecord.attributes.base.updated_at"),
      minWidth: defaultWidth,
      type: "dateTime",
      valueGetter: (value, row) => new Date(row.created_at),
      valueFormatter: (value, row) => moment(value as Date).format("L LT"),
    },
  ];

  if (!isNil(actions) && !isEmpty(actions)) {
    columns.push({
      field: "actions",
      headerName: I18n.t("base.actions"),
      width: 400,
      renderCell: (params) => {
        return (
          <ButtonGroup>
            {map(actions, (action, index) =>
              buttonForAssetAction(action, params.row.id, index),
            )}
          </ButtonGroup>
        );
      },
    });
  }

  return addStateContextColumns(columns, largeWidth, stateContexts);
};

export const AssetListWidget: React.FunctionComponent<AssetListWidgetProps> = ({
  dataUpdateEnabled = true,
  encloseInWidgetBox = true,
  pageSize = 10,
  tableHeight = 500,
  enableSearch = true,
  enableStateFilter = true,
  allowFullscreen = true,
  showStateContexts = true,
  ...props
}) => {
  const appCtx = useContext(AppContext);
  const pageSizes = useMemo(
    () => sortedUniq([10, pageSize, 20, 50, 100].sort((a, b) => a - b)),
    [pageSize],
  );

  const [pageSettings, setPageSettings] = useState({
    number: 1,
    size: pageSize,
  });

  const [stateContexts, setStateContexts] = useState<StateContext[]>([]);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);

  const debouncedSearchTerm = useDebounce(searchTerm, 1000);

  const [filter, setFilter] = useState<AssetJsonApiFilter>(() => {
    const initialFilter = { ...(props.filter || {}) };
    if (props.assetTypeId) {
      initialFilter.asset_type = props.assetTypeId;
    }

    return initialFilter;
  });

  useEffect(() => {
    if (props.filter) {
      setFilter((f) => ({
        ...props.filter,
        ...f,
      }));
    }
  }, [props.filter]);

  useEffect(() => {
    setFilter((f) => ({
      ...(f || {}),
      search: isEmpty(debouncedSearchTerm) ? null : debouncedSearchTerm,
    }));
  }, [debouncedSearchTerm]);

  const { data: assets, isLoading: loading } = useLoadAssetsQuery({
    variables: {
      currentOrganizationId: appCtx.currentOrganizationId,
      pageSettings,
      filter,
      scope: props.scope,
      includes: compact([
        "location",
        showStateContexts ? "context_state_machines" : null,
        showStateContexts ? "context_state_machines.state_context" : null,
        showStateContexts ? "context_state_machines.possible_states" : null,
        showStateContexts ? "context_state_machines.current_state" : null,
      ]),
    },
    placeholderData: {
      items: [],
      totalItems: -1,
      totalPages: -1,
    },
  });

  useEffect(() => {
    if (!isEmpty(assets)) {
      if (!showStateContexts) {
        setStateContexts([]);
      }
      const newStateContexts = uniqBy(
        flatMap(assets.items, (a) =>
          map(a.context_state_machines, (csm) => csm.state_context),
        ),
        (sc) => sc.id,
      );
      // do not update state if nothing changed
      if (
        !isEqual(
          uniq(map(stateContexts, (sc) => sc.id))?.sort(),
          uniq(map(newStateContexts, (sc) => sc.id))?.sort(),
        )
      ) {
        setStateContexts(newStateContexts);
      }
    }
  }, [assets]);

  const tableContent = (): React.ReactElement => {
    let showPagination = true;
    if (!isNil(pageSize) && !isNil(assets?.totalPages)) {
      // may be -1 before first load, but that should not bother this
      showPagination = pageSize <= assets.totalPages;
    }

    const columns = React.useMemo(() => {
      return gridColDef(props.actions, showStateContexts ? stateContexts : []);
    }, [stateContexts, props.actions, showStateContexts]);

    return (
      <Box style={{ height: tableHeight, width: "100%" }}>
        <DataGrid
          paginationMode="server"
          hideFooterPagination={showPagination}
          initialState={{
            density: defaultTo(props.density, "standard"),
            columns: {
              columnVisibilityModel: {
                id: false,
                created_at: false,
                updated_at: false,
                description: false,
              },
            },
          }}
          paginationModel={{
            pageSize: pageSettings.size,
            // Grid counts from 0, API counts from 1
            page: pageSettings.number - 1,
          }}
          pageSizeOptions={pageSizes}
          rowCount={isNil(assets?.totalItems) ? -1 : assets.totalItems}
          rows={defaultTo(assets?.items, [])}
          columns={columns}
          loading={loading}
          onRowClick={(row) => {
            const assetId = toInteger(row.id);
            props?.onAssetSelect(assetId, row);
          }}
          onPaginationModelChange={(model) => {
            setPageSettings({
              number: model.page + 1,
              size: model.pageSize,
            });
          }}
        />
      </Box>
    );
  };

  const [selectedContext, setSelectedContext] =
    React.useState<StateContext>(null);
  const [selectedState, setSelectedState] = React.useState<State>(null);

  React.useEffect(() => {
    if (selectedContext && selectedState) {
      setFilter((f) => ({
        ...f,
        with_state: {
          context: selectedContext.identifier,
          state: selectedState.identifier,
        },
      }));
    } else {
      if (!selectedState) {
        setFilter((f) => ({
          ...f,
          with_state: null,
        }));
      }
    }
  }, [selectedContext?.id, selectedState?.id]);

  return (
    <>
      {!encloseInWidgetBox ? (
        tableContent()
      ) : (
        <WidgetBox {...props}>
          <Grid container spacing={1} justifyContent="space-between">
            {enableStateFilter && (
              <StateByContextFilter
                stateContexts={stateContexts}
                selectedContextId={selectedContext?.id}
                selectedStateId={selectedState?.id}
                onStateSelect={(state, context) => {
                  setSelectedContext(context);
                  setSelectedState(state);
                }}
              />
            )}

            {enableSearch ? (
              <Grid item xs="auto">
                <TextField
                  size="small"
                  type="search"
                  className="float-right"
                  sx={{ minWidth: 200 }}
                  value={toString(searchTerm)}
                  label={I18n.t("frontend.search")}
                  onChange={(event) => {
                    setSearchTerm(event.currentTarget.value);
                  }}
                  InputProps={{
                    startAdornment: <Search />,
                  }}
                />
              </Grid>
            ) : null}
            <Grid item xs={12}>
              {tableContent()}
            </Grid>
          </Grid>
        </WidgetBox>
      )}
    </>
  );
};

function serializedConfigToProps(
  config: AssetListWidgetConfigSerialized,
): AssetListWidgetProps {
  return merge(widgetBoxPropsFromSerializedConfig(config), {
    dataUpdateEnabled: !config.disable_update,
    actions: config.actions,

    density: config.table_density,
    tableHeight: defaultTo(config.table_height, 500),
    assetTypeId: config.asset_type_id as number,
    page: defaultTo(config.page, 1),
    pageSize: defaultTo(config.page_size, 15),
    scope: config.scope,
    filter: config.filter as AssetJsonApiFilter,
    showStateContexts: config.show_state_contexts,
  } as AssetListWidgetProps);
}

export const AssetListWidgetDefinition: SialogicWidgetDefinition<
  typeof AssetListWidget,
  typeof serializedConfigToProps
> = {
  Component: AssetListWidget,
  serializedConfigToProps: serializedConfigToProps,
};
