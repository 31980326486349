import { defaultTo, isEmpty, isNil } from "lodash";
import * as React from "react";
import { FunctionComponent } from "react";

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Link,
  Paper,
  Skeleton,
  Typography,
} from "@mui/material";
import { SingleResourceDoc } from "jsonapi-typescript";

import {
  AssetEventJSONAPIAttributes,
  AssetEventJSONObject,
} from "../../json_api/asset_event";
import { jsonApiSingleResourceToFlatObject } from "../../json_api/jsonapi_tools";
import {
  api_asset_event_path,
  edit_asset_asset_event_path,
} from "../../routes";
import { loadDataFromUrl } from "../../utils/jquery_helper";
import { redirectTo } from "../../utils/redirection";
import { assetPath } from "../../utils/urls";
import { IDType } from "../../utils/urls/url_utils";
import { FixedBottomArea } from "../common/fixed_bottom_area";
import { FloatingButtons } from "../common/floating_buttons";
import { Icon } from "../common/icon";
import { SeverityLevelChip } from "../common/severity_level";
import { UserCard } from "../users/user_card";

import { Edit, KeyboardArrowLeft } from "@mui/icons-material";

import { ResourcePermission } from "../../models/resource_permission";
import { getTranslatedProp } from "../../utils/globalize";
import { useLoadAssetEventQuery } from "../../queries/asset_events_data";

export interface AssetEventDetailsProps {
  assetEvent?: AssetEventJSONObject;
  assetEventId: IDType;
  permission?: ResourcePermission;

  withFabButtons?: boolean;
  wrapInCard?: boolean;
  onCancel?: () => void;
}

function stringsForAssetEvent(assetEvent: AssetEventJSONObject) {
  if (isNil(assetEvent))
    return {
      name: "---",
      description: "---",
      message: "---",
      action: "---",
      code: "",
    };

  return {
    name: defaultTo(
      isNil(assetEvent.name) ? assetEvent.event_type?.name : assetEvent.name,
      "---",
    ),
    description: defaultTo(
      isNil(assetEvent.description)
        ? assetEvent.event_type?.description
        : assetEvent.description,
      "---",
    ),
    action: defaultTo(
      isNil(assetEvent.action)
        ? assetEvent.event_type?.action
        : assetEvent.action,
      "---",
    ),
    message: defaultTo<string>(
      isNil(assetEvent.message)
        ? assetEvent.event_type?.message
        : assetEvent.message,
      "---",
    ),
    code: defaultTo(
      isNil(assetEvent.code) ? assetEvent.event_type?.code : assetEvent.code,
      "---",
    ),
  };
}
export const AssetEventDetails: FunctionComponent<AssetEventDetailsProps> = ({
  withFabButtons = false,
  wrapInCard = false,
  ...props
}) => {
  const { data: assetEvent, isLoading: eventLoading } = useLoadAssetEventQuery({
    variables: {
      id: props.assetEvent?.id || props.assetEventId,
      includes: ["asset", "root_asset", "event_type", "user"],
    },
    initialData: defaultTo(props.assetEvent, undefined),
    enabled:
      isNil(props.assetEvent) ||
      (!isNil(props.assetEventId) &&
        props.assetEventId !== props.assetEvent?.id),
  });

  const [strings, setStrings] = React.useState(() =>
    stringsForAssetEvent(assetEvent),
  );

  React.useEffect(() => {
    if (assetEvent) {
      setStrings(stringsForAssetEvent(assetEvent));
    }
  }, [assetEvent]);

  let details = (
    <Grid container spacing={2}>
      {eventLoading ? (
        <Skeleton height={500} />
      ) : (
        <>
          <Grid item xs={12} marginBottom={2} borderBottom={1}>
            <Grid container spacing={2}>
              <Grid item container>
                <Grid item xs={12} sm={3} paddingX={1}>
                  <Typography variant="caption" marginY="auto">
                    {I18n.t("activerecord.attributes.asset_event.timestamp")}
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  {assetEvent.from != assetEvent.to &&
                  !isEmpty(assetEvent.to) &&
                  isEmpty(!assetEvent.from)
                    ? `${I18n.l(
                        "time.formats.sialogic_short",
                        new Date(assetEvent.from),
                      )} - ${I18n.l(
                        "time.formats.sialogic_short",
                        new Date(assetEvent.to),
                      )}`
                    : I18n.l(
                        "time.formats.sialogic_short",
                        new Date(assetEvent.from),
                      )}
                  <span className="ml-2"></span>
                </Grid>
              </Grid>

              <Grid item xs={12} container className="border-bottom mb-2">
                <Grid item xs={12} sm={3} paddingX={1}>
                  <Typography my="auto" variant="caption">
                    {I18n.t(
                      "activerecord.attributes.asset_event.severity_level",
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9} mb={1}>
                  <SeverityLevelChip
                    severityLevel={assetEvent.severity_level}
                  />
                </Grid>
              </Grid>
              {isNil(assetEvent.event_type) ? null : (
                <Grid
                  item
                  xs={12}
                  container
                  marginBottom={2}
                  className="border-bottom"
                >
                  <Grid item xs={12} sm={3} paddingX={1}>
                    <Typography my="auto" variant="caption">
                      {I18n.t(
                        "activerecord.attributes.asset_event.event_type_id",
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Box mr={1} component="span">
                      {!isEmpty(assetEvent.event_type.icon) ? (
                        <Icon
                          icon={assetEvent.event_type.icon}
                          color={assetEvent.event_type.color}
                        />
                      ) : null}
                    </Box>
                    <Typography component="span" variant="body2">
                      {assetEvent.event_type.name}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {["name", "description", "action", "message", "code"].map(
                (attr: keyof typeof strings) => (
                  <Grid
                    item
                    container
                    xs={12}
                    className="border-bottom mb-2"
                    key={attr}
                  >
                    <Grid item xs={12} sm={3} paddingX={1}>
                      <Typography className="auto-hyphen" variant="caption">
                        {I18n.t(`activerecord.attributes.asset_event.${attr}`)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <Typography variant="body2">{strings[attr]}</Typography>
                    </Grid>
                  </Grid>
                ),
              )}
              <Grid item xs={12} container className="border-bottom  mb-2">
                <Grid item xs={12} sm={3} paddingX={1}>
                  <Typography variant="caption">
                    {I18n.t("activerecord.attributes.asset_event.asset")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Link href={assetPath(assetEvent.asset_id)}>
                    {defaultTo(
                      assetEvent.asset?.name,
                      I18n.t("activerecord.models.asset.one"),
                    )}
                  </Link>
                </Grid>
              </Grid>
              {assetEvent.asset?.id === assetEvent.root_asset?.id ? null : (
                <Grid item container xs={12} className="mb-2">
                  <Grid item xs={12} sm={3} paddingX={1}>
                    <Typography variant="caption">
                      {I18n.t("activerecord.attributes.asset_event.root_asset")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Link href={assetPath(assetEvent.root_asset.id)}>
                      {defaultTo(assetEvent.root_asset?.name, "---")}
                    </Link>
                  </Grid>
                </Grid>
              )}
              {isNil(assetEvent.user) ? null : (
                <Grid item container xs={12} className="mb-2">
                  <Grid item xs={12} sm={3} paddingX={1}>
                    <Typography variant="caption">
                      {I18n.t("activerecord.attributes.asset_event.user")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Paper>
                      <UserCard user={assetEvent.user} />
                    </Paper>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );

  if (withFabButtons) {
    details = (
      <>
        {details}
        <FixedBottomArea>
          <FloatingButtons
            submitBtnIcon={<Edit />}
            onSubmit={() => {
              redirectTo(
                edit_asset_asset_event_path(
                  assetEvent.asset_id,
                  assetEvent?.id,
                ),
              );
            }}
            cancelIcon={<KeyboardArrowLeft />}
            onCancel={() => {
              if (isNil(props?.onCancel)) {
                redirectTo("back");
              } else {
                props.onCancel();
              }
            }}
          />
        </FixedBottomArea>
      </>
    );
  }
  if (!wrapInCard) {
    return details;
  }

  return (
    <Card>
      <CardHeader
        title={
          <>
            {I18n.t("activerecord.models.asset_event.one") + " "}
            {assetEvent?.asset
              ? getTranslatedProp(assetEvent?.asset, "name")
              : null}
          </>
        }
      />
      <CardContent>
        <Box display={"flex"} m={2}>
          {details}
        </Box>
      </CardContent>
    </Card>
  );
};
