/// <reference types="../definitions/index" />;
import { dialog } from "./dialog";

let confirmLeaveTitle: string = I18n.t("frontend.confirm_title");
let confirmLeaveMessage: string = I18n.t("frontend.confirm_leave");
let showDialog = false;

export function setConfirmLeaveMessage(title: string, message: string): void {
  confirmLeaveTitle = title;
  confirmLeaveMessage = message;
}

export function showBeforeUnloadDialog(enabled: boolean): void {
  showDialog = enabled;
}

export function bindUnloadCallbacks() {
  $(window).on("beforeunload", (event) => {
    if (!showDialog) {
      return;
    }

    // open confirm dialog (cross browser), sadly Chrome removed support for custom messages
    event.originalEvent.returnValue = confirmLeaveMessage as any; // Firefox and IE
    return confirmLeaveMessage; // Safari
  });
  $(window).on("turbo:before-visit", (event: any) => {
    if (!showDialog) {
      return;
    }

    event.preventDefault();

    return dialog
      .fire({
        showConfirmButton: true,
        showCancelButton: true,
        icon: "question",
        cancelButtonText: I18n.t("frontend.no"),
        confirmButtonText: I18n.t("frontend.yes"),
        title: confirmLeaveTitle,
        html: `<p>${confirmLeaveMessage}</p>`,
      })
      .then((result) => {
        if (!result.value) {
          return;
        }

        // disable dialog and resume page visit if confirm is clicked
        showDialog = false;

        (global as any).Turbo.visit(event.originalEvent.detail.url, {
          action: "replace",
        });
      });
  });
}
