import { Avatar, Box, Grid } from "@mui/material";
import { defaultTo, isNil } from "lodash";
import * as React from "react";
import { getStateColor } from "../../utils/colors";
import { FaSizeType } from "../common/icon";
import { getIconForName } from "../common/icon_for_name";
import { StateAvatar } from "./state_avatar";
export interface StateDisplayProps {
  inline?: boolean;
  stateName?: string;
  criticality?: number;
  stateColor?: string;
  stateIcon?: string;
  iconSize?: FaSizeType;
  imageUrl?: string;
  stateDescription?: string;
}

function getInlineContent(
  props: StateDisplayProps,
  styles: React.CSSProperties,
) {
  const displayName = defaultTo(props.stateName, "---");
  return (
    <Grid
      container
      style={styles}
      title={displayName}
      justifyContent={"center"}
    >
      <Grid item>
        {isNil(props.imageUrl) ? (
          getIconForName(
            props.stateIcon,
            defaultTo(props.iconSize, "1x" as FaSizeType),
          )
        ) : (
          <Avatar src={props.imageUrl} />
        )}
      </Grid>
      <Grid item className="my-auto">
        <span className="state-title ml-2">
          {defaultTo(props.stateName, "---")}
        </span>
      </Grid>
    </Grid>
  );
}

function getRowContent(props: StateDisplayProps, styles: React.CSSProperties) {
  const displayName = defaultTo(props.stateName, "---");
  return (
    <Grid container>
      <Grid item xs={12} className="text-center" title={props.stateDescription}>
        <StateAvatar
          imageUrl={props.imageUrl}
          iconName={props.stateIcon}
          color={props.stateColor}
          iconSize={props.iconSize}
          name={props.stateName}
        />
      </Grid>

      <Grid item container className="mt-2" xs={12}>
        <Grid
          item
          xs={12}
          className="text-center"
          title={props.stateDescription}
        >
          <Box
            component="span"
            className="state-title"
            title={displayName}
            style={styles}
          >
            {displayName}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
export function StateDisplay(props: StateDisplayProps) {
  const styles: React.CSSProperties = {};
  const color = getStateColor({
    criticality: props.criticality,
    color: props.stateColor,
  });
  if (!isNil(color)) {
    styles.color = color;
  }

  if (props.inline) {
    return getInlineContent(props, styles);
  } else {
    return getRowContent(props, styles);
  }
}
