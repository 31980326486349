import * as JSON from "json-typescript";
import * as JSONAPI from "jsonapi-typescript";
import { find, isArray, isEmpty } from "lodash";
import { ActiveStorageStoredFile } from "../models/active_storage_stored_file";
import {
  MeasurementAttributes,
  MeasurementValueAttributes,
} from "../models/measurement";
import { MeasurementCategory } from "../models/measurement_category";
import { MeasurementPlanAttributes } from "../models/measurement_plan";
import { MeasurementValueDefinitionAttributes } from "../models/measurement_value_definition";
import { AssetJsonApiAttributes, AssetJSONObject } from "./asset";
import { MeasurementCategoryJsonObject } from "./measurement_category";
import {
  MeasurementPlanJSONApiAttributes,
  MeasurementPlanJSONAPIAttributesObject,
} from "./measurement_plan";
import {
  MeasurementTypeJsonAttributes,
  MeasurementTypeJsonObject,
} from "./measurement_type";
import { MeasurementValueDefinitionJsonApiObject } from "./measurement_value_definition";

export const MEASUREMENT_JSON_API_TYPE = "measurements";

export type MeasurementJsonApiIncludes =
  | "measurement_plan"
  | "measurement_type"
  | "asset"
  | "measurement_value_definitions"
  | "measurement_categories";

export interface MeasurementJSONApiAttributes extends MeasurementAttributes {
  created_at?: string;

  measurement_plan_title?: string;
  meta_info: JSON.Object;
  attachments?: ActiveStorageStoredFile[];
}
interface MeasurementValueJsonObject
  extends JSON.Object,
    MeasurementValueAttributes {}
export interface MeasurementJsonObject
  extends JSON.Object,
    MeasurementJSONApiAttributes {
  asset?: AssetJSONObject;
  measurement_type?: MeasurementTypeJsonObject;
  measurement_values?: MeasurementValueJsonObject[];
  measurement_plan?: MeasurementPlanJSONAPIAttributesObject;
  measurement_value_definitions?: MeasurementValueDefinitionJsonApiObject[];
  measurement_categories?: MeasurementCategoryJsonObject[];
}

export type MeasurementJSONAPIAttributesObject =
  JSONAPI.AttributesObject<MeasurementJsonObject>;

interface ExtractedMeasurementData {
  assets?: AssetJsonApiAttributes[];
  measurementPlans: MeasurementPlanJSONApiAttributes[];
  measurementTypes: MeasurementTypeJsonAttributes[];
  measurementValues: MeasurementValueAttributes[];
  measurementValueDefinitions: MeasurementValueDefinitionAttributes[];
  measurements: MeasurementJSONAPIAttributesObject[];
  measurementCategories: MeasurementCategory[];
}

export function extractIncludedFromJsonApiListResponse(
  resp:
    | JSONAPI.CollectionResourceDoc<string, MeasurementJSONAPIAttributesObject>
    | JSONAPI.SingleResourceDoc<string, MeasurementJSONAPIAttributesObject>,
): ExtractedMeasurementData {
  const data: ExtractedMeasurementData = {
    assets: [],
    measurementPlans: [],
    measurementTypes: [],
    measurementValues: [],
    measurementValueDefinitions: [],
    measurements: [],
    measurementCategories: [],
  };

  resp?.included?.forEach((includedItem) => {
    let arr: Array<
      | MeasurementPlanAttributes
      | MeasurementValueDefinitionAttributes
      | MeasurementValueAttributes
      | MeasurementCategory
      | AssetJsonApiAttributes
    >;
    if (
      includedItem.type === "measurement_plans" ||
      includedItem.type === "measurement_plan"
    ) {
      arr = data.measurementPlans;
    } else if (includedItem.type === "measurement_value_definitions") {
      arr = data.measurementValueDefinitions;
    } else if (
      includedItem.type === "measurement_category" ||
      includedItem.type === "measurement_categories"
    ) {
      arr = data.measurementCategories;
    } else if (includedItem.type === "assets") {
      arr = data.assets;
    } else if (
      includedItem.type === "measurement_type" ||
      includedItem.type === "measurement_types"
    ) {
      arr = data.measurementTypes;
    } else {
      // no known models
      return;
    }
    arr.push({ ...includedItem.attributes, id: includedItem.id });
  });

  if (isEmpty(resp.data)) return data;

  const dataItems = isArray(resp?.data) ? resp.data : [resp.data];

  dataItems.forEach((measurementItem) => {
    const mp = find(
      data.measurementPlans,
      (mp) => mp.id == measurementItem?.attributes?.measurement_plan_id,
    );
    // assign measurement type and measurement plan
    if (mp) {
      measurementItem.attributes.measurement_plan = mp as any;
      const mt = find(
        data.measurementTypes,
        (mt) => mt.id == mp.measurement_type_id,
      );
      if (mt) {
        measurementItem.attributes.measurement_type = mt as any;
      }
    }

    const asset = find(
      data.assets,
      (asset) => asset.id == measurementItem?.attributes?.asset_id,
    );
    if (asset) {
      measurementItem.attributes.asset = asset as any;
    }

    const mvds = data.measurementValueDefinitions.filter((mvd) =>
      find(
        measurementItem.attributes.measurement_values,
        (mv) => mv.measurement_value_definition_id == mvd.id,
      ),
    );
    measurementItem.attributes.measurement_value_definitions = mvds as any;

    if (measurementItem?.attributes?.measurement_values) {
      data.measurementValues.push(
        ...measurementItem.attributes.measurement_values,
      );
    }

    data.measurements.push({
      ...measurementItem.attributes,
      id: parseInt(measurementItem.id),
    });
  });

  return data;
}
