import * as React from "react";
import { MeasurementPlan } from "../../../models/measurement_plan";

import {
  Button,
  Grid,
  MenuItem,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { isNil, uniqBy } from "lodash";
import { MeasurementPlanJSONAPIAttributesObject } from "../../../json_api/measurement_plan";
import { MeasurementTypeRefCompMode } from "../../../models/measurement_type";
import { useUpdateMeasurementType } from "../../../queries/measurement_type_data";
import { useLoadMeasurementPlans } from "../../../queries/measurements_plan_data";
import { success } from "../../../utils/toasts";
import { IDType } from "../../../utils/urls/url_utils";
import { DEFAULT_REFERENCE_DEVIATION_SETTINGS } from "../../measurements/utils/measurement_reference_eval";
interface MeasurementPlanReferenceSettingsProps {
  measurementPlan: MeasurementPlanJSONAPIAttributesObject;

  onMeasurementPlanChange?: (mp: MeasurementPlan) => void;
  onCancel?: () => void;
}

export const MeasurementPlanReferenceSettings: React.FunctionComponent<
  MeasurementPlanReferenceSettingsProps
> = ({ measurementPlan: measurementPlanFromProps, onCancel }) => {
  const { data: loadedPlanKeys, isLoading: planKeysLoading } =
    useLoadMeasurementPlans({
      variables: {
        filter: {
          asset_id: measurementPlanFromProps?.asset_id as IDType,
          with_key: true,
        },
        fields: ["key", "measurement_type_title"],
        page: { size: 100, number: 1 },
      },
      enabled: !isNil(measurementPlanFromProps?.asset_id),
    });

  const [measurementPlanKeys, setMeasurementPlanKeys] =
    React.useState<{ label: string; value: string }[]>(null);

  const [measurementPlan, setMeasurementPlan] =
    React.useState<MeasurementPlanJSONAPIAttributesObject>(
      measurementPlanFromProps,
    );

  React.useEffect(() => {
    setMeasurementPlan(measurementPlanFromProps);
  }, [measurementPlanFromProps]);
  React.useEffect(() => {
    if (loadedPlanKeys?.items) {
      const vals = uniqBy(
        loadedPlanKeys.items.map((plan) => ({
          label: plan.measurement_type_title,
          value: plan.key,
        })),
        "value",
      );
      setMeasurementPlanKeys(vals);
    }
  }, [loadedPlanKeys]);

  const {
    mutate: updateMeasurementType,
    isError: errorUpdateMeasurement,
    isPending: updatePending,
    error: updateMeasurementTypeError,
    isSuccess: updateSuccess,
  } = useUpdateMeasurementType();

  React.useEffect(() => {
    if (updateSuccess) {
      success(I18n.t("frontend.success"));
    }
  }, [updateSuccess]);
  return (
    <Grid container spacing={2} rowSpacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">
          {I18n.t(
            "frontend.measurement_plans.measurement_plan_settings.general_settings",
          )}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {planKeysLoading ? (
          <Skeleton />
        ) : (
          <TextField
            select
            fullWidth
            type="number"
            value={
              !measurementPlanKeys
                ? "NONE"
                : measurementPlan.measurement_type.ref_measurement_type_key ||
                  "NONE"
            }
            label={I18n.t(
              "activerecord.attributes.measurement_type.ref_measurement_type_key",
            )}
            onChange={(e) => {
              const key = e.target.value === "NONE" ? null : e.target.value;
              setMeasurementPlan({
                ...measurementPlan,
                measurement_type: {
                  ...measurementPlan.measurement_type,
                  ref_measurement_type_key: key,
                },
              });
            }}
            helperText={I18n.t(
              "frontend.measurement_plans.measurement_plan_settings.ref_measurement_type_key_help",
            )}
          >
            <MenuItem value="NONE" key="NONE">
              {I18n.t("frontend.none")}
            </MenuItem>
            {measurementPlanKeys?.map((key) => (
              <MenuItem value={key.value} key={key.value}>
                {key.label}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          select
          label={I18n.t(
            "activerecord.attributes.measurement_type.ref_comp_mode",
          )}
          value={
            measurementPlan.measurement_type.ref_measurement_comp_mode || "NONE"
          }
          helperText={I18n.t(
            "frontend.measurement_plans.measurement_plan_settings.ref_comp_mode_help",
          )}
          onChange={(e) => {
            const mode = e.target.value === "NONE" ? null : e.target.value;
            setMeasurementPlan({
              ...measurementPlan,
              measurement_type: {
                ...measurementPlan.measurement_type,
                ref_measurement_comp_mode: mode as MeasurementTypeRefCompMode,
              },
            });
          }}
        >
          <MenuItem value="NONE" key="NONE">
            {I18n.t("frontend.none")}
          </MenuItem>
          <MenuItem value="ref_comp_percentage" key="ref_comp_percentage">
            {I18n.t(
              "activerecord.attributes.measurement_type.ref_comp_modes.ref_comp_percentage",
            )}
          </MenuItem>
          <MenuItem value="ref_comp_absolute" key="ref_comp_absolute">
            {I18n.t(
              "activerecord.attributes.measurement_type.ref_comp_modes.ref_comp_absolute",
            )}
          </MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          type="number"
          onChange={(e) => {
            setMeasurementPlan({
              ...measurementPlan,
              measurement_type: {
                ...measurementPlan.measurement_type,
                ref_measurement_comp_low: Number(e.target.value),
              },
            });
          }}
          label={I18n.t(
            "activerecord.attributes.measurement_type.ref_comp_low",
          )}
          helperText={I18n.t(
            "frontend.measurement_plans.measurement_plan_settings.ref_comp_low_help",
          )}
          value={
            measurementPlan.measurement_type.ref_measurement_comp_low ||
            DEFAULT_REFERENCE_DEVIATION_SETTINGS.low
          }
          InputProps={{
            endAdornment:
              measurementPlan.measurement_type.ref_measurement_comp_mode ===
              "ref_comp_percentage"
                ? "%"
                : null,
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          type="number"
          label={I18n.t(
            "activerecord.attributes.measurement_type.ref_comp_high",
          )}
          value={
            measurementPlan.measurement_type.ref_measurement_comp_high ||
            DEFAULT_REFERENCE_DEVIATION_SETTINGS.high
          }
          helperText={I18n.t(
            "frontend.measurement_plans.measurement_plan_settings.ref_comp_high_help",
          )}
          InputProps={{
            endAdornment:
              measurementPlan.measurement_type.ref_measurement_comp_mode ===
              "ref_comp_percentage"
                ? "%"
                : null,
          }}
          onChange={(e) => {
            setMeasurementPlan({
              ...measurementPlan,
              measurement_type: {
                ...measurementPlan.measurement_type,
                ref_measurement_comp_high: Number(e.target.value),
              },
            });
          }}
        />
      </Grid>

      <Grid item xs={12} textAlign={"center"} mt={2}>
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={() => {
            updateMeasurementType({
              id: measurementPlan.measurement_type_id,
              // we are building a new object here to avoid mutation of unwanted fields
              mt: {
                id: measurementPlan.measurement_type_id,
                ref_measurement_comp_mode:
                  (measurementPlan.measurement_type
                    .ref_measurement_comp_mode as any) == "NONE"
                    ? null
                    : measurementPlan.measurement_type
                        .ref_measurement_comp_mode,
                ref_measurement_comp_high:
                  measurementPlan.measurement_type.ref_measurement_comp_high,
                ref_measurement_comp_low:
                  measurementPlan.measurement_type.ref_measurement_comp_low,
                ref_measurement_type_key:
                  measurementPlan.measurement_type.ref_measurement_type_key ==
                  "NONE"
                    ? null
                    : measurementPlan.measurement_type.ref_measurement_type_key,
              },
            });
          }}
        >
          {I18n.t("frontend.save")}
        </Button>
      </Grid>
    </Grid>
  );
};
