import { createQuery } from "react-query-kit";
import {
  ASSET_EVENT_JSON_API_SUBMIT_ATTRIBUTES,
  ASSET_EVENT_JSONAPI_RESOURCE_TYPE,
  AssetEventJsonApiFilter,
  AssetEventJsonApiInclude,
  AssetEventJSONObject,
  DEFAULT_ASSET_EVENT_INCLUDES,
} from "../json_api/asset_event";
import {
  jsonApiFilterParamsArgumentsFromFilterObject,
  jsonApiSingleResourceToFlatObject,
  loadItemResultForJsonApiCollectionResourceDoc,
  LoadItemsResult,
} from "../json_api/jsonapi_tools";

import {
  CollectionResourceDoc,
  RelationshipsObject,
  SingleResourceDoc,
} from "jsonapi-typescript";
import { merge, toString } from "lodash";
import { api_asset_event_path, api_asset_events_path } from "../routes";
import { loadDataFromUrl, sendJsonApiData } from "../utils/jquery_helper";
import { IDType } from "../utils/urls/url_utils";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  buildJsonApiSubmitData,
  JSONApiFormRequestMode,
} from "../utils/jsonapi_form_tools";
import { ExtensiblePageSettings } from "../components/common/page_size";

interface LoadAssetEventsQueryParams {
  filter: AssetEventJsonApiFilter;
  includes?: AssetEventJsonApiInclude[];
  pageOptions: ExtensiblePageSettings;
  sort?: string;
}

type LoadAssetEventsQueryResponse = LoadItemsResult<AssetEventJSONObject>;

function requestOptionsForFilter(
  filter: AssetEventJsonApiFilter,
  pageSettings: ExtensiblePageSettings,
  sort = "-from",
  includes: AssetEventJsonApiInclude[] = DEFAULT_ASSET_EVENT_INCLUDES,
) {
  const params = jsonApiFilterParamsArgumentsFromFilterObject(filter);
  const options: Record<string, string | number> = {};
  options["include"] = includes.join(",");
  options["page[size]"] = pageSettings.size;
  options["page[number]"] = pageSettings.number;
  options["sort"] = sort || "-from";

  return merge(options, params);
}

/**
 * Custom hook to load asset events using a query.
 *
 * @function useLoadAssetEventsQuery
 * @template LoadAssetEventsQueryResponse - The expected response type of the query.
 * @template LoadAssetEventsQueryParams - The parameters type for the query.
 * @returns {QueryResult<LoadAssetEventsQueryResponse>} The result of the query.
 *
 * @example
 * const { data, error, isLoading } = useLoadAssetEventsQuery({
 *   filter: { assetId: '123' },
 *   pageOptions: { page: 1, pageSize: 10 },
 *   sort: 'from',
 *   includes: ['relatedEntity']
 * });
 *
 * @param {Object} options - The options for the query.
 * @param {Object} options.filter - The filter criteria for the query.
 * @param {Object} options.pageOptions - The pagination options for the query.
 * @param {string} [options.sort='-from'] - The sorting criteria for the query.
 * @param {Array<string>} [options.includes=DEFAULT_ASSET_EVENT_INCLUDES] - The related entities to include in the query.
 * @param {AbortSignal} signal - The signal to abort the fetch request.
 *
 * @async
 * @function fetcher
 * @param {Object} fetchOptions - The options for the fetch request.
 * @param {Object} fetchOptions.filter - The filter criteria for the fetch request.
 * @param {Object} fetchOptions.pageOptions - The pagination options for the fetch request.
 * @param {string} [fetchOptions.sort='-from'] - The sorting criteria for the fetch request.
 * @param {Array<string>} [fetchOptions.includes=DEFAULT_ASSET_EVENT_INCLUDES] - The related entities to include in the fetch request.
 * @param {AbortSignal} signal - The signal to abort the fetch request.
 * @returns {Promise<LoadAssetEventsQueryResponse>} The response of the fetch request.
 */
export const useLoadAssetEventsQuery = createQuery<
  LoadAssetEventsQueryResponse,
  LoadAssetEventsQueryParams
>({
  queryKey: [ASSET_EVENT_JSONAPI_RESOURCE_TYPE],
  fetcher: async ({ filter, pageOptions, sort, includes }, { signal }) => {
    const requestOptions = requestOptionsForFilter(
      filter,
      pageOptions,
      sort || "-from",
      includes || DEFAULT_ASSET_EVENT_INCLUDES,
    );

    const responseDoc = await loadDataFromUrl<
      CollectionResourceDoc<string, AssetEventJSONObject>
    >(
      api_asset_events_path({
        ...requestOptions,
        _options: true,
        format: "json",
      }),
      "json",
      signal,
    );

    return loadItemResultForJsonApiCollectionResourceDoc(responseDoc);
    // Load asset events here
  },
});

/**
 * Custom hook to load an asset event using a query.
 *
 * @function useLoadAssetEventQuery
 * @template AssetEventJSONObject - The type of the asset event JSON object.
 * @template IDType - The type of the ID used to fetch the asset event.
 * @param {Object} params - The parameters for the query.
 * @param {IDType} params.id - The ID of the asset event to fetch.
 * @param {AssetEventJsonApiInclude[]} [params.includes] - Optional includes for the asset event JSON API.
 * @returns {QueryResult<AssetEventJSONObject>} The result of the query, including the asset event data.
 *
 * @example
 * const { data, error, isLoading } = useLoadAssetEventQuery({ id: '123' });
 */
export const useLoadAssetEventQuery = createQuery<
  AssetEventJSONObject,
  { id: IDType; includes?: AssetEventJsonApiInclude[] }
>({
  queryKey: [ASSET_EVENT_JSONAPI_RESOURCE_TYPE],

  fetcher: async ({ id, includes = DEFAULT_ASSET_EVENT_INCLUDES }) => {
    const data = await loadDataFromUrl<
      SingleResourceDoc<string, AssetEventJSONObject>
    >(
      api_asset_event_path(id, {
        _options: true,
        include: (includes || DEFAULT_ASSET_EVENT_INCLUDES).join(","),
      }),
    );
    return jsonApiSingleResourceToFlatObject(data);
  },
});

/**
 * Builds the submit data for an asset event.
 *
 * This function constructs the JSON API submit data for an asset event,
 * including the necessary relationships and attributes.
 *
 * @param {AssetEventJSONObject} event - The asset event object containing the event data.
 * @returns {Object} An object containing the mode of the request and the submit data.
 * @returns {JSONApiFormRequestMode} return.mode - The mode of the request, either "create" or "update".
 * @returns {SingleResourceDoc<string, AssetEventJSONObject>} [return.submitData] - The submit data document for the asset event.
 */
function buildSubmitData(event: AssetEventJSONObject): {
  mode: JSONApiFormRequestMode;
  submitData?: SingleResourceDoc<string, AssetEventJSONObject>;
} {
  const submitData = buildJsonApiSubmitData<AssetEventJSONObject>(
    event,
    ASSET_EVENT_JSONAPI_RESOURCE_TYPE,
    ASSET_EVENT_JSON_API_SUBMIT_ATTRIBUTES,
  );
  const { submitData: submitDataDoc, mode } = submitData;

  const relationships: RelationshipsObject = {};
  relationships["event_type"] = {
    data: {
      type: "asset_event_types",
      id: toString(event.event_type_id || event.event_type?.id),
    },
  };

  if (mode == "create") {
    relationships["asset"] = {
      data: {
        type: "assets",
        id: toString(event.asset_id),
      },
    };
  }

  submitDataDoc.data.relationships = relationships;

  return { submitData: submitDataDoc, mode };
}

export const useSaveAssetEvent = (onError?: (errorData: unknown) => void) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (event: AssetEventJSONObject) => {
      const { submitData, mode } = buildSubmitData(event);

      if (mode !== "create" && mode !== "update") {
        throw new Error("Invalid mode - expected create or update");
      }

      const url =
        mode === "create"
          ? api_asset_events_path()
          : api_asset_event_path(event.id);

      const method = mode === "create" ? "POST" : "PATCH";

      const resp = await sendJsonApiData<
        SingleResourceDoc<string, AssetEventJSONObject>,
        SingleResourceDoc<string, AssetEventJSONObject>
      >(url, submitData, method);

      return jsonApiSingleResourceToFlatObject(
        resp as SingleResourceDoc<string, AssetEventJSONObject>,
      );
    },
    onError,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [ASSET_EVENT_JSONAPI_RESOURCE_TYPE],
      });
      return res;
    },
  });
};

export const useDeleteAssetEvent = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: IDType) => {
      return sendJsonApiData(api_asset_event_path(id), null, "DELETE");
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [ASSET_EVENT_JSONAPI_RESOURCE_TYPE],
      });
      return data;
    },
  });
};
