import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PrintIcon from "@mui/icons-material/Print";
import { Fab, Grid, Typography } from "@mui/material";
import { isEmpty, isNil, keyBy, NumericDictionary, sortBy } from "lodash";
import moment from "moment";
import * as React from "react";
import { MeasurementJSONAPIAttributesObject } from "../../../json_api/measurement";
import { ActiveStorageStoredFile } from "../../../models/active_storage_stored_file";
import { MeasurementCategory } from "../../../models/measurement_category";

import { useConfirm } from "material-ui-confirm";
import { MeasurementTypeDiagramType } from "../../../models/measurement_type";
import {
  useDeleteMeasurement,
  useLoadMeasurement,
} from "../../../queries/measurements_data";
import { asset_measurement_plan_path } from "../../../routes";
import { logger } from "../../../utils/logger";
import { redirectTo } from "../../../utils/redirection";
import { error as errorToast, success } from "../../../utils/toasts";
import { editMeasurementPath } from "../../../utils/urls";
import { IDType } from "../../../utils/urls/url_utils";
import { AssetInfo } from "../../common/asset_info";
import { AttachmentList } from "../../common/attachment_list";
import { AttributeRow } from "../../common/attribute_row";
import { FilePreviewModal } from "../../common/file_preview_modal";
import { FixedBottomArea } from "../../common/fixed_bottom_area";
import { FloatingButtons } from "../../common/floating_buttons";
import { IBox, IBoxContent, IBoxTitle } from "../../common/ibox";
import { LoadingIcon } from "../../common/icon";
import { JSONSchemaValueRow } from "../../common/json_schema_value_row";
import {
  buildMeasurementReferenceValues,
  ReferenceMeasurementValue,
} from "../utils/measurement_reference_eval";
import { MeasurementGraph } from "./measurement_graph";
import { MeasurementGraphTypeSelect } from "./measurement_graph_type_select";
import { MeasurementValuesSection } from "./measurement_values_section";

interface MeasurementItemContainerProperties {
  measurementId: IDType;
  measurement?: MeasurementJSONAPIAttributesObject;
  measurementCategoryById?: NumericDictionary<MeasurementCategory>;
}

export const MeasurementItemContainer: React.FC<
  MeasurementItemContainerProperties
> = (props) => {
  const [fileToPreview, setFileToPreview] =
    React.useState<ActiveStorageStoredFile | null>(null);
  const confirm = useConfirm();
  const {
    mutate: deleteMeasurement,
    isSuccess: delete_success,
    error: deleteError,
  } = useDeleteMeasurement();

  React.useEffect(() => {
    if (delete_success) {
      void success(I18n.t("frontend.measurement_list_item.delete_success"));
      redirectTo(
        asset_measurement_plan_path(
          props.measurement?.asset_id,
          props.measurement?.measurement_plan_id,
        ),
      );
    }
  }, [delete_success]);

  React.useEffect(() => {
    if (deleteError) {
      errorToast(deleteError.message);
    }
  }, [deleteError]);

  const {
    data: measurement,
    error,
    isLoading: loading,
  } = useLoadMeasurement({
    variables: {
      id: props.measurementId,
      include: [
        "measurement_plan",
        "measurement_type",
        "measurement_value_definitions",
        "measurement_categories",
        "asset",
      ],
    },
    enabled: !isNil(props.measurementId) && isNil(props.measurement),
    initialData: props.measurement,
  });

  const measurementPlan = measurement?.measurement_plan;
  const asset = measurement?.asset;
  const measurementType = measurement?.measurement_type;

  // order the value definitions by position
  const measurementValueDefinitions = React.useMemo(
    () => sortBy(measurement?.measurement_value_definitions, "position"),
    [measurement?.measurement_value_definitions],
  );

  const measurementCategoriesById = React.useMemo(
    () => keyBy(measurement?.measurement_categories, "id"),
    [measurement?.measurement_categories],
  );

  const showDiagram =
    measurement?.measurement_values &&
    measurement?.measurement_values?.length > 0;

  const [diagramType, setDiagramType] =
    React.useState<MeasurementTypeDiagramType>(() => {
      let defaultType = measurement?.measurement_type?.diagram_type;
      if (
        !defaultType &&
        measurement?.measurement_type?.type_short ===
          "distribution_measurement_type"
      ) {
        defaultType = "pie";
      } else {
        defaultType = "line";
      }
      return defaultType;
    });

  React.useEffect(() => {
    let defaultType = measurement?.measurement_type?.diagram_type;
    if (
      !defaultType &&
      measurement?.measurement_type?.type_short ===
        "distribution_measurement_type"
    ) {
      defaultType = "pie";
    } else {
      defaultType = "line";
    }

    setDiagramType(defaultType);
  }, [measurement?.measurement_type?.diagram_type]);

  const {
    data: referenceMeasurement,
    isLoading: referenceMeasurementLoading,
    error: referenceMeasurementLoadError,
  } = useLoadMeasurement({
    variables: {
      id: measurement?.reference_measurement_id,
      include: [
        "measurement_plan",
        "measurement_type",
        "measurement_value_definitions",
        "measurement_categories",
        "asset",
      ],
    },
    enabled: Boolean(measurement?.reference_measurement_id),
  });
  const [referenceMeasurementValues, setReferenceMeasurementValues] =
    React.useState<ReferenceMeasurementValue[] | null>(null);

  React.useEffect(() => {
    if (referenceMeasurementLoadError) {
      errorToast(referenceMeasurementLoadError.message);
      return;
    }

    if (
      referenceMeasurement &&
      measurementValueDefinitions &&
      measurementCategoriesById
    ) {
      try {
        const refValues = buildMeasurementReferenceValues(
          referenceMeasurement,
          measurementValueDefinitions,
          referenceMeasurement.measurement_value_definitions,
          measurementType,
          measurementCategoriesById,
        );
        setReferenceMeasurementValues(refValues);
      } catch (error) {
        logger.error("Error building reference values", error);
        setReferenceMeasurementValues(null);
      }
    }
  }, [
    referenceMeasurement,
    referenceMeasurementLoadError,
    measurementCategoriesById,
  ]);

  return (
    <>
      <IBox>
        <IBoxTitle>
          <Typography variant="h5" className="auto-hyphen">
            {loading
              ? I18n.t("frontend.loading")
              : I18n.t("frontend.measurement_list_item.heading", {
                  date: moment(measurement?.time).format("L LT"),
                })}
          </Typography>
        </IBoxTitle>
        <IBoxContent>
          <Grid container rowGap={4}>
            {loading ? (
              <LoadingIcon size="lg" />
            ) : error ? (
              <>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    {I18n.t("frontend.measurement_list_item.error_loading")}
                  </Typography>
                  <Typography variant="caption">{error.message}</Typography>
                </Grid>
              </>
            ) : (
              <>
                <Grid item container xs={12} className="mt-3">
                  <Grid item xs={12} sm={6}>
                    <AttributeRow
                      attributeName={I18n.t(
                        "activerecord.attributes.measurement.created_by",
                      )}
                      value={measurement?.created_by_name}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {isEmpty(measurement?.note) ? null : (
                      <AttributeRow
                        attributeName={I18n.t(
                          "activerecord.attributes.measurement.note",
                        )}
                        value={measurement?.note}
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  className="border-top border-lg-none"
                >
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      {I18n.t(
                        "frontend.measurement_list_item.meta_info_heading",
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AssetInfo asset={asset} hideHeading={true} />
                  </Grid>
                  {isEmpty(measurement.meta_info) ? null : (
                    <>
                      <Grid container item xs={12} sm={6} spacing={1}>
                        <JSONSchemaValueRow
                          schema={measurementPlan.meta_info_schema}
                          jsonValues={measurement.meta_info}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid item xs={12} className="mt-3">
                  <Typography variant="h6">
                    {I18n.t("frontend.measurement_list_item.values_heading")}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={showDiagram ? 6 : 12}>
                  <MeasurementValuesSection
                    measurementCategoryById={measurementCategoriesById}
                    orderedMeasurementValueDefinitions={
                      measurementValueDefinitions
                    }
                    measurementValues={measurement.measurement_values}
                    showPercentage={
                      measurementType.type_short ===
                      "distribution_measurement_type"
                    }
                    mvdIntervalUnit={measurementType?.interval_unit}
                  />
                </Grid>
                {showDiagram ? (
                  <Grid item xs={12} lg={6} p={2}>
                    <MeasurementGraphTypeSelect
                      graphType={diagramType}
                      measurementType={measurementType}
                      onChangeGraphType={(dt) => {
                        setDiagramType(dt);
                      }}
                    />
                    <MeasurementGraph
                      measurement={measurement}
                      measurementValueDefinitions={measurementValueDefinitions}
                      referenceMeasurementValues={referenceMeasurementValues}
                      diagramType={diagramType}
                    />
                  </Grid>
                ) : null}

                {isEmpty(measurement.attachments) ? null : (
                  <>
                    <Grid item xs={12} className="mt-3">
                      <Typography variant="h6">
                        {I18n.t("frontend.measurement_list_item.files_heading")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <AttachmentList
                        attachments={measurement.attachments}
                        onShow={(file) => setFileToPreview(file)}
                      />
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
        </IBoxContent>
        {loading ? null : (
          <FilePreviewModal
            url={fileToPreview?.url}
            contentType={fileToPreview?.content_type}
            isOpen={!isNil(fileToPreview)}
            onClose={() => setFileToPreview(null)}
          />
        )}
      </IBox>
      <FixedBottomArea>
        <FloatingButtons
          showScrollToTopBtn={true}
          isProcessing={false}
          submitBtnIcon={<PrintIcon />}
          saveTitle={I18n.t("frontend.print")}
          onSubmit={() => {
            window.print();
          }}
        >
          <Fab
            size="medium"
            aria-label={I18n.t("frontend.edit")}
            title={I18n.t("frontend.edit")}
            color="default"
            disabled={isNil(measurement)}
            href={editMeasurementPath(props.measurementId ?? measurement?.id)}
          >
            <EditIcon />
          </Fab>
          <Fab
            aria-label={I18n.t("frontend.delete")}
            title={I18n.t("frontend.delete")}
            color="warning"
            size="medium"
            disabled={isNil(measurement)}
            onClick={() => {
              void confirm({ title: I18n.t("frontend.delete") }).then(() => {
                void deleteMeasurement({
                  id: (props.measurementId ?? measurement?.id) as any as IDType,
                  assetId: measurement?.asset_id,
                  measurementPlanId: measurement?.measurement_plan_id,
                });
              });
            }}
          >
            <DeleteIcon />
          </Fab>
        </FloatingButtons>
      </FixedBottomArea>
    </>
  );
};
