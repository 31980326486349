import {
  Add,
  AdminPanelSettings,
  Delete,
  Edit,
  Visibility,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Tab,
  Tabs,
  Tooltip,
} from "@mui/material";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { defaultTo, isNil, sortedUniq } from "lodash";
import * as React from "react";
import { AssetTemplateJSONObject } from "../../json_api/asset_template";
import { AssetTypeJSONObject } from "../../json_api/asset_type";
import {
  useDeleteAssetTemplate,
  useLoadAssetTemplates,
} from "../../queries/asset_template_data";
import { asset_template_path, rails_admin_edit_path } from "../../routes";
import { dialog } from "../../utils/dialog";
import { redirectTo } from "../../utils/redirection";
import { success } from "../../utils/toasts";
import { IDType } from "../../utils/urls/url_utils";
import { AppContext } from "../common/app_context/app_context_provider";
import { SialogicContext } from "../common/app_context/app_context_provider.types";
import { SialogicDialog } from "../common/sialogic_dialog";
import { AssetTemplateForm } from "./asset_template_form";

interface AssetTemplateListProps {
  assetType?: AssetTypeJSONObject;
  assetTypeId?: number;
  canEdit: boolean;
  tableHeight?: number;
  pageNumber?: number;
  pageSize?: number;
  maxTableHeight?: number | string;
  rowActions?: (assetTemplate: AssetTemplateJSONObject) => React.ReactElement[];
  useLongTitle?: boolean;
}
const largeWidth = 250;
const gridColDef = (
  props: AssetTemplateListProps,
  setShowItemDialog: (id: IDType, mode: "edit" | "show" | "new") => void,
  appContext: SialogicContext,
  deleteAssetTemplate: (id: number) => Promise<unknown>,
) => {
  return [
    {
      field: "id",
      headerName: I18n.t("activerecord.attributes.asset_template.id"),
    },
    {
      field: "title",
      headerName: I18n.t("activerecord.attributes.asset_template.title"),
    },
    {
      field: "name",
      headerName: I18n.t("activerecord.attributes.asset_template.name"),
    },

    {
      field: "short_name",
      headerName: I18n.t("activerecord.attributes.asset_template.short_name"),
    },
    {
      field: "key",
      headerName: I18n.t("activerecord.attributes.asset_template.key"),
    },
    {
      field: "manufacturer_id",
      headerName: I18n.t(
        "activerecord.attributes.asset_template.manufacturer_id",
      ),
    },
    {
      field: "timezone",
      headerName: I18n.t("activerecord.attributes.asset_template.timezone"),
    },
    {
      field: "min_count",
      headerName: I18n.t("activerecord.attributes.asset_template.min_count"),
    },
    {
      field: "max_count",
      headerName: I18n.t("activerecord.attributes.asset_template.max_count"),
    },
    {
      field: "description",
      headerName: I18n.t("activerecord.attributes.asset_template.description"),
    },
    {
      field: "parent",
      headerName: I18n.t("activerecord.attributes.asset_template.parent"),
    },
    {
      field: "Actions",
      headerName: I18n.t("frontend.actions"),
      type: "actions",
      width: largeWidth,
      getActions: ({ row }) => {
        const actions = props.canEdit
          ? [
              <GridActionsCellItem
                label={I18n.t("frontend.edit")}
                icon={
                  <Tooltip title={I18n.t("frontend.edit")}>
                    <Edit />
                  </Tooltip>
                }
                onAuxClick={() => {
                  redirectTo(asset_template_path(row.id), "_blank");
                }}
                onClick={() => {
                  setShowItemDialog(row.id, "edit");
                }}
                key="edit"
              />,
              <GridActionsCellItem
                label={I18n.t("frontend.show")}
                icon={
                  <Tooltip title={I18n.t("frontend.show")}>
                    <Visibility />
                  </Tooltip>
                }
                onAuxClick={() => {
                  redirectTo(asset_template_path(row.id), "_blank");
                }}
                onClick={() => {
                  setShowItemDialog(row.id, "show");
                }}
                key="show"
              />,
              <GridActionsCellItem
                label={I18n.t("frontend.delete")}
                icon={<Delete />}
                showInMenu={true}
                onClick={() => {
                  dialog
                    .fire({
                      title: I18n.t("frontend.delete_confirm"),
                      text: I18n.t(
                        "frontend.asset_templates.list.confirm_delete_text",
                      ),
                      icon: "warning",
                      confirmButtonText: I18n.t("frontend.delete"),
                      showCloseButton: true,
                      showCancelButton: true,
                      cancelButtonText: I18n.t("frontend.cancel"),
                    })
                    .then((result) => {
                      if (result.isConfirmed) {
                        void deleteAssetTemplate(row.id).then(() => {
                          success(
                            I18n.t("frontend.deleted"),
                            I18n.t("frontend.successfully_deleted"),
                          );
                        });
                      }
                    });
                }}
                key="delete"
              />,
            ]
          : [];
        if (appContext.user?.isAdmin) {
          actions.push(
            <GridActionsCellItem
              label={I18n.t("base.edit_in_backend")}
              icon={<AdminPanelSettings />}
              showInMenu
              onClick={() => {
                redirectTo(
                  rails_admin_edit_path("asset_template", row.id),
                  "_blank",
                );
              }}
              key="open_asset_template_in_backend"
            />,
          );
        }
        return [...actions, ...(props.rowActions?.(row) || [])];
      },
    },
  ] as GridColDef<AssetTemplateJSONObject>[];
};

export const AssetTemplateList: React.FC<AssetTemplateListProps> = ({
  assetType,
  assetTypeId,
  tableHeight = 600,
  maxTableHeight = "75vh",
  useLongTitle = false,
  pageNumber = 1,
  pageSize = 20,
  ...props
}) => {
  const appContext = React.useContext(AppContext);
  const [pageSizes, setPageSizes] = React.useState(() =>
    sortedUniq([10, pageSize, 25, 50, 100].sort((a, b) => a - b)),
  );
  React.useEffect(() => {
    setPageSizes(() =>
      sortedUniq([10, pageSize, 25, 50, 100].sort((a, b) => a - b)),
    );
  }, [pageSize]);

  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const [dialogMode, setDialogMode] = React.useState<"edit" | "show" | "new">();
  const [currentTab, setCurrentTab] = React.useState<string>(null);

  const [selectedAssetTemplateId, setSelectedAssetTemplateId] =
    React.useState<number>(null);

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize,
    page: pageNumber - 1,
  });
  const [totalItems, setTotalItems] = React.useState<number>(null);
  const [totalPages, setTotalPages] = React.useState<number>(null);

  const theAssetTypeID = assetType?.id || assetTypeId;

  const {
    isLoading: assetTemplateModelsLoading,
    data: {
      items: assetTemplates,
      totalItems: fetchedTotalItems,
      totalPages: fetchedTotalPages,
    },
  } = useLoadAssetTemplates({
    enabled: !isNil(theAssetTypeID),
    variables: {
      assetTypeId: theAssetTypeID,
      pageSettings: paginationModel,
      include: ["parent"],
    },
    placeholderData: { items: [], totalItems: 0, totalPages: 0 },
  });

  React.useEffect(() => {
    setTotalItems(fetchedTotalItems);
  }, [fetchedTotalItems]);
  React.useEffect(() => {
    setTotalPages(fetchedTotalPages);
  }, [fetchedTotalPages]);

  const { mutateAsync: deleteAssetTemplate } = useDeleteAssetTemplate();

  const showForm = React.useCallback(
    (id: number, mode: "edit" | "show" | "new") => {
      setSelectedAssetTemplateId(id);
      setDialogMode(mode);
      setShowDialog(true);
    },
    [props.canEdit],
  );

  const hideForm = React.useCallback(() => {
    setSelectedAssetTemplateId(null);
    setShowDialog(false);
  }, [props.canEdit]);

  const gridColDefs = React.useMemo(
    () => gridColDef(props, showForm, appContext, deleteAssetTemplate),
    [props, setSelectedAssetTemplateId, AppContext, deleteAssetTemplate],
  );

  return (
    <Card>
      <CardHeader
        title={
          assetType?.name && useLongTitle
            ? I18n.t("frontend.asset_templates.list.heading_for_asset_type", {
                asset_type_name: assetType.name,
              })
            : I18n.t("frontend.asset_templates.list.heading_for_asset_type")
        }
      />
      <CardContent>
        <Box height={tableHeight} width="100%" maxHeight={maxTableHeight}>
          <DataGrid
            loading={assetTemplateModelsLoading}
            paginationMode="server"
            pagination={isNil(assetTemplates) ? undefined : true}
            paginationModel={paginationModel}
            onPaginationModelChange={(newModel) => {
              setPaginationModel({ ...newModel });
            }}
            pageSizeOptions={pageSizes}
            columns={gridColDefs}
            rows={defaultTo(assetTemplates, [] as AssetTemplateJSONObject[])}
            rowCount={defaultTo(totalItems, 0)}
            editMode="row"
          />
        </Box>
      </CardContent>
      {props.canEdit ? (
        <CardActions>
          <Button
            color="primary"
            startIcon={<Add />}
            onClick={() => {
              showForm(null, "new");
            }}
          >
            {I18n.t("frontend.add")}
          </Button>
        </CardActions>
      ) : null}

      {showDialog ? (
        <SialogicDialog
          open={showDialog}
          maxWidth="lg"
          onClose={() => hideForm()}
        >
          <Tabs
            value={currentTab || "assetTemplateForm"}
            onChange={(e, newValue) => {
              setCurrentTab(newValue as string);
            }}
            aria-label="Asset Template Tabs"
          >
            <Tab
              label={
                dialogMode === "show"
                  ? I18n.t("frontend.asset_templates.form.form_show")
                  : dialogMode === "edit"
                    ? I18n.t("frontend.asset_templates.form.form_edit")
                    : I18n.t("frontend.asset_templates.form.form_new")
              }
              value="assetTemplateForm"
            />
            {/*!isNil(selectedAssetTemplateId) ? (
                <Tab
                  label={I18n.t("frontend.product_model.form.attachments")}
                  value="attachmentList"
                />
              ) : null*/}
          </Tabs>
          {!currentTab || currentTab == "assetTemplateForm" ? (
            <AssetTemplateForm
              buttonMode="card"
              assetType={assetType}
              assetTypeId={assetType?.id as number}
              assetTemplateId={selectedAssetTemplateId}
              readOnly={!props.canEdit || dialogMode == "show"}
              mode={dialogMode}
              onCancel={hideForm}
              onSuccess={hideForm}
            />
          ) : null}
          {!isNil(selectedAssetTemplateId) && currentTab == "attachmentList" ? (
            /*<Box minHeight={500}>
                <FileAttachmentList
                  itemType="ProductModel"
                  itemId={selectedAssetTemplateId}
                  allowDelete={props.canEdit}
                  allowEdit={props.canEdit}
                  allowUpload={props.canEdit}
                  groups={PRODUCT_MODEL_ATTACHMENT_GROUPS}
                />
              </Box>*/
            <Box></Box>
          ) : null}
        </SialogicDialog>
      ) : null}
    </Card>
  );
};
