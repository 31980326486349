import { createQuery } from "react-query-kit";
import {
  loadItemResultForJsonApiCollectionResourceDoc,
  LoadItemsResult,
} from "../json_api/jsonapi_tools";
import {
  CONFIGURED_KPI_JSONAPI_RESOURCE_TYPE,
  ConfiguredKpiJSONAPIAttributes,
  ConfiguredKpiJSONObject,
} from "../json_api/configured_kpi";
import { loadDataFromUrl } from "../utils/jquery_helper";
import { api_configured_kpis_path } from "../routes";
import { CollectionResourceDoc } from "jsonapi-typescript";
import { IDType } from "../utils/urls/url_utils";

interface LoadConfiguredKpisParams {
  assetId: IDType;
  pageSettings?: {
    number: number;
    size: number;
  };
}

export const useLoadConfiguredKpis = createQuery<
  LoadItemsResult<ConfiguredKpiJSONObject>,
  LoadConfiguredKpisParams
>({
  queryKey: [CONFIGURED_KPI_JSONAPI_RESOURCE_TYPE],
  fetcher: async ({ assetId, pageSettings }) => {
    const response = await loadDataFromUrl<
      CollectionResourceDoc<"string", ConfiguredKpiJSONAPIAttributes>
    >(
      api_configured_kpis_path({
        _options: true,
        filter: {
          asset_id: assetId,
        },
        page: {
          number: pageSettings?.number || 1,
          size: pageSettings?.size || 10,
        },
      }),
    );
    return loadItemResultForJsonApiCollectionResourceDoc(response);
  },
});
