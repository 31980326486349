import { CalendarToday } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Grid,
} from "@mui/material";
import { isNil } from "lodash";
import * as React from "react";
import { SubscriptionJSONAPIAttributes } from "../../json_api/subscription";
interface SubscriptionCardProps {
  subscription?: SubscriptionJSONAPIAttributes;
  onRequestPeriodsDisplay: (subsciption: SubscriptionJSONAPIAttributes) => void;
}

export const SubscriptionCard: React.FunctionComponent<
  SubscriptionCardProps
> = (props) => {
  return (
    <Card>
      <CardHeader
        avatar={
          <Chip
            size="small"
            color="primary"
            label={I18n.t(
              `activerecord.attributes.subscription.states.${props.subscription.state}`,
            )}
            variant="outlined"
          />
        }
        title={props.subscription.subscription_plan?.name}
      />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item container xs={12}>
            <Grid item xs={12} lg={4}>
              {I18n.t("activerecord.attributes.subscription.active_from")}:
            </Grid>
            <Grid item xs={12} lg={8}>
              {moment(props.subscription.active_from).format("L LT")}
            </Grid>
          </Grid>

          {isNil(props.subscription.active_to) ? null : (
            <Grid item container xs={12}>
              <Grid item xs={12} lg={4}>
                {I18n.t("activerecord.attributes.subscription.active_to")}
              </Grid>
              <Grid item xs={12} lg={8}>
                {moment(props.subscription.active_to).format("L LT")}
              </Grid>
            </Grid>
          )}
        </Grid>
        <CardActions>
          <Button
            size="small"
            color="primary"
            onClick={() => {
              props.onRequestPeriodsDisplay(props.subscription);
            }}
            startIcon={<CalendarToday />}
          >
            {I18n.t("activerecord.models.subscription_period.other")}
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  );
};
