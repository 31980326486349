import { MenuItem, TextField, TextFieldProps } from "@mui/material";
import { find } from "lodash";
import React, { useEffect } from "react";
import { ManufacturerModel } from "../../models/manufacturer";
import { IDType } from "../../utils/urls/url_utils";
import { useLoadManufacturersQuery } from "../../queries/manufacturer_data";

export interface ManufacturerSelectProps
  extends Pick<
    TextFieldProps,
    "disabled" | "label" | "required" | "helperText" | "error" | "fullWidth"
  > {
  pageNumber?: number;
  pageSize?: number;
  selectedManufacturerId?: IDType | null;
  readOnly?: boolean;

  onChange?: (manufacturerId: IDType) => void;
}

export const ManufacturerSelect: React.FC<ManufacturerSelectProps> = ({
  pageNumber = 1,
  pageSize = 1000,
  readOnly = false,
  selectedManufacturerId: selectedManufacturerIdFromProps = null,
  error,
  ...props
}) => {
  const [selectedManufacturer, setSelectedManufacturer] =
    React.useState<ManufacturerModel>(null);

  const [selectedManufacturerId, setSelectedManufacturerId] =
    React.useState<IDType>(selectedManufacturerIdFromProps);

  React.useEffect(() => {
    setSelectedManufacturerId(selectedManufacturerIdFromProps);
  }, [selectedManufacturerIdFromProps]);

  const {
    isLoading: manufacturersLoading,
    data: { items: manufacturers, totalItems, totalPages },
  } = useLoadManufacturersQuery({
    variables: {
      pageNumber: pageNumber,
      pageSize: pageSize,
    },
    enabled: true,
  });

  useEffect(() => {
    const manufacturer = find(
      manufacturers,
      (m) => m.id == selectedManufacturerId,
    );

    setSelectedManufacturer(manufacturer);

    if (props.onChange) {
      props.onChange(selectedManufacturerId);
    }
  }, [selectedManufacturerId, manufacturers]);

  return (
    <TextField
      inputProps={{ readOnly }}
      select
      error={error}
      helperText={props.helperText}
      fullWidth={props.fullWidth}
      value={selectedManufacturerId || "None"}
      onChange={(event) =>
        setSelectedManufacturerId(
          event.target.value == "None" ? null : (event.target.value as IDType),
        )
      }
      label={I18n.t("frontend.manufacturer.manufacturer_select.label")}
    >
      <MenuItem value={"None"}>
        <em style={{ color: "gray" }}>
          {I18n.t(
            "frontend.manufacturer.manufacturer_select.select_manufacturer",
          )}
        </em>
      </MenuItem>
      {manufacturers.map((manufacturer) => (
        <MenuItem key={manufacturer.id} value={manufacturer.id}>
          {manufacturer.name}
        </MenuItem>
      ))}
    </TextField>
  );
};
