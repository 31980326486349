import { Box, Grid, Link, TextField, Tooltip } from "@mui/material";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridDensity,
} from "@mui/x-data-grid";
import { useDebounce } from "@uidotdev/usehooks";
import {
  defaultTo,
  isEmpty,
  isNil,
  map,
  sortedUniq,
  toInteger,
  toString,
} from "lodash";
import * as React from "react";
import { ItemAction } from "../../models/item_action";
import { Organization } from "../../models/organization";
import { organizationPath } from "../../utils/urls";
import { OrganizationAvatar } from "../common/organization_icon";
import { PageSettings } from "../common/page_size";
import { useLoadOrganizationsQuery } from "./organization_data";
import { buttonForOrganizationAction } from "./organization_list.utils";

/**
 * Props for the OrganizationsList component.
 *
 * @property {number} [pageNumber] - The current page number.
 * @property {number} [pageSize] - The number of items per page.
 * @property {number} [totalPages] - The total number of pages.
 * @property {number} [totalItems] - The total number of items.
 * @property {Organization[]} [organizations] - The first page of the organizations to display. Loaded from backend if not provided.
 * @property {number} [assetId] - The ID of the asset.
 * @property {number} [tableHeight] - The height of the table.
 * @property {string} [maxTableHeight] - The maximum height of the table.
 * @property {string} [organizationsBaseUrl] - The base URL for organizations.
 * @property {ItemAction[]} [organizationActions] - The actions available for each organization.
 * @property {GridDensity} density - The density of the grid.
 * @property {boolean} [enableSearch] - Whether search functionality is enabled.
 * @property {(org: Organization) => void} [onSelect] - Callback function when an organization is selected.
 */
export interface OrganizationsListProps {
  pageNumber?: number;
  pageSize?: number;
  totalPages?: number;
  totalItems?: number;
  tableHeight?: number;
  maxTableHeight?: string;
  organizationsBaseUrl?: string;
  organizationActions?: ItemAction[];
  density: GridDensity;
  enableSearch?: boolean;

  onSelect?: (org: Organization) => void;
}

function gridColDef(
  props: OrganizationsListProps,
  onActionComplete?: () => void,
): GridColDef[] {
  const defaultWidth = 130;
  const largeWidth = 250;
  const columns: GridColDef[] = [
    { field: "id", headerName: "#" },
    {
      field: "logo",
      headerName: "Logo",
      align: "center",
      headerAlign: "center",
      type: "custom",
      display: "flex",
      renderCell: (params: GridCellParams) => (
        <Tooltip
          title={I18n.t("frontend.organizations.list.show_org", {
            name: params.row.name,
          })}
        >
          <Link
            underline={"none"}
            href={organizationPath(toInteger(params.row.id))}
            justifyContent={"center"}
            display={"flex"}
          >
            <OrganizationAvatar
              organization={params.row}
              size="small"
              variant="rounded"
            />
          </Link>
        </Tooltip>
      ),
    },
    {
      field: "name",
      headerName: I18n.t("activerecord.attributes.organization.name"),
      width: largeWidth,
      renderCell: (params: GridCellParams) => (
        <Tooltip
          title={I18n.t("frontend.organizations.list.show_org", {
            name: params.row.name,
          })}
        >
          <Link href={organizationPath(toInteger(params.row.id))}>
            {params.row.name}
          </Link>
        </Tooltip>
      ),
    },
    {
      field: "description",
      headerName: I18n.t("activerecord.attributes.organization.description"),
      width: largeWidth,
    },
  ];
  if (
    !isNil(props.organizationActions) &&
    !isEmpty(props.organizationActions)
  ) {
    columns.push({
      field: "actions",
      headerName: I18n.t("base.actions"),
      width: largeWidth,
      renderCell: (params: GridCellParams) => {
        return map(props.organizationActions, (action, index) =>
          buttonForOrganizationAction(
            action,
            params.row,
            index,
            onActionComplete,
          ),
        );
      },
    });
  }
  return columns;
}

/**
 * OrganizationsList component renders a list of organizations with pagination and optional search functionality.
 *
 * @param {OrganizationsListProps} props - The properties for the OrganizationsList component.
 * @param {number} [props.tableHeight=300] - The height of the table.
 * @param {number} [props.pageNumber=1] - The initial page number.
 * @param {number} [props.pageSize=20] - The number of items per page.
 * @param {string} [props.maxTableHeight="75vh"] - The maximum height of the table.
 * @param {boolean} [props.enableSearch=true] - Flag to enable or disable the search functionality.
 * @param {Object} props.organizations - The organizations data.
 * @param {string} props.organizationsBaseUrl - The base URL for fetching organizations.
 * @param {Function} props.onSelect - Callback function when an organization is selected.
 * @param {string} [props.density="standard"] - The density of the table.
 * @param {Object} props.organizationActions - Actions related to organizations.
 *
 * @returns {JSX.Element} The rendered OrganizationsList component.
 */
export const OrganizationsList: React.FunctionComponent<
  OrganizationsListProps
> = ({
  tableHeight = 300,
  pageNumber = 1,
  pageSize = 20,
  maxTableHeight = "75vh",
  enableSearch = true,
  ...props
}: OrganizationsListProps) => {
  const [pageSettings, setPageSettings] = React.useState<PageSettings>({
    number: pageNumber || 1,
    size: pageSize || 20,
  });
  const [pageSizes, setPageSizes] = React.useState(() =>
    sortedUniq([10, pageSize, 25, 50, 100].sort((a, b) => a - b)),
  );

  const [searchTerm, setSearchTerm] = React.useState(null);

  const debouncedSearch = useDebounce(searchTerm, 1500);
  const {
    data: organizations,
    isLoading: loading,
    error,
    refetch,
  } = useLoadOrganizationsQuery({
    variables: {
      pageSettings: pageSettings,
      searchTerm: enableSearch ? debouncedSearch : null,
      baseUrl: props.organizationsBaseUrl,
    },
    placeholderData: {
      items: [],
      totalItems: -1,
      totalPages: -1,
    },
  });

  const theGridColDef = React.useMemo(
    () =>
      gridColDef(props, () => {
        refetch();
      }),
    [props.organizationActions, refetch],
  );

  return (
    <Grid container>
      {enableSearch ? (
        <Grid item xs={12} className="mb-1">
          <TextField
            size="small"
            className="float-right"
            value={toString(searchTerm)}
            label={I18n.t("frontend.search")}
            type="search"
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
            onReset={() => {
              setSearchTerm(null);
            }}
          />
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <Box height={tableHeight} width="100%" maxHeight={maxTableHeight}>
          <DataGrid
            paginationMode="server"
            pagination
            paginationModel={{
              pageSize: pageSettings.size,
              page: (pageSettings.number || 0) - 1,
            }}
            rowCount={defaultTo(organizations?.totalItems, -1)}
            pageSizeOptions={pageSizes}
            rows={defaultTo(organizations?.items, [])}
            columns={theGridColDef}
            loading={loading}
            onRowClick={(data) => {
              if (!isNil(props.onSelect)) {
                props.onSelect(data as Organization);
              }
            }}
            onPaginationModelChange={(model, details) =>
              setPageSettings({ number: model.page + 1, size: model.pageSize })
            }
            initialState={{
              density: defaultTo(props.density, "standard"),
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
