import { each, isBoolean, isDate, isEmpty, isNumber, toString } from "lodash";
import moment, { Moment } from "moment";
import {
  IDType,
  ParamsType,
  applyParamsToBaseUrl,
  localizedBasePath,
} from "./url_utils";
import {
  api_context_state_machine_context_state_changes_path,
  api_context_state_machine_path,
} from "../../routes";

export type CSMIncludes =
  | "state_context"
  | "possible_states"
  | "current_state"
  | "stateful_item";
export function apiContextStateMachinesPath(
  statefulItemId: IDType,
  statefullItemType: "asset" | "device",
  contextIdentifier?: string,
): string {
  let path = `${localizedBasePath(true)}/${
    statefullItemType == "asset" ? "assets" : "devices"
  }/context_state_changes`;
  if (!isEmpty(contextIdentifier)) {
    path = path + "/" + contextIdentifier;
  }
  return path;
}

export function apiContextStateMachinePath(
  csmId: IDType,
  includes?: CSMIncludes[],
): string {
  const baseUrl = api_context_state_machine_path(csmId);
  return applyParamsToBaseUrl(baseUrl, undefined, undefined, includes);
}

export interface StateChangesOptions {
  from: string | Moment | Date;
  to: string | Moment | Date;
  include_prev?: boolean;
  include_next?: boolean;
  sort?: "asc" | "desc";
}

export function apiContextStateMachineStateChangesPath(
  csmId: IDType,
  options: StateChangesOptions,
): string {
  const basePath = api_context_state_machine_context_state_changes_path(csmId);
  const params: ParamsType = [];
  each(options, (value, key) => {
    if (
      isBoolean(value) ||
      (isNumber(value) && isNaN(value)) ||
      !isEmpty(value)
    ) {
      if (isDate(value)) {
        params.push([key, value.toISOString()]);
      } else if (moment.isMoment(value)) {
        params.push([key, value.toISOString()]);
      } else {
        params.push([key, toString(value)]);
      }
    }
  });
  return applyParamsToBaseUrl(basePath, null, null, null, params);
}
