import { Settings } from "@mui/icons-material";
import { Box, Grid, IconButton } from "@mui/material";
import Bluebird from "bluebird";
import geojson from "geojson";
import * as L from "leaflet";
import {
  compact,
  defaultTo,
  each,
  isEmpty,
  isNil,
  isNumber,
  isString,
  keys,
  map,
  merge,
  toInteger,
} from "lodash";
import { Moment } from "moment";
import { DateRange } from "moment-range";
import * as React from "react";
import { getMarkerIconForFeature } from "../../map/feature_icons";
import { getFeaturePopupContent } from "../../map/feature_popup";
import {
  SamplingRate,
  SensorSamplingRateUnit,
  SensorValueType,
} from "../../models/sensor";
import { State } from "../../models/state";
import { StateContext } from "../../models/state_context";
import { widgetMinHeight } from "../../utils/widget_height_class";
import { AssetMapWidgetConfigSerialized } from "../../widgets/asset_map_widget.types";
import { ItemSelection } from "../../widgets/item_selection";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import { DiagramSettings } from "../diagram_settings";
import { AssetMapWidgetProps } from "./asset_map_widget.types";
import { WidgetBox } from "./widget_box";

import { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
  AssetFeatureType,
  SensorFeatureProperties,
  ZoomSettings,
} from "../../map/map.types";
import { DashboardContext } from "../dashboard/dashboard_context/dashboard_context";
import {
  loadAssetLocations,
  loadLocationSensorData,
} from "../../queries/asset_map_widget.data";
import {
  handleLocationUpdate,
  handleNonLocationSensorValueUpdate,
  initMap,
} from "./asset_map_widget_utils";
import { SialogicWidgetDefinition } from "./sialogic_widget_component";
import ContextStateMachineChannel from "../../channels/context_state_machine_channel";
import { WidgetController } from "../../controller/widget_controller";

export const AssetMapWidget: React.FunctionComponent<AssetMapWidgetProps> = ({
  allowFullscreen = true,
  zoom = { min: null, max: null },
  tileZoom = { min: null, max: null },
  ...props
}: AssetMapWidgetProps) => {
  const [timeRange, setTimeRange] = useState(props.timeRange);
  const [sensorIds, setSensorIds] = useState(props.sensorIds);
  const [startPosition, setStartPos] = useState(props.startPosition);
  const [fullscreen, setFullscreen] = useState(false);
  const [renderDiagramSettings, setRenderDiagramSettings] = useState(
    props.renderDiagramSettings,
  );

  const mapRef = useRef<{
    map: L.Map;
    node: HTMLDivElement;
    cleanup: () => void;
  }>(null);
  const sensorsLayer = useRef<L.Realtime>(null);
  const assetLayer = useRef<L.FeatureGroup>(null);
  const mapDataLoaded = useRef<Bluebird<any>>(null);

  const assetIdMarkerMap = useRef<Map<number, L.Marker>>(new Map());
  const lineFeatures = useRef<{
    [sensorId: string]: geojson.FeatureCollection;
  }>({});
  const pointFeatures = useRef<{
    [sensorId: string]: geojson.FeatureCollection<
      geojson.Point,
      SensorFeatureProperties
    >;
  }>({});
  const assetSensorIdMarkerMap = useRef<Map<number, L.Marker>>(new Map());
  const assetContextStateMachineIdMarkerMap = useRef<Map<number, L.Marker>>(
    new Map(),
  );

  const samplingRate = useRef<SamplingRate>(props.samplingRate);

  const [csmIds, setCsmIds] = React.useState<number[]>([]);

  const handleMapRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (node === null) {
        if (mapRef.current) {
          mapRef.current.cleanup();
          mapRef.current = null;
        }
      } else if (!mapRef.current || mapRef.current?.node !== node) {
        if (mapRef.current) {
          mapRef.current.cleanup();
        }
        mapRef.current = initMap(node, {
          tileZoom,
          zoom,
          startPosition: startPosition || [52.13, 13.35],
          startZoom: props.startZoom,
          mapUrl: props.mapUrl,
          attribution: props.attribution,
        });
        void loadMapData();
      }
    },
    [mapRef],
  );

  const handleContextStateMachineUpdate = (
    contextStateMachineId: number,
    stateContext: StateContext,
    newState: State,
    time: Moment,
    stateful_item_id: number,
    stateful_item_type: string,
  ) => {
    const marker = assetContextStateMachineIdMarkerMap.current?.get(
      contextStateMachineId,
    );
    if (!isNil(marker)) {
      const feature = marker.feature as AssetFeatureType;
      const stateInfo = feature.properties.states[stateContext.identifier];
      stateInfo.criticality = newState.criticality;
      stateInfo.name = newState.name;
      stateInfo.icon = newState.icon;
      stateInfo.color = newState.color;
      stateInfo.identifier = newState.identifier;
      stateInfo.state_id = toInteger(newState.id);
      marker.setPopupContent(getFeaturePopupContent(feature));
      marker.setIcon(
        getMarkerIconForFeature(
          feature,
          props.markerMappingMode,
          props.markerMappingStateContextIdentifier,
          props.markerMappingSensorKey,
        ),
      );
    }
  };

  React.useEffect(() => {
    const handler = { handleContextStateMachineUpdate };

    const registeredSubscriptions: number[] = map(csmIds, (id) =>
      WidgetController.getInstance().contextStateMachineChannel.addEventListener(
        handler,
        id,
      ),
    );

    return () => {
      each(registeredSubscriptions, (id) => {
        WidgetController.getInstance().contextStateMachineChannel.removeEventListenerId(
          id,
        );
      });
    };
  }, [csmIds]);

  React.useEffect(() => {
    const handler = { handleSensorValueUpdate };

    const registeredSubscriptions: number[] = map(sensorIds, (id) =>
      WidgetController.getInstance().sensorDataChannel.addEventListener(
        handler,
        id as number,
      ),
    );

    return () => {
      each(registeredSubscriptions, (id) => {
        WidgetController.getInstance().sensorDataChannel.removeEventListenerId(
          id,
        );
      });
    };
  }, [sensorIds]);

  const handleSensorValueUpdate = (
    attributeKeyId: number,
    sensorId: number,
    value: SensorValueType,
    time: Moment,
    unit?: string,
  ) => {
    if (!isNil(timeRange) && !timeRange.contains(time)) {
      return;
    }

    if (isNumber(value) || isString(value)) {
      handleNonLocationSensorValueUpdate(
        assetSensorIdMarkerMap.current,
        sensorId,
        value,
        time,
        props,
      );
    } else {
      handleLocationUpdate(
        pointFeatures.current,
        lineFeatures.current,
        sensorsLayer.current,
        sensorId,
        value,
        time,
        attributeKeyId,
      );
    }
  };

  const updateTimeRange = (start: Moment, end: Moment) => {
    if (!isNil(start) || !isNil(end)) {
      setTimeRange(new DateRange(start, end));
      loadMapData();
    } else {
      setTimeRange(props.timeRange);
    }
  };

  const setStartPosition = () => {
    if (mapRef.current && isNil(startPosition)) {
      const assetBounds = !isNil(assetLayer.current)
        ? assetLayer.current.getBounds()
        : null;
      const sensorBounds = !isNil(sensorsLayer.current)
        ? sensorsLayer.current.getBounds()
        : null;

      if (!isNil(assetBounds) && assetBounds.isValid()) {
        mapRef.current.map.fitBounds(assetBounds, {
          maxZoom: defaultTo(props.startZoom, 7),
        });
      } else if (!isNil(sensorBounds) && sensorBounds.isValid()) {
        mapRef.current.map.fitBounds(sensorBounds, {
          maxZoom: props.startZoom,
        });
      } else {
        mapRef.current.map.setView([51.029672, 10.120477], 6);
      }

      setStartPos([
        mapRef.current.map.getCenter().lat,
        mapRef.current.map.getCenter().lng,
        mapRef.current.map.getZoom(),
      ]);
    }
  };

  const updateSamplingRate = (newSamplingRate: SamplingRate): void => {
    samplingRate.current = newSamplingRate;
    loadMapData();
  };

  const loadMapData = (): void => {
    if (!mapRef.current) return;

    if (!isNil(mapDataLoaded.current)) {
      mapDataLoaded.current.cancel();
    }

    mapDataLoaded.current = Bluebird.all([
      loadAssetLocations(
        props,
        assetIdMarkerMap.current,
        assetSensorIdMarkerMap.current,
        assetContextStateMachineIdMarkerMap.current,
        assetLayer.current,
        mapRef.current.map,
      ),
      loadLocationSensorData(props, sensorsLayer.current, mapRef.current.map),
    ]).then(([updateDataForAssetLocations]) => {
      if (!isNil(updateDataForAssetLocations)) {
        setSensorIds(updateDataForAssetLocations.sensorIds);
        setCsmIds(updateDataForAssetLocations.csmIds);
        assetLayer.current = updateDataForAssetLocations.assetLayer;
      }
      setStartPosition();
    });
  };

  const handleItemSelection = (itemSelection: ItemSelection<any>): void => {
    if (itemSelection.itemType === "Asset" && itemSelection.source !== this) {
      const marker = assetIdMarkerMap.current.get(itemSelection.itemId);
      if (!isEmpty(marker)) {
        mapRef.current.map.setView(marker.getLatLng(), 13);
        marker.openPopup();
      }
    }
  };

  return (
    <WidgetBox
      {...props}
      title={props.title ?? I18n.t("frontend.widgets.asset_map_widget.title")}
      allowFullscreen={allowFullscreen}
      onFullscreen={(fullscreen) => setFullscreen(fullscreen)}
      tools={[
        <IconButton
          key="dset"
          onClick={() => setRenderDiagramSettings(!renderDiagramSettings)}
        >
          <Settings />
        </IconButton>,
      ]}
    >
      <Grid container>
        {renderDiagramSettings ? (
          <Grid item xs={12} p={2}>
            <DiagramSettings
              showBeginAtZero={false}
              startDate={defaultTo(timeRange?.start, null)}
              endDate={defaultTo(timeRange?.end, null)}
              visible={renderDiagramSettings}
              showTimeRange={!isNil(timeRange)}
              showSamplingRate={isEmpty(sensorIds)}
              onChangeTimeRange={(startTime, endTime) =>
                updateTimeRange(startTime, endTime)
              }
              onChangeSamplingRate={(samplingRate, mode) =>
                updateSamplingRate(samplingRate)
              }
            />
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <Box height={fullscreen ? "75vh" : props.mapHeight}>
            <Box
              ref={handleMapRef}
              height="100%"
              width="100%"
              minHeight={widgetMinHeight(props.dashboardSettings?.height, 300)}
            />
          </Box>
        </Grid>
      </Grid>
    </WidgetBox>
  );
};

function serializedConfigToProps(
  config: AssetMapWidgetConfigSerialized,
): AssetMapWidgetProps {
  // load asset ids from data attribute
  const assetIds = config.asset_ids;
  const assetTypeId = config.asset_type_id;
  const sensorIds = config.sensor_ids;
  const renderDiagramSettings = defaultTo(
    config.render_diagram_settings,
    false,
  );

  const enableAssetClustering = defaultTo(config.enable_asset_clustering, true);

  // load map options from data attributions
  const mapUrl = config.map_url;

  const loadSensorWithTypes = compact(config.load_sensors_with_types);
  //this.loadSensorWithTypes = ['resource_level', 'operating_time_count'];//

  const loadAssetStates = defaultTo(config.load_asset_states, false);
  //this.loadAssetStates = false;
  const markerMappingSensorKey = config.marker_mapping_sensor_key;

  const markerMappingStateContextIdentifier =
    config.marker_mapping_state_context_identifier;
  //this.markerMappingSensorKey = 'ActFill';
  const markerMappingMode = config.marker_mapping_mode;
  //this.markerMappingMode = 'sensor';

  const attribution = config.map_attribution;
  const startPosition = config.start_position;
  const startZoom = config.start_zoom;

  const zoom: ZoomSettings = { min: null, max: null };
  if (!isNil(config.min_zoom)) {
    zoom.min = config.min_zoom;
  }
  if (!isNil(config.max_zoom)) {
    zoom.max = config.max_zoom;
  }
  const tileZoom: ZoomSettings = { min: null, max: null };

  if (!isNil(config.tile_max_zoom)) {
    tileZoom.max = config.tile_max_zoom;
  }
  if (!isNil(config.tile_min_zoom)) {
    tileZoom.min = config.tile_min_zoom;
  }
  // sampling rate
  const samplingRateUnit = config.sampling_rate_unit;
  const samplingRateVal = config.sampling_rate_value;

  let samplingRate: SamplingRate;
  if (!isNil(samplingRateVal)) {
    samplingRate = {
      value: samplingRateVal,
      unit: samplingRateUnit as SensorSamplingRateUnit,
    };
  } else {
    samplingRate = {
      value: null,
      unit: samplingRateUnit as SensorSamplingRateUnit,
    };
  }

  return merge(widgetBoxPropsFromSerializedConfig(config), {
    assetIds,
    sensorIds,
    assetTypeId,
    zoom,
    tileZoom,
    mapUrl,
    samplingRate,

    attribution,
    startPosition,
    mapHeight: config.height,
    startZoom,
    markerMappingMode,
    markerMappingStateContextIdentifier,
    markerMappingSensorKey,
    loadAssetStates,
    loadSensorWithTypes,
    enableAssetClustering,
    renderDiagramSettings,
  } as AssetMapWidgetProps);
}

export const AssetMapWidgetDefinition: SialogicWidgetDefinition<
  typeof AssetMapWidget,
  typeof serializedConfigToProps
> = {
  Component: AssetMapWidget,
  serializedConfigToProps: serializedConfigToProps,
};
