import AddIcon from "@mui/icons-material/Add";
import { Box, Fab, Typography } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { clone, findIndex, isEmpty, isNil, map, set } from "lodash";
import * as React from "react";
import { LoadItemsResult } from "../../../json_api/jsonapi_tools";
import { MeasurementPlanJSONAPIAttributesObject } from "../../../json_api/measurement_plan";
import {
  useLoadMeasurementPlans,
  useMeasurementPlanChangeSubscriptionStatus,
} from "../../../queries/measurements_plan_data";
import { logger } from "../../../utils/logger";
import { redirectTo } from "../../../utils/redirection";
import { success } from "../../../utils/toasts";
import { newAssetMeasurementPlanPath } from "../../../utils/urls";
import { FixedBottomArea } from "../../common/fixed_bottom_area";
import { FloatingButtons } from "../../common/floating_buttons";
import { IBox, IBoxContent, IBoxFooter } from "../../common/ibox";
import { LoadingIcon } from "../../common/icon";
import { PageSizeSelect } from "../../common/page_size_select";
import { MeasurementPlanItem } from "./measurement_plan_item";

interface MeasurementPlanListProperties {
  assetId?: string | number;
  pageSize?: number;
}

export const MeasurementPlanList: React.FunctionComponent<
  MeasurementPlanListProperties
> = (props) => {
  const [page, setPage] = React.useState({ number: 1, size: 10 });

  const {
    data: loadedMeasurementPlans,
    error,
    isLoading,
  } = useLoadMeasurementPlans({
    variables: {
      filter: { asset_id: props.assetId },
      page,
      include: [
        "asset",
        "last_measurement",
        "measurement_type",
        "measurement_value_definitions",
        "measurement_categories",
      ],
    },
  });

  const {
    mutate: changeSubscriptionPlanStatus,
    data: changeSubscriptionStatusResult,
    error: changeSubscriptionPlanStatusError,
    isPending,
    isSuccess,
    variables,
  } = useMeasurementPlanChangeSubscriptionStatus(false);

  const [measurementPlans, setMeasurementPlans] =
    React.useState<LoadItemsResult<MeasurementPlanJSONAPIAttributesObject> | null>(
      null,
    );

  React.useEffect(() => {
    if (loadedMeasurementPlans) {
      setMeasurementPlans(loadedMeasurementPlans);
    }
  }, [loadedMeasurementPlans]);

  React.useEffect(() => {
    if (changeSubscriptionPlanStatusError) {
      logger.error(changeSubscriptionPlanStatusError);
    }
  }, [changeSubscriptionPlanStatusError]);

  React.useEffect(() => {
    if (isSuccess) {
      const newMeasurementPlan = changeSubscriptionStatusResult;
      if (variables.subscribe) {
        success(
          I18n.t("frontend.measurement_plan_list.subscribe_successful", {
            name: newMeasurementPlan?.measurement_type_title,
          }),
        );
      } else {
        success(
          I18n.t("frontend.measurement_plan_list.unsubscribe_successful", {
            name: newMeasurementPlan?.measurement_type_title,
          }),
        );
      }

      setMeasurementPlans((mps) => {
        if (mps) {
          const index = findIndex(
            mps.items,
            (mp) => mp.id === newMeasurementPlan.id,
          );
          if (index >= 0) {
            const newMeasurementPlans = clone(mps);
            newMeasurementPlans.items = [...newMeasurementPlans.items];
            set(
              newMeasurementPlans.items,
              index,
              // merge with old plan to keep associated data
              {
                ...newMeasurementPlans.items[index],
                ...newMeasurementPlan,
              },
            );
            return newMeasurementPlans;
          }
        }
        return mps;
      });
    }
  }, [isSuccess, variables, changeSubscriptionStatusResult]);

  return (
    <IBox>
      <IBoxContent>
        <Box textAlign={"center"}>
          {isLoading && <LoadingIcon size="6x"></LoadingIcon>}
          {!isLoading && error && (
            <div>{I18n.t("frontend.measurement_plan_list.error_loading")}</div>
          )}
          {measurementPlans && isEmpty(measurementPlans.items) && (
            <Typography>
              {I18n.t("frontend.measurement_plan_list.no_measurement_plans")}
            </Typography>
          )}
        </Box>
        {isEmpty(measurementPlans)
          ? null
          : map(measurementPlans.items, (measurementPlan, index) => {
              return (
                <MeasurementPlanItem
                  measurementPlan={measurementPlan}
                  onSubscribe={(plan) => {
                    changeSubscriptionPlanStatus({
                      subscribe: true,
                      measurementPlan: plan,
                    });
                  }}
                  onUnsubscribe={(plan) => {
                    changeSubscriptionPlanStatus({
                      subscribe: false,
                      measurementPlan: plan,
                    });
                  }}
                  key={index}
                />
              );
            })}
      </IBoxContent>
      {measurementPlans && measurementPlans.totalPages > 1 ? (
        <IBoxFooter>
          <PageSizeSelect
            pageSize={page.size}
            onSelectPageSize={(ps) => {
              setPage({ ...page, size: ps });
            }}
          />
          <br />
          <Pagination
            page={page.number}
            count={measurementPlans.totalPages}
            onChange={(event, pageNumber) => {
              setPage({ ...page, number: pageNumber });
            }}
          />
        </IBoxFooter>
      ) : null}
      <FixedBottomArea key="bot-buttons">
        <FloatingButtons showScrollToTopBtn={true} isProcessing={false}>
          {isNil(props.assetId) ? null : (
            <Fab
              title={I18n.t(
                "frontend.measurement_plan_list.add_measurement_plan",
              )}
              color="primary"
              onClick={() => {
                redirectTo(newAssetMeasurementPlanPath(props.assetId));
              }}
            >
              <AddIcon fontSize="inherit" />
            </Fab>
          )}
        </FloatingButtons>
      </FixedBottomArea>
    </IBox>
  );
};
