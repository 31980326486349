import * as JSON from "json-typescript";
import * as JSONAPI from "jsonapi-typescript";
import { StateContext } from "../models/state_context";
import {
  jsonApiSingleResourceToFlatObject,
  loadItemResultForJsonApiCollectionResourceDoc,
  LoadItemsResult,
} from "./jsonapi_tools";
import { createQuery } from "react-query-kit";
import { loadDataFromUrl } from "../utils/jquery_helper";
import { api_state_context_path, api_state_contexts_path } from "../routes";
import { IDType } from "../utils/urls/url_utils";

export type StateContextJSONObject = StateContext & JSON.Object;

export type StateContextJSONAPIAttributes =
  JSONAPI.AttributesObject<StateContextJSONObject>;

export type StateContextJsonApiIncludes = "states";
interface StateContextVariables {
  includes: StateContextJsonApiIncludes[];
}
export const useLoadStateContextsQuery = createQuery<
  LoadItemsResult<StateContextJSONObject>,
  StateContextVariables
>({
  queryKey: ["state_contexts"],
  fetcher: async ({}, { signal }) => {
    const response = await loadDataFromUrl<
      JSONAPI.CollectionResourceDoc<string, StateContextJSONObject>
    >(api_state_contexts_path(), "json", signal);

    return loadItemResultForJsonApiCollectionResourceDoc(response);
  },
});

export const useLoadStateContextQuery = createQuery<
  StateContextJSONObject,
  { id: IDType; include?: StateContextJsonApiIncludes[] }
>({
  queryKey: ["state_contexts"],
  fetcher: async ({ id, include: include }, { signal }) => {
    const response = await loadDataFromUrl<
      JSONAPI.SingleResourceDoc<string, StateContextJSONObject>
    >(
      api_state_context_path(id, {
        _options: true,
        include: include?.join(","),
      }),
      "json",
      signal,
    );

    return jsonApiSingleResourceToFlatObject(response);
  },
});
