import {
  api_measurement_values_path,
  asset_measurement_plan_measurement_path,
  asset_measurement_plan_measurements_path,
  asset_measurement_plan_path,
  asset_measurement_plans_path,
  edit_asset_measurement_plan_path,
  edit_measurement_path,
  measurement_path,
  measurement_plan_measurements_path,
  measurement_plan_path,
  new_asset_measurement_plan_measurement_path,
  new_asset_measurement_plan_path,
  subscribe_asset_measurement_plan_path,
  unsubscribe_asset_measurement_plan_path,
} from "../../routes";
import { IDType, RequestFormat } from "./url_utils";

export function measurementValuesUrl(): string {
  return api_measurement_values_path();
}

export function assetMeasurementPlansPath(
  assetId: IDType,
  format: RequestFormat = "html",
): string {
  return asset_measurement_plans_path(assetId, {
    _options: true,
    locale: I18n.locale,
    format,
  });
}
export function newAssetMeasurementPlanPath(assetId: IDType): string {
  return new_asset_measurement_plan_path(assetId);
}
export function assetMeasurementPlanPath(
  assetId: IDType,
  measurementPlanId: IDType,
  format: RequestFormat = "html",
): string {
  if (assetId) {
    return measurement_plan_path(measurementPlanId, {
      _options: true,
      locale: I18n.locale,
      format,
    });
  } else {
    return asset_measurement_plan_path(assetId, measurementPlanId, {
      _options: true,
      locale: I18n.locale,
      format,
    });
  }
}

export function editAssetMeasurementPlanPath(
  assetId: IDType,
  measurementPlanId: IDType,
): string {
  return edit_asset_measurement_plan_path(assetId, measurementPlanId);
}

export function assetMeasurementPlansMeasurementsPath(
  assetId: IDType,
  measurementPlanId: IDType,
  format: RequestFormat = "html",
): string {
  return asset_measurement_plan_measurements_path(assetId, measurementPlanId, {
    _options: true,
    format,
  });
}

export function assetMeasurementPlanMeasurementPath(
  assetId: IDType,
  measurementPlanId: IDType,
  measurementId: IDType,
  format: RequestFormat = "html",
): string {
  return asset_measurement_plan_measurement_path(
    assetId,
    measurementPlanId,
    measurementId,
    {
      _options: true,
      format,
    },
  );
}

export function assetMeasurementPlanSubscribePath(
  assetId: IDType,
  measurementPlanId: IDType,
  format: RequestFormat = "html",
): string {
  return subscribe_asset_measurement_plan_path(assetId, measurementPlanId, {
    _options: true,
    format,
  });
}

export function assetMeasurementPlanUnsubscribePath(
  assetId: IDType,
  measurementPlanId: IDType,
  format: RequestFormat = "html",
): string {
  return unsubscribe_asset_measurement_plan_path(assetId, measurementPlanId, {
    _options: true,
    format,
  });
}

export function measurementPlanPath(
  measurementPlanId: IDType,
  format: RequestFormat = "html",
): string {
  return measurement_plan_path(measurementPlanId, {
    _options: true,
    format,
  });
}

export function createMeasurementPath(
  measurementPlanId: IDType,
  format: RequestFormat = "html",
): string {
  return measurement_plan_measurements_path(measurementPlanId, {
    _options: true,
    format,
  });
}

export function measurementPath(
  measurementId: IDType,
  format: RequestFormat = "html",
): string {
  return measurement_path(measurementId, {
    _options: true,
    format,
  });
}

export function editMeasurementPath(measurementId: IDType): string {
  return edit_measurement_path(measurementId);
}
export function newAssetMeasurementPath(
  assetId: IDType,
  measurementPlanId: IDType,
): string {
  return new_asset_measurement_plan_measurement_path(
    assetId,
    measurementPlanId,
  );
}

export function updateMeasurementPath(
  measurementId: IDType,
  format: RequestFormat = "html",
): string {
  return measurement_path(measurementId, {
    _options: true,
    format,
  });
}
