import {
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import JSONSchemaValidator from "@rjsf/validator-ajv8";
import { JSONSchema4 } from "json-schema";
import * as JSONType from "json-typescript";
import {
  cloneDeep,
  defaultTo,
  isEmpty,
  isNil,
  map,
  toInteger,
  toString,
} from "lodash";
import * as React from "react";

import { IBox, IBoxContent, IBoxTitle } from "../common/ibox";
import { Form as MuiForm } from "../common/json_schema_form/form";

import { ReportSpecJSONAPIObject } from "../../json_api/report_spec";
import { redirectTo } from "../../utils/redirection";
import { error as errorToast, success } from "../../utils/toasts";
import { FixedBottomArea } from "../common/fixed_bottom_area";
import { FloatingButtons } from "../common/floating_buttons";
import { RRuleForm } from "../common/rotation_input/rrule_form";
import { useCreateReportSpec, useUpdateReportSpec } from "./report_spec_data";

export interface ReportSpecIdentifiableItem {
  title: string;
  identifier: string;
  id?: number;
}

export interface ReportSpecEditFormProps {
  organizationId: number;
  schema?: JSONType.Object;
  spec?: ReportSpecJSONAPIObject;
  referer?: string;
  sensorTypes?: ReportSpecIdentifiableItem[];
  assetTypes?: ReportSpecIdentifiableItem[];
  sensorKeys?: string[];
  sensorContexts?: string[];
}

export interface ReportSpecEditorState {
  dataLoaded: boolean;
  schema?: JSONType.Object;
  config?: JSONType.Object;
  loading?: boolean;
}

function processSchema(
  sourceSchema: JSONSchema4,
  props: ReportSpecEditFormProps,
): JSONSchema4 {
  if (isNil(sourceSchema)) {
    return null;
  }

  const newSchema = cloneDeep(sourceSchema);
  const definitions = (newSchema?.definitions ??
    newSchema.$defs) as JSONType.Object;
  // populate "sensor_type" list
  if (
    (!isEmpty(props.sensorTypes) &&
      definitions &&
      definitions?.["sensor_type"]) ||
    definitions?.["SensorType"]
  ) {
    const sensorTypeDefinition = (definitions["sensor_type"] ||
      definitions["SensorType"]) as JSONSchema4;
    if (
      sensorTypeDefinition.type === "string" &&
      isEmpty(sensorTypeDefinition.enum)
    ) {
      sensorTypeDefinition.oneOf = map(props.sensorTypes, (st) => ({
        const: st.identifier,
        title: st.title,
      }));
    }
  }

  // populate "sensor_type" list
  if (
    (!isEmpty(props.sensorContexts) && definitions?.["sensor_context"]) ||
    definitions?.["SensorContext"]
  ) {
    const contextDefinition = (definitions?.["sensor_context"] ||
      definitions?.["SensorContext"]) as JSONSchema4;

    if (
      contextDefinition.type === "string" &&
      isEmpty(contextDefinition.enum)
    ) {
      contextDefinition.enum = props.sensorContexts;
    }
  }

  // populate "sensor_type" list
  if (
    (!isEmpty(props.sensorKeys) && definitions?.["sensor_attribute_key"]) ||
    definitions?.["SensorKey"]
  ) {
    const contextDefinition = (definitions?.["sensor_attribute_key"] ||
      definitions?.["SensorKey"]) as JSONSchema4;

    if (
      contextDefinition.type === "string" &&
      isEmpty(contextDefinition.enum)
    ) {
      contextDefinition.enum = props.sensorKeys;
    }
  }

  return newSchema;
}
export const ReportSpecEditForm: React.FunctionComponent<
  ReportSpecEditFormProps
> = (props) => {
  const [spec, setSpec] = React.useState(defaultTo(props.spec, {}));
  const [schema, setSchema] = React.useState<JSONType.Object>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (props.schema) {
      setSchema(processSchema(props.schema, props));
    }
  }, [props.schema]);

  const [data, setData] = React.useState(props.spec?.spec || {});

  const { mutateAsync: updateReportSpec, error: updateError } =
    useUpdateReportSpec();
  const { mutateAsync: createReportSpec, error: createError } =
    useCreateReportSpec();
  const submit = React.useCallback(async (spec: ReportSpecJSONAPIObject) => {
    const s = { ...spec, spec: data };
    if (spec.id) {
      await updateReportSpec({ reportSpec: s });
    } else {
      await createReportSpec({ reportSpec: s });
    }
  }, []);

  if (loading) {
    return (
      <IBox>
        <IBoxTitle>Loading ...</IBoxTitle>
        <IBoxContent>
          <CircularProgress className="d-print-none" size={50} />
        </IBoxContent>
      </IBox>
    );
  }

  const [error, setError] = React.useState<Error | null>(null);

  React.useEffect(() => {
    if (error) {
      void errorToast(
        I18n.t("frontend.report_specs.report_spec_editor.error_title"),
        I18n.t("frontend.report_specs.report_spec_editor.error_message", {
          error: error.message,
        }),
      );
    }
  }, [error]);
  React.useEffect(() => {
    setError(updateError ?? createError);
  }, [updateError, createError]);

  return (
    <Grid container spacing={2}>
      {error && (
        <Grid item xs={12}>
          <Typography>{error.message}</Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          label={I18n.t("activerecord.attributes.report_spec.name")}
          value={spec.name}
          fullWidth
          onChange={(e) => setSpec({ ...spec, name: e.target.value })}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          select
          fullWidth
          value={toString(spec.asset_type_id)}
          label={I18n.t("activerecord.attributes.report_spec.asset_type")}
          onChange={(e) =>
            setSpec({ ...spec, asset_type_id: toInteger(e.target.value) })
          }
        >
          {props.assetTypes?.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.title}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">
          {I18n.t("activerecord.attributes.report_spec.default_rrule")}
        </Typography>
        <RRuleForm
          rrule={spec.default_rrule}
          onChange={(rrule) => setSpec({ ...spec, default_rrule: rrule })}
        />
        <Divider />
      </Grid>
      {isEmpty(schema) ? null : (
        <Grid item xs={12}>
          <IBox>
            <IBoxTitle>
              {I18n.t(
                "frontend.report_specs.report_spec_editor.report_spec_heading",
              )}
            </IBoxTitle>
            <IBoxContent>
              <MuiForm
                validator={JSONSchemaValidator}
                experimental_defaultFormStateBehavior={{
                  constAsDefaults: "skipOneOf",
                  emptyObjectFields: "populateRequiredDefaults",
                }}
                schema={schema}
                formData={data}
                disabled={loading}
                onChange={(event) => setData(event.formData)}
                showErrorList={"top"}

                //disabled={!this.props.editable  }
              >
                <div />
              </MuiForm>
            </IBoxContent>
          </IBox>
        </Grid>
      )}

      <FixedBottomArea id="fixed-bottom-area">
        <FloatingButtons
          isProcessing={loading}
          onSubmit={() => {
            void submit(spec)
              .then(() => {
                void success(
                  I18n.t(
                    "frontend.report_specs.report_spec_editor.success_title",
                  ),
                  I18n.t(
                    "frontend.report_specs.report_spec_editor.success_message",
                  ),
                );
                redirectTo(defaultTo(props.referer, "back"));
              })
              .finally(() => {
                setLoading(false);
              });
          }}
          onCancel={() => {
            setLoading(false);
          }}
          disableSave={loading}
          showScrollToTopBtn={true}
          saveTitle={I18n.t("base.save")}
        />
      </FixedBottomArea>
    </Grid>
  );
};
