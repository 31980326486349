import { defaultTo, merge } from "lodash";
import * as React from "react";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import {
  AssetPropertiesWidgetConfigSerialized,
  AssetPropertiesWidgetProps,
} from "./asset_properties_widget.types";
import {
  SerializedConfigToProps,
  SialogicWidgetComponent,
  SialogicWidgetDefinition,
} from "./sialogic_widget_component";
import { WidgetBox } from "./widget_box";
import { AssetPropertiesList } from "../asset_properties/asset_properties_list";
import { DashboardContext } from "../dashboard/dashboard_context/dashboard_context";
import { IDType } from "../../utils/urls/url_utils";
export const AssetPropertiesWidget: SialogicWidgetComponent<
  AssetPropertiesWidgetProps
> = ({
  encloseInWidgetBox = true,
  encloseInIBox = true,
  ...props
}: AssetPropertiesWidgetProps) => {
  const context = React.useContext(DashboardContext);

  const [assetId, setAssetId] = React.useState<IDType | undefined>(
    props.assetId || context.asset.id,
  );

  React.useEffect(() => {
    setAssetId(props.assetId || context.asset.id);
  }, [props.assetId, context.asset.id]);

  const content = (
    <AssetPropertiesList
      readOnly={true}
      assetId={assetId}
      keysAllowList={props.keysAllowList}
      keysDenyList={props.keysDenyList}
      hidePropertiesWithoutGroup={props.hidePropertiesWithoutGroup}
      groupsAllowList={props.groupsAllowList}
      groupsDenyList={props.groupsDenyList}
      hideGroupNames={props.hideGroupNames}
    />
  );

  return (
    <>
      {!encloseInWidgetBox ? (
        content
      ) : (
        <WidgetBox
          widgetId={props.widgetId}
          dashboardSettings={props.dashboardSettings}
          title={defaultTo(
            props.title as string,
            I18n.t("activerecord.models.widgets/asset_properties_widget.one"),
          )}
          titleLinkUrl={props.titleLinkUrl}
          contentLinkUrl={props.contentLinkUrl}
          encloseInIBox={encloseInIBox}
        >
          {content}
        </WidgetBox>
      )}
    </>
  );
};

const serializedConfigToProps: SerializedConfigToProps<
  AssetPropertiesWidgetConfigSerialized,
  AssetPropertiesWidgetProps
> = (
  config: AssetPropertiesWidgetConfigSerialized,
): AssetPropertiesWidgetProps => {
  return merge(widgetBoxPropsFromSerializedConfig(config), {
    hidePropertiesWithoutGroup: config.hide_properties_without_group,
    groupsAllowList: config.groups_allow_list,
    groupsDenyList: config.groups_deny_list,
    hideGroupNames: config.hide_group_names,
    keysAllowList: config.keys_allow_list,
    keysDenyList: config.keys_deny_list,
  } as AssetPropertiesWidgetProps);
};

export const AssetPropertiesWidgetDefinition: SialogicWidgetDefinition<
  typeof AssetPropertiesWidget,
  typeof serializedConfigToProps
> = {
  Component: AssetPropertiesWidget,
  serializedConfigToProps: serializedConfigToProps,
};
