import {
  Avatar,
  Box,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
} from "@mui/material";
import { StateContext } from "../../models/state_context";
import { isEmpty, isNumber, toInteger } from "lodash";
import * as React from "react";
import { useLoadStateContextQuery } from "../../json_api/state_contexts";
import { error } from "../../utils/toasts";
import { State } from "../../models/state";
import { Cancel } from "@mui/icons-material";
import { IDType } from "../../utils/urls/url_utils";
import { Icon } from "../common/icon";
import { StateAvatar } from "./state_avatar";
import { getStateColor } from "../../utils/colors";

interface StateByContextFilterProps {
  stateContexts: StateContext[];
  onStateSelect: (state: State, stateContext: StateContext) => void;
  selectedContextId: IDType;
  selectedStateId: IDType;
}

export const StateByContextFilter: React.FC<StateByContextFilterProps> = ({
  stateContexts,
  onStateSelect,

  selectedContextId,
  selectedStateId,
}) => {
  const [selectedContext, setSelectedContext] =
    React.useState<StateContext>(null);
  const [selectedState, setSelectedState] = React.useState<State>(null);

  const {
    data: loadedContextData,
    isLoading: contextIsLoading,
    error: contextLoadingError,
  } = useLoadStateContextQuery({
    variables: { id: selectedContext?.id, include: ["states"] },
    enabled: !isEmpty(selectedContext),
  });

  React.useEffect(() => {
    if (stateContexts) {
      if (!selectedContextId) {
        setSelectedContext(null);
      }
      if (selectedContext?.id != selectedContextId) {
        setSelectedContext(
          stateContexts.find(
            (sc) => toInteger(sc.id) == toInteger(selectedContextId),
          ),
        );
      }
    }
  }, [selectedContextId]);

  React.useEffect(() => {
    if (loadedContextData) {
      if (!selectedStateId) {
        setSelectedState(null);
        return;
      }

      if (selectedState?.id != selectedStateId) {
        setSelectedState(
          loadedContextData.states.find(
            (sc) => toInteger(sc.id) == toInteger(selectedContextId),
          ),
        );
      }
    }
  }, [selectedStateId, loadedContextData]);

  React.useEffect(() => {
    if (isEmpty(selectedContext)) {
      onStateSelect(null, null);
    } else {
      if (
        selectedState &&
        !loadedContextData?.states?.find((s) => s.id === selectedState.id)
      ) {
        onStateSelect(null, null);
      } else {
        onStateSelect(selectedState, selectedContext);
      }
    }
  }, [selectedContext, selectedState]);

  React.useEffect(() => {
    if (contextLoadingError) {
      error(contextLoadingError.message);
    }
  }, [contextLoadingError]);
  return (
    <Grid container item xs="auto" spacing={1}>
      <Grid item xs="auto">
        <IconButton size="small" onClick={() => onStateSelect(null, null)}>
          <Cancel />
        </IconButton>
      </Grid>
      <Grid item xs="auto">
        <TextField
          size="small"
          select
          sx={{ minWidth: 220 }}
          label={I18n.t("activerecord.models.state_context.one")}
          value={selectedContext?.id || ""}
          onChange={(event) => {
            if (!isNumber(event.target.value) && isEmpty(event.target.value)) {
              setSelectedContext(null);
            } else {
              setSelectedContext(
                stateContexts.find(
                  (sc) => toInteger(sc.id) == toInteger(event.target.value),
                ),
              );
            }
          }}
        >
          <MenuItem value="">{I18n.t("base.none")}</MenuItem>
          {stateContexts?.map((sc) => (
            <MenuItem value={sc.id} key={sc.id}>
              {sc.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs="auto">
        {loadedContextData ? (
          <TextField
            size="small"
            select
            sx={{ minWidth: 200 }}
            label={I18n.t("activerecord.models.state.one")}
            value={selectedState?.id || ""}
            onChange={(event) => {
              if (
                !isNumber(event.target.value) &&
                isEmpty(event.target.value)
              ) {
                setSelectedState(null);
              } else {
                setSelectedState(
                  loadedContextData?.states?.find(
                    (s) => toInteger(s.id) == toInteger(event.target.value),
                  ),
                );
              }
            }}
          >
            <MenuItem value="">
              <ListItemIcon></ListItemIcon>
              <ListItemText>{I18n.t("base.none")}</ListItemText>
            </MenuItem>
            {loadedContextData.states?.map((state) => (
              <MenuItem value={state.id} key={state.id}>
                <ListItemIcon sx={{ padding: "2px" }}>
                  {state.icon || state.color ? (
                    <StateAvatar
                      iconName={state.icon}
                      color={getStateColor(state)}
                      imageUrl={state.image_url}
                      size={35}
                      iconSize="1x"
                      name={state.name}
                    />
                  ) : (
                    <Box component="span" width={35} />
                  )}
                </ListItemIcon>
                <ListItemText>{state.name}</ListItemText>
              </MenuItem>
            ))}
          </TextField>
        ) : null}
      </Grid>
    </Grid>
  );
};
