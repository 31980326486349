import { compact, Dictionary } from "lodash";
import { Measurement, MeasurementValue } from "../../../models/measurement";
import { MeasurementType } from "../../../models/measurement_type";
import { MeasurementValueDefinition } from "../../../models/measurement_value_definition";
import { computeValuePercent } from "../../../utils/calculate_percentage";
import { logger } from "../../../utils/logger";
import { IDType } from "../../../utils/urls/url_utils";
import { MeasurementCategory } from "../../../models/measurement_category";

export interface ReferenceMeasurementValue extends MeasurementValue {
  mvd: MeasurementValueDefinition;
  mc: MeasurementCategory;
  settings: ReferenceDeviationSettings;
  // absolute reference value high bound
  high: number;
  // absolute reference value high bound in percent
  highPercent: number;
  // absolute reference value high bound
  low: number;
  // absolute reference value low bound in percent
  lowPercent: number;
  // difference to previous value
  toPrev: number;
  // difference to previous value validity range high bound
  toPrevHigh: number;
  // difference to previous value validity range low bound
  toPrevLow: number;
}

export interface ReferenceDeviationSettings {
  high: number;
  low: number;
  useAsPercent: boolean;
}

export function buildMeasurementReferenceValues(
  referenceMeasurement: Measurement,
  measurementValueDefinitions: MeasurementValueDefinition[],
  referenceMeasurementValueDefinitions: MeasurementValueDefinition[],
  measurementType: MeasurementType,
  measurementCategoriesById: Dictionary<MeasurementCategory>,
): ReferenceMeasurementValue[] {
  const refMvdIdToMvd: Record<IDType, MeasurementValueDefinition> = {};

  referenceMeasurementValueDefinitions.forEach((refMvd) => {
    const correspondingMvd = measurementValueDefinitions.find(
      (mvd) => mvd.id == refMvd.id || mvd.key == refMvd.key,
    );

    if (correspondingMvd) {
      refMvdIdToMvd[refMvd.id] = correspondingMvd;
    } else {
      logger.warn(
        `No corresponding MVD found for refMvd: ${refMvd.id}-${refMvd.key}`,
      );
    }
  });

  let values: Partial<ReferenceMeasurementValue>[] = compact(
    referenceMeasurement?.measurement_values?.map((value) => {
      const mvd = refMvdIdToMvd[value.measurement_value_definition_id];

      if (!mvd) {
        logger.warn(
          `No corresponding MVD found for value: ${value.measurement_value_definition_id}`,
        );
        return null;
      }
      const mc = measurementCategoriesById[mvd.measurement_category_id];
      return {
        ...value,
        mvd: mvd,
        mc,
      };
    }),
  );

  if (!values) {
    return null;
  }

  // compute percent values for distribution measurement types
  if (measurementType.type_short == "distribution_measurement_type") {
    values = computeValuePercent(values);
  }

  // calculate reference values
  const referenceDeviationSettings =
    calculateDeviationSettings(measurementType);

  // build reference values with limits
  return buildReferenceValuesForMeasurement(
    values,
    measurementType,
    referenceDeviationSettings,
  );
}

export const DEFAULT_REFERENCE_DEVIATION_SETTINGS: Partial<ReferenceDeviationSettings> =
  {
    high: 10,
    low: 10,
  };

function calculateDeviationSettings(
  measurementType: MeasurementType,
): ReferenceDeviationSettings {
  return {
    high:
      measurementType.ref_measurement_comp_high ??
      DEFAULT_REFERENCE_DEVIATION_SETTINGS.high,
    low:
      measurementType.ref_measurement_comp_low ??
      DEFAULT_REFERENCE_DEVIATION_SETTINGS.low,
    useAsPercent:
      measurementType.ref_measurement_comp_mode == "ref_comp_percentage",
  };
}

function buildReferenceValuesForMeasurement(
  rmv: Partial<ReferenceMeasurementValue>[],
  measurementType: MeasurementType,
  referenceDeviationSettings: ReferenceDeviationSettings,
): ReferenceMeasurementValue[] {
  let prevValue: Partial<ReferenceMeasurementValue> = null;
  return rmv.map((value) => {
    const high = referenceDeviationSettings.useAsPercent
      ? value.value + (value.value * referenceDeviationSettings.high) / 100.0
      : value.value + referenceDeviationSettings.high;
    const low = referenceDeviationSettings.useAsPercent
      ? value.value - (value.value * referenceDeviationSettings.low) / 100.0
      : value.value - referenceDeviationSettings.low;

    const lowPercent = referenceDeviationSettings.useAsPercent
      ? value.percent - (value.percent * referenceDeviationSettings.low) / 100.0
      : value.percent -
        ((value.value * 100) / value.percent) * referenceDeviationSettings.low;

    const highPercent = referenceDeviationSettings.useAsPercent
      ? value.percent +
        (value.percent * referenceDeviationSettings.high) / 100.0
      : value.percent +
        // calculate percentage deviation from value
        ((value.value * 100) / value.percent) * referenceDeviationSettings.high;

    const toPrev = prevValue ? value.value - prevValue.value : 0;
    const toPrevHigh = referenceDeviationSettings.useAsPercent
      ? toPrev + (toPrev * referenceDeviationSettings.high) / 100.0
      : referenceDeviationSettings.high;
    const toPrevLow = referenceDeviationSettings.useAsPercent
      ? toPrev - (toPrev * referenceDeviationSettings.low) / 100.0
      : referenceDeviationSettings.low;
    prevValue = value;
    return {
      ...value,
      value: value.value,
      measurement_value_definition_id: value.mvd.id as string,
      mvd: value.mvd,
      mc: value.mc,
      settings: referenceDeviationSettings,
      high,
      highPercent,
      low,
      lowPercent,
      toPrev: prevValue ? value.value - prevValue.value : 0,
      toPrevHigh,
      toPrevLow,
    };
  });
}
