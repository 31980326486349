import DeleteIcon from "@mui/icons-material/Delete";
import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { FormProps } from "@rjsf/core";
import { RJSFSchema } from "@rjsf/utils";
import JSONSchemaValidator from "@rjsf/validator-ajv8";
import { defaultTo, find, has, isEmpty, isNil, isString, noop } from "lodash";
import moment, { Moment } from "moment";
import * as React from "react";
import { MeasurementValue } from "../../../models/measurement";
import { getDataUrl, uploadFile } from "../../../utils/file_uploader";
import { AttachmentList } from "../../common/attachment_list";
import { MaterialUiDatePicker } from "../../common/date_picker";
import { FilePreviewModal } from "../../common/file_preview_modal";
import { FileUploadInput } from "../../common/file_upload_input";
import { UploadedFile } from "../../common/file_upload_input/file_upload_input";
import { FixedBottomArea } from "../../common/fixed_bottom_area";
import { FloatingButton, FloatingButtons } from "../../common/floating_buttons";
import { IBox, IBoxContent, IBoxTitle } from "../../common/ibox";
import { Form as MuiForm } from "../../common/json_schema_form/form";
import { User } from "../../maintenance_form/data/model";
import { Measurement } from "../data/model";
import { MeasurementDistributionValueHeader } from "./measurement_distribution_value_header";
import { MeasurementDistributionValueInput } from "./measurement_distribution_value_input";
import { MeasurementValueHeader } from "./measurement_value_header";
import {
  MeasurementValueInput,
  MeasurementValueInputProps,
} from "./measurement_value_input";
import { MeasurementValuesAggregateRow } from "./measurement_values_aggregate";

interface MeasurementFormProps {
  measurement: Measurement;
  users: User[];
  isProcessing: boolean;
  editable?: boolean;
  deleteable?: boolean;

  onSubmit: () => void;
  onCancel: () => void;
  onUpdateMeasurement: (measurement: Partial<Measurement>) => void;
  onUpdateMeasurementValue: (measurementValue: MeasurementValue) => void;
  onDelete?: () => void;
}

interface MeasurementFormState {
  previewFileName?: string;
  previewUrl?: string;
  previewContentType?: string;
  isPreviewOpen: boolean;
}

export class MeasurementForm extends React.Component<
  MeasurementFormProps,
  MeasurementFormState
> {
  static defaultProps = {
    editable: true,
    isProcessing: false,
  };
  constructor(props: MeasurementFormProps) {
    super(props);

    this.state = {
      previewFileName: undefined,
      previewUrl: undefined,
      isPreviewOpen: false,
      previewContentType: undefined,
    };
  }

  render(): React.ReactNode {
    const props = this.props;
    const measurementType =
      props.measurement?.measurement_plan?.measurement_type;

    const measurementValueDefinitions =
      measurementType?.measurement_value_definitions || [];
    const metaInfoScheme: RJSFSchema =
      measurementType?.meta_info || ({} as RJSFSchema);
    const metaInfoFieldOrder = metaInfoScheme?.fieldOrder as string[];

    return (
      <Grid container>
        {isNil(props.measurement?.errors?.base) ? null : (
          <Grid item xs={12}>
            <div className="error-report">
              <h5>{I18n.t("frontend.measurement_form.base_errors_heading")}</h5>
              <ul>
                <li>{props.measurement?.errors?.base as string}</li>
              </ul>
            </div>
          </Grid>
        )}
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <IBox>
              <IBoxTitle>
                <Typography variant="h5">
                  {I18n.t("activerecord.models.measurement.one")}
                </Typography>
              </IBoxTitle>
              <IBoxContent>
                <Grid container spacing={2}>
                  <Grid item container xs={12} spacing={2}>
                    <Grid item xs={12} lg={6}>
                      {this.props.editable ? (
                        <MaterialUiDatePicker
                          type="datetime"
                          size="small"
                          name="measurement.time"
                          label={I18n.t(
                            "activerecord.attributes.measurement.time",
                          )}
                          value={
                            props.measurement.time
                              ? moment(props.measurement.time)
                              : null
                          }
                          error={props.measurement?.errors?.time as string}
                          onChange={(event) =>
                            this.onChangeMeasurementTime(event)
                          }
                          dateFormat="L LT"
                          required
                          autoApply
                        />
                      ) : (
                        <TextField
                          name="measurement.time"
                          label={I18n.t(
                            "activerecord.attributes.measurement.time",
                          )}
                          size="small"
                          value={
                            props.measurement.time
                              ? moment(props.measurement.time).format("L LT")
                              : null
                          }
                          InputProps={{ readOnly: true }}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Autocomplete<User | null, false, false, true | false>
                        size="small"
                        options={props.users}
                        getOptionLabel={(user) =>
                          isString(user) ? user : user.name
                        }
                        value={defaultTo(props.measurement?.created_by, null)}
                        fullWidth
                        freeSolo
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="measurement.created_by"
                            label={I18n.t(
                              "activerecord.attributes.measurement.created_by",
                            )}
                            required
                            error={has(props.measurement, "errors.created_by")}
                            helperText={
                              props.measurement?.errors?.created_by as string
                            }
                            InputProps={{
                              ...params.InputProps,
                              readOnly: !props.editable,
                            }}
                            InputLabelProps={{
                              ...params.InputLabelProps,
                              shrink: true,
                            }}
                          />
                        )}
                        onChange={(_event, value) => {
                          if (isString(value)) {
                            props.onUpdateMeasurement({
                              created_by: {
                                id: null,
                                type: "GuestUser",
                                name: value,
                              },
                            });
                          } else {
                            props.onUpdateMeasurement({
                              created_by: value,
                            });
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                  {!props.measurement?.measurement_plan?.measurement_type
                    ?.allow_measurement_notes ? null : (
                    <>
                      <Grid item container xs={12}>
                        <TextField
                          fullWidth
                          name="measurement.note"
                          label={I18n.t(
                            "activerecord.attributes.measurement.note",
                          )}
                          value={defaultTo(props.measurement.note, "")}
                          error={has(props.measurement, "error.note")}
                          helperText={props.measurement?.errors?.note as string}
                          multiline
                          onChange={(event) =>
                            props.onUpdateMeasurement({
                              note: event.target.value,
                            })
                          }
                          InputProps={{
                            readOnly: !this.props.editable,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      {isEmpty(metaInfoScheme) ? null : (
                        <Grid item xs={12}>
                          <MuiForm
                            validator={JSONSchemaValidator}
                            schema={metaInfoScheme}
                            experimental_defaultFormStateBehavior={{
                              constAsDefaults: "skipOneOf",
                              emptyObjectFields: "populateRequiredDefaults",
                            }}
                            uiSchema={{
                              "ui:options": { order: metaInfoFieldOrder },
                            }}
                            formData={
                              (props.measurement?.meta_info || {}) as FormProps
                            }
                            disabled={!this.props.editable}
                            onChange={(event) =>
                              props.onUpdateMeasurement({
                                meta_info: event.formData as object,
                              })
                            }
                          >
                            <div></div>
                          </MuiForm>
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>
              </IBoxContent>
            </IBox>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <IBox>
            <IBoxTitle>
              <Typography variant="h5">
                {I18n.t("activemodel.models.measurement_value.other")}
              </Typography>
            </IBoxTitle>
            <IBoxContent>
              <Grid container spacing={2}>
                {isEmpty(measurementValueDefinitions) ? (
                  <Grid item>
                    {I18n.t(
                      "frontend.measurement_form.no_measurement_values_defined",
                    )}
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={12}>
                      {props.measurement?.measurement_plan?.measurement_type
                        .type ===
                      "MeasurementTypes::DistributionMeasurementType" ? (
                        <MeasurementDistributionValueHeader
                          measurementType={
                            props.measurement?.measurement_plan
                              ?.measurement_type
                          }
                        />
                      ) : (
                        <MeasurementValueHeader
                          measurementType={
                            props.measurement?.measurement_plan
                              ?.measurement_type
                          }
                        />
                      )}
                    </Grid>
                    {measurementValueDefinitions.map(
                      (measurementValueDefinition, i) => {
                        const measurementValue = find(
                          props.measurement.measurement_values_attributes,
                          (measurementValue) =>
                            measurementValue.measurement_value_definition_id ==
                            measurementValueDefinition.id,
                        );

                        if (!measurementValue) {
                          return;
                        }

                        const inputValueProps: MeasurementValueInputProps = {
                          measurementValue,
                          measurementValueDefinition,
                          measurementType:
                            props.measurement?.measurement_plan
                              ?.measurement_type,
                          error: props.measurement?.errors
                            ?.measurement_values as string,
                          onUpdateMeasurementValue:
                            props.onUpdateMeasurementValue,

                          editable: this.props.editable,
                        };
                        return (
                          <Grid item xs={12} key={i}>
                            {inputValueProps?.measurementType?.type ===
                            "MeasurementTypes::DistributionMeasurementType" ? (
                              <MeasurementDistributionValueInput
                                {...inputValueProps}
                              />
                            ) : (
                              <MeasurementValueInput {...inputValueProps} />
                            )}
                          </Grid>
                        );
                      },
                    )}
                    <Grid item xs={12}>
                      <MeasurementValuesAggregateRow
                        measurementType={
                          props.measurement?.measurement_plan?.measurement_type
                        }
                        measurementValues={
                          props.measurement?.measurement_values_attributes
                        }
                        aggregateUnit={measurementValueDefinitions?.[0]?.unit}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </IBoxContent>
          </IBox>
        </Grid>
        {props.measurement?.measurement_plan?.measurement_type
          ?.allow_attachments ? (
          <Grid item xs={12}>
            <IBox>
              <IBoxTitle>
                <Typography variant="h5">
                  {I18n.t("activerecord.attributes.measurement.documents")}
                </Typography>
              </IBoxTitle>
              <IBoxContent>
                <Grid container>
                  <Grid item xs={12}>
                    {this.props.editable ? (
                      <FileUploadInput
                        files={props.measurement.documents}
                        onChange={(files) => void this.onChangeFiles(files)}
                        onShow={(file) => this.onShowFile(file)}
                      />
                    ) : (
                      <AttachmentList
                        attachments={props.measurement.documents.map((d) => ({
                          content_type: d.type,
                          byte_size: d.size,
                          filename: d.name,
                          url: d.url,
                        }))}
                        onShow={(file) =>
                          this.onShowFile({
                            name: file.filename,
                            type: file.content_type,
                            size: file.byte_size,
                            url: file.url,
                          })
                        }
                      />
                    )}
                  </Grid>
                </Grid>
              </IBoxContent>
            </IBox>
          </Grid>
        ) : null}

        <FixedBottomArea id="fixed-bottom-area">
          {this.props.editable === false ? (
            <></>
          ) : (
            <FloatingButtons
              isProcessing={props.isProcessing}
              onSubmit={props.onSubmit}
              onCancel={props.onCancel}
              showScrollToTopBtn={true}
              saveTitle={I18n.t("frontend.measurement_form.submit_title")}
            >
              {isNil(this.props.onDelete) ? (
                <></>
              ) : (
                <FloatingButton
                  size="medium"
                  onClick={() =>
                    isNil(this.props.onDelete) ? noop : this.props.onDelete()
                  }
                  title={I18n.t("frontend.delete")}
                  color="secondary"
                >
                  <DeleteIcon />
                </FloatingButton>
              )}
            </FloatingButtons>
          )}
        </FixedBottomArea>
        <FilePreviewModal
          isOpen={this.state.isPreviewOpen}
          fileName={this.state.previewFileName}
          url={defaultTo(this.state.previewUrl, "")}
          contentType={this.state.previewContentType}
          onClose={() => this.setState({ isPreviewOpen: false })}
        />
      </Grid>
    );
  }

  private onChangeMeasurementTime(time: Moment): void {
    this.props.onUpdateMeasurement({ time: time?.toISOString() });
  }

  private async onChangeFiles(files: Array<File | UploadedFile>) {
    const uploadedFiles: UploadedFile[] = [];
    for (const file of files) {
      if (file instanceof File) {
        const [blob, dataUrl] = await Promise.all([
          uploadFile(file),
          getDataUrl(file),
        ]);

        uploadedFiles.push({
          name: file.name,
          type: file.type,
          size: file.size,
          blob: blob,
          url: dataUrl,
        });
      } else {
        uploadedFiles.push(file);
      }
    }

    this.props.onUpdateMeasurement({
      documents: uploadedFiles,
    });
  }

  private onShowFile(file: File | UploadedFile): void {
    if (file instanceof File) {
      return;
    }

    this.setState({
      previewFileName: file.name,
      previewUrl: file.url,
      previewContentType: file.type,
      isPreviewOpen: true,
    });
  }
}
