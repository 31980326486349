import {
  AccountTree,
  Cancel,
  Check,
  Edit,
  NavigateBefore,
} from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Fab,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import * as JSONAPI from "jsonapi-typescript";
import {
  defaultTo,
  each,
  find,
  isEmpty,
  isNil,
  last,
  map,
  toString,
} from "lodash";
import * as React from "react";

import { useQuery } from "@tanstack/react-query";
import { translatedAttributeName } from "../../i18n/translation_helper";
import { AssetTypeJSONObject } from "../../json_api/asset_type";
import {
  jsonApiResourceCollectionToFlatObjects,
  jsonApiSingleResourceToFlatObject,
} from "../../json_api/jsonapi_tools";
import { ManufacturerJSONObject } from "../../json_api/manufacturer";
import { ProductModelJSONObject } from "../../json_api/product_model";
import { ProductModel, ProductModelModelFor } from "../../models/product_model";
import {
  api_manufacturers_path,
  api_product_model_path,
  asset_type_product_model_product_model_asset_template_settings_path,
  asset_type_product_models_path,
  edit_asset_type_product_model_path,
  edit_device_model_path,
} from "../../routes";
import { HttpError, loadDataFromUrl } from "../../utils/jquery_helper";
import { logger } from "../../utils/logger";
import { redirectTo } from "../../utils/redirection";
import { IDType } from "../../utils/urls/url_utils";
import { AppContext } from "../common/app_context/app_context_provider";
import { SialogicContext } from "../common/app_context/app_context_provider.types";
import { FixedBottomArea } from "../common/fixed_bottom_area";
import { FloatingButtons } from "../common/floating_buttons";
import { LoadingWrapper } from "../common/loading_wrapper";
import {
  useCreateProductModel,
  useUpdateProductModel,
} from "../../queries/product_model_data";

type OperationMode = "show" | "edit" | "new";
type RequestMode = "create" | "update";

export interface ProductModelFormProps {
  productModelId?: IDType;
  assetTypeId?: IDType;
  assetType?: AssetTypeJSONObject;
  mode?: OperationMode;
  canEdit?: boolean;
  readOnly?: boolean;
  modelFor?: ProductModelModelFor;

  buttonMode?: "global" | "card";
  onCancel?: () => void;
  onSuccess?: (productModel: ProductModelJSONObject) => void;
}

type ProductModelFormErrors = Partial<Record<keyof ProductModel, string>>;

function validate(
  productModel: ProductModelJSONObject,
  oldErrors: ProductModelFormErrors = {},
): ProductModelFormErrors {
  const nameError = productModel?.name ? null : I18n.t("errors.messages.blank");
  if (nameError === null) {
    delete oldErrors.name;
  } else {
    oldErrors.name = nameError;
  }
  return oldErrors;
}

export const ProductModelForm: React.FunctionComponent<
  ProductModelFormProps
> = (props) => {
  const context = React.useContext(AppContext);
  let operationMode = props.mode;
  if (isNil(props.mode)) {
    if (isNil(props.productModelId)) {
      operationMode = "new";
    } else {
      operationMode = props.readOnly === true ? "show" : "edit";
    }
  }

  const [readonly] = React.useState(operationMode == "show");
  const [assetType] = React.useState<AssetTypeJSONObject>(props.assetType);
  const [productModel, setProductModel] =
    React.useState<ProductModelJSONObject>({
      id: props.productModelId,
      model_for: props.modelFor || "asset",
      asset_type_id: props.assetTypeId,
    });
  const [availableManufacturers, setAvailableManufacturers] =
    React.useState<ManufacturerJSONObject[]>(null);
  const [selectedManufacturer, setSelectedManufacturer] =
    React.useState<ManufacturerJSONObject>(null);

  const [productModelErrors, setProductModelErrors] =
    React.useState<ProductModelFormErrors>({});
  const [isProcessing, setIsProcessing] = React.useState(false);

  const { data: loadedProductModel, isLoading: productModelLoading } = useQuery(
    {
      queryKey: ["productModel", { productModelId: props.productModelId }],
      queryFn: async () => {
        if (isNil(props.productModelId)) return null;

        const doc = await loadDataFromUrl<
          JSONAPI.SingleResourceDoc<string, ProductModelJSONObject>
        >(
          api_product_model_path(props.productModelId, {
            _options: true,
            include: "manufacturer",
          }),
        );
        return jsonApiSingleResourceToFlatObject(doc);
      },
      enabled: !isNil(props.productModelId),
      placeholderData: productModel,
    },
  );

  React.useEffect(() => {
    if (loadedProductModel) {
      setProductModel(loadedProductModel);
      if (
        isNil(availableManufacturers) &&
        !isNil(loadedProductModel.manufacturer)
      ) {
        setAvailableManufacturers([loadedProductModel.manufacturer]);
      }
      setSelectedManufacturer(
        loadedProductModel.manufacturer as ManufacturerJSONObject,
      );
    }
  }, [loadedProductModel]);

  const { data: loadedManufacturers, isLoading: loadingManufacturers } =
    useQuery({
      queryKey: ["manufacturers"],
      queryFn: async () => {
        const doc = await loadDataFromUrl<
          JSONAPI.CollectionResourceDoc<string, ManufacturerJSONObject>
        >(api_manufacturers_path());
        return jsonApiResourceCollectionToFlatObjects<ManufacturerJSONObject>(
          doc,
        );
      },
      enabled: isNil(props.productModelId) || !readonly,
    });

  React.useEffect(() => {
    if (loadedManufacturers) {
      setAvailableManufacturers(loadedManufacturers);
    }
  }, [loadedManufacturers]);

  const {
    mutateAsync: createProductModel,
    isPending: createPending,
    error: createError,
  } = useCreateProductModel();
  const {
    mutateAsync: updateProductModel,
    isPending: updatePending,
    error: updateError,
  } = useUpdateProductModel();

  React.useEffect(() => {
    if (!createError && !updateError) return;
    let error = (createError || updateError) as HttpError;
    const errors: ProductModelFormErrors = {};

    void toasts.error(I18n.t("base.error"), I18n.t("base.error"));

    each(
      ((error as HttpError).request?.responseJSON as JSONAPI.DocWithErrors)
        ?.errors,
      (error) => {
        logger.error(error);
        const attrWithError = error?.source?.pointer as string;
        const aName = last(attrWithError?.split("/")) as keyof ProductModel;
        if (!isNil(aName)) {
          errors[aName] = error.detail;
        }
      },
    );
    setProductModelErrors(errors);
  }, [createError]);

  React.useEffect(() => {
    setIsProcessing(createPending || updatePending);
  }, [createPending, updatePending]);

  const submit = React.useCallback(() => {
    let promise: Promise<
      JSONAPI.SingleResourceDoc<string, ProductModelJSONObject>
    >;
    let mode: RequestMode;
    if (!productModel.id) {
      mode = "create";
      promise = createProductModel({ productModel });
    } else {
      mode = "update";
      promise = updateProductModel({ productModel });
    }
    void promise.then((resp) => {
      handleRequestSuccess(
        mode,
        resp,
        props.assetTypeId,
        setProductModel,
        props.onSuccess,
        context,
      );
    });
  }, [productModel, selectedManufacturer]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={I18n.t("frontend.product_model.form.heading", {
              product_model: isNil(productModel?.id)
                ? I18n.t("frontend.product_model.form.new_product_model")
                : productModel.name,
              asset_type: assetType?.name,
            } as Record<string, string>)}
          />

          <CardContent>
            <LoadingWrapper loading={isProcessing || productModelLoading}>
              {isProcessing ? null : (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        autoFocus
                        required
                        fullWidth
                        inputProps={{ readOnly: props.readOnly }}
                        title={translatedAttributeName("product_model", "name")}
                        label={translatedAttributeName("product_model", "name")}
                        value={toString(productModel.name)}
                        error={
                          isEmpty(productModel.name) ||
                          !isEmpty(productModelErrors?.name)
                        }
                        disabled={isProcessing}
                        helperText={productModelErrors.name}
                        onChange={(event) => {
                          setProductModelErrors(validate(productModel));
                          setProductModel({
                            ...productModel,
                            name: event.target.value,
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        inputProps={{ readOnly: props.readOnly }}
                        title={translatedAttributeName(
                          "product_model",
                          "identifier",
                        )}
                        label={translatedAttributeName(
                          "product_model",
                          "identifier",
                        )}
                        value={toString(productModel.identifier)}
                        error={
                          isEmpty(productModel.identifier) ||
                          !isEmpty(productModelErrors.identifier)
                        }
                        disabled={isProcessing}
                        helperText={productModelErrors.identifier}
                        onChange={(event) => {
                          setProductModelErrors(validate(productModel));
                          setProductModel({
                            ...productModel,
                            identifier: event.target.value,
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        multiline
                        minRows={2}
                        fullWidth
                        inputProps={{ readOnly: props.readOnly }}
                        title={translatedAttributeName(
                          "product_model",
                          "description",
                        )}
                        label={translatedAttributeName(
                          "product_model",
                          "description",
                        )}
                        value={toString(productModel.description)}
                        disabled={isProcessing}
                        onChange={(event) => {
                          setProductModel({
                            ...productModel,
                            description: event.target.value,
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        select
                        inputProps={{ readOnly: props.readOnly }}
                        title={translatedAttributeName(
                          "product_model",
                          "manufacturer_id",
                        )}
                        label={translatedAttributeName(
                          "product_model",
                          "manufacturer_id",
                        )}
                        value={toString(selectedManufacturer?.id)}
                        disabled={loadingManufacturers || isProcessing}
                        onChange={(event) => {
                          const selectedManufacturer = find(
                            availableManufacturers,
                            (m) => m.id == event.target.value,
                          );
                          setSelectedManufacturer(selectedManufacturer);
                          setProductModel({
                            ...productModel,
                            manufacturer_id: selectedManufacturer?.id,
                          });
                        }}
                      >
                        <MenuItem key={"no-manufacturer"} value={""}>
                          {I18n.t("frontend.none")}
                        </MenuItem>
                        {map(availableManufacturers, (m) => (
                          <MenuItem key={m.id} value={m.id}>
                            {m.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                  {props.buttonMode == "card" ? null : (
                    <FixedBottomArea id="fixed-bottom-area">
                      {readonly ? (
                        <FloatingButtons
                          isProcessing={isProcessing}
                          showScrollToTopBtn
                        >
                          <Tooltip title={I18n.t("frontend.back")}>
                            <Fab
                              size="medium"
                              onClick={() => {
                                redirectTo("back");
                              }}
                            >
                              <NavigateBefore />
                            </Fab>
                          </Tooltip>

                          <Tooltip
                            title={I18n.t(
                              "activerecord.models.product_model_asset_template_setting",
                              { count: 2 },
                            )}
                          >
                            <Fab
                              onClick={() => {
                                redirectTo(
                                  asset_type_product_model_product_model_asset_template_settings_path(
                                    props.assetTypeId,
                                    productModel.id,
                                  ),
                                );
                              }}
                              color={"primary"}
                            >
                              <AccountTree />
                            </Fab>
                          </Tooltip>
                          {!props.canEdit ? null : (
                            <Tooltip title={I18n.t("frontend.edit")}>
                              <Fab
                                className="submit"
                                onClick={() => {
                                  redirectTo(
                                    props.modelFor == "device" ||
                                      productModel?.model_for == "device"
                                      ? edit_device_model_path(
                                          props.productModelId,
                                        )
                                      : edit_asset_type_product_model_path(
                                          props.assetType?.id ||
                                            props.assetTypeId,
                                          props.productModelId,
                                        ),
                                  );
                                }}
                                color={"primary"}
                              >
                                <Edit />
                              </Fab>
                            </Tooltip>
                          )}
                        </FloatingButtons>
                      ) : (
                        <FloatingButtons
                          isProcessing={isProcessing}
                          onSubmit={() => {
                            void submit();
                          }}
                          onCancel={() => {
                            if (props.onCancel) {
                              props.onCancel();
                            } else {
                              redirectTo("back");
                            }
                          }}
                          disableSave={
                            isProcessing || !isEmpty(productModelErrors)
                          }
                          showScrollToTopBtn={true}
                          saveTitle={I18n.t(
                            "frontend.organizations.form.submit_title",
                          )}
                        />
                      )}
                    </FixedBottomArea>
                  )}
                </>
              )}
            </LoadingWrapper>
          </CardContent>

          {props.buttonMode != "card" ? null : (
            <CardActions>
              {props.mode !== "show" ? (
                <Button
                  color="primary"
                  disabled={isProcessing}
                  startIcon={<Check />}
                  onClick={() => {
                    void submit();
                  }}
                >
                  {I18n.t("frontend.save")}
                </Button>
              ) : null}
              <Button
                disabled={isProcessing}
                startIcon={<Cancel />}
                onClick={() => {
                  if (props.onCancel) {
                    props.onCancel();
                  } else {
                    redirectTo("back");
                  }
                }}
              >
                {props.mode == "show"
                  ? I18n.t("frontend.close")
                  : I18n.t("frontend.cancel")}
              </Button>
            </CardActions>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

function handleRequestSuccess(
  mode: RequestMode,
  resultData: JSONAPI.SingleResourceDoc<string, ProductModelJSONObject>,
  assetTypeId: IDType,
  setProductModel: (productModel: ProductModelJSONObject) => void,
  onSuccess: (productModel: ProductModelJSONObject) => void,
  context: SialogicContext,
) {
  if (mode === "create") {
    void toasts.success(I18n.t("base.successfully_created"));
  } else {
    void toasts.success(I18n.t("base.successfully_updated"));
  }

  const productModel = jsonApiSingleResourceToFlatObject(resultData);
  setProductModel(productModel);

  if (!isNil(onSuccess)) {
    onSuccess(productModel);
  } else {
    redirectTo(
      defaultTo(context.referrer, asset_type_product_models_path(assetTypeId)),
    );
  }
}
