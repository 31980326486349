import { createRoot, Root } from "react-dom/client";
import { AppRoot } from "../../components/common/app_root";
import { MqttMessageViewer } from "../../components/mqtt_viewer/mqtt_message_viewer";
import * as React from "react";
import { MqttViewerPage } from "./mqtt_viewer_page";
const mqttViewerRoots: Root[] = [];
/**
 * Initializes the MQTT Viewer page by finding all elements with the data-toggle attribute
 * set to "mqtt-viewer" and rendering a React component for each element.
 *
 * This function iterates over each matching element, retrieves the asset ID from the element's
 * data attributes, and then creates a React root to render the `MqttMessageViewer` component
 * within an `AppRoot` component.
 *
 * The rendered components are stored in the `mqttViewerRoots` array.
 *
 * @remarks
 * This function relies on jQuery to select elements and retrieve data attributes.
 *
 * @example
 * ```typescript
 * // Assuming the HTML contains elements like:
 * // <div data-toggle="mqtt-viewer" data-asset-id="123"></div>
 * // <div data-toggle="mqtt-viewer" data-asset-id="456"></div>
 *
 * initializeMqttViewerPage();
 * ```
 */
export function initializeMqttViewerPage(): void {
  $('[data-toggle="mqtt-viewer"]').each((index, element) => {
    const assetId = $(element).data("asset-id") as string;

    const root = createRoot(element);
    mqttViewerRoots.push(root);
    root.render(
      <AppRoot>
        <MqttViewerPage assetId={assetId} />
      </AppRoot>,
    );
  });
}
/**
 * Remove react component from all elements with data-toggle="maintenance-form"
 */
export function destroyMqttViewerPage(): void {
  mqttViewerRoots.forEach((root) => {
    root.unmount();
  });
  mqttViewerRoots.length = 0;
}
