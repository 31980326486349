import consumer from "./consumer";

import moment, { Moment } from "moment";
import {
  EventSubscriberBase,
  EventSubscription,
  ModelDataChannel,
} from "./model_data_channel";
import { EventNotification } from "../models/event_notification";
import { each, isNil } from "lodash";

import { IDType } from "../utils/urls/url_utils";
import { AssetProperties } from "../json_api/asset";
import { AssetCableValue } from "./asset_notification_channel";

export interface MeasurementChannelCableValue
  extends AssetCableValue<MeasurementMessage> {
  action: "measurement";
  measurement_id: IDType;
}

interface MeasurementMessage {
  time: string;
  measurement_id: IDType;
  asset_id: IDType;
  root_asset_id?: IDType;
  measurement_plan_id: IDType;
  measurement_plan_key: string;
  measurement_values: Record<string, number>;
}
export interface MeasurementSubscriber extends EventSubscriberBase {
  handleMeasurement?(
    measurementId: IDType,
    assetId: IDType,
    rootAssetId: IDType,
    time: Moment,
    measurementValues: Record<string, number>,
    measurementPlanId: IDType,
    measurementPlanKey: string,
  ): void;
}

const WILDCARD_ATTRIBUTE_ID = -1;

export class MeasurementChannel extends ModelDataChannel<
  MeasurementSubscriber,
  MeasurementChannelCableValue
> {
  protected includeSubtreeInSubscription: boolean;
  constructor(includeSubtree = true) {
    super();
    this.includeSubtreeInSubscription = includeSubtree;
  }
  protected getChannelNameWithParams(
    modelId: number,
  ): string | ActionCable.ChannelNameWithParams {
    return {
      channel: "MeasurementChannel",
      asset_id: modelId,
      include_subtree: this.includeSubtreeInSubscription,
    };
  }

  public subscribe(modelId: number) {
    return super.subscribe(modelId);
  }
  protected handleDataMessage(
    data: MeasurementChannelCableValue,
    listeners: EventSubscription<MeasurementSubscriber>[],
  ): void {
    if (isNil(listeners)) return;
    if (data.action == "measurement") {
      const time = moment(data.message.time);
      each(listeners, (listener) => {
        listener.f.handleMeasurement?.(
          data.message.measurement_id,
          data.message.asset_id,
          data.message.root_asset_id,
          time,
          data.message.measurement_values,
          data.message.measurement_plan_id,
          data.message.measurement_plan_key,
        );
      });
    }
  }
}

export default MeasurementChannel;
