import * as React from "react";
import { MeasurementPlan } from "../../../models/measurement_plan";

import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { useLocalStorage } from "@uidotdev/usehooks";
import { MeasurementJsonObject } from "../../../json_api/measurement";
import { MeasurementPlanJSONAPIAttributesObject } from "../../../json_api/measurement_plan";
import { useLoadMeasurement } from "../../../queries/measurements_data";
import { useLoadMeasurementPlans } from "../../../queries/measurements_plan_data";
import { getLocalStorageKey } from "../../../storage/storage_keys";
import { AttributeRow } from "../../common/attribute_row";
import { MeasurementGraph } from "../../measurements/views/measurement_graph";
import { MeasurementGraphTypeSelect } from "../../measurements/views/measurement_graph_type_select";
import { MeasurementTable } from "../../measurements/views/measurement_table";
import { first, isEmpty } from "lodash";
import { Check, Delete } from "@mui/icons-material";
interface SelectReferenceMeasurementProps {
  measurementPlan: MeasurementPlanJSONAPIAttributesObject;
  onSelect?: (
    plan: MeasurementPlan,
    referenceMeasurement: MeasurementJsonObject,
  ) => void;
  onCancel?: () => void;
}

export const SelectReferenceMeasurement: React.FunctionComponent<
  SelectReferenceMeasurementProps
> = ({ measurementPlan, onCancel, onSelect }) => {
  const { data: existingReferenceMeasurement } = useLoadMeasurement({
    variables: {
      id: measurementPlan.reference_measurement_id,
      include: [
        "measurement_value_definitions",
        "measurement_categories",
        "measurement_plan",
        "measurement_type",
        "asset",
      ],
    },
    enabled: !!measurementPlan.reference_measurement_id,
  });

  const [selectedMeasurementPlan, setSelectedMeasurementPlan] =
    React.useState<MeasurementPlanJSONAPIAttributesObject | null>(
      measurementPlan.measurement_type.ref_measurement_type_key
        ? null
        : measurementPlan,
    );

  const {
    data: loadedReferenceMeasurementPlan,
    isLoading: referenceMeasurementPlanLoading,
    error: referenceMeasurementPlanLoadError,
  } = useLoadMeasurementPlans({
    variables: {
      filter: {
        key: measurementPlan?.measurement_type?.ref_measurement_type_key,
        asset_id: measurementPlan?.asset_id,
      },
      include: [
        "measurement_type",
        "measurement_value_definitions",
        "measurement_categories",
        "asset",
      ],
    },
    enabled: Boolean(
      measurementPlan?.measurement_type?.ref_measurement_type_key,
    ),
  });

  React.useEffect(() => {
    if (loadedReferenceMeasurementPlan) {
      const newSelectedMeasurementPlan =
        loadedReferenceMeasurementPlan.items[0] || measurementPlan;
      setSelectedMeasurementPlan(newSelectedMeasurementPlan);
    } else {
      setSelectedMeasurementPlan(measurementPlan);
    }
  }, [loadedReferenceMeasurementPlan]);

  const [selectedMeasurement, setSelectedMeasurement] =
    React.useState<MeasurementJsonObject | null>(null);

  React.useEffect(() => {
    if (existingReferenceMeasurement) {
      setSelectedMeasurement(existingReferenceMeasurement);
    }
  }, [existingReferenceMeasurement]);

  const [selectedGraphType, setSelectedGraphType] = useLocalStorage(
    getLocalStorageKey("measurementPlan", measurementPlan?.id, "diagramType"),
    measurementPlan?.measurement_type?.diagram_type || "line",
  );

  return (
    <Grid container spacing={3} rowGap={3}>
      {!selectedMeasurementPlan && (
        <Grid item xs={12}>
          <CircularProgress title={I18n.t("frontend.loading")} />
        </Grid>
      )}
      {selectedMeasurementPlan && (
        <>
          <Grid item xs={12}>
            <Typography variant="h6">
              {I18n.t(
                "frontend.measurement_plans.select_reference_measurement.measurements_for",
                { plan: selectedMeasurementPlan?.measurement_type_title },
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MeasurementTable
              measurementPlan={selectedMeasurementPlan}
              measurementPlanId={selectedMeasurementPlan.id}
              multiselect={false}
              referenceMeasurementId={selectedMeasurement?.id}
              onSelect={(measurements) => {
                if (!first(measurements)) {
                  if (existingReferenceMeasurement) {
                    setSelectedMeasurement(existingReferenceMeasurement);
                  } else {
                    setSelectedMeasurement(null);
                  }
                } else {
                  setSelectedMeasurement(first(measurements));
                }
              }}
            />
            <Box mt={2} textAlign={"center"}>
              {existingReferenceMeasurement &&
                existingReferenceMeasurement.id != selectedMeasurement?.id && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setSelectedMeasurement(existingReferenceMeasurement);
                    }}
                  >
                    {I18n.t(
                      "frontend.measurement_plans.select_reference_measurement.reset_to_previous",
                    )}
                  </Button>
                )}
            </Box>
          </Grid>
          <Grid container item xs={12} lg={6} rowSpacing={3}>
            <Grid item xs={12}>
              <MeasurementGraphTypeSelect
                graphType={selectedGraphType}
                measurementType={selectedMeasurementPlan?.measurement_type}
                onChangeGraphType={setSelectedGraphType}
              />
            </Grid>
            {selectedMeasurement ? (
              <>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    {I18n.t(
                      "frontend.measurement_plans.select_reference_measurement.selected_reference_measurement",
                    )}
                  </Typography>
                  <AttributeRow
                    attributeName={I18n.t(
                      "activerecord.attributes.measurement.time",
                    )}
                    value={selectedMeasurement?.time}
                  />
                </Grid>
                <Grid item xs={12}>
                  {!selectedMeasurement && (
                    <Typography>
                      {I18n.t(
                        "frontend.measurement_plans.select_reference_measurement.please_select_a_measurement_from_list",
                      )}
                    </Typography>
                  )}
                  {selectedMeasurement &&
                  !isEmpty(selectedMeasurement?.measurement_values) ? (
                    <MeasurementGraph
                      diagramType={selectedGraphType}
                      measurement={selectedMeasurement}
                      measurementValueDefinitions={
                        selectedMeasurementPlan?.measurement_value_definitions
                      }
                    />
                  ) : (
                    <Typography>
                      {I18n.t(
                        "frontend.measurement_plans.select_reference_measurement.no_measurement_values",
                      )}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} textAlign={"center"}>
                  <ButtonGroup>
                    {existingReferenceMeasurement && (
                      <Button
                        variant="contained"
                        size="large"
                        color="warning"
                        onClick={() => {
                          // use the provided measurement plan which is not necessarily the same as the selected one
                          onSelect(measurementPlan, null);
                        }}
                        startIcon={<Delete />}
                        title={I18n.t(
                          "frontend.measurement_plans.select_reference_measurement.remove_reference_measurement_hint",
                        )}
                      >
                        {I18n.t(
                          "frontend.measurement_plans.select_reference_measurement.remove_reference_measurement",
                        )}
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      disabled={
                        !selectedMeasurement ||
                        !onSelect ||
                        isEmpty(selectedMeasurement?.measurement_values)
                      }
                      onClick={() => {
                        // use the provided measurement plan which is not necessarily the same as the selected one
                        onSelect(measurementPlan, selectedMeasurement);
                      }}
                      startIcon={<Check />}
                    >
                      {I18n.t(
                        "frontend.measurement_plans.select_reference_measurement.apply_selection",
                      )}
                    </Button>
                    {onCancel && (
                      <Button
                        variant="contained"
                        size="large"
                        onClick={() => {
                          onCancel();
                        }}
                      >
                        {I18n.t("frontend.cancel")}
                      </Button>
                    )}
                  </ButtonGroup>
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <Typography textAlign={"center"}>
                  {I18n.t(
                    "frontend.measurement_plans.select_reference_measurement.no_reference_measurement_selected",
                  )}
                </Typography>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};
