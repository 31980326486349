import { Skeleton } from "@mui/material";
import Box from "@mui/material/Box";
import { useQueries, UseQueryResult } from "@tanstack/react-query";
import { CollectionResourceDoc } from "jsonapi-typescript";
import { defaultTo, isEmpty, isNil, isUndefined, merge } from "lodash";
import * as React from "react";

import {
  jsonApiFilterParamsArgumentsFromFilterObject,
  jsonApiResourceCollectionToFlatObjects,
} from "../../json_api/jsonapi_tools";
import { MaintenanceJobJSONObject } from "../../json_api/maintenance_job";
import { MaintenancePlanJSONObject } from "../../json_api/maintenance_plan";
import { api_maintenance_jobs_path } from "../../routes";
import { loadDataFromUrl } from "../../utils/jquery_helper";
import { IDType } from "../../utils/urls/url_utils";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import { ExtensiblePageSettings, PageSettings } from "../common/page_size";
import { DashboardContext } from "../dashboard/dashboard_context/dashboard_context";
import { MaintenanceJobsFilter } from "../maintenance_jobs/maintenance_jobs_filter";
import { MaintenancesWidgetItem } from "../maintenance_jobs/maintenances_widget_item";
import {
  MaintenancesWidgetConfigSerialized,
  MaintenancesWidgetProps,
} from "./maintenances_widget.types";
import {
  SerializedConfigToProps,
  SialogicWidgetDefinition,
} from "./sialogic_widget_component";
import { WidgetBox } from "./widget_box";

const MAINTENANCE_JOB_INCLUDES = ["user", "asset", "maintenance_plan"];

function generateMaintenanceJobsURL(
  filter: MaintenanceJobsFilter,
  pageSettings: PageSettings &
    Record<string, number> & { totalPages: number; totalRecords: number },
  sort: string = "-done_at",
) {
  return api_maintenance_jobs_path(
    merge(
      {
        include: MAINTENANCE_JOB_INCLUDES.join(","),
        sort: sort,
        page: pageSettings,
      },
      jsonApiFilterParamsArgumentsFromFilterObject(filter),
    ),
  );
}

export const MaintenancesWidget: React.FunctionComponent<
  MaintenancesWidgetProps
> = ({
  encloseInWidgetBox = true,
  encloseInIBox = false,
  ...props
}: MaintenancesWidgetProps) => {
  const dashboardContext = React.useContext(DashboardContext);

  // implement display content here ...
  const [jobs, setJobs] = React.useState<MaintenanceJobJSONObject[]>([]);

  const [jobRequestPageSettings, setJobRequestPageSettings] = React.useState<
    ExtensiblePageSettings & { totalPages: number; totalRecords: number }
  >({
    size: 2,
    number: 1,
    totalPages: null,
    totalRecords: null,
  });

  const [jobsFilter, setJobsFilter] = React.useState<MaintenanceJobsFilter>({
    asset: props.assetIds,
    // maintenancePlan: props.maintenancePlanIds ? props.maintenancePlanIds : null,
    done_after: null,
    done_before: null,
    search: null,
  });

  React.useEffect(() => {
    setJobRequestPageSettings((oldSettings) => ({
      ...oldSettings,
      size: props.numberOfJobsPerPlan,
    }));
  }, [props.numberOfJobsPerPlan]);

  const [loading, setLoading] = React.useState<boolean>(true);
  const [pending, setPending] = React.useState<boolean>(true);

  //
  // create the urls to request the jobs for each plan
  //

  const [jobUrls, setJobUrls] = React.useState<string[]>([]);
  React.useEffect(() => {
    if (props.assetIds && isEmpty(jobsFilter.asset)) {
      // avoid loading all assets when an asset scope is requested and no asset filter is set (yet)
      return;
    }
    const paths: string[] = props.sortedMaintenancePlanIds?.map(
      (planId: IDType) => {
        return generateMaintenanceJobsURL(
          { ...jobsFilter, maintenance_plan: [planId] },
          jobRequestPageSettings,
        );
      },
    );
    setJobUrls(paths);
  }, [props.sortedMaintenancePlanIds, jobsFilter, jobRequestPageSettings.size]);

  const combineQueryResults = React.useCallback(
    (
      results: UseQueryResult<
        {
          jobs: MaintenanceJobJSONObject[];
        },
        Error
      >[],
    ) => {
      return {
        data: results.map((result) => result.data),
        isPending: results.some((result) => result.isPending),
        isLoading: results.some((result) => result.isLoading),
      };
    },
    [],
  );

  const jobsPerPlanQueries = useQueries({
    queries: (jobUrls || []).map((url) => {
      return {
        queryKey: [url],
        queryFn: () =>
          loadDataFromUrl<
            CollectionResourceDoc<string, MaintenanceJobJSONObject>
          >(url).then((loadedJobs) => {
            const result = {
              jobs: jsonApiResourceCollectionToFlatObjects(loadedJobs),
            };
            return result;
          }),
        enabled: !isNil(jobUrls),
      };
    }),
    combine: combineQueryResults, // external function to avoid execution in every render pass
  });

  React.useEffect(() => {
    if (
      jobsPerPlanQueries &&
      jobsPerPlanQueries.data &&
      jobsPerPlanQueries.data.length > 0
    ) {
      if (!isEmpty(jobsPerPlanQueries.data[0])) {
        const jobs_for_plans: MaintenanceJobJSONObject[] = [];
        jobsPerPlanQueries.data.forEach((plan_with_jobs) => {
          plan_with_jobs?.jobs?.forEach((job) => {
            if (job != undefined && !isEmpty(job)) {
              jobs_for_plans.push(job);
            }
          });
        });
        if (props.sortJobsByTime) {
          setJobs(
            jobs_for_plans.sort((a, b) => {
              return a.done_at < b.done_at ? 1 : -1;
            }),
          );
        } else {
          const sortedJobs: MaintenanceJobJSONObject[] = [];
          props.sortedMaintenancePlanIds?.forEach((planId) => {
            jobs_for_plans
              .filter((job) => job.maintenance_plan_id === planId)
              .forEach((job) => {
                sortedJobs.push(job);
              });
          });
          setJobs(sortedJobs);
        }
      } else {
        setJobs([]);
      }
    }
  }, [
    jobsPerPlanQueries.data,
    props.sortJobsByTime,
    props.sortedMaintenancePlanIds,
  ]);

  React.useEffect(() => {
    setLoading(jobsPerPlanQueries.isLoading);
  });

  React.useEffect(() => {
    setPending(jobsPerPlanQueries.isPending);
  });

  React.useEffect(() => {
    setJobsFilter((oldFilter) => ({
      ...oldFilter,
      done_before: dashboardContext?.timeRange?.end?.toDate(),
    }));
  }, [dashboardContext?.timeRange]);

  const currentContent = (
    <Box>
      {loading || pending ? (
        <>
          <Skeleton variant="rectangular" height={40} />
          <Box height={10} />
          <Skeleton variant="rectangular" height={40} />
          <Box height={10} />
          <Skeleton variant="rectangular" height={40} />
        </>
      ) : !isEmpty(jobs) ? (
        jobs.map((job, index) => {
          const mPlan = props.sortedMaintenancePlanIds.find(
            (planId) => planId === job.maintenance_plan_id,
          );
          return !isNil(mPlan) && !isUndefined(mPlan) ? (
            <MaintenancesWidgetItem
              job={job}
              maintenancePlan={
                job.maintenance_plan as MaintenancePlanJSONObject
              }
              key={job.id + "_" + index}
            />
          ) : null;
        })
      ) : (
        I18n.t("widgets.maintenances_widget.no_entries_in_this_time_range")
      )}
    </Box>
  );

  return (
    <>
      {!encloseInWidgetBox ? (
        currentContent
      ) : (
        <WidgetBox
          widgetId={props.widgetId}
          dashboardSettings={props.dashboardSettings}
          title={defaultTo(
            props.title as string,
            I18n.t("activerecord.models.widgets/maintenances_widget.one"),
          )}
          titleLinkUrl={props.titleLinkUrl}
          contentLinkUrl={props.contentLinkUrl}
          encloseInIBox={encloseInIBox}
          assetId={props.assetId}
        >
          {currentContent}
        </WidgetBox>
      )}
    </>
  );
};

const serializedConfigToProps: SerializedConfigToProps<
  MaintenancesWidgetConfigSerialized,
  MaintenancesWidgetProps
> = (config: MaintenancesWidgetConfigSerialized): MaintenancesWidgetProps => {
  return {
    ...widgetBoxPropsFromSerializedConfig(config),
    displayMode: config.display_mode,
    assetIds: config.asset_ids,
    sortedMaintenancePlanIds: config.maintenance_plan_ids,
    sortJobsByTime:
      config.sort_jobs_by_time != undefined ? config.sort_jobs_by_time : false,
    numberOfJobsPerPlan: config.number_of_entries_per_plan,
  } as MaintenancesWidgetProps;
};

export const MaintenancesWidgetDefinition: SialogicWidgetDefinition = {
  Component: MaintenancesWidget,
  serializedConfigToProps: serializedConfigToProps,
};
