/// <reference types="../definitions/index" />;

import { findIndex, isFinite, isNil, values } from "lodash";

import { Moment } from "moment";
import { DateRange } from "moment-range";
import { ColorUtils } from "../utils/colors";
import { convertToUnit, unitDisplayString } from "../utils/unit_conversion";
import { Plotly, PlotlyLayoutYaxisNames } from "./plotly_package";

export interface BarChartDataset {
  name: string;
  x: string[];
  y: number[];

  text: string[];
  sensor_ids: number[];
  key_ids: number[];

  unit: string;
  range: [number, number];
  timestamps: Date[];
}

export interface BarChartData {
  datasets: BarChartDataset[];
  stacked?: boolean;
  orientation?: "h" | "v";
}

export class PlotlyBarChart {
  static fillColors: string[] = ColorUtils.getColorsRgba(0.75);
  static margins = {
    t: 10,
    b: 60,
    l: 40,
    r: 40,
  };

  static font = {
    family: '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
    size: 11,
    color: "rgb(103, 106, 108)",
  };
  id: string;
  chartData: BarChartData;
  timeScope: string;
  timeRange: DateRange;
  yAxes: Partial<Plotly.LayoutAxis>[];
  chartElement: HTMLElement;
  chart: Plotly.PlotlyHTMLElement;

  private dataRevision: number;

  constructor(
    id: string,
    chartData: BarChartData,
    timeScope?: string,
    timeRange?: DateRange,
  ) {
    this.id = id;
    this.chartData = chartData;
    this.timeScope = timeScope;
    this.timeRange = timeRange;
    if (chartData.orientation !== "h") {
      this.yAxes = this.createYAxis(chartData);
    }
    this.chartElement = $(`#${id}-bar-chart`).get(0);
    this.chart = null;
    this.dataRevision = 1;

    void this.updateChart().then(() =>
      setTimeout(() => {
        void this.resize();
      }, 10),
    );
  }

  destroyChart() {
    if (isNil(this.chartElement) || isNil(this.chart)) {
      return;
    }

    Plotly.purge(this.chartElement);
    this.chart = null;
  }

  /**
   * Resize to diagram container
   */
  resize(): void {
    if (isNil(this.chart) || isNil(this.chartElement)) {
      return;
    }

    Plotly.Plots.resize(this.chartElement);
  }

  getChartFillColor(chartIndex: number): string {
    return PlotlyBarChart.fillColors[
      chartIndex % PlotlyBarChart.fillColors.length
    ];
  }
  updateChart(): Promise<void> {
    const chartData: Partial<Plotly.PlotData>[] = this.chartData.datasets.map(
      (dataset, index) => ({
        type: "bar",
        yaxis: index === 0 ? "y" : `y${index + 1}`,
        fillcolor: this.getChartFillColor(index),

        name: dataset.name,
        text: dataset.text,
        textposition: "auto",
        hoverinfo: this.chartData.orientation == "h" ? "y" : "x",
        hoverlabel: {
          bgcolor: "#ffffff",
          font: {
            color: "#000000",
          },
        },
        x: this.chartData.orientation == "h" ? dataset.y : dataset.x,
        y: this.chartData.orientation == "h" ? dataset.x : dataset.y,
        orientation: isNil(this.chartData.orientation)
          ? "v"
          : this.chartData.orientation,
        marker: {
          line: {
            color: "#000000",
            width: 1,
          },
        },
      }),
    );
    if (this.chart) {
      const p = Plotly;
      return p.animate(
        this.chartElement,
        { data: chartData },
        {
          transition: {
            duration: 5000,
            easing: "cubic-in-out",
          },
        },
      ) as Promise<void>;
    } else {
      return Plotly.react(
        this.chartElement,
        chartData,
        {
          font: PlotlyBarChart.font,
          datarevision: this.dataRevision,
          barmode: this.chartData.stacked ? "stack" : "group",
          autosize: true,
          showlegend: false,

          margin: PlotlyBarChart.margins,
          xaxis: {
            fixedrange: true,
            autorange: true,
          },

          transition: {
            duration: 3000,
            easing: "cubic",
          },
          frame: {
            duration: 5000,
          },
          ...this.getYAxisLayout(),
        } as any,
        {
          responsive: true,
          displaylogo: false,
          displayModeBar: false,
          locale: I18n.locale,
        },
      ).then((chart) => {
        this.chart = chart;
      });
    }
  }

  /**
   * Live update diagram data
   * @param attributeKeyId Attribute key of the incomming value
   * @param sensorId Sensor id of the incomming value
   * @param timestamp Timestamp of incomming value
   * @param value
   * @param unit Unit of incomming value(may differ from dataset value)
   */
  updateValue(
    attributeKeyId: number,
    sensorId: number,
    timestamp: Moment,
    value: number,
    unit?: string,
  ): void {
    if (!isNil(this.timeRange) && !this.timeRange.contains(timestamp)) {
      return;
    }
    let dataUpdated = false;

    this.chartData.datasets.forEach((dataset) => {
      const dataIndex = findIndex(
        dataset.key_ids,
        (key_id) => key_id == attributeKeyId,
      );

      if (dataIndex < 0) {
        return;
      }
      if (dataset.timestamps[dataIndex] > timestamp.toDate()) {
        return;
      }

      if (!isNil(unit) && dataset.unit !== unit) {
        value = convertToUnit(value, unit, dataset.unit);
      }

      // if unit coversion fails null may be returned
      if (isNil(value)) {
        return;
      }
      value = value * 4;
      dataset.timestamps[dataIndex] = timestamp.toDate();
      dataset.y[dataIndex] = value;
      dataset.text[dataIndex] = value.toLocaleString(I18n.locale, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 2,
      });

      dataUpdated = true;
    });

    if (!dataUpdated) {
      return;
    }

    this.dataRevision++;
    void this.updateChart();
  }

  private createYAxis(chartData: BarChartData): Partial<Plotly.LayoutAxis>[] {
    return chartData.datasets.map((dataset, axisIndex) => {
      return {
        title: unitDisplayString(dataset.unit),
        side: axisIndex % 2 === 0 ? "left" : "right",
        fixedrange: true,
        autorange: !isNil(dataset.range)
          ? !(isFinite(dataset.range[0]) && isFinite(dataset.range[1]))
          : true,
        range:
          !isNil(dataset.range) &&
          isFinite(dataset.range[0]) &&
          isFinite(dataset.range[1])
            ? dataset.range
            : undefined,
        overlaying: axisIndex > 0 ? "y" : undefined,
        automargin: true,
      } as Partial<Plotly.LayoutAxis>;
    });
  }

  private getYAxisLayout(): Partial<Plotly.Layout> {
    const axisLayout: Partial<Plotly.Layout> = {};

    values(this.yAxes).forEach((axis, index) => {
      const key: PlotlyLayoutYaxisNames =
        index === 0 ? "yaxis" : (`yaxis${index + 1}` as PlotlyLayoutYaxisNames);

      axisLayout[key] = axis;
    });

    return axisLayout;
  }
}
