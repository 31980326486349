import { each, isEmpty, isNil } from "lodash";
import {
  MappedSensor,
  SensorMappingAttributes,
} from "../../models/svg_animation_widget_config";
import { logger } from "../../utils/logger";
import { Action } from "../../actions/actions.types";

export enum MappingType {
  ScaleMapping = "scale_mapping",
  LabelMapping = "label_mapping",
  ColorMapping = "color_mapping",
  RotateMapping = "rotate_mapping",
  AnimationMapping = "animation_mapping",
  ClickableMapping = "clickable_mapping",
  HideMapping = "hide_mapping",
}

/** Base Class for Mapping a value to an SVG element / its properties
 *
 *
 * @export
 * @abstract
 * @class BaseMapping
 * @template ElementType SVG element type to use for mapping
 */
export abstract class BaseMapping<ElementType extends SVGElement = SVGElement> {
  /** The Element to manipulate
   *
   *
   * @type {ElementType}
   * @memberof BaseMapping
   */
  element?: ElementType;
  elements?: NodeListOf<ElementType>;

  /** Selector to retrieve the element from the SVG document
   *
   *
   * @type {string}
   * @memberof BaseMapping
   */
  elementSelector?: string;
  hideOnMissing?: boolean;

  listeners: [ElementType, string, EventListener][] = [];

  onClick?: (
    event: MouseEvent,
    element: ElementType,
    action: Action,
    config: SensorMappingAttributes,
  ) => void;

  config: SensorMappingAttributes;

  constructor(
    config: SensorMappingAttributes,
    onClick?: (
      event: MouseEvent,
      element: ElementType,
      action: Action,
      config: SensorMappingAttributes,
    ) => void,
  ) {
    this.config = config;
    this.onClick = onClick;
    this.elementSelector = config.element_selector
      ? config.element_selector
      : null;
    this.hideOnMissing = config.hide_on_missing;
  }

  destroy(): void {
    each(this.listeners, ([element, event, listener]) =>
      element.removeEventListener(event, listener),
    );
  }
  /** Method for applying the mapping to a SVG element. Called whenever the SVG element changes.
   * A mapping should search their affected elements in the method an not use more tree queries in value application
   *
   *
   *
   * @param {SVGSVGElement} svgElement the SVG element
   * @memberof BaseMapping
   */
  setElementInfoFromSvg(svgElement: SVGSVGElement): void {
    if (isNil(this.element)) {
      this.initElementBinding(svgElement);
    }

    if (isNil(this.element)) {
      logger.debug(
        "SVG Element for mapping could not be found for in svg document",
        this,
        svgElement,
        this.elementSelector,
      );
    }
  }

  allowBindMultipleElements(): boolean {
    return false;
  }

  /** Initializes the element binding for the mapping. Attaches the onClick event if defined
   *
   *
   * @param {SVGElement} svgElement
   * @memberof BaseMapping
   */
  initElementBinding(svgElement: SVGSVGElement): void {
    if (isNil(svgElement) || isEmpty(this.elementSelector)) {
      this.element = null;
    } else {
      try {
        let elements;
        if (this.allowBindMultipleElements()) {
          this.elements = svgElement.querySelectorAll(this.elementSelector);
          elements = this.elements;
          this.element = this.elements[0];
        } else {
          this.element = svgElement?.querySelector(this.elementSelector);
          if (this.element) {
            elements = [this.element];
          } else {
            elements = null;
          }
        }

        if (!isEmpty(elements)) {
          if (!isNil(this.onClick)) {
            each(elements, (element) => {
              const listener = (event: MouseEvent) => {
                this.onClick(
                  event,
                  // should be ok as we only allow SVG SVG element children
                  element as any,
                  (this.config as any)?.["action"] as any,
                  this.config,
                );
              };

              element.addEventListener("click", listener);
              this.listeners.push([element, "click", listener]);
            });
          }
        }
      } catch (e) {
        logger.error(
          "Error while initializing element binding for mapping",
          e,
          this,
          svgElement,
          this.elementSelector,
        );
        this.element = null;
        this.elements = null;
      }
    }
  }

  hide(svgElement: SVGSVGElement): void {
    if (isNil(this.element)) {
      this.initElementBinding(svgElement);
    }
    if (this.allowBindMultipleElements()) {
      each(this.elements, (element) => {
        element.setAttribute("visibility", "hidden");
      });
    } else {
      if (this.element) {
        this.element.setAttribute("visibility", "hidden");
      }
    }
  }

  /** Abstract method definition to apply the given sensor to the svg document
   *
   *
   * @abstract
   * @param {MappedSensor} sensorConfig
   * @param {SVGElement} svgElement
   * @memberof BaseMapping
   */
  abstract applyValueToSvg(
    sensorConfig: MappedSensor,
    svgElement: SVGSVGElement,
  ): void;
}
