import { Action } from "../../actions/actions.types";
import {
  MappedSensor,
  SensorMappingAttributes,
} from "../../models/svg_animation_widget_config";
import { BaseMapping } from "./mapping_base";

export class HideMapping extends BaseMapping<SVGTextElement | SVGTSpanElement> {
  constructor(
    config: SensorMappingAttributes,
    onClick?: (
      event: MouseEvent,
      element: SVGElement,
      action: Action,
      config: SensorMappingAttributes,
    ) => void,
  ) {
    super(config, onClick);
    // always hide on missing
    this.hideOnMissing = true;
  }

  // as we want multiple elements to be potentially hidden, we return true here
  allowBindMultipleElements(): boolean {
    return true;
  }

  applyValueToSvg(sensorConfig: MappedSensor, svgElement: SVGSVGElement): void {
    return;
    // nothing to do here, clickable is clickable
    // maybe there could be a use case for hiding elements based on their value later on
  }
}
