import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MEASUREMENT_JSON_API_TYPE } from "../json_api/measurement";
import {
  MEASUREMENT_TYPE_JSON_API_TYPE,
  MeasurementTypeJSONAPIAttributesObject,
  MeasurementTypeUpdatableFields,
} from "../json_api/measurement_type";
import { api_measurement_type_path } from "../routes";
import { sendJsonApiData } from "../utils/jquery_helper";
import { buildJsonApiSubmitData } from "../utils/jsonapi_form_tools";
import { IDType } from "../utils/urls/url_utils";
import { MEASUREMENT_PLAN_JSON_API_TYPE } from "../json_api/measurement_plan";

export const useUpdateMeasurementType = () => {
  const client = useQueryClient();
  return useMutation<
    unknown,
    Error,
    { id: IDType; mt: MeasurementTypeJSONAPIAttributesObject }
  >({
    mutationFn: ({ id, mt }) => {
      const submitData = buildJsonApiSubmitData(
        mt,
        MEASUREMENT_TYPE_JSON_API_TYPE,
        MeasurementTypeUpdatableFields,
      );
      return sendJsonApiData(
        api_measurement_type_path(id),
        submitData.submitData,
        "PATCH",
      );
    },
    onSuccess: () => {
      client.invalidateQueries({ queryKey: [MEASUREMENT_TYPE_JSON_API_TYPE] });
      client.invalidateQueries({ queryKey: [MEASUREMENT_PLAN_JSON_API_TYPE] });
    },
  });
};
