import { isNaN, isNil, isNumber, isPlainObject, isString } from "lodash";
import { SensorValueType } from "../models/sensor";
export function getValueString(value: SensorValueType, precision = 2) {
  if (isNaN(value) || isNil(value)) {
    return "---";
  }

  return getFormattedStringForValue(value, precision ?? 2);
}

export function getFormattedStringForValue(
  value: SensorValueType,
  precision = 2,
): string {
  if (isNumber(value)) {
    return value.toLocaleString(I18n.locale, {
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
    });
  } else if (isString(value)) {
    return value;
  } else if (isPlainObject(value)) {
    // as a plain object this is a location value

    return `${getFormattedStringForValue(
      value.x,
      precision,
    )} | ${getFormattedStringForValue(value.y, precision)}${
      isNil(value.z)
        ? ""
        : " | " + getFormattedStringForValue(value.z, precision)
    }`;
  }
}
