import { isNil, merge, toInteger } from "lodash";
import { Moment } from "moment";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { WidgetController } from "../../controller/widget_controller";
import { jsonApiResourceCollectionToFlatObjects } from "../../json_api/jsonapi_tools";
import { OrganizationJSONAPIAttributes } from "../../json_api/organization";
import { SensorValueType } from "../../models/sensor";
import { OrganizationsWidgetConfigSerialized } from "../../widgets/organizations_widget.types";
import { widgetBoxPropsFromSerializedConfig } from "../../widgets/widget";
import { OrganizationsList } from "../organizations/organization_list";
import { OrganizationsWidgetProps } from "./organizations_widget.types";
import { SialogicWidgetDefinition } from "./sialogic_widget_component";
import { WidgetBox } from "./widget_box";

export const OrganizationsWidget: React.FunctionComponent<
  OrganizationsWidgetProps
> = ({
  dataUpdateEnabled = true,
  allowFullscreen = true,
  encloseInWidgetBox = true,
  tableHeight = 300,
  pageSize = 20,
  enableSearch = true,
  ...props
}: OrganizationsWidgetProps) => {
  const [title, setTitle] = useState(props.title);
  const [titleLinkUrl, setTitleLinkUrl] = useState(props.titleLinkUrl);
  const [contentLinkUrl, setContentLinkUrl] = useState(props.contentLinkUrl);

  const content = (
    <OrganizationsList
      pageNumber={props.pageNumber}
      enableSearch={enableSearch}
      pageSize={pageSize}
      density={props.tableDensity}
      totalPages={props.totalPages}
      totalItems={props.totalItems}
      tableHeight={tableHeight}
      organizationActions={props.organizationActions}
      organizationsBaseUrl={props.organizationsBaseUrl}
    />
  );

  return (
    <>
      {!encloseInWidgetBox ? (
        content
      ) : (
        <WidgetBox
          {...props}
          title={title}
          titleLinkUrl={titleLinkUrl}
          contentLinkUrl={contentLinkUrl}
        >
          {content}
        </WidgetBox>
      )}
    </>
  );
};

function serializedConfigToProps(
  config: OrganizationsWidgetConfigSerialized,
): OrganizationsWidgetProps {
  let totalItems: number = null;
  let totalPages: number = null;
  let orgs = null;
  if (!isNil(config.organizations)) {
    orgs =
      jsonApiResourceCollectionToFlatObjects<OrganizationJSONAPIAttributes>(
        config.organizations,
      );
    totalItems = toInteger(config.organizations?.meta?.record_count);
    totalPages = toInteger(config.organizations?.meta?.page_count);
  }

  return merge(widgetBoxPropsFromSerializedConfig(config), {
    enableSearch: config.enable_search,
    tableDensity: config.density,
    pageSize: config.page_size,
    pageNumber: config.page_number,
    tableHeight: config.table_height,
    organizationsBaseUrl: config.base_url,
    totalItems,
    totalPages,
    organizationActions: config.actions,
  } as OrganizationsWidgetProps);
}

export const OrganizationsWidgetDefinition: SialogicWidgetDefinition<
  typeof OrganizationsWidget,
  typeof serializedConfigToProps
> = {
  Component: OrganizationsWidget,
  serializedConfigToProps: serializedConfigToProps,
};
