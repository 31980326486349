import { Edit, HighlightOff } from "@mui/icons-material";
import { Box, Grid, IconButton, TextField } from "@mui/material";
import * as JSONAPI from "jsonapi-typescript";
import { defaultTo, isEmpty, isNil, omit, toNumber, toString } from "lodash";
import * as React from "react";
import { Component } from "react";

import { Root, createRoot } from "react-dom/client";
import { translatedAttributeName } from "../../i18n/translation_helper";
import {
  ModelErrors,
  extractErrorsFromJsonApi,
  jsonApiSingleResourceToFlatObject,
  modelPropertyError,
} from "../../json_api/jsonapi_tools";
import {
  OrganizationJSONAPIAttributes,
  OrganizationJSONObject,
} from "../../json_api/organization";
import { Organization } from "../../models/organization";
import {
  OrganizationRelation,
  OrganizationRelationType,
} from "../../models/organization_relation";
import { ASFileUpload, UploadDelegate } from "../../utils/file_uploader";
import { HttpError, RequestMethod, sendData } from "../../utils/jquery_helper";
import { buildJsonApiSubmitData } from "../../utils/jsonapi_form_tools";
import { redirectTo } from "../../utils/redirection";
import {
  organizationPath,
  organizationsPath,
  relatedOrganizationsPath,
} from "../../utils/urls";
import { AppContext } from "../common/app_context/app_context_provider";
import { SialogicContext } from "../common/app_context/app_context_provider.types";
import { AppRoot } from "../common/app_root";
import { Dropzone } from "../common/file_dropzone/dropzone";
import { FixedBottomArea } from "../common/fixed_bottom_area";
import { FloatingButtons } from "../common/floating_buttons";
import { IBox, IBoxContent, IBoxTitle } from "../common/ibox";
import { LoadingWrapper } from "../common/loading_wrapper";
import { OrganizationAvatar } from "../common/organization_icon";

import {
  GeocoderResultAddress,
  LocationPicker,
} from "../locations/location_picker";

import { mapGeoSearchAddressToLocation } from "../../queries/location_data";

type OperationMode = "show" | "edit" | "new" | "new-related";
type RequestMode = "create" | "update";
export interface OrganizationFormProps {
  organizationId?: number;
  organization: Organization | null;
  mode?: OperationMode;
  readOnly?: boolean;
  parentOrganization?: Organization | null;
  relationType?: OrganizationRelationType;
  relationExtRef?: string;
  submitUrl?: string;
}

type OrganizationFormErrors = ModelErrors<OrganizationJSONObject>;

export interface OrganizationFormState {
  isProcessing: boolean;
  mode: OperationMode;
  readOnly: boolean;
  organization: Organization;
  organizationRelation: Partial<OrganizationRelation>;
  editIcon: boolean;
  errors?: OrganizationFormErrors;
  uploadPercent?: number;
  lastUpload?: ASFileUpload;
  uploadedIconBlobId: string;
}

export class OrganizationForm extends Component<
  OrganizationFormProps,
  OrganizationFormState
> {
  static defaultProps: OrganizationFormProps = {
    organization: null,
    organizationId: null,
    mode: "new",
    readOnly: false,
    relationType: null,
    parentOrganization: null,
  };

  static contextType?: React.Context<SialogicContext> = AppContext;

  context!: React.ContextType<typeof AppContext>;
  constructor(props: OrganizationFormProps) {
    super(props);
    let operationMode = props.mode;
    if (isNil(props.mode)) {
      if (isNil(props.organizationId) && isNil(props.organization?.id)) {
        operationMode = "new";
      } else {
        operationMode = props.readOnly === true ? "show" : "edit";
      }
    }
    const org = defaultTo(props.organization, {
      id: null,
    });
    this.state = {
      isProcessing: false,
      readOnly: false,
      organization: org,
      editIcon: false,
      organizationRelation: isNil(props.relationType)
        ? null
        : {
            rel_type: props.relationType,
            ext_ref: props.relationExtRef,
          },
      mode: operationMode,
      errors: this.validate(org),
      uploadPercent: null,
      lastUpload: null,
      uploadedIconBlobId: null,
    };
  }

  validate(org: Organization): OrganizationFormErrors {
    const nameError = isEmpty(org?.name)
      ? I18n.t("errors.messages.blank")
      : null;

    const prefixError = isEmpty(org?.prefix)
      ? I18n.t("errors.messages.blank")
      : null;

    const oldErrors = { ...this.state?.errors };
    if (nameError === null) {
      delete oldErrors.name;
    } else {
      oldErrors.name = [nameError];
    }
    if (isNil(prefixError)) {
      delete oldErrors.prefix;
    } else {
      oldErrors.prefix = [prefixError];
    }
    return oldErrors;
  }

  render(): React.ReactNode {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <IBox>
            <IBoxTitle>
              <h5>{I18n.t("frontend.organizations.form.heading")}</h5>
            </IBoxTitle>
            <IBoxContent>
              <LoadingWrapper loading={this.state.isProcessing}>
                {this.state.isProcessing ? null : (
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Box justifyContent="center" display="flex">
                        {this.state.editIcon &&
                        this.state.readOnly === false ? (
                          <>
                            <Dropzone
                              fileSelectionChanged={(files) =>
                                void this.handleIconChange(files[0])
                              }
                              acceptedFileTypes={[
                                "image/png",
                                "image/jpeg",
                                "image/svg+xml",
                              ]}
                              initialFiles={
                                isNil(this.state.organization?.icon_url)
                                  ? null
                                  : [this.state.organization?.icon_url]
                              }
                              filesLimit={1}
                              dropzoneText={I18n.t(
                                "frontend.organizations.form.icon_url",
                              )}
                              uploading={this.state.uploadPercent !== null}
                              uploadProgressPercent={this.state.uploadPercent}
                            />

                            <IconButton
                              size="small"
                              onClick={() => {
                                this.setState({
                                  editIcon: !this.state.editIcon,
                                });
                              }}
                            >
                              <HighlightOff />
                            </IconButton>
                          </>
                        ) : (
                          <>
                            <OrganizationAvatar
                              size="xlarge"
                              variant="square"
                              organization={this.state.organization}
                            />
                            <IconButton
                              size="small"
                              onClick={() => {
                                this.setState({
                                  editIcon: !this.state.editIcon,
                                });
                              }}
                              title={I18n.t(
                                "frontend.organizations.form.edit_icon",
                              )}
                            >
                              <Edit />
                            </IconButton>
                          </>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        title={translatedAttributeName("organization", "name")}
                        label={translatedAttributeName("organization", "name")}
                        value={toString(this.state.organization?.name)}
                        error={
                          isEmpty(this.state.organization.name) ||
                          !isEmpty(this.state.errors?.name)
                        }
                        disabled={this.state.isProcessing}
                        helperText={this.propertyHelperText("name")}
                        onChange={(event) => {
                          const org = {
                            ...this.state.organization,
                            name: event.target.value,
                          };
                          this.setState({
                            organization: org,
                            errors: this.validate(org),
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        title={translatedAttributeName(
                          "organization",
                          "prefix",
                        )}
                        label={translatedAttributeName(
                          "organization",
                          "prefix",
                        )}
                        value={toString(this.state.organization?.prefix)}
                        error={
                          isEmpty(this.state.organization.prefix) ||
                          !isEmpty(this.state.errors?.prefix)
                        }
                        disabled={this.state.isProcessing}
                        helperText={this.propertyHelperText("prefix")}
                        onChange={(event) => {
                          const org = {
                            ...this.state.organization,
                            prefix: event.target.value,
                          };
                          const errs = { ...this.state.errors };
                          delete errs.prefix;
                          this.setState({
                            organization: org,
                            errors: this.validate(org),
                          });
                        }}
                      />
                    </Grid>
                    {isNil(this.props.relationType) ? null : (
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          title={translatedAttributeName(
                            `${this.props.relationType}_organization_relation`,
                            "ext_ref",
                          )}
                          label={translatedAttributeName(
                            `${this.props.relationType}_organization_relation`,
                            "ext_ref",
                          )}
                          value={toString(
                            this.state.organizationRelation?.ext_ref,
                          )}
                          //error={!isEmpty(this.state.errors?.ext_ref)}
                          helperText={this.propertyHelperText("ext_ref")}
                          disabled={this.state.isProcessing}
                          onChange={(event) => {
                            const errs = { ...this.state.errors };
                            delete errs.ext_ref;
                            this.setState({
                              errors: errs,
                              organizationRelation: {
                                ...this.state.organizationRelation,
                                ext_ref: event.target.value,
                              },
                            });
                          }}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        title={translatedAttributeName("location", "street")}
                        label={translatedAttributeName("location", "street")}
                        value={toString(this.state.organization.street)}
                        error={!isEmpty(this.state.errors?.street)}
                        helperText={this.propertyHelperText("street")}
                        disabled={this.state.isProcessing}
                        onChange={(event) => {
                          const errs = { ...this.state.errors };
                          delete errs.street;
                          this.setState({
                            errors: errs,
                            organization: {
                              ...this.state.organization,
                              street: event.target.value,
                            },
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        title={translatedAttributeName("location", "zip")}
                        label={translatedAttributeName("location", "zip")}
                        value={toString(this.state.organization.zip)}
                        error={!isEmpty(this.state.errors?.zip)}
                        disabled={this.state.isProcessing}
                        helperText={this.propertyHelperText("zip")}
                        onChange={(event) => {
                          const errs = { ...this.state.errors };
                          delete errs.zip;
                          this.setState({
                            errors: errs,
                            organization: {
                              ...this.state.organization,

                              zip: event.target.value,
                            },
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        title={translatedAttributeName("location", "city")}
                        label={translatedAttributeName("location", "city")}
                        value={toString(this.state.organization.city)}
                        error={!isEmpty(this.state.errors?.city)}
                        disabled={this.state.isProcessing}
                        helperText={this.propertyHelperText("city")}
                        onChange={(event) => {
                          const errs = { ...this.state.errors };
                          delete errs.city;
                          this.setState({
                            errors: errs,
                            organization: {
                              ...this.state.organization,
                              city: event.target.value,
                            },
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <LocationPicker
                        location={{
                          lat: this.state.organization.lat,
                          lon: this.state.organization.lon,
                          title: this.state.organization.name,
                          street: this.state.organization.street,
                          city: this.state.organization.city,
                          zip: this.state.organization.zip,
                        }}
                        localizableItem={this.state.organization}
                        saveLocationOnChange={false}
                        onLocationSelected={(newLocation) => {
                          this.setState({
                            organization: {
                              ...this.state.organization,
                              lat: newLocation.lat,
                              lon: newLocation.lon,
                            },
                          });
                        }}
                        searchResultApplied={(
                          searchResult: GeocoderResultAddress,
                        ) => {
                          const newOrg: OrganizationJSONObject = {
                            ...this.state.organization,
                          };

                          this.setState({
                            organization: mapGeoSearchAddressToLocation(
                              searchResult,
                              newOrg,
                            ),
                          });
                        }}
                      ></LocationPicker>
                    </Grid>
                  </Grid>
                )}
              </LoadingWrapper>
              <FixedBottomArea id="fixed-bottom-area">
                {!this.state.readOnly && (
                  <FloatingButtons
                    isProcessing={this.state.isProcessing}
                    onSubmit={() => {
                      void this.onSubmit();
                    }}
                    onCancel={() => {
                      void this.onCancel();
                    }}
                    disableSave={
                      this.state.isProcessing || !isEmpty(this.state.errors)
                    }
                    showScrollToTopBtn={true}
                    saveTitle={I18n.t(
                      "frontend.organizations.form.submit_title",
                    )}
                  />
                )}
              </FixedBottomArea>
            </IBoxContent>
          </IBox>
        </Grid>
      </Grid>
    );
  }

  propertyHelperText(
    propertyName: keyof Organization | keyof Location,
  ): string {
    return modelPropertyError(this.state.errors, propertyName);
  }

  async onSubmit(): Promise<void> {
    const { submitData, mode } = this.buildSubmitData();

    let url = this.props.submitUrl;
    let method: RequestMethod = "POST";
    ({ url, method } = this.requestUrlAndMethod(url, mode, method));

    try {
      this.setState({ isProcessing: true });
      const result = await sendData(url, submitData, method);

      this.handleRequestSuccess(mode, result);
    } catch (err) {
      this.handleRequestError(mode, err);
    }
  }

  private handleRequestSuccess(mode: RequestMode, resultData: unknown) {
    if (mode === "create") {
      void toasts.success(I18n.t("base.successfully_created"));
    } else {
      void toasts.success(I18n.t("base.successfully_updated"));
    }

    const org = jsonApiSingleResourceToFlatObject(
      resultData as JSONAPI.SingleResourceDoc<
        string,
        OrganizationJSONAPIAttributes
      >,
    );
    this.setState({ isProcessing: false, organization: org });
    redirectTo(defaultTo(this.context.referrer, organizationsPath()));
  }

  private handleRequestError(mode: string, err: any) {
    const errors: OrganizationFormErrors = extractErrorsFromJsonApi<
      Location | Organization
    >((err as HttpError).request?.responseJSON as JSONAPI.DocWithErrors);

    if (mode === "create") {
      void toasts.error(I18n.t("base.error"), I18n.t("base.error_creating"));
    } else {
      void toasts.error(I18n.t("base.error"), I18n.t("base.error_updating"));
    }

    this.setState({ isProcessing: false, errors });
  }

  private requestUrlAndMethod(
    url: string,
    mode: RequestMode,
    method: RequestMethod,
  ) {
    if (isNil(url) || isEmpty(url)) {
      if (mode === "create") {
        if (
          !isNil(this.props.relationType) &&
          !isEmpty(this.props.relationType) &&
          !isNil(this.props.parentOrganization?.id)
        ) {
          url = relatedOrganizationsPath(
            this.props.parentOrganization.id,
            this.props.relationType,
            "json",
          );
        } else {
          url = organizationsPath("json");
        }
        method = "POST";
      } else {
        url = organizationPath(this.state.organization.id, "json");
        method = "PATCH";
      }
    }
    return { url, method };
  }
  private async handleIconChange(icon: File) {
    if (isNil(icon)) {
      return;
    }

    if (this.state.lastUpload?.file?.name === icon.name) {
      return;
    }

    try {
      const uploadDelegate: UploadDelegate = {
        onStart: (upload) => {
          this.setState({ uploadPercent: 0 });
        },
        onProgress: (progress, upload) => {
          this.setState({ uploadPercent: progress * 100 });
        },
        onComplete: (upload) => {
          this.setState({ uploadPercent: null });
        },
      };
      const upload = new ASFileUpload(icon, uploadDelegate);
      // set the currently running upload
      this.setState({ lastUpload: upload });
      const blob = await upload.upload();

      this.setState({
        organization: {
          ...this.state.organization,
        },
        // set the signed blob id as it is assignable to the organization icon
        uploadedIconBlobId: blob.signed_id,
      });
    } catch (error) {
      void toasts.error(I18n.t("frontend.error_uploading_file"));
    }
  }
  private buildSubmitData(): {
    mode: RequestMode;
    submitData:
      | JSONAPI.SingleResourceDoc<string, OrganizationJSONObject>
      | undefined;
  } {
    const { submitData, mode } = buildJsonApiSubmitData<
      OrganizationJSONObject & { icon_signed_blob_id?: string }
    >(
      {
        ...omit(this.state.organization as OrganizationJSONObject, [
          "icon_url",
          "icon",
          "organization_user_group_id",
        ]),
        icon_signed_blob_id: this.state.uploadedIconBlobId,
      },
      "organizations",
    );

    if (!isNil(this.state.organizationRelation)) {
      submitData.data.attributes.parent_relation = {
        rel_type: this.state.organizationRelation.rel_type,
        ext_ref: this.state.organizationRelation.ext_ref,
      };
    }
    return { submitData, mode };
  }

  onCancel(): void {
    this.setState({ isProcessing: false });
    redirectTo(this.context.referrer);
  }
}

const organizationFormRoots: Root[] = [];
/**
 * Initialize react component WidgetEditorFor within all elements with data-toggle="widget-editor-form".
 * Initial state is loaded from "data-role-definition" and "data-form-url".
 * State is expected to be in JSON format.
 */
export function initializeOrganizationForm(
  selector: JQuery = $('[data-toggle="organization-form"]'),
): void {
  selector.each((_i, element) => {
    const jqElement = $(element);
    const organizationId = jqElement.data("organization-id") as string;

    const organization: JSONAPI.SingleResourceDoc<
      string,
      OrganizationJSONAPIAttributes
    > = jqElement.data("organization") as JSONAPI.SingleResourceDoc<
      string,
      OrganizationJSONAPIAttributes
    >;
    const parentOrganization: JSONAPI.SingleResourceDoc<
      string,
      OrganizationJSONAPIAttributes
    > = jqElement.data("parent-organization") as JSONAPI.SingleResourceDoc<
      string,
      OrganizationJSONAPIAttributes
    >;
    const relationType = jqElement.data(
      "relation-type",
    ) as OrganizationRelationType;
    const root = createRoot(element);
    root.render(
      <AppRoot>
        <OrganizationForm
          organization={
            jsonApiSingleResourceToFlatObject(
              organization,
            ) as any as Organization
          }
          organizationId={
            isNil(organizationId) ? null : toNumber(organizationId)
          }
          parentOrganization={
            jsonApiSingleResourceToFlatObject(
              parentOrganization,
            ) as any as Organization
          }
          relationType={relationType}
        />
      </AppRoot>,
    );
    organizationFormRoots.push(root);
  });
}

export function destroyOrganizationForm(): void {
  organizationFormRoots.forEach((root) => {
    root.unmount();
  });
  organizationFormRoots.length = 0;
}
