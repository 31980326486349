import { Grid } from "@mui/material";
import { has, isEmpty } from "lodash";
import * as React from "react";
import { FunctionComponent } from "react";
import { DashboardProps } from "./dashboard.types";
import { DashboardWidgetRow } from "./dashboard_widget_row";

const sidebarWidth = { xs: 12, lg: 3, xl: 3 };

export const Dashboard: FunctionComponent<DashboardProps> = (props) => {
  if (!props.dashboardConfig) return null;
  // apply the column sizes from the dashboard config if they exist, otherwise use the defaults
  const sizes = React.useMemo(() => {
    const left_sidebar = {
      xs:
        props.dashboardConfig.column_sizes?.left_sidebar?.xs || sidebarWidth.xs,
      lg:
        props.dashboardConfig.column_sizes?.left_sidebar?.lg || sidebarWidth.lg,
      xl:
        props.dashboardConfig.column_sizes?.left_sidebar?.xl || sidebarWidth.xl,
    };
    const right_sidebar = {
      xs:
        props.dashboardConfig.column_sizes?.right_sidebar?.xs ||
        sidebarWidth.xs,
      lg:
        props.dashboardConfig.column_sizes?.right_sidebar?.lg ||
        sidebarWidth.lg,
      xl:
        props.dashboardConfig.column_sizes?.right_sidebar?.xl ||
        sidebarWidth.xl,
    };
    const center = {
      xs: 12,
      lg: 12 - left_sidebar.lg - right_sidebar.lg,
      xl: 12 - left_sidebar.xl - right_sidebar.xl,
    };
    return {
      left_sidebar,
      center,
      right_sidebar,
    };
  }, [props.dashboardConfig.column_sizes]);

  const leftSidebar = props.dashboardConfig.columns?.left_sidebar;
  const centerColumns = props.dashboardConfig.columns?.center;
  const rightSidebar = props.dashboardConfig.columns?.right_sidebar;
  const hasLeftSidebar = !isEmpty(leftSidebar);

  const hasRightSidebar = !isEmpty(rightSidebar);

  let numSidebars = hasLeftSidebar ? 1 : 0;
  numSidebars += hasRightSidebar ? 1 : 0;

  // If there are no sidebars, the center column should take up the full width or the rest of the width if there is only one sidebar
  const centerWidths = React.useMemo(() => {
    return {
      xs: sizes.center.xs,
      lg:
        sizes.center.lg +
        (hasLeftSidebar ? 0 : sizes.left_sidebar.lg) +
        (hasRightSidebar ? 0 : sizes.right_sidebar.lg),
      xl:
        sizes.center.xl +
        (hasLeftSidebar ? 0 : sizes.left_sidebar.xl) +
        (hasRightSidebar ? 0 : sizes.right_sidebar.xl),
    };
  }, [hasLeftSidebar, hasRightSidebar, sizes]);

  return (
    <Grid container spacing={2}>
      {hasLeftSidebar ? (
        <Grid
          item
          xs={sizes.left_sidebar.xs}
          lg={sizes.left_sidebar.lg}
          xl={sizes.left_sidebar.xl}
        >
          <Grid container spacing={1}>
            {leftSidebar?.map((widgets, index) => (
              <DashboardWidgetRow widgets={widgets} key={index} />
            ))}
          </Grid>
        </Grid>
      ) : null}
      <Grid item xs={centerWidths.xs} lg={centerWidths.lg} xl={centerWidths.xl}>
        <Grid container spacing={1}>
          {centerColumns?.map((widgets, index) => (
            <DashboardWidgetRow widgets={widgets} key={index} />
          ))}
        </Grid>
      </Grid>
      {hasRightSidebar ? (
        <Grid
          item
          xs={sizes.right_sidebar.xs}
          lg={sizes.right_sidebar.lg}
          xl={sizes.right_sidebar.xl}
        >
          <Grid container spacing={1}>
            {rightSidebar?.map((widgets, index) => (
              <DashboardWidgetRow widgets={widgets} key={index} />
            ))}
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};
