import React, { useState } from "react";

import { Box, Grid, Paper, Typography } from "@mui/material";
import { DeviceForm } from "../../components/devices/device_form";
import { DeviceJsonApiAttributes } from "../../json_api/device";
import { ResourcePermission } from "../../models/resource_permission";
import { IDType } from "../../utils/urls/url_utils";
import { MqttUserList } from "../../components/mqtt_users/mqtt_user_table";
interface DeviceShowPageProps {
  deviceId?: IDType;
  permissions: ResourcePermission;
}

export const DeviceShowPage: React.FC<DeviceShowPageProps> = ({
  deviceId,
  permissions,
}) => {
  return (
    <Paper>
      <Box p={2}>
        <Grid container>
          <Grid item xs={12}>
            <DeviceForm deviceId={deviceId} mode="show" />
          </Grid>
          <Grid item xs={12}>
            <MqttUserList deviceId={deviceId} />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};
